import PerformanceValidationHome from "../../../components/admin/PerformanceValidationHome";

function PerformanceValidationHomePage() {
  return (
    <>
      <PerformanceValidationHome />
    </>
  );
}

export default PerformanceValidationHomePage;
