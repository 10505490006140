import { useContext, useRef, useState } from "react";
import QnAComponent, { QnAComponentRef } from "../../common/QnAComponent";
import AdminContext from "../../../store/admin-context";
import { updateSiteActivities } from "../../../helpers/api-util";
import RoundedButton from "../../atoms/formElements/RoundedButton";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { getGuid } from "../../../helpers/ui-util";
import {
  onQuestionClearFiles,
  onQuestionFileUpload,
} from "../../common/QnASheet";
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";

export interface EnrollmentQnASheetProps {
  siteIds: any;
  acepSiteNumber: any;
  enrollmentYear: number;
  enrollmentId: string;
  siteEnrollmentId: string;
  initiativeActivityId: string;
  onSubmit: (enrollData: any) => void;
  onCancel: () => void;
  enrollmentQuesData?: any;
  enrollmentQuesAnswerData?: any;
  physicianGroupName: any;
  selectedRows?: any;
}

function EnrollmentQnASheet(props: EnrollmentQnASheetProps) {
  const {
    siteIds,
    acepSiteNumber,
    enrollmentId,
    enrollmentYear,
    siteEnrollmentId,
    initiativeActivityId,
    onSubmit,
    onCancel,
    enrollmentQuesData,
    enrollmentQuesAnswerData,
    selectedRows,
  } = props;
  const [refreshData, setRefreshData] = useState(false);
  const adminContext = useContext(AdminContext);
  const guid = getGuid();
  const folderStructure = `${enrollmentQuesData?.id}/${enrollmentQuesData?.initiativeActivity?.id}/${guid}`;
  let containerName: string;

  if (Array.isArray(acepSiteNumber)) {
    if (acepSiteNumber.length > 0) {
      containerName = acepSiteNumber[0].toString();
    } else {
      containerName = ""; // Empty string when the array is empty
    }
  } else if (typeof acepSiteNumber === "string") {
    containerName = acepSiteNumber;
  } else {
    containerName = ""; // Default value for other types
  }
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const qnaRef = useRef<QnAComponentRef>(null);

  function getData() {
    return qnaRef.current?.getData();
  }
  function validateSurvey() {
    return qnaRef.current?.validate();
  }

  function onQuestionValueChanged(sender: any, options: any) {
    try {
      return;
    } catch (ex) {
      console.log(ex);
    }
  }

  async function onEnrollmentQuestionFileUpload(sender: any, options: any) {
    try {
      waitIndicatorLoaderRef.current?.show();
      await onQuestionFileUpload(
        sender,
        options,
        containerName,
        folderStructure
      );
      waitIndicatorLoaderRef.current?.hide();
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  }
  async function onEnrollmentQuestionClearFiles(sender: any, options: any) {
    try {
      waitIndicatorLoaderRef.current?.show();
      await onQuestionClearFiles(sender, options);
      waitIndicatorLoaderRef.current?.hide();
    } catch (ex) {
      console.log(ex);
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  async function saveButtonOnClickHandler() {
    try{     
    waitIndicatorLoaderRef.current?.show();
    if (validateSurvey() == false) {
      waitIndicatorLoaderRef.current?.hide();
      return;
    }

    var surveyAnswerData = getData();

    var multiSiteActivityData: {
      siteId: any;
      enrollmentId: any;
      siteEnrollmentId: string;
      answer: string;
      status: number;
      initiativeActivityId?: any;
    }[] = [];

    siteIds.forEach((siteId: any) => {
      var siteEnrollId = null;
      if (selectedRows != null) {
        var siteEnrollment = selectedRows.find(function (row: any) {
          return row.id == siteId;
        });
        if (siteEnrollment != null) {
          siteEnrollId = siteEnrollment?.siteEnrollmentId;
        }
      }
      if (siteEnrollId == null) {
        siteEnrollId = siteEnrollmentId;
      }
      var siteActivityData = {
        siteId: siteId,
        enrollmentId: props.enrollmentId
          ? props.enrollmentId
          : enrollmentQuesData.id,
        siteEnrollmentId: siteEnrollId,
        answer: JSON.stringify(surveyAnswerData),
        status: 1,
      };

      multiSiteActivityData.push(siteActivityData);
    });
    const response = await updateSiteActivities(multiSiteActivityData);
    waitIndicatorLoaderRef.current?.hide();
    if (response.data != null && response.status == 200) {    
      onSubmit(response.data);
      adminContext.onRecordSave(true);
    }
    }catch(ex){
      console.log(ex);
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  if (enrollmentQuesData && enrollmentQuesData.initiativeActivity) {
    const activity = enrollmentQuesData.initiativeActivity;
    return (
      <>
        <DialogTitle
          style={{
            fontWeight: 600,
            fontSize: "32px",
            padding: "55px 0px 55px 55px",
          }}
        >
          Enrollment Questionnaire
        </DialogTitle>
        <DialogContent
          style={{
            padding: "0px 55px 0px 55px",
            fontSize: "16px",
            lineHeight: "18px",
          }}
        >
          <WaitIndicator ref={waitIndicatorLoaderRef} />
          <QnAComponent
            ref={qnaRef}
            activity={activity}
            answer={enrollmentQuesAnswerData?.answer}
            onQuestionValueChanged={onQuestionValueChanged}
            onQuestionFileUpload={onEnrollmentQuestionFileUpload}
            onQuestionClearFiles={onEnrollmentQuestionClearFiles}
          />

          <div>
            <DialogActions style={{ padding: "0px 0px 55px 0px" }}>
              <RoundedButton
                name="btnQuestionnaireeCancel"
                text="Cancel"
                buttonType="secondary"
                onClick={onCancel}
              />
              <RoundedButton
                name="btnQuestionnaireeSubmit"
                text="Submit"
                onClick={saveButtonOnClickHandler}
              />
            </DialogActions>
          </div>
        </DialogContent>
      </>
    );
  }

  return <p>Enrollment questionnaire not found! </p>;
}

export default EnrollmentQnASheet;
