import { Grid } from "@mui/material";
import { ChangeEvent, FormEvent, useState,useContext, useRef, useEffect } from "react";
import { Form } from "react-router-dom";
import useAppData from "../../hooks/useAppData";
import { SendEmailForNewSite,  getSites,getUserInfoFromStorage } from "../../helpers/api-util";
import SiteForm from "./SiteForm";
import RoundedButton from "../atoms/formElements/RoundedButton";
import TypeAheadList, { TypeAheadListRef } from "../atoms/formElements/TypeAheadList";
import AdminContext from "../../store/admin-context";
import WaitIndicator, { WaitIndicatorRef } from "../atoms/WaitIndicator";

function RequestToEnrollSite() {
    const adminContext = useContext(AdminContext);       
    const [siteFormDisplay, setSiteFormDisplay] = useState("none"); 
    const typeAheadListRef = useRef<TypeAheadListRef>(null);
    const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
    const [formData, setFormData] = useState({
            siteId: "",
            siteName: "",
            isEDPartOfLargeGroup: "",
            physicianGroupName: "",
            country: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zipCode: "",
        })
    const appSitesData = useAppData(async () => {
        waitIndicatorLoaderRef.current?.show();
        return await getSites();
    }, false);

    useEffect(() => {
        waitIndicatorLoaderRef.current?.hide();

    }, [appSitesData?.appData]);
    
            

          
    async function handleSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
         event.preventDefault();
          
         waitIndicatorLoaderRef.current?.show();         
            try {
                const user_info: IACEPTokenInfo =await getUserInfoFromStorage();                  
                    const newSiteRequestData: INewSiteRequestData = {
                      toEmail:"" ,//not passed it will be handled in the backend
                      fromEmail: user_info.email,
                      ccEmail: "",//not passed it will be handled in the backend
                      acepNumber: user_info.sub,//acepNumber
                      givenName: user_info.given_name,
                      familyName: user_info.family_name,
                      siteId: formData.siteId,   
                      siteName: formData.siteName,
                      isEDPartOfLargeGroup: formData.isEDPartOfLargeGroup,
                      physicianGroupName:formData.physicianGroupName,
                      country:formData.country,
                      addressLine1:formData.addressLine1,
                      addressLine2:formData.addressLine2,
                      city:formData.city,
                      state:formData.state,
                      zipCode:formData.zipCode,
                    };
                const response = await await SendEmailForNewSite(newSiteRequestData);
                if (response?.data) {
                    adminContext.onRecordSave(true, 'Your request was successfully submitted.');
                } else {
                    adminContext.onError(true, `An unexpected error has occurred, please <a target="_blank" rel="noopener noreferrer" style={{ color: "#1976D2" }} href=${process.env.REACT_APP_ENROLL_SITE_CONTACT_US_URL}>contact us</a>.`);
                }
                resetSearchQuery();
                resetForm();
                waitIndicatorLoaderRef.current?.hide();
                
            } catch (error) {
                console.error('API Error:', error);
            }
        }
    
    const validateSite = () => {

        return formData.siteId !== "";
    };
    const validateSiteDetails = () => {

            return formData?.siteName.trim() !== "" && validateIsEPDPartOflargeGroup();
    }
    const resetSearchQuery = () => {
        // Call the resetSearchQuery function in TypeAheadList
        typeAheadListRef?.current?.resetSearchQuery();
      };
   
    const validateIsEPDPartOflargeGroup= ()=>
    {
        if(formData?.isEDPartOfLargeGroup)
        {
            if(formData?.isEDPartOfLargeGroup==="Yes")
            {
                return formData?.physicianGroupName.trim() !== "" 
            }
            else if(formData?.isEDPartOfLargeGroup==="No")
            {
                return true
            }               

        }
        return false;
    }
    const setValueHandler = (e: any) => {
        if (e.id === "notFound") {
            setSiteFormDisplay("block");
            setFormData((values: any) => ({ ...values, siteId: e.id ,siteName:""}));
        }
        else {
            setSiteFormDisplay("none");
            setFormData((values: any) => ({
                ...values,
                siteId: e.id,
                siteName: e.name,
                country: e.country !== null && e.country.toLowerCase() !== "null" ? e.country : "",
                addressLine1: e.address1 !== null && e.address1.toLowerCase() !== "null" ? e.address1 : "",
                addressLine2: e.address2 !== null && e.address2.toLowerCase() !== "null" ? e.address2 : "",
                city: e.city !== null && e.city.toLowerCase() !== "null" ? e.city : "",
                state: e.state !== null && e.state.toLowerCase() !== "null" ? e.state : "",
                zipCode: e.zipCode !== null && e.zipCode.toLowerCase() !== "null" ? e.zipCode : "",
                isEDPartOfLargeGroup: "",
                physicianGroupName: e.physicianGroup !== null && e.physicianGroup.toLowerCase() !== "null" ? e.physicianGroup : "",
              }));
              
        }
        
       
    }
   
    const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        try {
         
            resetForm();
            setSiteFormDisplay("none");
           
        }
        catch (ex) {
           
        }
    }
    const resetForm = () => {   
        setFormData((values: any) => ({
            ...values,
            siteId: "",
            siteName: "",
            isEDPartOfLargeGroup: "",
            physicianGroupName: "",
            country: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            zipCode: "",
        }));
        setSiteFormDisplay("none");       
    }
    function isFormValid() {    
        if (validateSite() && siteFormDisplay === "none") {
            return true;
            
        }
        else if (validateSite() && siteFormDisplay === "block" && validateSiteDetails()) {
            return true;
        }

        return false;
    }
   
    return (


        <Form style={{ marginLeft: "25%", maxWidth: "50%", marginRight: "25%", position: "relative" }} onSubmit={handleSubmit} >
           <div style={{fontSize:"32px",fontWeight:"600" ,lineHeight:"38.4px",marginTop:"40px"}}>Request to Enroll My Site to E-QUAL
           </div>
           <div style={{fontSize:"18px",fontWeight:"400",lineHeight:"25.2px",marginTop:"20px"}}>Thanks for your interest in E-QUAL. We need more information to get your site enrolled. Please fill out the information below and we will reach out to you. If you have any questions, <a style={{ color: "#1976D2" }} target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_ENROLL_SITE_CONTACT_US_URL}>contact us</a>.
           </div>
            <Grid
                item                
                style={{ marginTop: 20 }}
            >
                <TypeAheadList
                    label="Site"
                    name="sites"
                    options={appSitesData.appData}                    
                    value={formData.siteName}
                    setValueHandler={setValueHandler}
                    handleTextFieldChange={handleTextFieldChange}
                    notFoundMessage="I do NOT see my ED site"
                    ref={typeAheadListRef}
                />
            </Grid>
            <SiteForm style={{ display: siteFormDisplay }}
            formData={formData}
            setFormData={setFormData}
            ></SiteForm>
            <Grid             
                style={{ padding: "20px 0px 20px 0px",display:"flex",justifyContent:"flex-end",flexDirection:"inherit"  }}
            >
                <RoundedButton
                    name="btnSiteSubmit"
                    text="Submit"
                    disabled={!isFormValid()}
                    onClick={handleSubmit}
                />
            </Grid>            
            <WaitIndicator ref={waitIndicatorLoaderRef}   />
           
        </Form>
    )
}
export default RequestToEnrollSite;