export const mapInitiativesGridData = (resultData: any): InitiativesEnrollment[] => {
  let initiativesGridData: InitiativesEnrollment[] = [];

  try {
    // console.log("test data", resultData);

    if (Array.isArray(resultData) && resultData.length > 0) {
      initiativesGridData = resultData.map((result: any, index: number) => {

        return {
          id: result.id,
          siteName: result.siteName,
          acepSiteNumber: result.acepSiteNumber,
          physicianGroupName: result?.physicianGroupName !== null ? result.physicianGroupName : "",
          initiativeId: result.initiativeId,
          initiativeName: result.initiativeName,
          initiativeStatusName: result.initiativeStatusName,
          openToActivity: result.openToActivity,
          siteId: result.siteId,
          siteEnrollmentId: result.siteEnrollmentId,
          lastUpdated: result.lastUpdated ? result.lastUpdated : "",
          progressPercentage: result.progressPercentage ? result.progressPercentage : 0,
        };
      });
    }
  } catch (error) {
    console.error("Error occurred while fetching Data:", error);
  }
  return initiativesGridData;
};


export const getFilteredDataByInitiativeId = (initiativesGridData: InitiativesEnrollment[], initiativeId: string): InitiativesEnrollment[] => {
  let filteredData: InitiativesEnrollment[] = [];
  try {
    if (Array.isArray(initiativesGridData) && initiativesGridData.length > 0) {
      filteredData = initiativesGridData.filter((initiative: InitiativesEnrollment) => initiative.initiativeId === initiativeId);
    }
  } catch (error) {
    console.error("Error occurred while fetching Data:", error);
  }
  return filteredData;
}

export const getSameSiteEnrollmentData = (filteredData: InitiativesEnrollment[], initiativeId: string): InitiativesEnrollment[] => {
  let sameSiteEnrollmentData: InitiativesEnrollment[] = [];
  try {
    if (Array.isArray(filteredData) && filteredData.length > 0) {
      sameSiteEnrollmentData = filteredData.filter((item: InitiativesEnrollment) => item.initiativeId === initiativeId);
    }
  } catch (error) {
    console.error("Error occurred while fetching Data:", error);
  }
  return sameSiteEnrollmentData;
}

export const filterOpenToActivityData = (initiativesGridData: InitiativesEnrollment[]): InitiativesEnrollment[] => {
  let filteredData: InitiativesEnrollment[] = [];
  try {
    if (Array.isArray(initiativesGridData) && initiativesGridData.length > 0) {
      filteredData = initiativesGridData.filter((initiative: InitiativesEnrollment) => initiative.openToActivity === true);
    }
  } catch (error) {
    console.error("Error occurred while fetching Data:", error);
  }
  return filteredData;
}
