import classes from "./AdminEnrollmentHome.module.css";
import {
  getPublishedInitiativeYears,
  getSiteEnrollments,
} from "../../helpers/api-util";
import { Grid, MenuItem, Select, Tooltip } from "@mui/material";
import RoundedButton from "../atoms/formElements/RoundedButton";
import ReactDataTable from "../atoms/tables/ReactDataTable";
import EnrollmentListItemStatus from "../common/EnrollmentListItemStatus";
import EnrollmentListItemAction from "../common/EnrollmentListItemAction";
import { useNavigate } from "react-router-dom";
import useAppData from "../../hooks/useAppData";
import {
  getUIFormattedDate,
  handleErrorReponse,
  insertUniqueIdElementInArray,
} from "../../helpers/ui-util";
import { useState } from "react";
import WaitIndicator from "../atoms/WaitIndicator";

export default function AdminEnrollmentHome() {
  const [refreshData, setrefreshData] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const navigate = useNavigate();

  const enrollmentsData = useAppData(async () => {
    return await getSiteEnrollments(year);
  }, refreshData);

  const publishedInitiativeYears = useAppData(async () => {
    var response = await getPublishedInitiativeYears();
    if (response && response.data && response.data.length > 0) {
      setYear(response.data.includes(year) ? year : response.data[0]);
      setrefreshData(!refreshData);
    }
    return response;
  }, false);

  let adminSiteEnrollmentViewColumns = [
    {
      id: 1,
      name: "Site",
      selector: (row: { siteName: string }) => {
        return row.siteName;
      },
      cell: (row: { siteName: string }) => (
        <Tooltip title={row.siteName} placement="top" arrow>
          <div className="ellipsis">{row.siteName}</div>
        </Tooltip>
      ),
      sortable: true,
      grow: 3,
      adjustWidthOnSmallerDevice: true,
      //width: "25.5%",
    },
    {
      id: 2,
      name: "Physician Group",
      selector: (row: { physicianGroupName: string }) => {
        return row.physicianGroupName;
      },
      cell: (row: { physicianGroupName: string }) => (
        <Tooltip title={row.physicianGroupName} placement="top" arrow>
          <div className="ellipsis">{row.physicianGroupName}</div>
        </Tooltip>
      ),
      sortable: true,
      grow: 2,
      adjustWidthOnSmallerDevice: true,
      //width: "13%",
    },
    // {
    //   id: 3,
    //   name: "Returning",
    //   selector: (row: { returning: string }) => row.returning,
    // },
    {
      id: 3,
      name: "Status",
      selector: (row: { siteEnrollmentStatusName: string }) =>
        row.siteEnrollmentStatusName,
      cell: (row: { siteEnrollmentStatusName: string }) => (
        <EnrollmentListItemStatus
          activityStatus={row.siteEnrollmentStatusName}
        />
      ),
      sortable: true,
       
      style: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      id: 4,
      name: "Action",
      cell: (row: any) => (
        <EnrollmentListItemAction
          siteId={row.id}
          acepSiteNumber={row.acepSiteNumber}
          enrollmentDataId={row.id}
          enrollmentYear={year}
          setRefreshData={onSuccessRefresh}
          type="admin"
          enrollmentStatus={row.siteEnrollmentStatusName}
          siteEnrollmentId={row.siteEnrollmentId}
          enrollmentId={row.enrollmentId}
          openInitiatives={enrollmentsData.appData.openInitiatives}
          selectedSitePhysicianGroupId={row.physicianGroupId}
          selectedSitePhysicianGroupName={row.physicianGroupName}
        />
      ),
      grow: 1,
      center: true,
       
    },
  ];

  function mapData(enrollData: any) {
    const resultData = enrollData;
    //var sites = resultData.sites;
    var siteInitiativeEnrollments = resultData.siteInitiativeEnrollments;
    var openInitiatives = resultData.openInitiatives;
    var initiativeColumns: any = [];
    let results: { [key: string]: any }[] = [];
    openInitiatives.forEach((element: any) => {
      var newInitiativeColumn = {
        id: element.id,
        name: element.name,
        selector: (row: any) => {
          const dateValue = new Date(row[element.name]);
          // Check if the dateValue is valid (not NaN)
          if (!isNaN(dateValue.getTime())) {
            return dateValue.toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }); // Convert Date to formatted string using date-fns
          } else {
            return ""; // Return empty string for invalid dates
          }
        },
        sortable: true,
        showOnMobileDevice: false,
        showOnTabletDevice: false,
      };
      initiativeColumns.push(newInitiativeColumn);
      insertUniqueIdElementInArray(
        adminSiteEnrollmentViewColumns,
        adminSiteEnrollmentViewColumns.length - 2,
        newInitiativeColumn
      );
    });

    var processedSiteIds = new Array();
    var isSiteEnrolledToOpenInitiative = false;
    var isSiteFilledQuestionnaire = false;
    siteInitiativeEnrollments.forEach((element: any) => {
      if (processedSiteIds.includes(element.siteId)) {
        return;
      }
      let resultElement: any = {
        id: element.siteId,
        siteName: element.siteName,
        acepSiteNumber: element.acepSiteNumber,
        physicianGroupId: element.physicianGroupId ?? "",
        physicianGroupName: element.physicianGroupName ?? "",
        siteEnrollmentId: "",
        siteEnrollmentStatusId: "",
        siteEnrollmentStatusName: "",
      };
      processedSiteIds.push(element.siteId);
      //filter the siteInitiativeEnrollments for current id
      let siteInitiativeEnrollmentsFiltered = siteInitiativeEnrollments.filter(
        (item: any) => item.siteId === resultElement.id
      );

      if (siteInitiativeEnrollmentsFiltered.length > 0) {
        var currentSiteInitiativeEnrollment =
          siteInitiativeEnrollmentsFiltered[0];
        resultElement.siteEnrollmentId =
          currentSiteInitiativeEnrollment.siteEnrollmentId;
        resultElement.enrollmentId =
          currentSiteInitiativeEnrollment.enrollmentId;
        resultElement.siteEnrollmentStatusId =
          currentSiteInitiativeEnrollment.siteEnrollmentStatusId;
        resultElement.siteEnrollmentStatusName =
          currentSiteInitiativeEnrollment.siteEnrollmentStatusName;
        isSiteEnrolledToOpenInitiative = false;
        for (let index = 0; index < initiativeColumns.length; index++) {
          const element = initiativeColumns[index];
          let filteredIniEnrollmentrecord =
            siteInitiativeEnrollmentsFiltered.filter(
              (item: any) => item.initiativeId === element.id
            );
          if (filteredIniEnrollmentrecord.length > 0) {
            if (filteredIniEnrollmentrecord[0].isEnrolled === true) {
              isSiteEnrolledToOpenInitiative = true;
              // Check if Questionnaire is filled
              isSiteFilledQuestionnaire =
                filteredIniEnrollmentrecord[0].siteEnrollmentQuestionaireId !=
                null;
              resultElement[element.name] = getUIFormattedDate(
                filteredIniEnrollmentrecord[0].enrolledOn
              );
            }
          }
        }
      }
      var enrollmentStatus = "";
      if (
        isSiteEnrolledToOpenInitiative === true &&
        isSiteFilledQuestionnaire === true
      ) {
        enrollmentStatus = "Enrolled";
      } else if (isSiteEnrolledToOpenInitiative === true) {
        enrollmentStatus = "Pending";
      } else {
        enrollmentStatus = "";
      }
      resultElement.siteEnrollmentStatusName = enrollmentStatus;

      if (
        resultElement.siteEnrollmentStatusName === "Enrolled" ||
        resultElement.siteEnrollmentStatusName === "Pending"
      ) {
        results.push(resultElement);
      }
    });

    return results;
  }

  function enrollSiteOnClickHandler(_event: any) {
    navigate("/admin/enroll-site", {
      replace: true,
      state: { enrollmentData: enrollmentsData.appData, selectedYear: year },
    });
  }
  function handleYearChange(event: any) {
    setYear(event.target.value);
    setrefreshData(!refreshData);
  }

  function onSuccessRefresh() {
    setrefreshData(!refreshData);
  }

  let resultJSX;

  if (
    enrollmentsData.loading === true ||
    publishedInitiativeYears.loading === true
  ) {
    resultJSX = (
      <div>
        <WaitIndicator show={true}></WaitIndicator>
      </div>
    );
  } else if (enrollmentsData.error) {
    resultJSX = handleErrorReponse(enrollmentsData.error);
  } else {
    const mappedData = mapData(enrollmentsData.appData);
    //const enrollmentYears = getYears();
    const enrollmentYears = publishedInitiativeYears.appData;

    resultJSX = (
      <>
        <Grid container spacing={2} display={"flex"}>
          <Grid item xs={12} justifyContent="flex-end">
            <div
              style={{
                flexDirection: "row",
                flexGrow: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div className={classes.yearSelection}>
                <Select
                  labelId="year-label"
                  id="year"
                  name="year"
                  value={year}
                  MenuProps={{
                    PaperProps: {
                      elevation: 0,
                    },
                  }}
                  onChange={handleYearChange}
                  style={{
                    fontSize: "14px",
                    lineHeight: "140%",
                    fontWeight: 600,
                    borderRadius: 100,
                    color: year ? "#1976d2" : "inherit",
                    height: "38px",
                  }}
                  fullWidth
                  disabled={enrollmentYears.length === 0}
                >
                  {enrollmentYears.map((year: any) => (
                    <MenuItem
                      key={year}
                      value={year}
                      style={{
                        fontSize: "16px",
                        backgroundColor: "transparent",
                      }}
                    >
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <RoundedButton
                  name="btnEnrollSite"
                  text="Enroll Sites"
                  onClick={enrollSiteOnClickHandler}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <ReactDataTable
              columns={adminSiteEnrollmentViewColumns}
              data={mappedData}
            ></ReactDataTable>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.container_header}>
        <div className={classes.container_header_title}>
          <h1>Enrollment</h1>
        </div>
      </div>
      <div className={classes.enrollment_container}>{resultJSX}</div>
    </div>
  );
}
