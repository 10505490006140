import Tooltip from "@mui/material/Tooltip";
import ReactDataTable from "../../atoms/tables/ReactDataTable";
import UsersListItemAction from "../../atoms/tables/UsersListItemAction";
import useAppData from "../../../hooks/useAppData";
import { getPeerChampions } from "../../../helpers/api-util";
import { forwardRef, useImperativeHandle, useState } from "react";
import WaitIndicator from "../../atoms/WaitIndicator";
import { mapPeerChampionsGridData } from "./ManageChampionsGrid";
import { handleErrorReponse } from "../../../helpers/ui-util";

export interface ManageChampionGridViewRef {
  onSuccessRefresh: () => void;
}
export interface ManageChampionGridViewProps {
  onsuccess: any;
}
const ManageChampionGridView = forwardRef<
  ManageChampionGridViewRef,
  ManageChampionGridViewProps
>((props, ref) => {
  const [refreshData, setrefreshData] = useState(false);
  const [progress, setProgress] = useState(false);
  const { appData, loading, error } = useAppData(async () => {
    const data = await getPeerChampions();
    return data;
  }, refreshData);
  const mappedData: PeerChampion[] = mapPeerChampionsGridData(appData);
  function onSuccessRefresh() {
    try {
      setrefreshData(!refreshData);
      props.onsuccess();
    } catch (ex) {
      // console.log(ex);
    }
  }
  useImperativeHandle(ref, () => ({
    onSuccessRefresh,
  }));

  const columns = [
    {
      name: "Name",
      selector: (row: { firstName: any; lastName: any }) =>
        row.firstName + " " + row.lastName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: { emailAddress: any }) => row.emailAddress,
      cell: (row: { emailAddress: any }) => (
        <a href={`mailto:${row.emailAddress}`} style={{ color: "#1976D0" }}>
          {row.emailAddress}
        </a>
      ),
      style: {
        color: "#1976D2!important",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      name: "Status",
      selector: (row: { userStatusName: any }) => row.userStatusName,
      width: "8%",
      showOnMobileDevice: false,
      showOnTabletDevice: false,
    },
    {
      name: "Site",
      selector: (row: { siteName: any }) => {
        return row.siteName;
      },
      cell: (row: { siteName: any }) => (
        <Tooltip title={row.siteName} placement="top" arrow>
          <div className={"ellipsis"}>{row.siteName}</div>
        </Tooltip>
      ),
      sortable: true,
      style: {
        paddingleft: "0px!important",
      },
      width: "25%",
      showOnMobileDevice: false,
      showOnTabletDevice: true,
       
    },
    {
      name: "Physician Group",
      selector: (row: { physicianGroupName: any }) => {
        return row.physicianGroupName;
      },
      cell: (row: { physicianGroupName: any }) => (
        <Tooltip title={row.physicianGroupName} placement="top" arrow>
          <div className={"ellipsis"}>{row.physicianGroupName}</div>
        </Tooltip>
      ),
      sortable: true,
      width: "200px",
      showOnMobileDevice: false,
      showOnTabletDevice: false,
    },
    {
      name: "Action",
      cell: (row: any) => (
        <UsersListItemAction
          userRoleId={row.id}
          onsuccess={onSuccessRefresh}
          userStatus={"remove"}
        />
      ),
      center: true,
      width: "85px",
    },
  ];
  if (loading) {
    return <WaitIndicator show={true} />;
  }
  if (error) {
    return handleErrorReponse(error);
  }
  return (
    <ReactDataTable
      columns={columns}
      data={mappedData}
      selectable={false}
      progress={progress}
    ></ReactDataTable>
  );
});
export default ManageChampionGridView;
