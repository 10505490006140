import { ClearFilesEvent } from "survey-core/typings/survey-events-api";
import { deleteFilesToAzureStorageContainer, uploadFilesToAzureStorageContainer } from "../../helpers/api-util";

export async function onQuestionFileUpload(sender: any, options: any,containerName:string, folderStructure:string) {      
    // eslint-disable-next-line array-callback-return    
    try {
     var acceptedFileTypeIndex = options.question.acceptedTypes.indexOf(options.files[0].type)
     //below code is to check for file extention for heic,mobi,mov,mus,musx,sib,key
     if(acceptedFileTypeIndex===0 && options.files[0].type.trim()==="")
     {
        var fileExtension = options.files[0].name.split('.').pop();
        var acceptedFileExtension ="heic,mobi,mov,mus,musx,sib,key,wpd"        
        if(acceptedFileExtension.indexOf(fileExtension)<0)
        {
         options.question.addError("Invalid file type.");
         options.callback("error");
         return;
        }
     }
      if(acceptedFileTypeIndex <0  ) {
       options.question.addError("Invalid file type.");
       options.callback("error");
       return;
     }
     const fileData= await  uploadFilesToAzureStorageContainer( options.files,containerName, folderStructure);
      if(fileData && fileData.data)
      {            
       options.callback(
       "success",        
         options.files.map((file:any) => {            
           return             { 
             file: file,              
             content:fileData.data.files[0].fileUri             
           };
         })
       );
      }
     } catch (error) {
       console.error("Error:", error);
     } 
 
 }
 export async function onQuestionClearFiles(sender: any, options: any) {
    try {
        if (!options.value || options.value.length === 0)
                return options.callback("success");
            if (!options.fileName && !!options.value) {
                for (const item of options.value) {
                    await deleteFile(item.content, options);                      
                }
            } else {
                const fileToRemove = options.value.find(
                    (item: { name: string; }) => item.name === options.fileName
                );
                if (fileToRemove) {
                    await deleteFile(fileToRemove.content, options);        
                } else {
                    console.error(`File with name ${options.fileName} is not found`);
                }
            }
    } catch (error) {
        console.error("Error:", error);
      } 
 }
 export async function deleteFile(fileURL: string, options: ClearFilesEvent) {
   try {          
       const name:string = fileURL;
       const response= await  deleteFilesToAzureStorageContainer(fileURL);         
       if (response.status === 200) {
           console.log(`File ${name} was deleted successfully`);
           options.callback("success");
          
       } else {
           console.error(`Failed to delete file: ${name}`);
           options.callback("error");
           
       }
   } catch (error) {
       console.error("Error while deleting file: ", error);
       options.callback("error");
   }
}  