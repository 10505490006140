import RoundedButton from "../../atoms/formElements/RoundedButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { FormEvent, useContext, useEffect, useRef, useState } from "react";
import InitiativeEnrollmentPopup, {
  InitiativeEnrollmentPopupRef,
} from "../../common/InitiativeEnrollPopup";
import classes from "./EnrollmentView.module.css";
import EnrollmentListItemAction from "../../common/EnrollmentListItemAction";
import EnrollmentListItemStatus from "../../common/EnrollmentListItemStatus";
import { CheckBoxIcon } from "../../atoms/Icons/Icon";
import {
  checkInitiativeEnrolled,
  checkQuestionnaireToFilled,
  mapSiteChampionEnrollmentData,
  openInitiativesEnrolledForSite,
} from "../../common/InitiativeEnrollment";
import MessageConfirmDialog, {
  MessageConfirmDialogRef,
} from "../../atoms/dialogs/MessageConfirmDialog";
import {  
  getSiteEnrollments,
  getEnrollmentQuestionnaireByYear,
  getEnrollmentQuestionnaireById,
  getSiteActivityBySiteEnrollmentId,
  getPublishedInitiativeYears,  
  getInitiativesEnrollments,
  getPerformanceValidationActivities,
  getUserInfoFromStorage,
  SendEmailForNewSite,
} from "../../../helpers/api-util";
import ReactDataTable from "../../atoms/tables/ReactDataTable";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import useAppData from "../../../hooks/useAppData";
import WaitIndicator from "../../atoms/WaitIndicator";
import SiteForm from "../../common/SiteForm";
import { WaitIndicatorRef } from "../../atoms/WaitIndicator";
import AdminContext from "../../../store/admin-context";
import EnrollmentQnASheet from "../../admin/questionnaire/EnrollmentQnASheet";
import { handleErrorReponse } from "../../../helpers/ui-util";

export default function EnrollmentPage() {
  const adminContext = useContext(AdminContext);
  const [formData, setFormData] = useState({
    siteId: "",
    siteName: "",
    isEDPartOfLargeGroup: "",
    physicianGroupName: "",
    country: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [isAddNewSiteDialogOpen, setAddNewSiteDialogOpen] = useState(false);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const [isQuestionnaireDialogOpen, setQuestionnaireDialogOpen] =
    useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedInitiatives, setSelectedInitiatives] = useState([]);
  const [SearchValue, setSearchValue] = useState("");
  const [siteData, setSiteData] = useState([]);
  const [enrollId, setEnrollId] = useState<any>(null);
  const [selectedSite, setSelectedSite] = useState<any[]>([]);
  const [selectedSitePhysicianGroupId, setSelectedSitePhysicianGroupId] =
    useState(null);
  const initiativeEnrollmentPopupRef =
    useRef<InitiativeEnrollmentPopupRef>(null);
  const ErrorConfirmPopupRef = useRef<MessageConfirmDialogRef>(null);
  const questionnaireErrorConfirmPopupRef =
    useRef<MessageConfirmDialogRef>(null);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [refreshData, setrefreshData] = useState(false);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [enrollmentQuesData, setEnrollmentQuesData] = useState<any>(null);
  const [enrollmentQuesAnswerData, setEnrollmentQuesAnswerData] =
    useState<any>(null);

  const enrollmentData = useAppData(async () => {
    return await getSiteEnrollments(year);
  }, refreshData);

  const initiativesEnrollmentData = useAppData(async () => {
    return await getInitiativesEnrollments();
  }, refreshData);

  const initiativeAndActivitiesData = useAppData(async () => {
    return await getPerformanceValidationActivities();
  }, refreshData);

  useEffect(() => {
    if (initiativesEnrollmentData?.appData?.length > 0) {
      var initiativesFound = {
        initiativeFound:
          initiativesEnrollmentData?.appData?.length > 0 ? true : false,
        performanceValidationEnabled:
          initiativeAndActivitiesData?.appData?.length > 0 ? true : false,
      };
      adminContext.setRecordsFound(initiativesFound);
    } else {
      var initiativesFound = {
        initiativeFound: false,
        performanceValidationEnabled: false,
      };
      adminContext.setRecordsFound(initiativesFound);
    }
  }, [initiativesEnrollmentData, initiativeAndActivitiesData, refreshData]);

  const publishedInitiativeYears = useAppData(async () => {
    var response = await getPublishedInitiativeYears();
    if (response && response.data && response.data.length > 0) {
      setYear(response.data.includes(year) ? year : response.data[0]);
      setrefreshData(!refreshData);
    }
    return response;
  }, false);

  const filterIt = (query: string, data: any[]) => {
    if (query.trim() === "") return data;
    const searchTerms = query.toLowerCase().split(" ");
    return data.filter((item) => {
      for (let prop in item) {
        if (
          item.hasOwnProperty(prop) &&
          (prop === "siteName" || prop === "physicianGroupName")
        ) {
          const propValue = item[prop];
          if (typeof propValue === "string") {
            const propValueLower = propValue.toLowerCase();
            if (searchTerms.every((term) => propValueLower.includes(term))) {
              return true;
            }
          }
        }
      }
      return false;
    });
  };

  function onSelectedRowsChange(selectedRows: any) {
    setSelectedRows(selectedRows);
    let rowsSelected: any[] = [];
    if (selectedRows != null) {
      selectedRows.forEach((element: any) => {
        rowsSelected.push(element.id);
      });
    } else {
      rowsSelected = [];
    }

    if (selectedRows && selectedRows.length == 1) {
      setSelectedSitePhysicianGroupId(selectedRows[0].physicianGroupId);
    } else {
      setSelectedSitePhysicianGroupId(null);
    }

    setSelectedSite(rowsSelected);
    setSelectedInitiatives(selectedRows);
    setSiteData(selectedRows.map((row: any) => row.id));
    const enrollmentIdArray = selectedRows.map(
      (row: any) => row.siteEnrollmentId
    );
    if (enrollmentIdArray.length > 0) {
      setEnrollId(enrollmentIdArray[0]);
    }
  }

  async function enrollQuestionnaireButtonClickHandler() {
    const questionnaireToFilled = checkQuestionnaireToFilled(
      selectedSite,
      enrollmentData.appData
    );

    if (questionnaireToFilled && selectedRows && selectedRows.length > 0) {
      const enrollmentId = selectedRows[0].enrollmentId;
      const siteEnrollmentId = selectedRows[0].siteEnrollmentId;

      if (enrollmentId != null || year != null) {
        waitIndicatorLoaderRef.current?.show();
        const enrollmentQuesData = enrollmentId
          ? await getEnrollmentQuestionnaireById(enrollmentId)
          : await getEnrollmentQuestionnaireByYear(year);

        setEnrollmentQuesData(enrollmentQuesData.data);
        const enrollmentQuesAnswerData =
          await getSiteActivityBySiteEnrollmentId(siteEnrollmentId);
        setEnrollmentQuesAnswerData(enrollmentQuesAnswerData.data);
        waitIndicatorLoaderRef.current?.hide();
        setQuestionnaireDialogOpen(true);
      }
    } else {
      questionnaireErrorConfirmPopupRef.current?.openDialog();
      return;
    }
  }

  function handleCloseDialogs() {
    setSelectedSite([]);
    setQuestionnaireDialogOpen(false);
    setrefreshData(!refreshData);
    setClearSelectedRows(!clearSelectedRows);
  }

  function handleInitiativeEnrollmentButtonClick() {
    const sameInitiativeEnrolled = checkInitiativeEnrolled(
      selectedSite,
      enrollmentData.appData
    );

    if (sameInitiativeEnrolled) {
      var enrolledInitiaties = openInitiativesEnrolledForSite(
        enrollmentData.appData,
        selectedSite[0]
      );
      var enrolledInitiatiesIds = new Array();
      if (enrolledInitiaties != null && enrolledInitiaties.length > 0) {
        enrolledInitiaties.forEach((element: any) => {
          enrolledInitiatiesIds.push(element.initiativeId);
        });
      }

      // Open the initiative enrollment dialog
      initiativeEnrollmentPopupRef.current?.setEnrolledInitiatives(
        enrolledInitiatiesIds
      );
      initiativeEnrollmentPopupRef.current?.openDialog();
    } else {
      ErrorConfirmPopupRef.current?.openDialog();
    }
  }

  function handleDiffInitiativeEnrollPopupClose() {
    setSelectedInitiatives([]);
    setSelectedSite([]);
    setSearchValue("");
    setEnrollId(null);
    setClearSelectedRows(!clearSelectedRows);
  }

  function handleErrorCloseDialogs() {
    setSelectedInitiatives([]);
    setSelectedSite([]);
    setSearchValue("");
    setEnrollId(null);
    setClearSelectedRows(!clearSelectedRows);
  }

  function handleDataRefresh() {
    setrefreshData(!refreshData);
    setClearSelectedRows(!clearSelectedRows);
    setSelectedInitiatives([]);
    setSelectedSite([]);
  }
  function handleYearChange(event: any) {
    setYear(event.target.value);
    handleDataRefresh();
  }

  function handleSubmitQuestionnaire(formData: any) {
    const answers = formData;
    // Perform any necessary actions with the questionnaire answers
    if (answers === null || answers === undefined) {
      return false;
    }
    setSelectedSite([]);
    handleCloseDialogs();
    handleDataRefresh();
  }
  const closeAddNewSiteDialog = () => {
    setAddNewSiteDialogOpen(false);
    resetForm();
  };

  const columns = [
    {
      name: "Site",
      selector: (row: { siteName: string }) => {
        return row.siteName;
      },
      cell: (row: { siteName: string }) => (
        <Tooltip title={row.siteName} placement="top" arrow>
          <div className="ellipsis">{row.siteName}</div>
        </Tooltip>
      ),
      sortable: true,
      style: {
        justifyContent: "Left !important",
      },
      width: "28%",
      adjustWidthOnSmallerDevice: true,
    },
    {
      name: "Physician Group",
      selector: (row: { physicianGroupName: string }) => {
        return row.physicianGroupName;
      },
      cell: (row: { physicianGroupName: string }) => (
        <Tooltip title={row.physicianGroupName} placement="top" arrow>
          <div className="ellipsis">{row.physicianGroupName}</div>
        </Tooltip>
      ),
      sortable: true,
      style: {
        justifyContent: "Left !important",
      },
      width: "18%",
      showOnMobileDevice: false,
      showOnTabletDevice: false,
    },
    {
      name: "Initiative",
      selector: (row: { initiativeNames: string[] }) => (
        <Tooltip title={row.initiativeNames.join(", ")} placement="top" arrow>
          <div>{row.initiativeNames.join(", ")}</div>
        </Tooltip>
      ),
      style: {
        justifyContent: "Left !important",
      },
      width: "22%",
      showOnMobileDevice: false,   
      adjustWidthOnSmallerDevice: true,    
    },
    {
      name: "Questionnaire",
      cell: (row: { siteEnrollmentQuestionaireId: any }) =>
        row.siteEnrollmentQuestionaireId ? <CheckBoxIcon /> : null,
      left: true,
      width: "125px",
      showOnMobileDevice: false,
      showOnTabletDevice: false,
    },

    {
      name: "Status",
      selector: (row: { siteEnrollmentStatusName: string }) =>
        row.siteEnrollmentStatusName,
      cell: (row: { siteEnrollmentStatusName: string }) => (
        <EnrollmentListItemStatus
          activityStatus={row.siteEnrollmentStatusName}
        />
      ),
      width: "85px",      
      style: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      name: "Action",
      cell: (row: any) => (
        <EnrollmentListItemAction
          siteId={row.id}
          acepSiteNumber={row.acepSiteNumber}
          enrollmentDataId={row.id}
          enrollmentYear={year}
          setRefreshData={handleDataRefresh}
          enrollmentStatus={row.siteEnrollmentStatusName}
          type="champion"
          siteEnrollmentId={row.siteEnrollmentId}
          openInitiatives={enrollmentData.appData.openInitiatives}
          selectedSitePhysicianGroupId={row.physicianGroupId}
          selectedSitePhysicianGroupName={row.physicianGroupName}
        />
      ),
      center: true,
      width: "85px",
      
    },
  ];
  const clearSearchFun = () => {
    setSearchValue("");
  };
  const openAddNewSiteDialog = () => {
    setAddNewSiteDialogOpen(true);
  };
  const resetForm = () => {
    setFormData((values: any) => ({
      ...values,
      siteId: "",
      siteName: "",
      isEDPartOfLargeGroup: "",
      physicianGroupName: "",
      country: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
    }));
  };
  function isFormValid() {
    if (validateIsEPDPartOflargeGroup() && validateSiteName()) {
      return true;
    }
    return false;
  }
  const validateSiteName = () => {
    return formData?.siteName.trim() !== "";
  };
  const validateIsEPDPartOflargeGroup = () => {
    if (formData?.isEDPartOfLargeGroup) {
      if (formData?.isEDPartOfLargeGroup === "Yes") {
        return formData?.physicianGroupName.trim() !== "";
      } else if (formData?.isEDPartOfLargeGroup === "No") {
        return true;
      }
    }
    return false;
  };
  async function handleSubmit(
    event: FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
   
    try {
      waitIndicatorLoaderRef.current?.show();    
      const user_info: IACEPTokenInfo = await getUserInfoFromStorage();
      const newSiteRequestData: INewSiteRequestData = {
        toEmail:"" ,//not passed it will be handled in the backend
        fromEmail: user_info.email,
        ccEmail: "",//not passed it will be handled in the backend
        acepNumber: user_info.sub,//acepNumber
        givenName: user_info.given_name,
        familyName: user_info.family_name,
        siteId: formData.siteId,   
        siteName: formData.siteName,
        isEDPartOfLargeGroup: formData.isEDPartOfLargeGroup,
        physicianGroupName:formData.physicianGroupName,
        country:formData.country,
        addressLine1:formData.addressLine1,
        addressLine2:formData.addressLine2,
        city:formData.city,
        state:formData.state,
        zipCode:formData.zipCode,
      };
      const response = await SendEmailForNewSite(newSiteRequestData);
      if (response?.data) {
        adminContext.onRecordSave(
          true,
          "Your request was successfully submitted."
        );
        waitIndicatorLoaderRef.current?.hide();
        closeAddNewSiteDialog();
      } else {
        adminContext.onError(
          true,
          `An unexpected error has occurred, please <a target="_blank" rel="noopener noreferrer" style={{ color: "#1976D2" }} href=${process.env.REACT_APP_ENROLL_SITE_CONTACT_US_URL}>contact us</a>.`
        );
        waitIndicatorLoaderRef.current?.hide();
        closeAddNewSiteDialog();
      }
      closeAddNewSiteDialog();
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  if (
    enrollmentData.loading === true ||
    publishedInitiativeYears.loading === true
  ) {
    return (
      <div>
        <WaitIndicator show={true}></WaitIndicator>
      </div>
    );
  }
  if (enrollmentData.error) {
    return handleErrorReponse(enrollmentData.error);
  }

  let mappedData: Enroll[] = mapSiteChampionEnrollmentData(
    enrollmentData.appData
  );

  const filteredData = filterIt(SearchValue, mappedData);
  const enrollmentYears = publishedInitiativeYears.appData;

  return (
    <Grid container spacing={2}>
       
        <div style={{ display: "flex", alignItems: "center",justifyContent: "flex-start",  paddingTop:'10px',minWidth:'435px',paddingLeft:'16px' }}>
          <TextField
            InputProps={{
              inputProps: {
                style: {
                  padding: 5,
                  borderRadius: 0,
                  marginLeft: "10px",
                  fontSize: "16px",
                  opacity: 0.6,
                  
                  
                },
              },
              style: { borderRadius: 20, width: "100%", height: "38px"},
              endAdornment: (
                <InputAdornment position="end">
                  {SearchValue && (
                    <IconButton
                      aria-label="clear search"
                      onClick={clearSearchFun}
                      edge="end"
                    >
                      <CloseRoundedIcon sx={{ color: "#000000" }} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            autoFocus={true}
            required
            id="search"
            name="search"
            value={SearchValue}
            fullWidth
            onChange={(r) => setSearchValue(r.target.value)}
            placeholder="Search by Site and Physician Group"
          />
         
        </div>
       
        <div
              style={{              
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start", 
                paddingLeft:'16px',paddingTop:'10px'              
              }}
            >
              <div className={classes.yearSelection}>
                <Select
                  labelId="year-label"
                  id="year"
                  name="year"
                  value={year}
                  MenuProps={{
                    PaperProps: {
                      elevation: 0,
                    },
                  }}
                  onChange={handleYearChange}
                  style={{
                    fontSize: "14px",
                    lineHeight: "140%",
                    fontWeight: 600,
                    borderRadius: 100,
                    color: year ? "#1976d2" : "inherit",
                    height: "38px",
                  }}
                  fullWidth
                  disabled={enrollmentYears.length === 0}
                >
                  {enrollmentYears.map((year: any) => (
                    <MenuItem
                      key={year}
                      value={year}
                      style={{ fontSize: "16px", backgroundColor: "transparent" }}
                    >
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </div>
        </div>
      
        <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              paddingLeft:'16px',paddingTop:'10px' 
            }}
          >
            
              <RoundedButton
                name="btnEnrollment"
                text="Enrollment Questionnaire"
                onClick={enrollQuestionnaireButtonClickHandler}
                disabled={selectedSite.length === 0}
                className={classes.enrollQuestButton}
              />
            
        </div>
     
        <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingLeft:'16px',paddingTop:'10px' 
        }}

      >

        <div  >              
          <RoundedButton
            name="btnInitiative"
            text="Initiative Enrollment"
            onClick={handleInitiativeEnrollmentButtonClick}
            disabled={selectedSite.length === 0}
            className={classes.initiativeButton}
          />
        </div>
      </div>
       
        <div style={{               
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              paddingLeft:'16px',paddingTop:'10px' 
            }}>
       
            <div  >
              <RoundedButton
                name="btnNewSite"
                text="Add New Site"
                onClick={openAddNewSiteDialog}
                buttonType="secondary"
              />
            </div>       
        </div>
     
      <Grid item xs={12}>
        <ReactDataTable
          selectable={true}
          columns={columns}
          data={filteredData}
          onSelectedRowsChange={onSelectedRowsChange}
          clearSelectedRows={clearSelectedRows}
        ></ReactDataTable>
      </Grid>
      <Dialog
        open={isAddNewSiteDialogOpen}
        onClose={closeAddNewSiteDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          style={{
            fontWeight: 600,
            fontSize: "32px",
            lineHeight: "40px",
            padding: "55px 0px 25px 55px",
          }}
        >
          Add New Site
        </DialogTitle>
        <DialogContent style={{ padding: "0px 55px 0px 55px" }}>
          <SiteForm formData={formData} setFormData={setFormData} />
        </DialogContent>
        <DialogActions style={{ padding: "55px 55px 55px 0px" }}>
          <RoundedButton
            name="btnCancel"
            text="Cancel"
            onClick={closeAddNewSiteDialog}
            buttonType="secondary"
          />
          <RoundedButton
            name="btnSubmit"
            text="Submit"
            disabled={!isFormValid()}
            onClick={handleSubmit}
          />
        </DialogActions>
        <WaitIndicator ref={waitIndicatorLoaderRef} />
      </Dialog>

      <Dialog
        open={isQuestionnaireDialogOpen}
        onClose={handleCloseDialogs}
        fullWidth
        maxWidth="md"
      >
        <EnrollmentQnASheet
          selectedRows={selectedRows}
          acepSiteNumber={
            selectedRows && selectedRows[0]
              ? selectedRows[0].acepSiteNumber
              : null
          }
          siteIds={siteData}
          enrollmentYear={year}
          onSubmit={handleSubmitQuestionnaire}
          onCancel={handleCloseDialogs}
          enrollmentId={
            selectedRows && selectedRows[0]
              ? selectedRows[0].enrollmentId
              : null
          }
          siteEnrollmentId={
            selectedRows && selectedRows[0]
              ? selectedRows[0].siteEnrollmentId
              : null
          }
          initiativeActivityId=""
          enrollmentQuesData={enrollmentQuesData}
          enrollmentQuesAnswerData={enrollmentQuesAnswerData}
          physicianGroupName={
            selectedRows && selectedRows[0]
              ? selectedRows[0].physicianGroupName
              : null
          }
        />
      </Dialog>
      <InitiativeEnrollmentPopup
        ref={initiativeEnrollmentPopupRef}
        onClose={handleDataRefresh}
        selectedSite={selectedSite}
        enrollmentType="enroll"
        selectedYear={year}
        onsuccess={handleDataRefresh}
        userRole="champion"
      />
      <MessageConfirmDialog
        ref={ErrorConfirmPopupRef}
        onConfirm={handleDiffInitiativeEnrollPopupClose}
        onClose={handleErrorCloseDialogs}
        children="The sites you have selected are already 
          enrolled into different initiatives, please update your selection and try again."
      />
      <MessageConfirmDialog
        ref={questionnaireErrorConfirmPopupRef}
        onConfirm={handleDiffInitiativeEnrollPopupClose}
        onClose={handleErrorCloseDialogs}
        children="At least 1 of the selected sites has completed the Enrollment Questionnaire. Please update your selection and try again."
      />
      <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
    </Grid>
  );
}
