import { getInitiativeById } from "../../../../helpers/api-util";
import { handleErrorReponse } from "../../../../helpers/ui-util";
import useAppData from "../../../../hooks/useAppData";
import RoundedButton from "../../../atoms/formElements/RoundedButton";
import WaitIndicator from "../../../atoms/WaitIndicator";
import WizardHeader from "../../../atoms/WizardHeader";

import classes from "./ActivitySettingsHeader.module.css";

function ActivitySettingsHeader(props: {
  initiativeId: string;
  enableAddActivity: boolean;
  addActivityOnClick: any;
  refreshData: boolean;
}) {
  const { appData, loading, error } = useAppData(async () => {
    return await getInitiativeById(props.initiativeId);
  }, props.refreshData); 
  if (loading) {
    return (
      <div>
        <WaitIndicator show={true}></WaitIndicator>
      </div>
    );
  }
  if (error) {
    const errorJSX = handleErrorReponse(error);
    return <>{errorJSX}</>;
  }
  const initiativeName = appData.name;
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <WizardHeader title="Activity Settings" />
        <div className={classes.button}>
          <RoundedButton
            disabled={props.enableAddActivity}
            name="btnAddActivity"
            text="Add Activity"
            buttonType="secondary"
            onClick={props.addActivityOnClick}
          />
        </div>
      </div>
      <div className={classes.subHeader}>
        <h3>{initiativeName}</h3>
      </div>
      <div className={classes.tableHeader}>
        <div className={classes.tableHeaderName}>
          <h5>Activity Name</h5>
        </div>
        <div className={classes.tableHeaderStartDate}>
          <h5>Start Date</h5>
        </div>
        <div className={classes.tableHeaderEndDate}>
          <h5>End Date</h5>
        </div>
      </div>
    </div>
  );
}

export default ActivitySettingsHeader;
