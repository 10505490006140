import { RouterProvider } from "react-router-dom";
import { adminRoutes } from "./routes/admin-routes";
import { championRoutes } from "./routes/champion-routes";
import { unAuthenticatedRoutes } from "./routes/unauthenticated-routes";
import AcepMaster from "./helpers/acep-master";
import { useState } from 'react';
export interface appProps {
  userInfo: IEQUALTokenInfo;
}

function NewApp(props: appProps) {
  const [isAcepMasterLoaded, setAcepMasterLoaded] = useState(false);
  const handleAcepMasterLoad = () => {
    setAcepMasterLoaded(true);
  };

  const user_info = props.userInfo;
  const userRoles = user_info.roles;
  //Search in the roles array for the role that contains the word "admin"
  const isAdmin =
    userRoles.find((role) => role.toLowerCase().includes("admin")) === "Admin";
  const isChampion =
    userRoles.find((role) => role.toLowerCase().includes("champion")) ===
    "Champion";

   
    return (     
       <AcepMaster onAcepMasterLoad={handleAcepMasterLoad}> 
       { isAcepMasterLoaded  &&  <RouterProvider router={isAdmin ? adminRoutes : isChampion ? championRoutes : unAuthenticatedRoutes} />  }
      </AcepMaster>     
  );
   
  
}

export default NewApp;
