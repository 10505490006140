import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { getUpcomingEnrolledInitiativeActivitiesForChampion } from "../../../../helpers/api-util";
import classes from "./UpcomingDatesHome.module.css";
import useAppData from "../../../../hooks/useAppData";
import { mapUpcomingDatesHomeData } from "./UpcomingDates";
import WaitIndicator from "../../../atoms/WaitIndicator";
import {
  getUIFormattedDate,
  handleErrorReponse,
} from "../../../../helpers/ui-util";

interface UpcomingDatesHomeProps {
  allowRedirect?: boolean;
}
export default function UpcomingDatesHome(props: UpcomingDatesHomeProps) {
  const [refreshData, setRefreshData] = useState(false);

  const { appData, loading, error } = useAppData(async () => {
    return await getUpcomingEnrolledInitiativeActivitiesForChampion();
  }, refreshData);
  const mappedData = mapUpcomingDatesHomeData(appData);
  const filteredData = mappedData.filter(
    (activity) => new Date(activity.activityEndDate) >= new Date()
  );
  const hasUpcomingDates = appData && filteredData.length > 0;

  if (!hasUpcomingDates) {
    return null; // Return null to hide the entire container when there are no upcoming dates
  }

  if (loading) {
    return <WaitIndicator show={true} />;
  }
  if (error) {
    return handleErrorReponse(error);
  } else if (!appData || filteredData.length === 0) {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.container}>
          <div className={classes.noDataMessage}>
            {appData
              ? "There are no upcoming dates for your enrolled initiatives."
              : "No data available. Please make sure you are enrolled in initiatives."}
          </div>
        </CardContent>
      </Card>
    );
  }

  const calculateDaysRemaining = (endDate: any) => {
    const today = new Date();
    const end = new Date(endDate);
    const timeDifference = end.getTime() - today.getTime();
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysRemaining;
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.container}>
        <div className={classes.LongTitle}>Upcoming Dates</div>
        <div
          className={classes.rowContainer}
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            gap: "20px",
            maxWidth: "100%",
          }}
        >
          {filteredData.slice(0, 3).map((activity, index) => (
            <Card
              key={`${activity.activityId}-${index}`}
              className={classes.cardList}
              sx={{
                flexBasis: "100%",
                maxWidth: {
                  xs: "100%", // 1 card per row on xs 0-600px
                  sm: "100%", // 1 card per row on sm 600-900px
                  md: "calc(33.33% - 16px)", // 3 cards per row on md 900-1200px
                  lg: "calc(33.33% - 16px)", // 3 cards per row on lg 1200-1536px
                  xl: "calc(33.33% - 16px)", // 3 cards per row on xl screens (1536px and above)
                },
              }}
            >
              <CardContent sx={{ padding: "0!important" }}>
                
                {/* add one more class of ellipses <div className={classes.initiativeName} && "ellipses"  > */}
                <div className={`${classes.initiativeName} ${"ellipsis"}`}>               
                  {activity.initiativeName}
                </div>
                <div className={classes.activityDescription}>
                  <span>{activity.activityName}</span>
                </div>
                <div className={classes.activityEnded}>
                  Ends in {calculateDaysRemaining(activity.activityEndDate)}{" "}
                  {calculateDaysRemaining(activity.activityEndDate) === 1
                    ? "day"
                    : "days"}
                </div>
                <div
                  className={classes.activityDate}
                  style={{
                    color:
                      new Date(activity.activityEndDate) < new Date()
                        ? "red"
                        : "inherit",
                  }}
                >
                  {getUIFormattedDate(activity.activityEndDate)}
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
