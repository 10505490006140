import classes from "./UpdateActivity.module.css";
import ActivityForm from "../activity-form/ActivityForm";
import { useContext, useState, useRef } from "react";
import AdminContext from "../../../../store/admin-context";
import ActivityFormHeader from "../activity-form/ActivityFormHeader";
import {
  deleteInitiativeActivity,
  updateInitiativeActivity,
} from "../../../../helpers/api-util";
import DeleteConfirmDialog from "../../../atoms/dialogs/DeleteConfirmDialog";
import SaveConfirmDialog from "../../../atoms/dialogs/SaveConfirmDialog";
import { getFormattedDate, scrollToTop } from "../../../../helpers/ui-util";
import WaitIndicator, { WaitIndicatorRef } from "../../../atoms/WaitIndicator";

function UpdateActivity(props: any) {
  const [activityData, setActivityData] = useState({});
  const [confirmSaveOpen, setConfirmSaveOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const adminContext = useContext(AdminContext);

  const { activity } = props;

  const updateActivityData = {
    id: activity.id,
    name: activity.name,
    startDate: getFormattedDate(activity.startDate),
    endDate: getFormattedDate(activity.endDate),
    longSummary: activity.longSummary,
    includePerformanceValidation: activity.includePerformanceValidation,
    performanceValidationName: activity.performanceValidationName,
    isOptional: activity.isOptional,
  };

  async function saveOnClickHandler(activityData: any) {
    try {
      if (activityData === null) {
        adminContext.onError(true);
      } else {
        waitIndicatorLoaderRef.current?.show();
        const response = await updateInitiativeActivity(activityData);
        waitIndicatorLoaderRef.current?.hide();
        if (response.data != null) {
          adminContext.onRecordSave(true, "Activity Saved");
          props.saveOnClick(true);
          scrollToTop();
          return;
        }
        if (response.error != null) {
          var status = response.error?.response?.status;
          if (status === 400) {
            adminContext.onError(true, "Bad Request");
            scrollToTop();
            return;
          }
          adminContext.onError(true, "Error Saving Activity");
          scrollToTop();
          return;
        }
      }
    } catch (ex) {
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  async function cancelOnClickHandler(activityData: any) {
    if (activityData.isDirty === true) {
      setConfirmSaveOpen(true);
      setActivityData(activityData.data);
      scrollToTop();
    } else {
      props.cancelOnClick();
      scrollToTop();
    }
  }

  async function activityonDeleteClickHandler(event: any) {
    event.preventDefault();
    setConfirmOpen(true);
  }
  async function saveActivityAndExit() {
    try {
      waitIndicatorLoaderRef.current?.show();
      const response = await updateInitiativeActivity(activityData);
      waitIndicatorLoaderRef.current?.hide();
      if (response.data != null) {
        adminContext.onRecordSave(true, "Activity Saved");
        props.saveOnClick(true);
        scrollToTop();
        return;
      }
      if (response.error != null) {
        var status = response.error?.response?.status;
        if (status === 400) {
          adminContext.onError(true, "Bad Request");
          scrollToTop();
          return;
        }
        adminContext.onError(true, "Error Saving Activity");
        scrollToTop();
        return;
      }
    } catch (ex) {
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  async function deleteActivity() {
    try {
      waitIndicatorLoaderRef.current?.show();
      const response = await deleteInitiativeActivity(activity.id);
      waitIndicatorLoaderRef.current?.hide();
      if (response.data != null) {
        props.deleteOnClick(true);
        scrollToTop();
      }
    } catch (ex) {
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  return (
    <>
      <SaveConfirmDialog
        title="Are you sure?"
        open={confirmSaveOpen}
        setOpen={setConfirmSaveOpen}
        onConfirm={saveActivityAndExit}
      />
      <DeleteConfirmDialog
        title="Are you sure?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteActivity}
      ></DeleteConfirmDialog>
      <div className={classes.container}>
        <ActivityFormHeader
          showDelete={true}
          onDeleteClick={activityonDeleteClickHandler}
        />
        <ActivityForm
          activity={updateActivityData}
          cancelOnClick={cancelOnClickHandler}
          saveOnClick={saveOnClickHandler}
        />
      </div>
      <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
    </>
  );
}

export default UpdateActivity;
