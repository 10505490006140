import { useRef, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RoundedButton from "../../atoms/formElements/RoundedButton";
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";
import classes from "./ReportsHome.module.css";
import {
  getEnrollmentReportYear,
  downloadEnrollmentReport,
} from "../../../helpers/api-util";
import useAppData from "../../../hooks/useAppData";
import { handleErrorReponse } from "../../../helpers/ui-util";

export default function EnrollmentReportPage() {
  const [selectedInitiativeYear, setSelectedInitiativeYear] = useState();
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const [refreshData, setRefreshData] = useState(false);

  const publishedQuestionnaireAPI = useAppData(async () => {
    return await getEnrollmentReportYear();
  }, refreshData);
  if (publishedQuestionnaireAPI?.loading) {
    return (
      <>
        <div>
          <WaitIndicator show={true}></WaitIndicator>
        </div>
      </>
    );
  }
  if (publishedQuestionnaireAPI?.error) {
    const errorJSX = handleErrorReponse(publishedQuestionnaireAPI.error);
    return <>{errorJSX}</>;
  }
  const publishedQuestionnaire = publishedQuestionnaireAPI.appData;
  const handleYearChange = (event: any) => {
    setSelectedInitiativeYear(event.target.value);
  };

  const handleExportClick = async () => {
    try {
      waitIndicatorLoaderRef.current?.show();

      let enrollmentToExport = selectedInitiativeYear;
      // If no initiative is selected, use the publishedQuestionnaire  as a default
      if (!enrollmentToExport && publishedQuestionnaire) {
        enrollmentToExport = publishedQuestionnaire[0].year;
        setSelectedInitiativeYear(enrollmentToExport);
      }
      if (!enrollmentToExport) {
        console.error("No initiatives available for export");
        waitIndicatorLoaderRef.current?.hide();
        return;
      }
      var done = await downloadEnrollmentReport(enrollmentToExport);
      if (done) {
        waitIndicatorLoaderRef.current?.hide();
      } else {
        waitIndicatorLoaderRef.current?.hide();
      }
    } catch (error) {
      console.log(error);
      waitIndicatorLoaderRef.current?.hide();
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div>
          <h2 className={classes.cardTitle}>Enrollment Report</h2>
          <div className={classes.cardDescription}>
            Download to see list of sites enrolled by initiative and site level
            demographics.
          </div>
          {publishedQuestionnaire.length > 0 ? (
            <div style={{ marginTop: "24px" }}>
              <Select
                value={
                  selectedInitiativeYear ||
                  (publishedQuestionnaire && publishedQuestionnaire[0]?.year)
                }
                MenuProps={{
                  PaperProps: {
                    elevation: 0,
                  },
                }}
                onChange={handleYearChange}
                className={classes.select}
              >
                {publishedQuestionnaire.map((data: any) => (
                  <MenuItem
                    key={data.year}
                    value={data.year}
                    className={classes.menuItem}
                  >
                    {data.year}
                  </MenuItem>
                ))}
              </Select>
            </div>
          ) : (
            <div>
              <Select value="" disabled className={classes.select}>
                <MenuItem disabled />
              </Select>
            </div>
          )}
        </div>
        <div className={classes.exportBtn}>
          <RoundedButton
            name="btnExport"
            text="Export"
            onClick={handleExportClick}
            disabled={
              selectedInitiativeYear === undefined &&
              publishedQuestionnaire?.length === 0
            }
          />
        </div>
      </CardContent>
      <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
    </Card>
  );
}
