import { useContext, useRef, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RoundedButton from "../../atoms/formElements/RoundedButton";
import {
  getActiveInitiatives,
  downloadSiteDataReport,
} from "../../../helpers/api-util";
import useAppData from "../../../hooks/useAppData";
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";
import classes from "./ReportsHome.module.css";
import { handleErrorReponse } from "../../../helpers/ui-util";
import AdminContext from "../../../store/admin-context";

export interface Initiative {
  id: string;
  name: string;
  initiativeStartDate: string;
  initiativeStatus: {
    name: string;
  };
}
export default function SiteDataReportPage() {
  const [selectedInitiative, setSelectedInitiative] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const adminContext = useContext(AdminContext);

  const initiativesFromAPI = useAppData(async () => {
    return await getActiveInitiatives();
  }, refreshData);
  if (initiativesFromAPI?.loading) {
    return (
      <>
        <div>
          <WaitIndicator show={true}></WaitIndicator>
        </div>
      </>
    );
  }
  if (initiativesFromAPI?.error) {
    const errorJSX = handleErrorReponse(initiativesFromAPI.error);
    return <>{errorJSX}</>;
  }
  const filteredInitiatives = initiativesFromAPI?.appData?.filter(
    (initiative: any) => initiative.initiativeStatus?.name === "Published"
  );

  const getInitiativeWithEarliestStartDate = () => {
    if (filteredInitiatives.length > 0) {
      const filteredPublishInitiatives: Initiative[] = filteredInitiatives;
      // Sort the initiatives by their start dates in ascending order
      filteredPublishInitiatives.sort((a: Initiative, b: Initiative) => {
        const dateA: Date = new Date(a.initiativeStartDate);
        const dateB: Date = new Date(b.initiativeStartDate);
        if (dateA.getTime() === dateB.getTime()) {
          // If dates are the same, sort by initiative names
          return a.name?.localeCompare(b?.name);
        }
        return dateA.getTime() - dateB.getTime();
      });
      // Retrieve the initiative with the earliest start date (first in the sorted array)
      return filteredPublishInitiatives;
    } else {
      return null;
    }
  };

  const earliestInitiative = getInitiativeWithEarliestStartDate();

  const handleInitiativeChange = (event: any) => {
    setSelectedInitiative(event.target.value);
  };

  const handleExportClick = async () => {
    try {
      waitIndicatorLoaderRef.current?.show();
      let initiativeToExport = selectedInitiative;

      // If no initiative is selected, use the earliest initiative as a default
      if (!initiativeToExport && earliestInitiative) {
        initiativeToExport = earliestInitiative[0].id;
        setSelectedInitiative(initiativeToExport);
      }

      if (!initiativeToExport) {
        console.error("No initiatives available for export");
        waitIndicatorLoaderRef.current?.hide();
        return;
      }
      var done = await downloadSiteDataReport(initiativeToExport);
      if (done) {
        waitIndicatorLoaderRef.current?.hide();
      } else {
        waitIndicatorLoaderRef.current?.hide();
        adminContext.onError(true, "Failed to download site data report");
      }
    } catch (error) {
      console.log(error);
      waitIndicatorLoaderRef.current?.hide();
      adminContext.onError(true, "Failed to download site data report");
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div>
          <h2 className={classes.cardTitle}>Site Data</h2>
          <div className={classes.cardDescription}>
            Download this to see site submission data for all activities.
            Activities are separated by tabs. This is the data source for
            analytics.
          </div>
          {filteredInitiatives.length > 0 ? (
            <div style={{ marginTop: "24px" }}>
              <Select
                value={
                  selectedInitiative ||
                  (earliestInitiative && earliestInitiative[0].id)
                }
                MenuProps={{
                  PaperProps: {
                    elevation: 0,
                  },
                }}
                onChange={handleInitiativeChange}
                className={classes.select}
              >
                {earliestInitiative?.map((initiative: any) => (
                  <MenuItem
                    key={initiative?.id}
                    value={initiative?.id}
                    className={classes.menuItem}
                  >
                    {initiative?.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          ) : (
            <div>
              <Select value="" disabled className={classes.select}>
                <MenuItem disabled />
              </Select>
            </div>
          )}
        </div>
        <div className={classes.exportBtn}>
          <RoundedButton
            name="btnExport"
            text="Export"
            disabled={
              (selectedInitiative === null || selectedInitiative === "") &&
              earliestInitiative === null
            }
            onClick={handleExportClick}
          />
        </div>
      </CardContent>
      <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
    </Card>
  );
}
