import ActivityListItem from "./ActivityListItem";
import classes from "./ActivityList.module.css";
import { Grid } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  userSelect: "none",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? "lightgrey" : "white",
  width: "100%",
});

function ActivityList(props: any) {
  const activities = props.activities;

  function onDragEnd(result: any) {
    if (!result.destination) {
      // dropped outside the list
      return;
    }
    const updatedActivityInfo = {
      id: result.draggableId,
      activityOldIndex: result.source.index,
      activityNewIndex: result.destination.index,
    };
    props.onActivityDragDrop(updatedActivityInfo);
  }

  if (!activities || activities.length === 0) {
    return <h4>No Activity Found!</h4>;
  }

  return (
    <Grid container className={classes.container} rowSpacing={5}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any, snapshot: any) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <Grid item className={classes.activityItem} sm={12} xs={12}>
                {activities.map((activity: any, index: any) => (
                  <Draggable
                    key={activity.id}
                    draggableId={activity.id}
                    index={index}
                  >
                    {(provided: any, snapshot: any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <ActivityListItem
                          saveOnClick={props.saveOnClick}
                          cancelOnClick={props.cancelOnClick}
                          deleteOnClick={props.deleteOnClick}
                          activity={activity}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </Grid>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
}

export default ActivityList;
