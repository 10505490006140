import React, { useState } from 'react';
import ActiveInitiativeList from './ActiveInitiativeList';
import ExpiredInitiativeList from './ExpiredInitiativeList';
import GenericTabs from '../../../atoms/GenericTabs';

function ManageInitiativeTabs() {
  const [pageIndex, setPageIndex] = useState(0);

  const handleWizardPageChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setPageIndex(newValue);
  };

  const tabs = [
    {
      label: 'Active',
      content: <ActiveInitiativeList />,
    },
    {
      label: 'Expired',
      content: <ExpiredInitiativeList />,
    },
  ];

  return (
    <GenericTabs tabs={tabs} activeTab={pageIndex} onChange={handleWizardPageChange} />
  );
}

export default ManageInitiativeTabs;
