import React, { useContext, useRef, useState } from "react";
import WizardHeader from "../../../atoms/WizardHeader";
import { EnrollmentQuestionnaireForm } from "./EnrollmentQuestionnaireForm";
import WaitIndicator, { WaitIndicatorRef } from "../../../atoms/WaitIndicator";
import { useNavigate } from "react-router-dom";
import AdminContext from "../../../../store/admin-context";
import { updateEnrollmentQuestionnaire } from "../../../../helpers/api-util";
import { useEnrollmentQuestionnaireData } from "./ManageEnrollmentQuestionnaireLayout";

function UpdateEnrollmentQuestionnaire() {
  const navigate = useNavigate();
  const adminContext = useContext(AdminContext);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const enrollmentQuesData = useEnrollmentQuestionnaireData();

  async function updateEnrollmentQuestionnaireInfo(formData: any) {
    let response = await updateEnrollmentQuestionnaire(formData);
    if (response.data != null) {
      adminContext.setEnrollmentId(response.data.id);
    }

    if (response.error != null) {
      if (response.error.response?.status === 400) {
        const error = (
          response.error.response?.data as { value: { error: string } }
        )?.value?.error;
        adminContext.onError(true, error);
        return response;
      }

      adminContext.onError(true, "Unable to update enrollment questionnaire!");
      return response;
    }
    return response;
  }

  async function onSaveAndContinue(formData: any) {   
    try {
      waitIndicatorLoaderRef.current?.show();
      const response = await updateEnrollmentQuestionnaireInfo(formData);
      if (response.data && response.data.id) {
        enrollmentQuesData?.refreshData();
        navigate("/admin/questionnaire/builder");
      }
      waitIndicatorLoaderRef.current?.hide();
    } catch (ex) {
      console.log(ex);
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  const headerJSX = <WizardHeader title="Setting" />;
  if (enrollmentQuesData.enrollmentQuesData.loading) {
    return (
      <>
        {headerJSX}
        <WaitIndicator show={true} />
      </>
    );
  }
  if (enrollmentQuesData.enrollmentQuesData.error) {
    return (
      <>
        {headerJSX}
        <div>Unable to load enrollment questionnaire!</div>
      </>
    );
  }

  if (
    enrollmentQuesData.enrollmentQuesData.appData &&
    enrollmentQuesData.enrollmentQuesData.appData.id
  ) {
    const enrollment = enrollmentQuesData.enrollmentQuesData.appData;
    return (
      <>
        {headerJSX}
        <EnrollmentQuestionnaireForm
          enrollment={enrollment}
          onSaveAndContinue={onSaveAndContinue}
        />
        <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
      </>
    );
  }

  return <>{headerJSX}</>;
}

export default UpdateEnrollmentQuestionnaire;
