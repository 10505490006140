import { useState } from "react";
import classes from "./PerformanceValidationHome.module.css";
import PerformanceValidationView from "./performance-validation/PerformanceValidationView";

export default function EnrollmentHome() {
  const [selectedActivityId, setSelectedActivityId] = useState<string>("");
  return (
    <div className={classes.container}>
      <div className={classes.container_header}>
        <div className={classes.container_header_title}>
          <h1>Performance Validation</h1>
        </div>
      </div>
      <div className={classes.performance_container}>
        <PerformanceValidationView onActivityChange={setSelectedActivityId} />
      </div>
    </div>
  );
}
