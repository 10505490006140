import RoundedButton from "../../atoms/formElements/RoundedButton";
import classes from "./QuestionnaireAction.module.css";

interface QuestionnaireActionProps {
  cancelOnClick: (e: any) => void;
  saveOnClick: (e: any) => void;
  cancelDisabled?: boolean;
  saveDisabled?: boolean;
}

function QuestionnaireAction(props: QuestionnaireActionProps) {
  return (
    <div className={classes.container}>
      <div>
        <RoundedButton
          name="btnCancel"
          text="Cancel"
          buttonType="secondary"
          disabled={props.cancelDisabled}
          onClick={props.cancelOnClick}
        />
      </div>
      <div>
        <RoundedButton
          name="btnSave"
          text="Save"
          buttonType="secondary"
          disabled={props.saveDisabled}
          onClick={props.saveOnClick}
        />
      </div>
    </div>
  );
}

export default QuestionnaireAction;
