import { useState } from "react";
import useAppData from "../../../../hooks/useAppData";
import { getEnrollmentQuestionnaires } from "../../../../helpers/api-util";
import WaitIndicator from "../../../atoms/WaitIndicator";
import ReactDataTable from "../../../atoms/tables/ReactDataTable";
import { getUIFormattedDate } from "../../../../helpers/ui-util";
import ListItemStatus from "../../../common/ListItemStatus";
import EnrollQuesListItemActionMenu from "./EnrollQuesListItemActionMenu";
import EnrollmentQuestionnaireListDetail from "./EnrollmentQuestionnaireListDetail";
import ListDetailContainer from "../../../common/ListDetailContainer";
import { ListDataTableStyle } from "../../../../helpers/ui-util";

export interface EnrollmentQuestionnaireListProps {
  enrollmentQuesListData: any;
  onDataRefresh: () => void;
}

export function EnrollmentQuestionnaireList(
  props: EnrollmentQuestionnaireListProps
) {
  const { enrollmentQuesListData, onDataRefresh } = props;

  const columns = [
    {
      name: "Year",
      selector: (row: { year: string }) => row.year,
      sortable: false,
    },

    {
      name: "Status",
      selector: (row: { status: string }) => (
        <ListItemStatus status={row.status} />
      ),
    },
    {
      name: "Last Updated By",
      selector: (row: { lastUpdatedBy: string }) => row.lastUpdatedBy,
    },
    {
      name: "Last Updated",
      selector: (row: { lastUpdated: string }) => row.lastUpdated,
      showOnMobileDevice: false,
      showOnTabletDevice: false,
    },
    {
      name: "Actions",
      cell: (row: any) => (
        <EnrollQuesListItemActionMenu
          status={row.status}
          onDataRefresh={onDataRefresh}
          enrollmentId={row.id}
        />
      ),
      center: true,
    },
  ];

  return (
    <>
      <ReactDataTable
        columns={columns}
        data={enrollmentQuesListData}
        expandableRows={true}
        expandableRowsComponent={(props: any) => (
          <ListDetailContainer>
            <EnrollmentQuestionnaireListDetail
              data={props.data.initiativeActivity}
            />
          </ListDetailContainer>
        )}
        dataTableStyles={ListDataTableStyle}
      />
    </>
  );
}
