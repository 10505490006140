import classes from "./RequiredError.module.css";
import ErrorIcon from "@mui/icons-material/Error";
function RequiredError() {
  return (
    <div className={classes.required_error}>
      <span style={{ color: "red" }}>
        <ErrorIcon />
        This field is required.
      </span>
    </div>
  );
}
export default RequiredError;
