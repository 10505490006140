import { InputLabel, TextField } from "@mui/material";
import { useState } from "react";
import RequiredError from "../required-error/RequiredError";
import classes from "./LabelStyle.module.css";

interface LabelDateFieldProps {
  displayName: string;
  name: string;
  value?: string;
  onDateChange?: (e: any) => void;
  onValidate?: () => boolean;
}

function LabelDateField(props: LabelDateFieldProps) {
  const labelId = `${props.name}-label`;
  const [isValid, setIsValid] = useState(true);

  const validateData = () => {
    if (props.onValidate) {
      setIsValid(props.onValidate());
    }
  };

  return (
    <>
      <InputLabel id={labelId} className={classes.commonInputStyle}>{props.displayName}</InputLabel>
      <TextField
        InputProps={{
          inputProps: {
            style: { padding: 10, borderRadius: 0 },
          },
          className: classes.commonInputStyle, 
          style: { ...{ borderRadius: 20 } },
        }}
        type="date"
        required
        id={props.name}
        name={props.name}
        value={props.value}
        fullWidth
        onChange={props.onDateChange}
        onBlur={validateData}
      />
      {isValid === false && <RequiredError />}
    </>
  );
}

export default LabelDateField;
