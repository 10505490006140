import { useState } from 'react';
import { getExpiredInitiativeEnrollments } from '../../../helpers/api-util';
import useAppData from '../../../hooks/useAppData';
import ReactDataTable from '../../atoms/tables/ReactDataTable';
import { Tooltip } from '@mui/material';
import { getUIFormattedDate } from '../../../helpers/ui-util';
import GenericLinearProgress from '../../atoms/formElements/linearProgressbar';
import WaitIndicator from '../../atoms/WaitIndicator';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from 'react-router-dom';

interface InitiativesEnrollment {
    id: string;
    siteName: string;
    acepSiteNumber: string;
    physicianGroupName: string;
    initiativeId: string;
    initiativeName: string;
    initiativeStatusName: string;
    openToActivity: boolean;
    siteId: string;
    siteEnrollmentId: string;
    lastUpdated: string;
    progressPercentage: number;
    }

const ExpiredInitiativeEnrollmentsGrid = () => {
    const [refreshData, setRefreshData] = useState(false);
    const [selectedRows, setSelectedRows] = useState<InitiativesEnrollment[]>([]); 
    const navigate = useNavigate();

    const expiredInitiativesData = useAppData(async () => {
        return await getExpiredInitiativeEnrollments();
    }
    , refreshData);
    
    const mappedData = expiredInitiativesData?.appData?.map((result: any, index: number) => {
      return {
        id: result.id,
        siteName: result.siteName,
        acepSiteNumber: result.acepSiteNumber,
        physicianGroupName: result?.physicianGroupName !== null ? result.physicianGroupName : "",
        initiativeId: result.initiativeId,
        initiativeName: result.initiativeName,
        initiativeStatusName: result.initiativeStatusName,
        openToActivity: result.openToActivity,
        siteId: result.siteId,
        siteEnrollmentId: result.siteEnrollmentId,
        lastUpdated: result.lastUpdated ? result.lastUpdated : "",
        progressPercentage: result.progressPercentage ? result.progressPercentage : 0,
      };
    });
    const onArrowButtonClickHandler = (row: InitiativesEnrollment) => {
      setSelectedRows([row]);
      var sameSiteEnrollmentData = mappedData.filter(
        (item: any) => item.initiativeId === row.initiativeId
      );
      navigate("/champion/initiative-questionnaires", {
        replace: true,
        state: {
          initiativeEnrollmentInfo: row,
          sameSiteEnrollmentData: sameSiteEnrollmentData,
        },
      });
    };
    if(expiredInitiativesData.loading) {
        return <WaitIndicator show={true} />;
    }
    if(expiredInitiativesData.error) {
        return <div>Error...</div>
    }
    else{

  
    const columns = [{
        name: "Site",
        selector: (row: InitiativesEnrollment) => row.siteName,
        cell: (row: InitiativesEnrollment) => (
          <Tooltip title={row.siteName} placement="top" arrow>
            <div className={"ellipsis"}>{row.siteName}</div>
          </Tooltip>
        ),
        sortable: true,
        adjustWidthOnSmallerDevice: true,
        width: "30%",
      },
      {
        name: "Physician Group",
        selector: (row: InitiativesEnrollment) => row.physicianGroupName,
        cell: (row: any) => (
          <Tooltip title={row.physicianGroupName} placement="top" arrow>
            <div className={"ellipsis"}>{row.physicianGroupName}</div>
          </Tooltip>
        ),
        sortable: true,
        showOnMobileDevice: false,
        showOnTabletDevice: false,
      },
      {
        name: "Progress",
        width: "18%",
        selector: (row: InitiativesEnrollment) => row.progressPercentage,
        cell: (row: any) => (           
          <GenericLinearProgress value={row.progressPercentage}  variant="determinate"  />
        ),      
      },
      {
        name: "Initiative",
        selector: (row: InitiativesEnrollment) => row.initiativeName,
        cell: (row: InitiativesEnrollment) => (
          <Tooltip title={row.initiativeName} placement="top" arrow>
            <div className={"ellipsis"}>{row.initiativeName}</div>
          </Tooltip>
        ),
       
      adjustWidthOnSmallerDevice: true,
      },
      {
        name: "Last Updated",
        sortable: false,
        width: "13%",
        selector: (row: InitiativesEnrollment) => row.lastUpdated,
        cell: (row: InitiativesEnrollment) => (
          <div>
            {row.lastUpdated !== "" ? getUIFormattedDate(row.lastUpdated) : ""}
          </div>
        ),
        showOnMobileDevice: false,
      showOnTabletDevice: false,
      },
      {
        name: "",
        cell: (row: InitiativesEnrollment) => (
          <button
            style={{
              border: "none",
              background: "none",
              cursor:
                row.initiativeStatusName === "Deactivated"
                  ? "default"
                  : "pointer",
            }}
            onClick={(e: React.MouseEvent) => {
             
              onArrowButtonClickHandler(row);
            }}
          >
            <KeyboardArrowRightIcon
              style={{
                color:
                  row.initiativeStatusName === "Deactivated" 
                    ? "#cccccc"
                    : "",
              }}
            />
          </button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: "60px",
      },
    ];
    const newStyle = {
        headRow: {
          border: "none",
        },
        rows: {
          border: "1px solid #DDDDDD",
          borderRadius: "10px",
          marginBottom: "10px",
          marginTop: "0px",
        },
      };
    return (
        <ReactDataTable
            data={mappedData}
            columns={columns}
            dataTableStyles={newStyle}
        />
    );
    }
};

export default ExpiredInitiativeEnrollmentsGrid;