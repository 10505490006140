import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { red } from "@mui/material/colors";
import classes from "./ActivityFormHeader.module.css";

function ActivityFormHeader(props: any) {
  return (
    <header className={classes.container}>
      <div>
        <h3>Activity Details</h3>
      </div>
      <div>
        {props.showDelete ? (
          <IconButton aria-label="delete" onClick={props.onDeleteClick}>
            <DeleteIcon sx={{ color: red[500] }} />
          </IconButton>
        ) : (
          <></>
        )}
      </div>
    </header>
  );
}

export default ActivityFormHeader;
