import { InputLabel, TextField, Typography } from "@mui/material";
import { useState } from "react";
import RequiredError from "../required-error/RequiredError";
import classes from "./LabelStyle.module.css";

interface LabelMultilineTextFieldProps {
  displayName: string;
  name: string;
  value?: string;
  onTextChange?: (e: any) => void;
  rows: string;
  onValidate?: () => boolean;
  maxCharacter?: number;
}

function LabelMultilineTextField(props: LabelMultilineTextFieldProps) {
  const labelId = `${props.name}-label`;
  const [isValid, setIsValid] = useState(true);
  const [characterCount, setCharacterCount] = useState(props.value ? props.value.length : 0);

  const validateData = () => {
    if (props.onValidate) {
      setIsValid(props.onValidate());
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (props.maxCharacter && newValue.length > props.maxCharacter) {
      // If the character count exceeds the limit, truncate the text.
      e.target.value = newValue.substring(0, props.maxCharacter);
      setCharacterCount(props.maxCharacter);
    } else {
      setCharacterCount(newValue.length);
    }

    if (props.onTextChange) {
      props.onTextChange(e);
    }
  };

  return (
    <>
      <InputLabel id={labelId} className={classes.commonInputStyle} >
        {props.displayName}
      </InputLabel>
      <TextField
        InputProps={{
          inputProps: {
            style: { padding: 0, borderRadius: 0 },
          },
          style: { borderRadius: 20 },
          className: classes.commonInputStyle,        
        }}
        required
        id={props.name}
        name={props.name}
        fullWidth
        multiline
        value={props.value}
        rows={props.rows}
        onChange={handleTextChange}
        onBlur={validateData}
      />
      {isValid === false && <RequiredError />}
      {props.maxCharacter && (
        <Typography
          style={{
            fontSize: 12,
          }}
        >
          Maximum character count: {characterCount}/{props.maxCharacter}.
        </Typography>
      )}
    </>
  );
}

export default LabelMultilineTextField;
