import { Box, Grid } from "@mui/material";
import classes from "./ManageChampionsHome.module.css";
import RoundedButton from "../atoms/formElements/RoundedButton";
import ManageChampionGridView,{ManageChampionGridViewRef} from "./manage-champion/ManageChampionsGridView";
import ManageChampionsFormDialog, {
  ManageChampionsFormDialogRef,
} from "./manage-champion/ManageChampionsFormDialog";

import { useRef, useState } from "react";
function ManageChampionsHome() {
  const manageChampionsFormDialogRef =  useRef<ManageChampionsFormDialogRef>(null);
  const manageChampionGridViewRef=useRef<ManageChampionGridViewRef>(null);
  const [refreshData, setrefreshData] = useState(false);
  function onSuccessRefresh() {
    try {
      setrefreshData(!refreshData);
      manageChampionGridViewRef.current?.onSuccessRefresh();   
      manageChampionsFormDialogRef.current?.onSuccessRefresh();
    } catch (ex) {
     // console.log(ex);
    }
  }

  const handleClickOpen = () => {
    // waitIndicatorLoaderRef.current?.show();
    manageChampionsFormDialogRef.current?.openDialog();
    //waitIndicatorLoaderRef.current?.hide();
  };

  return (
    <div className={classes.container}>
      <div className={classes.container_header}>
        <div className={classes.container_header_title}>
          <h1>Manage Champions</h1>
        </div>
      </div>
      <div className={classes.championContainer}>
        <div className={classes.addChampion}>
          <Box mt={2} mb={2} mr={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <RoundedButton
                name="btnCreateNewChampion"
                text="Add Champion"
                onClick={handleClickOpen}
              />
            </Grid>
          </Box>
        </div>
        <div className={classes.manageChampionGridcontainer}>
          <Box mt={2} mb={2}>
            <Grid item xs={12}>
              <ManageChampionGridView
              ref={manageChampionGridViewRef}
              onsuccess={onSuccessRefresh}             
              // onDataRefresh={function (): void {
              //   throw new Error("Function not implemented.");
              // }}
              ></ManageChampionGridView>
            </Grid>
          </Box>
        </div>
        <div>
          <Box mt={2} mb={2} mr={2}>
            <ManageChampionsFormDialog
              ref={manageChampionsFormDialogRef}
              onsuccess={onSuccessRefresh}
              onDataRefresh={function (): void {
                throw new Error("Function not implemented.");
              }}
              userStatus={"remove"}
             // users={undefined}
              // roles={undefined}
              // sites={undefined}
              // physician={undefined}
            />
          </Box>
        </div>
      </div>
    </div>
  );
}

export default ManageChampionsHome;
