import CreateInitiative from "../../../components/admin/initiative/CreateInitiative";

function CreateInitiativePage() {
  return (
    <>
      <CreateInitiative />
    </>
  );
}

export default CreateInitiativePage;
