import AdminEnrollmentHome from "../../../components/admin/AdminEnrollmentHome";

function EnrollmentHomePage() {
  return (
    <>
      <AdminEnrollmentHome />
    </>
  );
}

export default EnrollmentHomePage;
