import classes from "./EnrollSite.module.css";
import { useState, useRef } from "react";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import RoundedButton from "../../atoms/formElements/RoundedButton";
import ReactDataTable from "../../atoms/tables/ReactDataTable";
import { getSiteEnrollments, getSites } from "../../../helpers/api-util";
import InitiativeEnrollmentPopup, {
  InitiativeEnrollmentPopupRef,
} from "../../common/InitiativeEnrollPopup";
import { getAdminEnrollSiteColumns } from "./AdminSiteEnrollmentColumns";
import useAppData from "../../../hooks/useAppData";
import { useLocation } from "react-router-dom";
import MessageConfirmDialog, {
  MessageConfirmDialogRef,
} from "../../atoms/dialogs/MessageConfirmDialog";

import {
  checkInitiativeEnrolled,
  openInitiativesEnrolledForSite,
} from "../../common/InitiativeEnrollment";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";
import { handleErrorReponse } from "../../../helpers/ui-util";
import ManageUserSearchBox from "../manage-users/ManageUserSearchBox";
import React from "react";
 

export default function EnrollSite(props: any) {
  const location = useLocation();
  const initiativeEnrollmentPopupRef =
    useRef<InitiativeEnrollmentPopupRef>(null);
  const ErrorConfirmPopupRef = useRef<MessageConfirmDialogRef>(null);
  const [sitesEnrollmentData, setSitesEnrollmentData] = useState<any>(
    location.state.enrollmentData
  );
  const [selectedYear, setSelectedYear] = useState<any>(
    location.state.selectedYear
  );
  const [selectedSite, setSelectedSite] = useState<any[]>([]);
  const [searchTerm1, setSearchTerm1] = useState<string>("");
  const [refreshData, setRefreshData] = useState(false);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  // Add a state to track whether the search button is clicked  
  const searchInputBoxRef = useRef<HTMLInputElement>(null);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const [progress, setProgress] = useState(false);

  const sitesData = useAppData(async () => {
    return await getSites();
  }, refreshData);

  function handleCloseDialogs() {
    //setInitiativeDialogOpen(false);
  }

  function handleErrorCloseDialogs() {
    setSearchTerm1("");
    setSelectedSite([]);
    setClearSelectedRows(!clearSelectedRows);
  }

  function handleDiffInitiativeEnrollPopupClose() {
    setSearchTerm1("");
    setSelectedSite([]);
    setClearSelectedRows(!clearSelectedRows);
  }

  React.useEffect(() => {   
    //Here we have hide the wait indicators when anything changes in the search term
    //when we search something, then we have changed the search term at the end
    //when we clears the text then also we have shown the wait indicatos so here those will stop
    //To resolve the infinite loop of the setProress in other places
    setProgress(false)
      waitIndicatorLoaderRef?.current?.hide();
      
  }, [searchTerm1]);
  
  function processData(data: any) {
    let results: { id: any; site: string; physician_group: any }[] = [];
    data.map((element: any) => {
      let siteid = element.id;
      let siteName = element.name;
      let physicianGroupName =
        element.physicianGroup != null ? element.physicianGroup : "";
      results.push({
        id: siteid,
        site: siteName,
        physician_group: physicianGroupName,
      });
    });
    return results;
  }

  async function getEnrollmentData() {
    const response = await getSiteEnrollments(selectedYear);
    if (response.status === 200) {
      setSitesEnrollmentData(response.data);
    }
  }

  async function onSuccessRefresh() {
    await getEnrollmentData();
    setRefreshData(!refreshData);
    setClearSelectedRows(!clearSelectedRows);
  }

  function onSelectedRowsChange(selectedRows: any) {
    let rowsSelected: any[] = [];
    if (selectedRows != null) {
      selectedRows.forEach((element: any) => {
        rowsSelected.push(element.id);
      });
    } else {
      rowsSelected = [];
    }
    setSelectedSite(rowsSelected);
  }

  function handleInitiativeEnrollmentButtonClick() {
    const sameInitiativeEnrolled = checkInitiativeEnrolled(
      selectedSite,
      sitesEnrollmentData
    );

    if (sameInitiativeEnrolled) {
      var enrolledInitiaties = openInitiativesEnrolledForSite(
        sitesEnrollmentData,
        selectedSite[0]
      );
      var enrolledInitiatiesIds = new Array();
      if (enrolledInitiaties != null && enrolledInitiaties.length > 0) {
        enrolledInitiaties.forEach((element: any) => {
          if (element.isEnrolled === true) {
            enrolledInitiatiesIds.push(element.initiativeId);
          }
        });
      }
      // Open the initiative enrollment dialog
      initiativeEnrollmentPopupRef.current?.setEnrolledInitiatives(
        enrolledInitiatiesIds
      );
      initiativeEnrollmentPopupRef.current?.openDialog();
    } else {
      ErrorConfirmPopupRef.current?.openDialog();
    }
  }
  
  // Modify the filterData function to apply filtering only when the search button is clicked
  const filterData = (data: any[], query: string) => {   
    if (query.trim() === "") { return data; }
    const searchTerms = query.toLowerCase().split(" ");
    return data.filter((item) => {
      for (let prop in item) {
        if (
          item.hasOwnProperty(prop) &&
          (prop === "site" || prop === "physician_group")
        ) {
          const propValue = item[prop];
          if (typeof propValue === "string") {
            const propValueLower = propValue.toLowerCase();
            if (searchTerms.every((term) => propValueLower.includes(term))) {              
              return true;
            }
          }
        }
      }      
      return false;
    });    
  };

  // handleSearchButtonClick function to apply filtering when the search button is clicked
  function handleSearchButtonClick() {
    try {     
      waitIndicatorLoaderRef.current?.show();
      setProgress(prevProgress => {        
        return true;
    })// Set progress to true when search button is clicked
  
      var searchValue = searchInputBoxRef.current?.value;
      searchValue = searchValue !== undefined ? searchValue : "";
  
      if (searchTerm1 === searchValue) {
        waitIndicatorLoaderRef.current?.hide();
        setProgress(false); // Set progress to false if search term hasn't changed
        return; // Exit early if the search term hasn't changed
      }
  
      setSearchTerm1(searchValue);
    } catch (ex) {      
      setProgress(false); // Set progress to false if an exception occurs
    }
  }
  

  let returnJSX = <></>;
  if (sitesData.loading) {
    returnJSX = (
      <div>
        <WaitIndicator show={true}></WaitIndicator>
      </div>
    );
  } else if (sitesData.error) {
    returnJSX = handleErrorReponse(sitesData.error);
  } else {
  

   const enrollSiteColumns = getAdminEnrollSiteColumns();   
    const data = processData(sitesData.appData);
    const filteredData = filterData(data,searchTerm1);
    
    const clearSearchFun = () => { 
      waitIndicatorLoaderRef.current?.show();
      setProgress(true); 
      setSearchTerm1("") 
      
      
    };
    returnJSX = (
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <div className={classes.searchBoxContainer}>
            <ManageUserSearchBox //Here using the existing search controll.
              initialSearchTerm={searchInputBoxRef.current?.value}
              searchInputBoxRef={searchInputBoxRef}
              clearSearchFun={clearSearchFun}
              placeHolderText="Search by Site and Physician Group"
            />

          <div className={classes.searchButtonContainer}>
              <RoundedButton
                  name="btnsearch"
                  text="Search"
                  buttonType="secondary"
                  className={classes.searchButton} 
                 
                  onClick={handleSearchButtonClick} // Call handleSearchButtonClick when the search button is clicked                            
                  />
            </div>
            <div style={{ marginLeft: "10px"  }}>
                <RoundedButton
                  name="btnInitiative"
                  text="Initiative Enrollment"
                  onClick={handleInitiativeEnrollmentButtonClick}
                  disabled={selectedSite.length === 0}
                  className={classes.initiativeButton}
                />
            </div>
          </div>
          
        </Grid>
        <Grid item xs={12} sm={12}>
         <ReactDataTable
            progress={progress}
            selectable={true}
            columns={enrollSiteColumns}
            data={filteredData}
            onSelectedRowsChange={onSelectedRowsChange}
            clearSelectedRows={clearSelectedRows}
          ></ReactDataTable>
        </Grid>
     
        <WaitIndicator ref={waitIndicatorLoaderRef} show={progress} />
      </Grid>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.container_header}>
        <div className={classes.container_header_title}>
          <h1>Enroll Sites</h1>
        </div>
      </div>
      <div className={classes.enrollment_container}>
        {returnJSX}
        <InitiativeEnrollmentPopup
          ref={initiativeEnrollmentPopupRef}
          onClose={handleCloseDialogs}
          selectedSite={selectedSite}
          selectedYear={selectedYear}
          enrollmentType="enroll"
          onsuccess={onSuccessRefresh}
          userRole="admin"
        />
        <MessageConfirmDialog
          ref={ErrorConfirmPopupRef}
          onConfirm={handleDiffInitiativeEnrollPopupClose}
          onClose={handleErrorCloseDialogs}
          children="The sites you have selected are already 
          enrolled into different initiatives, please update your selection and try again."
        />
      </div>
    </div>
  );
}
