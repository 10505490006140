import axios, { AxiosError } from "axios";
import { Token_Header_Key } from "../config/config";

import jwt from "jwt-decode";

const apiBaseUrl = process.env.REACT_APP_API_URL;
var refreshInterval: any = null;
var userUnAuthorized = false;
let lastCallDatetime = new Date();

// axios.interceptors.request.use(
//   (config) => {
//     // Update lastCallDatetime with the current datetime before each request is sent
//     lastCallDatetime = new Date();
//     console.log(`Making a new request to ${config.url} at ${lastCallDatetime}`);
//     return config;
//   },
//   (error) => {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

export function isTokenExpired() {
  const user_info: IEQUALTokenInfo = getEQUALUserInfoFromStorage();
  var now = new Date();
  now.setMinutes(now.getMinutes() + 5); // Add 5 minutes to the current time for a buffer
  // var utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  //var int_date_frame = Math.floor(utc.getTime() / 1000);
  var local_time = Math.floor(now.getTime() / 1000);
  var session_exp_time = Number(user_info.exp) || 0;
  if (local_time >= session_exp_time) {
    return true;
  } else {
    return false;
  }
}

export const validateToken = async () => {
  if (isTokenExpired()) {
    userUnAuthorized = true;
  }
  if (userUnAuthorized) {
    await generateToken();
  }
  axios.defaults.headers.common[Token_Header_Key] =
    "Bearer " + localStorage.getItem("EQUALUSERINFO");
};

export async function getDataForToken() {
  const user_info: IACEPTokenInfo =await getUserInfoFromStorage();
  if (user_info.sub !== undefined) {
    try {
      var postdata = {
        userName: process.env.REACT_APP_CLIENT_USERNAME,
        password: process.env.REACT_APP_CLIENT_PASSWORD,
        acepId: user_info.sub,
        acepJTI: user_info.jti,
      };
      return postdata;
    } catch (error) {
      console.log("Error getting user info from token: ", error);
      return null;
    }
  } else {
    console.log("Error getting user info from token: ");
    return null;
  }
}

export async function generateToken() {
  // if (refreshInterval) {
  //   clearInterval(refreshInterval);
  // }

  var postedData =await getDataForToken();
  if (postedData === null) {
    userUnAuthorized = true;
    return null;
  }

  const response = await axios
    .post(apiBaseUrl + "auth/generateToken", postedData)
    .then((result: any) => {
      localStorage.setItem("EQUALUSERINFO", String(result.data.equaltoken));
      userUnAuthorized = false;
    })
    .catch((error) => {
      console.log("Error generating token: ", error);
      console.log("Error response code: ", error.response?.status === 401);
      userUnAuthorized = true;
      if (error.response?.status === 401) {
        userUnAuthorized = true;
      }
    });

  // var refreshIntervalTimeInMs = process.env.REACT_APP_TOKEN_REFRESH_INTERVAL;
  // if (!refreshIntervalTimeInMs) {
  //   refreshIntervalTimeInMs = "600000"; // 600,000 milliseconds = 10 minutes
  // }

  // refreshInterval = setInterval(async function () {
  //   await refreshToken();
  // }, parseInt(refreshIntervalTimeInMs));

  return response;
}

export async function refreshToken() {
  const response = await axios
    .post(apiBaseUrl + "auth/refreshToken")
    .then((result: any) => {
      localStorage.setItem("EQUALUSERINFO", String(result.data.equaltoken));
      userUnAuthorized = false;
    })
    .catch((error) => {
      console.log("Error refreshing token: ", error);
      console.log("Error response code: ", error.response?.status === 401);
      userUnAuthorized = true;
      // if (error.response?.status === 401) {
      //   clearInterval(refreshInterval);
      // }
    });
  return response;
}

async function getData(url: string) {
  try {
    await validateToken();
    const response = await axios(url);
    return {
      data: response.data,
      error: null,
      status: response.status,
    };
  } catch (error) {
    var returnData = {
      data: null,
      error: error as AxiosError,
      status: (error as AxiosError).response?.status,
    };
    return returnData;
  }
}

async function updateData(url: string, data: any) {
  try {
    await validateToken();
    const response = await axios.put(url, data);
    return {
      data: response.data,
      error: null,
      status: response.status,
    };
  } catch (error) {
    return {
      data: null,
      error: error as AxiosError,
    };
  }
}

async function patchData(url: string, data: any) {
  try {
    await validateToken();
    const response = await axios.patch(url, data);
    return {
      ok: true,
      data: response.data,
      error: null,
    };
  } catch (error) {
    return {
      ok: false,
      data: null,
      error: error as AxiosError,
    };
  }
}

async function deleteData(url: string) {
  try {
    await validateToken();
    const response = await axios.delete(url);
    return {
      data: response.data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error: error as AxiosError,
    };
  }
}

async function postData(url: string, data: any) {
  try {
    await validateToken();
    const response = await axios.post(url, data);
    return {
      data: response.data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error: error as AxiosError,
    };
  }
}

export async function uploadFileToAzureStorageContainer(
  containerName: string,
  file: File
) {
  try {
    let formData = new FormData();
    formData.append("file", file);
    const url = `${apiBaseUrl}UploadToStorage/${containerName}`;
    await validateToken();
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return {
      data: response.data,
      error: null,
      status: response.status,
    };
  } catch (error) {
    return {
      data: null,
      error: error as AxiosError,
    };
  }
}

export async function uploadPeformanceValidationReports(
  initiativeId: string,
  activityId: string,
  files: File[]
) {
  try {
    let formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file);
    });
    const url = `${apiBaseUrl}PerformanceValidation/${initiativeId}/${activityId}/UploadReports`;
    await validateToken();
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return {
      data: response.data,
      error: null,
      status: response.status,
    };
  } catch (error) {
    return {
      data: null,
      error: error as AxiosError,
    };
  }
}

export async function uploadFilesToAzureStorageContainer(
  files: File[],
  containerName: string,
  folderStructure?: string
) {
  try {
    let formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file);
    });
    formData.append("folderStructure", folderStructure ? folderStructure : "");
    formData.append("containerName", containerName);
    const url = `${apiBaseUrl}UploadToStorage`;
    await validateToken();
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return {
      data: response.data,
      error: null,
      status: response.status,
    };
  } catch (error) {
    return {
      data: null,
      error: error as AxiosError,
    };
  }
}

export async function deleteFilesToAzureStorageContainer(fileURL: string) {
  try {
    const url = `${apiBaseUrl}UploadToStorage/?fileURL=${fileURL}`;
    await validateToken();
    const response = await axios.delete(url);

    return {
      error: null,
      status: response.status,
    };
  } catch (error) {
    return {
      error: error as AxiosError,
    };
  }
}

export async function getAllInitiatives() {
  const path = `${apiBaseUrl}Initiative/`;
  return await getData(path);
}

export async function getActiveInitiatives() {
  const path = `${apiBaseUrl}Initiative/Active`;
  return await getData(path);
}

export async function getOpenInitiatives() {
  const path = `${apiBaseUrl}Initiative/Open`;
  return await getData(path);
}

export async function getExpiredInitiatives() {
  const path = `${apiBaseUrl}Initiative/Expired`;
  return await getData(path);
}

export async function getInitiativesEnrollments() {
  const path = `${apiBaseUrl}Initiative/Enrollments`;
  return await getData(path);
}
export async function getInitiativeById(initiativeId: string) {
  const path = `${apiBaseUrl}Initiative/${initiativeId}`;
  return await getData(path);
}

export async function updateInitiative(initiativeData: any) {
  const url = `${apiBaseUrl}Initiative/${initiativeData.id}`;
  return await updateData(url, initiativeData);
}

export async function createInitiative(initiativeData: any) {
  const apiBaseUrl = process.env.REACT_APP_API_URL;
  const url = `${apiBaseUrl}Initiative`;
  return await postData(url, initiativeData);
}

export async function deleteInitiativeData(initiativeId: string) {
  const url = `${apiBaseUrl}Initiative/${initiativeId}/Delete`;
  return await deleteData(url);
}
export async function deActivateInitiative(initiativeId: string) {
  const url = `${apiBaseUrl}Initiative/${initiativeId}/Deactivate`;
  return await patchData(url, {});
}
export async function activateInitiative(initiativeId: string) {
  const url = `${apiBaseUrl}Initiative/${initiativeId}/Activate`;
  return await patchData(url, {});
}
export async function duplicateInitiative(initiativeId: string) {
  const url = `${apiBaseUrl}Initiative/${initiativeId}/Duplicate`;
  return await patchData(url, {});
}

export async function getInitiativeActivities(initiativeId: string) {
  const url = `${apiBaseUrl}Initiative/${initiativeId}/Activities`;
  return await getData(url);
}

export async function getSiteChampions() {
  const url = `${apiBaseUrl}Site/Champions`;
  return await getData(url);
}

export async function downloadSiteDataReport(initiativeId: string) {
  try {
    const downloadUrl = `${apiBaseUrl}Initiative/${initiativeId}/SiteDataReport`;
    // const downloadUrl1 =
    //   "https://localhost:7226/api/v1/Initiative/de3e8f60-c4b4-4692-88a8-fb96a040b599/SiteDataReport";
    await validateToken();

    const response = await axios.get(downloadUrl, {
      responseType: "arraybuffer",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");

    // Extract filename from Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    let filename = null;
    const matches = contentDisposition.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    );

    if (matches && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }

    link.href = url;
    link.setAttribute("download", filename?.trim() || "SiteDataReport.xlsx");
    document.body.appendChild(link);
    link.click();
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function downloadCompletionReport(initiativeId: string) {
  try {
    const downloadUrl = `${apiBaseUrl}Initiative/${initiativeId}/CompletionReport`;
    await validateToken();

    const response = await axios.get(downloadUrl, {
      responseType: "arraybuffer",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");

    // Extract filename from Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    let filename = null;
    const matches = contentDisposition.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    );

    if (matches && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }

    link.href = url;
    link.setAttribute("download", filename?.trim() || "CompletionReport.xlsx");
    document.body.appendChild(link);
    link.click();
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function exportSiteChampionsData() {
  try {
    const downloadUrl = `${apiBaseUrl}Site/Champions/Export`;
    //return await getData(url);

    await validateToken();

    const response = await axios({
      url: downloadUrl,
      method: "GET",
      responseType: "blob", // Set the response type to 'blob' to handle binary data
    });

    // Extract filename from Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    const filenameMatch =
      contentDisposition && contentDisposition.match(/filename="(.+)"/);
    const filename = filenameMatch
      ? filenameMatch[1]
      : "Sites and Champions Report.xlsx";

    // Create a download link
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Create a temporary <a> element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); // Set the file name

    // Append the <a> element to the DOM and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up the temporary <a> element
    document.body.removeChild(link);

    // You can return something if needed
    return { success: true, message: "Export successful" };
  } catch (error) {
    // Handle the error
    console.error(error);
    // You can return an error object or throw an error if needed
    return { success: false, message: "Export failed", error };
  }
}

export async function downloadEnrollmentReport(initiativeYear: number) {
  try {
    const downloadUrl = `${apiBaseUrl}EnrollmentQues/${initiativeYear}/EnrollmentReport`;

    await validateToken();

    const response = await axios.get(downloadUrl, {
      responseType: "arraybuffer",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");

    // Extract filename from Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    let filename = null;
    const matches = contentDisposition.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    );

    if (matches && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }

    link.href = url;
    link.setAttribute("download", filename?.trim() || "SiteDataReport.xlsx");
    document.body.appendChild(link);
    link.click();
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function createInitiativeActivity(
  initiativeId: string,
  activityData: any
) {
  const newActivityData = {
    name: activityData.name,
    activityIndex: -1,
    definition: "",
    startDate: activityData.startDate,
    endDate: activityData.endDate,
    longSummary: activityData.longSummary,
    InitiativeId: initiativeId,
    includePerformanceValidation: activityData.includePerformanceValidation,
    performanceValidationName: activityData.performanceValidationName,
    isOptional: activityData.isOptional,
  };

  const url = `${apiBaseUrl}InitiativeActivity`;
  return await postData(url, newActivityData);
}

export async function updateInitiativeActivity(activityData: any) {
  const url = `${apiBaseUrl}InitiativeActivity/${activityData.id}`;
  return await updateData(url, activityData);
}

export async function reIndexInitiativeActivity(activityData: any) {
  const url = `${apiBaseUrl}InitiativeActivity/${activityData.id}/ReIndex`;
  return await patchData(url, activityData);
}

export async function deleteInitiativeActivity(activityId: string) {
  const url = `${apiBaseUrl}InitiativeActivity/${activityId}`;
  return await deleteData(url);
}

export async function getQuestionnaireData(activityId: string) {
  const url = `${apiBaseUrl}InitiativeActivity/${activityId}/Questionnaires`;
  return await getData(url);
}

export async function updateQuestionnaireData(
  activityId: string,
  questionnaireData: any
) {
  const url = `${apiBaseUrl}InitiativeActivity/${activityId}/Questionnaires`;
  return await postData(url, questionnaireData);
}

export async function publishInitiative(initiativeId: string) {
  const url = `${apiBaseUrl}Initiative/${initiativeId}/Publish`;
  return await patchData(url, {});
}

export function isObjectEmpty(objectName: any) {
  return Object.keys(objectName).length === 0;
}

export async function saveInitiativeIcon(
  initiativeData: any,
  containerName: string,
  iconFile: File
) {
  let response = await uploadFileToAzureStorageContainer(
    containerName,
    iconFile
  );
  if (response && response.data != null) {
    //upload successful
    initiativeData.logo = response.data.filePath;
    response = await updateInitiative(initiativeData);
  }
  return response;
}

export async function getUsers() {
  const path = `${apiBaseUrl}User`;
  return await getData(path);
}

export async function getUsersWithAssignedRoles() {
  const path = `${apiBaseUrl}User/AssignedRoles`;
  return await getData(path);
}

export async function getRoles() {
  const path = `${apiBaseUrl}userRole`;
  return await getData(path);
}

export async function getSites() {
  const path = `${apiBaseUrl}Site`;
  return await getData(path);
}
export async function getPhysicianGroup() {
  const path = `${apiBaseUrl}PhysicianGroup`;
  return await getData(path);
}
export async function getEnrollmentPhysicianGroup() {
  const path = `${apiBaseUrl}Site/Enrollment/PhysicianGroups`;
  return await getData(path);
}
export async function updateQuestionnaire(enrollQuestionnaireeData: any) {
  const url = `${apiBaseUrl}Site/Enrollment/Questionnaires`;
  return await updateData(url, enrollQuestionnaireeData);
}
export async function updateUsers(
  userData: any,
  id: any,
  userRoleName: string
) {
  if (userRoleName === "admin") {
    const url = `${apiBaseUrl}User/${id}/AssignRole/${userRoleName}`;
    return await updateData(url, {});
  } else {
    const url = `${apiBaseUrl}User/${id}/AssignRole/${userRoleName}`;
    return await updateData(url, {
      siteId: userData.siteId,
      physicianGroupId: userData.physicianGroupId,
    });
  }
  // if (userRoleName == "champion") {
  //   const url = `${apiBaseUrl}User/${id}/AssignRole/${userData.userRoleName}`;
  //   return await updateData(url, userData);
  // }
}
export async function updateUnenrollment(initiativeId: any, Id: string) {
  const url = `${apiBaseUrl}Site/${Id}/UnEnroll`;
  return await updateData(url, initiativeId);
}
export async function getSiteEnrollments(year?: number) {
  const path = `${apiBaseUrl}Site/${year}/Enrollments`;
  return await getData(path);
}

export async function RemoveUserData(id: any) {
  const url = `${apiBaseUrl}User/${id}/RemoveRole`;
  return await deleteData(url);
}

export async function createUser(userData: any) {
  const apiBaseUrl = process.env.REACT_APP_API_URL;
  const url = `${apiBaseUrl}User`;
  return await postData(url, userData);
}

export async function getInitiativesToEnroll(year: number) {
  const path = `${apiBaseUrl}Initiative/${year}/ToEnroll`;
  return await getData(path);
}

export async function getUserInfoFromStorage() {
  const user_info: IACEPTokenInfo = localStorage.getItem("ACEPUSERINFO")
    ? jwt(String(localStorage.getItem("ACEPUSERINFO")))
    : {
        jti: "",
        sub: "",
        email: "",
        given_name: "",
        family_name: "",
        exp: 0,
        iss: "",
        aud: "",
      };
  return user_info;
}

export function getEQUALUserInfoFromStorage() {
  const user_info: IEQUALTokenInfo = localStorage.getItem("EQUALUSERINFO")
    ? jwt(String(localStorage.getItem("EQUALUSERINFO")))
    : {
        username: "",
        roles: [],
        exp: 0,
        iss: "",
        aud: "",
        token: "",
      };
  return user_info;
}

export async function getSiteEnrollmentInitiativesById(siteEnrollmentId: any) {
  const path = `${apiBaseUrl}Site/Enrollments/${siteEnrollmentId}/initiatives`;
  return await getData(path);
}

export async function getSiteEnrollmentQuestionnaireById(
  siteEnrollmentId: any
) {
  const path = `${apiBaseUrl}Site/Enrollments/${siteEnrollmentId}/Questionnaire`;
  return await getData(path);
}

export async function SiteUnenroll(siteData: any) {
  const apiBaseUrl = process.env.REACT_APP_API_URL;
  const url = `${apiBaseUrl}Site/UnEnroll`;
  return await updateData(url, siteData);
}

export async function getSitesById(siteId: any) {
  const path = `${apiBaseUrl}Site/${siteId}`;
  return await getData(path);
}

export async function SiteToEnroll(siteData: any) {
  const apiBaseUrl = process.env.REACT_APP_API_URL;
  const url = `${apiBaseUrl}Site/Enroll`;
  return await updateData(url, siteData);
}

export async function getPhysicianGroupById(PhysicianGroupById: any) {
  const path = `${apiBaseUrl}PhysicianGroup/${PhysicianGroupById}`;
  return await getData(path);
}
export async function getPeerChampions() {
  const path = `${apiBaseUrl}User/PeerChampions`;
  return await getData(path);
}

let searchCancelToken: any;

export async function getSearchUsers(searchTerm: string) {
  //Check if there is a pending request
  if (searchCancelToken) {
    searchCancelToken.cancel("Operation canceled due to new request.");
    //console.log("cancelled");
  }
  //Save the cancel token for the current request
  searchCancelToken = axios.CancelToken.source();

  const searchTermData = {
    searchTerm: searchTerm,
  };

  const url = `${apiBaseUrl}User/SearchUsers`;
  //return await postData(url, searchTermData);

  try {
    await validateToken();
    const response = await axios.post(url, searchTermData, {
      cancelToken: searchCancelToken.token,
    });
    return {
      data: response.data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error: error as AxiosError,
    };
  }
}

export async function createEnrollmentQuestionnaire(questionnaireData: any) {
  const url = `${apiBaseUrl}EnrollmentQues`;
  return await postData(url, questionnaireData);
}

export async function getEnrollmentQuestionnaireById(enrollmentId: string) {
  const url = `${apiBaseUrl}EnrollmentQues/${enrollmentId}`;
  return await getData(url);
}
export async function getEnrollmentQuestionnaireByYear(enrollmentYear: number) {
  const url = `${apiBaseUrl}EnrollmentQues/${enrollmentYear}`;
  return await getData(url);
}

export async function getEnrollmentQuestionnaires() {
  const url = `${apiBaseUrl}EnrollmentQues`;
  return await getData(url);
}

export async function publishEnrollmentQuestionnaire(enrollmentId: string) {
  const url = `${apiBaseUrl}EnrollmentQues/${enrollmentId}/Publish`;
  return await patchData(url, {});
}

export async function updateEnrollmentQuestionnaire(questionnaireData: any) {
  const url = `${apiBaseUrl}EnrollmentQues/${questionnaireData.id}`;
  return await updateData(url, questionnaireData);
}

export async function deleteEnrollmentQuesData(enrollmentId: string) {
  const url = `${apiBaseUrl}EnrollmentQues/${enrollmentId}/delete`;
  return await deleteData(url);
}

export async function duplicateEnrollmentQues(enrollmentId: string) {
  const url = `${apiBaseUrl}EnrollmentQues/${enrollmentId}/Duplicate`;
  return await patchData(url, {});
}

export async function getSiteActivityBySiteEnrollmentId(
  siteEnrollmentId: string
) {
  const path = `${apiBaseUrl}Site/Enrollments/${siteEnrollmentId}/Questionnaire/answer`;
  //const url = `${apiBaseUrl}Enrollments/${siteEnrollmentId}/Questionnaire/answer`;
  return await getData(path);
}

export async function updateSiteActivityBySiteEnrollmentId(
  siteEnrollmentId: string,
  siteActivityData: any
) {
  const path = `${apiBaseUrl}Site/Enrollments/${siteEnrollmentId}/Questionnaire/answer`;
  return await updateData(path, siteActivityData);
}

export async function updateSiteActivities(multiSiteActivityData: any) {
  const path = `${apiBaseUrl}Site/Enrollments/Questionnaire/answer`;
  return await updateData(path, multiSiteActivityData);
}

export async function sendEmail(emailData: IEmailData) {
  const path = `${apiBaseUrl}EmailSender/SendEmail`;
  return await postData(path, emailData);
}

export async function sendEmailForNewChampion(
  newChampionEmailData: INewChmapionEmailData
) {
  const path = `${apiBaseUrl}EmailSender/SendEmailForNewChampion`;
  return await postData(path, newChampionEmailData);
}

export async function SendEmailForNewSite(
  newSiteRequestData: INewSiteRequestData
) {
  const path = `${apiBaseUrl}site/SendEmailForNewSite`;
  return await postData(path, newSiteRequestData);
}

export async function getPublishedInitiativeYears() {
  try {
    const response = await getActiveInitiatives();
    var returnedYear: number[] = [];
    if (response && response.data) {
      var activeInitiatives = response.data;
      activeInitiatives.forEach((initiative: any) => {
        if (
          initiative &&
          initiative.initiativeStatus &&
          initiative.initiativeStatus.name === "Published"
        ) {
          if (!returnedYear.includes(initiative.year))
            returnedYear.push(initiative.year);
        }
      });
    }
    if (returnedYear.length > 0) {
      returnedYear.sort((a, b) => a - b);
    }

    return {
      data: returnedYear,
      error: null,
      status: response.status,
    };
  } catch (error) {
    return {
      data: null,
      error: error as AxiosError,
    };
  }
}

export async function getSiteActivitiesBySiteInitiativeEnrollmentId(
  siteInitiativeEnrollmentId: string
) {
  const path = `${apiBaseUrl}Initiative/Enrollments/${siteInitiativeEnrollmentId}/answer`;
  //const url = `${apiBaseUrl}Enrollments/${siteEnrollmentId}/Questionnaire/answer`;
  return await getData(path);
}

export async function getUpcomingEnrolledInitiativeActivitiesForChampion() {
  const path = `${apiBaseUrl}Initiative/Enrollments/Activities/Upcoming`;
  return await getData(path);
}

export async function getSiteInitiativesEnrollmentProgressForAllSites() {
  const path = `${apiBaseUrl}Initiative/Enrollments/Sites/Status`;
  return await getData(path);
}

export async function getSiteInitiativesEnrollmentProgressForAllAdminSites() {
  const path = `${apiBaseUrl}Initiative/Enrollments/Sites/CompletionStatus`;
  return await getData(path);
}

export async function getEnrollmentReportYear() {
  const path = `${apiBaseUrl}Minimal/EnrollmentReportYear`;
  return await getData(path);
}
export async function getPerformanceValidationActivities() {
  const path = `${apiBaseUrl}Minimal/PerformanceValidationActivities`;
  return await getData(path);
}
export async function GetLastUpdated() {
  const path = `${apiBaseUrl}Minimal/LastUpdatedDateTimes`;
  return await getData(path);
}
export async function getInitiativeEnrollmentSummary() {
  const path = `${apiBaseUrl}Initiative/Enrollments/Summary`;
  return await getData(path);
}

export async function GetInitiativeEnrolledSiteLocations() {
  const path = `${apiBaseUrl}Initiative/Enrollments/SiteLocations`;
  return await getData(path);
}

export async function getPerformanceValidationSiteData(activityId: string) {
  const path = `${apiBaseUrl}PerformanceValidation/SiteData/${activityId}`;
  return await getData(path);
}

export async function getPerformanceValidationSummary() {
  const path = `${apiBaseUrl}PerformanceValidation/Summary`;
  return await getData(path);
}
export async function getExpiredInitiativeEnrollments() {
  const path = `${apiBaseUrl}Initiative/Enrollments/Expired`;
  return await getData(path);
}
export function processError(response: any) {
  var errorString = "something went wrong";
  if (response.error != null) {
    if (response.error.response?.status === 400) {
      const error = (
        response.error.response?.data as { value: { error: string } }
      )?.value?.error;
      errorString = error;
    }
    if (response.error.response?.status === 401) {
      errorString = "Unauthorized";
    }
    if (response.error.response?.status === 403) {
      errorString = "Forbidden";
    }
    if (response.error.response?.status === 404) {
      errorString = "Not Found";
    }
    if (response.error.response?.status === 500) {
      errorString = "Internal Server Error";
    }
  }
  return errorString;
}

export async function downloadPerformanceValidationReports(
  initiativeId: string,
  activityId: string
) {
  try {
    const path = `${apiBaseUrl}PerformanceValidation/${initiativeId}/${activityId}/DownloadReports`;
    await validateToken();
    const response = await axios.get(path, {
      responseType: "arraybuffer", // Important to handle binary data
    });

    // Create a new object URL for the blob
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");

    // Extract filename from Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    let filename = null;
    const matches = contentDisposition?.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    );

    if (matches && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }

    // Create a temporary link element and trigger the download
    link.href = url;
    //a.download = "files.zip"; // Name of the downloaded file
    link.setAttribute("download", filename?.trim() || "Download.zip");
    document.body.appendChild(link);
    link.click();
    return true;
  } catch (error) {
    console.error("Error downloading files:", error);
  }
  return false;
}
