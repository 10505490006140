import { Checkbox, Grid, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import LabelDateField from "../../../atoms/formElements/LabelDateField";
import LabelMultilineTextField from "../../../atoms/formElements/LabelMultilineTextField";
import LabelTextField from "../../../atoms/formElements/LabelTextField";
import ActivityAction from "./ActivityAction";
import { isValidDate } from "../../../../helpers/ui-util";
import productFeatues from "../../../../store/features-set.json";

function ActivityForm(props: any) {
  const { activity } = props;
  const formInitialState = {
    id: activity.id,
    name: activity.name,
    startDate: activity.startDate,
    endDate: activity.endDate,
    longSummary: activity.longSummary,
    includePerformanceValidation: activity.includePerformanceValidation,
    performanceValidationName: activity.performanceValidationName,
    isOptional: activity.isOptional,
  };

  var isOptionalActivityEnabled = productFeatues.features.allowOptionalActivity;

  const [formData, setFormData] = useState(formInitialState);

  const validateName = () => {
    return formData.name.length > 0;
  };
  const validateActivityStartDate = () => {
    return formData.startDate.length > 0 && isValidDate(formData.startDate);
  };
  const validateActivityEndDate = () => {
    let isValid = formData.endDate.length > 0 && isValidDate(formData.endDate);
    return isValid;
  };
  const validateDescription = () => {
    return formData.longSummary.length > 0;
  };
  const validatePerformanceValidationName = () => {
    return (
      formData.performanceValidationName.length > 0 &&
      !/\s/.test(formData.performanceValidationName)
    );
  };

  function isFormValid() {
    if (
      (validateName() &&
        validateActivityStartDate() &&
        validateActivityEndDate() &&
        validateDescription()) ||
      (formData.includePerformanceValidation &&
        validatePerformanceValidationName())
    ) {
      return true;
    }
    return false;
  }

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    // Remove spaces for performanceValidationName
    if (e.target.name === "performanceValidationName") {
      newValue = newValue.replace(/\s/g, "");
    }
    setFormData({ ...formData, [e.target.name]: newValue });
  };
  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };

  async function saveOnClickHandler(event: any) {
    event.preventDefault();
    if (isFormValid()) {
      props.saveOnClick(formData);
    } else {
      props.saveOnClick(null);
    }
  }
  async function cancelOnClickHandler(event: any) {
    event.preventDefault();
    if (JSON.stringify(formData) != JSON.stringify(formInitialState)) {
      props.cancelOnClick({
        data: formData,
        isDirty: true,
      });
    } else {
      props.cancelOnClick({
        data: formData,
        isDirty: false,
      });
    }
  }

  function handleActivityFormSubmit(event: any) {
    event.preventDefault();
    if (isFormValid()) {
      props.saveOnClick(formData);
    } else {
      props.saveOnClick(null);
    }
  }

  return (
    <>
      <form onSubmit={handleActivityFormSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <LabelTextField
              displayName="Activity Name"
              name="name"
              value={formData.name}
              onTextChange={handleTextFieldChange}
              onValidate={validateName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDateField
              displayName="Start Date"
              name="startDate"
              value={formData.startDate}
              onDateChange={handleTextFieldChange}
              onValidate={validateActivityStartDate}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDateField
              displayName="End Date"
              name="endDate"
              value={formData.endDate}
              onDateChange={handleTextFieldChange}
              onValidate={validateActivityEndDate}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LabelMultilineTextField
              displayName="Description"
              name="longSummary"
              value={formData.longSummary}
              onTextChange={handleTextFieldChange}
              rows="4"
              maxCharacter={1000}
              onValidate={validateDescription}
            />
          </Grid>
          {isOptionalActivityEnabled && (
            <Grid item xs={12} sm={12}>
              <Checkbox
                checked={formData.isOptional}
                onChange={handleCheckboxChange}
                style={{ color: "#000000", paddingLeft: "0px" }}
                name="isOptional"
              />
              Optional activity, allow user to skip
            </Grid>
          )}

          <Grid item xs={12} sm={12} sx={{ paddingTop: "10px!important" }}>
            <Checkbox
              checked={formData.includePerformanceValidation}
              onChange={handleCheckboxChange}
              style={{ color: "#000000", paddingLeft: "0px" }}
              name="includePerformanceValidation"
            />
            Performance Validation Upload Required
          </Grid>
          {formData.includePerformanceValidation && (
            <Grid item xs={12} sm={12} sx={{ paddingTop: "10px!important" }}>
              <LabelTextField
                displayName="Performance Validation Name"
                name="performanceValidationName"
                value={formData.performanceValidationName}
                onTextChange={handleTextFieldChange}
                onValidate={validatePerformanceValidationName}
              />
              <div
                style={{
                  color: "#09101D",
                  opacity: "60%",
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "20px",
                }}
              >
                No spaces allowed in Upload Name.
              </div>
            </Grid>
          )}
        </Grid>
        <ActivityAction
          disabled={!isFormValid()}
          cancelOnClick={cancelOnClickHandler}
          //saveOnClick={saveOnClickHandler}
        />
      </form>
    </>
  );
}

export default ActivityForm;
