import ConfirmDialog from "./ConfirmDialog";

function DeleteConfirmDialog(props: any) {
  let { title, children, open, setOpen, onConfirm } = props;

  if (!title) {
    title = "Are you sure?";
  }
  if (!children) {
    children = "Are you sure you want to delete this?";
  }
  return (
    <ConfirmDialog
      title={title}
      children={children}
      open={open}
      setOpen={setOpen}
      onConfirm={onConfirm}
      confirmButtonTitle="Delete"
      style={{
        DialogTitle: {         
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "40px",
        },
        DialogContent: {        
          fontWeight: 400,
          fontSize: "16px",               
        },
      }}
    />
  );
}

export default DeleteConfirmDialog;
