import { Outlet } from "react-router-dom";
import AdminSidebar from "../../components/admin/AdminSidebar";
import ErrorAlert from "../../components/atoms/alerts/ErrorAlert";
import SuccessAlert from "../../components/atoms/alerts/SuccessAlert";
import { AdminContextProvider } from "../../store/admin-context";

function AdminLayout() {
  return (
    <AdminContextProvider>
      <SuccessAlert />
      <ErrorAlert />
      <AdminSidebar />
      <main>
        <Outlet />
      </main>
    </AdminContextProvider>
  );
}

export default AdminLayout;
