import { useRef, useState } from "react";
import { Grid, CardContent, Typography, TextField, IconButton, InputAdornment, Card, Box } from "@mui/material";
import RoundedButton from "../../atoms/formElements/RoundedButton";
import classes from "./SiteChampionsView.module.css";
import { exportSiteChampionsData } from "../../../helpers/api-util";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";

interface Champion {
  userId: string;
  userFirstName: string;
  userLastName: string;
  userNumber: string;
  userEmail: string;
}

interface GroupedSite {
  siteId: string; 
  siteName: string;
  stateName: string;
  Champions: Champion[];
  physicianGroupName?: string;
}

export interface Site {
  physicianGroupName: string;
  siteId: string;
  siteName: string;
  siteNumber: string;
  stateName: string;
  userEmail: string;
  userNumber: string;
  userFirstName: string;
  userId: string;
  userLastName: string;
}

// interface SiteChampionsViewProps {
//   Champions: Champion[];
// }
function ChampionView(props: any) {
  const { Champions } = props;
  return (
    <>
      {Champions.map((champion: Champion) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          key={champion.userId}
        >
          <>
            <Typography
              style={{
                fontSize: 16,
              }}
            >
              {champion.userFirstName} {champion.userLastName} ({champion.userNumber})
            </Typography>
            <Typography
              style={{
                fontSize: 16,
              }}
            >
              <a
                href={`mailto:${champion.userEmail}`}
                style={{
                  textDecoration: "none",
                  color: "#1976D2",
                }}
              >
                {champion.userEmail}
              </a>
            </Typography>
          </>
        </div>
      ))}
    </>
  );
}

export interface SiteChampionsViewProps {
  siteData: Site[];
}

function SiteChampionsView(props: SiteChampionsViewProps) {
  
  const convertToCardData = (sites: any) => {
    const groupedArray: { [siteId: string]: GroupedSite } = {};

    // Iterate over each object in the original array
    sites.forEach((obj: Site) => {
      const {
        siteId,
        siteName,
        stateName,
        userId,
        userFirstName,
        userLastName,
        userNumber,
        userEmail,
        physicianGroupName,
      } = obj;

      // If the siteId doesn't exist in the grouped array, create a new object for it
      if (!groupedArray[siteId]) {
        groupedArray[siteId] = {
          siteId,
          siteName,        
          stateName,
          Champions: [],
          physicianGroupName,
        };
      }

      // Push the user details into the corresponding siteId object's users array
      groupedArray[siteId].Champions.push({
        userId,
        userFirstName,
        userLastName,
        userNumber,
        userEmail,
      });
    });

    // Convert the grouped object into an array of objects
    const resultArray: GroupedSite[] = Object.values(groupedArray);
    return resultArray;
  };

  // Define state for filtering and year selection
  const [filteredSiteData, setFilteredSiteData] = useState(
    convertToCardData(props.siteData)
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(false);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);

  const [clearIconClicked, setClearIconClicked] = useState(false);

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      // If search query is empty, show all data
      setFilteredSiteData(convertToCardData(props.siteData));

    } else {
      // Filter data based on search query
      let filteredData = filterIt(searchQuery, props.siteData);
      if (!filteredData) {
        filteredData = [];
      }
      setFilteredSiteData(convertToCardData(filteredData));
    }
  };
  const clearSearchFun = () => {
    setSearchQuery("");
    setFilteredSiteData(convertToCardData(props.siteData));
  }

  const filterIt = (query: string, data: any[]) => {

    if (query.trim() === "") return data;
  
    const searchTerms = query.toLowerCase().split(" ").filter(term => term.trim() !== "");
    const firstLastNameSearch = searchTerms.filter(term => term.length > 0);
    const physicianGroupNameSearch = searchTerms.filter(term => term.length > 0);
    const siteNameSearch= searchTerms.filter(term => term.length > 0);
  
    return data.filter((item) => {      
      const firstLastNameMatch = firstLastNameSearch.every(term =>
        item.userFirstName.toLowerCase().includes(term) || item.userLastName.toLowerCase().includes(term)   
        
        );      
      const siteNameMatch = siteNameSearch.every(term =>
        item.siteName.toLowerCase().includes(term)
      );
      const physicianGroupNameMatch = (item.physicianGroupName!=null &&  item.physicianGroupName!=undefined) ? physicianGroupNameSearch.every(term =>
        item.physicianGroupName?.toLowerCase().includes(term)
      ):"";
  
      return (
        firstLastNameMatch ||       
        (physicianGroupNameMatch && physicianGroupNameSearch.length > 0 ) || siteNameMatch
      );
    });
  };



  const handleExport = async () => {
    try {
      // Disable the export button while exporting
      setIsExportButtonDisabled(true);
      waitIndicatorLoaderRef.current?.show();
     
       var done = await exportSiteChampionsData();  
       if(done){
        waitIndicatorLoaderRef.current?.hide();
        setIsExportButtonDisabled(false);
       }
    } catch (error) {
      console.log(error);
      setIsExportButtonDisabled(false);
    } 
  };

  return (
    <div>
    <Grid container spacing={2}>
    <Grid
        item
        xs={12}
      >
        <div style={{
          display: "flex",
          alignItems: "center",
          margin: "0px 8px",
        }}>
              <TextField
                InputProps={{
                  inputProps: {
                    style: { padding: 5, borderRadius: 0, marginLeft: "10px",fontSize: "16px",lineHeight:"18px",opacity:0.6 },
                  },
                  style: { borderRadius: 20, width: "100%",height:"38px"},
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchQuery && (
                        <IconButton
                          aria-label="clear search"
                          onClick={clearSearchFun}
                          edge="end"
                        >
                          <CloseRoundedIcon 
                          sx={{color: "#000000"}}
                          />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                autoFocus={true}
                required
                id="search"
                name="search"
                value={searchQuery}
                fullWidth
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                  setClearIconClicked(true);
                }}
                placeholder="Search by User Name, Site and Physician Group"
              />
         <div style={{
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center", marginLeft: "10px"
          }}>
          <RoundedButton
            name="btnSearch"
            text="Search"
            buttonType="secondary"
            onClick={handleSearch}
            className={classes.searchButton}
          />
          <div style={{ marginLeft: "10px" }}>
          <RoundedButton
            name="btnExport"
            text="Export"
            onClick={handleExport}
            className={classes.exportButton}
            disabled={isExportButtonDisabled}
          />
          </div>
          </div>
        </div>
        </Grid>
      <Grid item className={classes.cardContainer} xs={12} sm={12} md={12} lg={12} xl={12} sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "100%", xl: "100%" },
        }}>
        {filteredSiteData &&
          filteredSiteData.length > 0 &&
          filteredSiteData.map((site: GroupedSite) => (
            <Card 
            key={site.siteId}
            sx={{
              flexBasis: '100%', // Default flexBasis for xs and sm screens (1 card per row)
              maxWidth: { 
                xs: '100%', // 1 card per row on xs 0-600px
                sm: '100%', // 1 card per row on sm 600-900px
                md: 'calc(50% - 16px)', // 2 cards per row on md 900-1200px
                lg: 'calc(33.33% - 16px)', // 3 cards per row on lg 1200-1536px
                xl: 'calc(33.33% - 16px)', // 3 cards per row on xl screens (1536px and above)
              },
              width: '100%',
              minHeight: '150px',
              borderRadius: '16px',             
              position: 'relative',
              boxShadow: '0 0 0 1px #DDDDDD',
              justifyContent: 'space-between',
            }}              
            >
                <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column',justifyContent: 'space-between', height:'100%', }}>
                  <Typography
                    component="div"
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      
                    }}
                  >
                    {site.siteName}
                  </Typography>
              
                  <Typography
                    sx={{ mb: 1.5 }}
                    style={{
                      fontWeight: 600,
                      fontSize:17,
                    }}
                  >
                    {site.physicianGroupName}
                  </Typography>
                    <ChampionView Champions={site.Champions} />
                    </Box>
                </CardContent>
            </Card>
          ))}
      </Grid>
   </Grid>
  <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
    </div>
  );
}

export default SiteChampionsView;
