import ActivityPublishHome from "../../../../components/admin/activity/activity-publish/ActivityPublishHome";

function ActivityPublishPage() {
  return (
    <>
      <ActivityPublishHome />
    </>
  );
}
export default ActivityPublishPage;
