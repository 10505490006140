import { Outlet } from "react-router-dom";
import ChampionSidebar from "../../components/champion/ChampionSidebar";
import SuccessAlert from "../../components/atoms/alerts/SuccessAlert";
import { AdminContextProvider } from "../../store/admin-context";
import HTMLErrorAlert from "../../components/atoms/alerts/HTMLErrorAlert";

function ChampionLayout() {
  return (
    <AdminContextProvider>
      <SuccessAlert />
      <HTMLErrorAlert />
      <ChampionSidebar />
      <main>
        <Outlet />
      </main>
    </AdminContextProvider>
  );
}

export default ChampionLayout;
