export interface Activity {
  activityId: string;
  activityName: string;
  activityIndex: number;
  activityStatusId: string;
  activityStartDate: string;
  performanceValidationName: string;
  includePerformanceValidation: boolean;
}

interface UniqueAccumulator {
  seen: { [key: string]: boolean };
  unique: Activity[];
}

export interface Initiative {
  initiativeId: string;
  year: number;
  initiativeName: string;
  initiativeStatusId: string;
  initiativeStatusName: string;
  initiativeStartDate: string;
  activities: Activity[];
}

export interface MappedInitiative {
  id: string;
  year: number;
  name: string;
  statusId: string;
  statusName: string;
  initiativeStartDate: string;
  activityDetails: Activity[];
}

const removeDuplicates = (initiatives: Initiative[]): any[] => {
  return initiatives.map((initiative: Initiative) => {
    const uniqueActivityDetails = initiative.activities.reduce((acc: UniqueAccumulator, activity: Activity) => {
      if (!acc.seen[activity.activityId]) {
        acc.seen[activity.activityId] = true; // Mark this activityId as seen
        acc.unique.push({ // Add the activity to the unique array
          activityId: activity.activityId,
          activityName: activity.activityName,
          activityIndex: activity.activityIndex,
          activityStatusId: activity.activityStatusId,
          activityStartDate: activity.activityStartDate,
          performanceValidationName: activity.performanceValidationName,
          includePerformanceValidation: activity.includePerformanceValidation,
        });
      }
      return acc;
    }, { seen: {}, unique: [] }).unique; // Initialize with an empty seen object and unique array, extract the unique array at the end

    return {
      initiativeId: initiative.initiativeId,
      year: initiative.year,
      initiativeName: initiative.initiativeName,
      initiativeStatusId: initiative.initiativeStatusId,
      initiativeStatusName: initiative.initiativeStatusName,
      initiativeStartDate: initiative.initiativeStartDate,
      activities: uniqueActivityDetails ? uniqueActivityDetails.sort((a, b) => a.activityIndex - b.activityIndex) : []
    };
  });
};



export const mapPerformanceInitiativesData = (initiatives: Initiative[]): MappedInitiative[] => {
  initiatives = removeDuplicates(initiatives);
  var mappedInitiatives = initiatives.map((initiative) => {
    return {
      id: initiative.initiativeId,
      year: initiative.year,
      name: initiative.initiativeName,
      statusId: initiative.initiativeStatusId,
      statusName: initiative.initiativeStatusName,
      initiativeStartDate: initiative.initiativeStartDate,
      activityDetails: initiative.activities.map((activity) => ({
        activityId: activity.activityId,
        activityName: activity.activityName,
        activityIndex: activity.activityIndex,
        activityStatusId: activity.activityStatusId,
        activityStartDate: activity.activityStartDate,
        performanceValidationName: activity.performanceValidationName,
        includePerformanceValidation: activity.includePerformanceValidation,
      })),
    };
  });
  return mappedInitiatives;
};

export const getInitiativeWithEarliestStartDate = (initiatives: MappedInitiative[]) => {
  if (initiatives.length > 0) {
    // Filter and sort initiatives based on status priority
    const filteredInitiatives: MappedInitiative[] = initiatives.sort(
      (a: MappedInitiative, b: MappedInitiative) => {
        const statusPriority = ["Published", "Deactivated", "Expired"];
        // Compare status priority
        const statusComparison =
          statusPriority.indexOf(a.statusName) -
          statusPriority.indexOf(b.statusName);
        if (statusComparison === 0) {
          // If status is the same, sort by start dates
          const dateA: Date = new Date(a.initiativeStartDate);
          const dateB: Date = new Date(b.initiativeStartDate);
          if (dateA.getTime() === dateB.getTime()) {
            // If start dates are the same, sort by initiative names
            return a.name?.localeCompare(b?.name);
          }
          return dateA.getTime() - dateB.getTime();
        }

        return statusComparison;
      }
    );
    return filteredInitiatives;
  } else {
    return null;
  }
};

export const getActivitiesWithEarliestStartDate = (initiativeSelected: any, initiatives: MappedInitiative[]) => {
  if (initiatives.length > 0) {
    let activities: Activity[] = [];
    activities =
      initiatives.find((initiative) => initiative.id === initiativeSelected)
        ?.activityDetails || [];
    const filteredActivities: Activity[] = activities.sort(
      (a: Activity, b: Activity) => {
        const indexA = activities.findIndex(
          (activity) => activity.activityIndex === a.activityIndex
        );
        const indexB = activities.findIndex(
          (activity) => activity.activityIndex === b.activityIndex
        );
        return indexA - indexB;
      }
    );
    return filteredActivities;
  } else {
    return null;
  }
};