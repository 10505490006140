import DataTable from "react-data-table-component";
import Checkbox from "@mui/material/Checkbox";
import { ArrowDownward } from "@mui/icons-material";

const sortIcon = <ArrowDownward />;

const selectProps = {
  indeterminate: (indeterminate: boolean) => indeterminate,
};

function DatatableBase<T>(props: any): JSX.Element {
  return (
    <DataTable
      selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={selectProps}
      sortIcon={sortIcon}
      // dense
      {...props}
    />
  );
}

export default DatatableBase;
