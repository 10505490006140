import classes from "./NoActivity.module.css";

function NoActivity() {
  return (
    <div className={classes.container}>
      <p>
        <h5>To get started, please add at least one activity.</h5>
      </p>
    </div>
  );
}

export default NoActivity;
