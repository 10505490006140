import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scrollToTop } from "../helpers/ui-util";

interface RouteChangeListenerProps {
  children: ReactNode;
}

const RouteChangeListener: React.FC<RouteChangeListenerProps> = ({
  children,
}) => {
  const location = useLocation();

  useEffect(() => {
    //console.log("Route changed to:", location.pathname);
    // Insert any logic you want to run on route change here
     scrollToTop();
  }, [location]);

  return <>{children}</>;
};

export default RouteChangeListener;
