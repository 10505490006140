import { Grid, Tooltip } from "@mui/material";
import classes from "./ManageUsersView.module.css";
import RoundedButton from "../../atoms/formElements/RoundedButton";
import { useContext, useEffect, useRef, useState } from "react";
import {
  getPhysicianGroup,
  getRoles,
  getSites,
  getUsersWithAssignedRoles,
} from "../../../helpers/api-util";
import useAppData from "../../../hooks/useAppData";
import ReactDataTable from "../../atoms/tables/ReactDataTable";
import UserFormDialog, { UserFormDialogRef } from "./UserFormDialog";
import React from "react";
import ManageUserSearchBox from "./ManageUserSearchBox";
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";
import AdminContext from "../../../store/admin-context";
import { mappedFilteredUsers, MappedUser } from "./ManageUsers";
import UsersListItemAction from "../../atoms/tables/UsersListItemAction";
import { handleErrorReponse } from "../../../helpers/ui-util";

export default function ManageUsersView(props: any) {
  const adminContext = useContext(AdminContext);
  const userFormDialogRef = React.useRef<UserFormDialogRef>(null);
  const [refreshData, setrefreshData] = useState(false);
  const [progress, setProgress] = useState(false);
  const [dataFiltered, setDataFiltered] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const searchInputBoxRef = React.useRef<HTMLInputElement>(null);

  function onSuccessRefresh() {
    try {
      setrefreshData(!refreshData);
      setSearchTerm("");
    } catch (ex) {
      // console.log(ex);
    }
  }

  const appUserWithRolesData = useAppData(async () => {
    const appUserWithRolesData = await getUsersWithAssignedRoles();
    const dataFilter: MappedUser[] = mappedFilteredUsers(
      appUserWithRolesData.data
    );
    setDataFiltered(dataFilter);
    return appUserWithRolesData;
  }, refreshData);

  const appRoleData = useAppData(async () => {
    return await getRoles();
  }, false);

  const appSitesData = useAppData(async () => {
    return await getSites();
  }, false);

  const appPhysicianGroupData = useAppData(async () => {
    return await getPhysicianGroup();
  }, false);

  const handleClickOpen = () => {
    if (!isAddUserDataAvailable()) {
      adminContext.onError(
        true,
        "An error occured while fetching the add users related information!"
      );
      return;
    }
    waitIndicatorLoaderRef.current?.show();
    userFormDialogRef.current?.openDialog();
    waitIndicatorLoaderRef.current?.hide();
  };

  function isAddUserDataAvailable() {
    if (
      appRoleData.loading === false &&
      appSitesData.loading === false &&
      appPhysicianGroupData.loading === false &&
      appRoleData.error === null &&
      appSitesData.error === null &&
      appPhysicianGroupData.error === null
    ) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    waitIndicatorLoaderRef.current?.show();
    let FilteredData = filterIt(searchTerm, dataFiltered);
    if (FilteredData === null) {
      FilteredData = [];
    }
    setDataFiltered(FilteredData);
    setProgress(false);
    waitIndicatorLoaderRef.current?.hide();
  }, [searchTerm, appUserWithRolesData.appData]);

  const filterIt = (query: string, data: any[]) => {
    try {
      if (query.trim() === "") return data;

      const searchTerms = query
        .toLowerCase()
        .split(" ")
        .filter((term) => term.trim() !== "");
      const firstLastNameSearch = searchTerms.filter((term) => term.length > 0);
      const physicianGroupNameSearch = searchTerms.filter(
        (term) => term.length > 0
      );
      const siteNameSearch = searchTerms.filter((term) => term.length > 0);

      return data.filter((item) => {
        const firstLastNameMatch = firstLastNameSearch.every(
          (term) =>
            item.firstName?.toLowerCase().includes(term) ||
            item.lastName?.toLowerCase().includes(term)
        );
        const siteNameMatch = siteNameSearch.every((term) =>
          item.siteName?.toLowerCase().includes(term)
        );
        const physicianGroupNameMatch = physicianGroupNameSearch.every((term) =>
          item.physicianGroupName?.toLowerCase().includes(term)
        );

        return (
          firstLastNameMatch ||
          (physicianGroupNameMatch && physicianGroupNameSearch.length > 0) ||
          siteNameMatch
        );
      });
    } catch (ex) {
      return data;
    }
  };

  function clearSearchFun() {
    onSuccessRefresh();
    setProgress(true);
  }
  function handleSearchButtonClick() {
    try {
      var searchValue = searchInputBoxRef.current?.value;
      searchValue = searchValue !== undefined ? searchValue : "";
      searchTerm !== searchValue && waitIndicatorLoaderRef.current?.show();
      setProgress(true);
      setSearchTerm(searchValue);
    } catch (ex) {
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  let returnJSX;
  if (appUserWithRolesData.loading === true) {
    returnJSX = <WaitIndicator show={true} />;
  } else if (appUserWithRolesData.error) {
    returnJSX = handleErrorReponse(appUserWithRolesData.error);
  }

  if (returnJSX) {
    return returnJSX;
  }

  const columns = [
    {
      name: "Name",
      selector: (row: { firstName: string; lastName: string }) =>
        row.firstName + " " + row.lastName,
      sortable: true,
       
    },
    {
      name: "Email",
      selector: (row: { emailAddress: string }) => row.emailAddress,
      cell: (row: { emailAddress: string }) => (
        <a
          href={`mailto:${row.emailAddress}`}
          style={{ color: "#1976D0" }}
          className="ellipsis"
        >
          {row.emailAddress}
        </a>
      ),
      sortable: true,
      
    },
    {
      name: "Role",
      selector: (row: { userRoleName: string }) => row.userRoleName,
      sortable: true,
      width: "10%",
      showOnMobileDevice: false,
      showOnTabletDevice: true,
    },
    {
      name: "Status",
      selector: (row: { userStatusName: string }) => row.userStatusName,
      sortable: true,
      width: "90px",
      showOnMobileDevice: false,
      showOnTabletDevice: true,
    },
    {
      name: "Site",
      selector: (row: { siteName: string }) => {
        return row.siteName;
      },
      cell: (row: { siteName: string }) => (
        <Tooltip title={row.siteName} placement="top" arrow>
          <div className="ellipsis">{row.siteName}</div>
        </Tooltip>
      ),
      sortable: true,
      style: {
        paddingleft: "0px!important",
      },
      width: "25%",
      showOnMobileDevice: false,
      showOnTabletDevice: false,
    },
    {
      name: "Physician Group",
      selector: (row: { physicianGroupName: string }) => {
        return row.physicianGroupName;
      },
      cell: (row: { physicianGroupName: string }) => (
        <Tooltip title={row.physicianGroupName} placement="top" arrow>
          <div className="ellipsis">{row.physicianGroupName}</div>
        </Tooltip>
      ),
      sortable: true,
      width: "15%",
      showOnMobileDevice: false,
      showOnTabletDevice: false,
    },
    {
      name: "Action",
      cell: (row: any) => (
        <UsersListItemAction
          userRoleId={row.userAssignedRoleId}
          onsuccess={onSuccessRefresh}
          userStatus={"remove"}
        />
      ),
      center: true,
      width: "85px",
    },
  ];
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.searchBoxContainer}>
            <ManageUserSearchBox
              initialSearchTerm={searchInputBoxRef.current?.value}
              searchInputBoxRef={searchInputBoxRef}
              clearSearchFun={clearSearchFun}
              placeHolderText="Search by User Name, Site and Physician Group"
            />
            <div className={classes.searchButtonContainer}>
              <RoundedButton
                name="btnsearch"
                text="Search"
                buttonType="secondary"
                onClick={handleSearchButtonClick}
                className={classes.searchButton}
              />
              <div style={{ marginLeft: "10px" }}>
                <RoundedButton
                  name="btnCreateNewUser"
                  text="Add User"
                  onClick={handleClickOpen}
                  className={classes.addUserButton}
                />
              </div>
              <UserFormDialog
                ref={userFormDialogRef}
                roles={appRoleData}
                sites={appSitesData}
                physician={appPhysicianGroupData}
                onsuccess={onSuccessRefresh}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          {appRoleData.loading ||
          appSitesData.loading ||
          appPhysicianGroupData.loading ||
          appUserWithRolesData.loading ? (
            <WaitIndicator show={true} />
          ) : (
            <ReactDataTable
              columns={columns}
              data={dataFiltered}
              progress={progress}
            ></ReactDataTable>
          )}
        </Grid>
      </Grid>
      <WaitIndicator ref={waitIndicatorLoaderRef} show={progress} />
    </div>
  );
}
