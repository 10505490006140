import ActivitySettingsHome from "../../../../components/admin/activity/activity-settings/ActivitySettingsHome";

function ActivityListPage() {
  return (
    <>
      <ActivitySettingsHome />
    </>
  );
}
export default ActivityListPage;
