import ReportsHome from "../../../components/admin/ReportsHome";

function ReportsHomePage() {
  return (
    <>
    <ReportsHome />
    </>
  );
}

export default ReportsHomePage;
