import { useState } from "react";
import { Collapse, ListItemButton } from "@mui/material";
import classes from "../activity-list/ActivityListItem.module.css";
import { ExpandMoreIcon, ExpandLessIcon } from "../../../atoms/Icons/Icon";
import Avatar from "@mui/material/Avatar";

export interface ActivityListItemProps {
  activity: any;
  children?: React.ReactNode;
}

function ActivityListItem(props: ActivityListItemProps) {
  const activity = props.activity ? props.activity : {};
  const [open, setOpen] = useState(false);

  const handleExpandCollapseButtonClick = (event: any) => {
    event.preventDefault();
    setOpen(!open);
  };

  function cancelOnClickHandler(data: any) {
    setOpen(false);
  }

  return (
    <div className={classes.ListItem} key={activity.id}>
      <div
        className={classes.ListItemRow}
        style={{
          backgroundColor: open ? "#595959" : "white",
          border: open ? "1px solid #DDDDDD;" : "",
          borderRadius: open ? "10px 10px 0px 0px" : "10px",
          color: open ? "white" : "black",
        }}
      >
        <div className={classes.container}>
          <p className={classes.name}>{activity.name}</p>
        </div>
        <div>
          <ListItemButton
            onClick={handleExpandCollapseButtonClick}
            className={classes.ListItemButton}
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
            disableRipple
          >
            {open ? (
              <Avatar
                sx={{
                  bgcolor: "white",
                  border: "1px solid",
                  borderColor: "#dddddd",
                }}
              >
                <ExpandMoreIcon />
              </Avatar>
            ) : (
              <Avatar
                sx={{
                  bgcolor: "white",
                  border: "1px solid",
                  borderColor: "#dddddd",
                }}
              >
                <ExpandLessIcon />
              </Avatar>
            )}
          </ListItemButton>
        </div>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </div>
  );
}

export default ActivityListItem;
