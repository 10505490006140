import React from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from "survey-react-ui";
import MUIRichTextEditor from "mui-rte";
import { convertFromHTML, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";

const CUSTOM_PARAGRAPHQUESTION_TYPE = "Paragraph";

class ParagraphHtmlModel extends Question {
  public constructor(paragraph = "") {
    super(paragraph);
    this.titleLocation = "hidden";
    this.createLocalizableString("paragraph", this);
    this.paragraph = paragraph;
  }
  public getType(): string {
    return CUSTOM_PARAGRAPHQUESTION_TYPE;
  }

  public get paragraph(): string {
    return this.getLocalizableStringText("paragraph");
  }
  public set paragraph(val: string) {
    this.setLocalizableStringText("paragraph", val);
  }
  public get locParagraph(): any {
    return this.getLocalizableString("paragraph");
  }
}

export function registerParagraphQuestion() {
  ElementFactory.Instance.registerElement(
    CUSTOM_PARAGRAPHQUESTION_TYPE,
    (name) => {
      return new ParagraphHtmlModel(name);
    }
  );
}

Serializer.addClass(
  CUSTOM_PARAGRAPHQUESTION_TYPE,
  [
    {
      name: "paragraph",
      category: "general",
      visibleIndex: 2,
      serializationProperty: "locParagraph",
    },
  ],
  function () {
    return new ParagraphHtmlModel("");
  },
  "question"
);

class SurveyQuestionParagraph extends SurveyQuestionElementBase {
  public constructor(props: any) {
    super(props);
    this.question.hideNumber = true;
    this.question.titleLocation = "hidden";
  }

  protected get question(): ParagraphHtmlModel {
    return this.questionBase as ParagraphHtmlModel;
  }

  public renderElement(): JSX.Element {
    //const sampleMarkup =
    //  '<b>Bold text</b>, <i>Italic text</i><br/ ><br />Other text<br /><br /><a href="http://myurl.com">Some link</a>';
    const sampleMarkup = this.question.locParagraph.textOrHtml;
    const contentHTML = convertFromHTML(sampleMarkup);
    const state = ContentState.createFromBlockArray(
      contentHTML.contentBlocks,
      contentHTML.entityMap
    );
    const content = JSON.stringify(convertToRaw(state));

    const onEditorChange = (event: any) => {
      //const plainText = event.getCurrentContent().getPlainText(); // for plain text
      const rteContent = convertToRaw(event.getCurrentContent()); // for rte content with text formating
      this.question.paragraph = draftToHtml(rteContent);
    };

    if (this.question.isDesignMode) {
      return (
        <div
          style={{
            paddingBottom: "20px",
          }}
        >
          <MUIRichTextEditor
            defaultValue={content}
            controls={[
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "link",
              "numberList",
              "bulletList",
              "quote",
              "code",
              "clear",
            ]}
            label="Start typing..."
            onChange={onEditorChange}
          />
        </div>
      );
      //return SurveyElementBase.renderLocString(this.question.locParagraph);
    } else {
      return (
        <div
          style={{
            maxWidth: "100%",
            display: "block",
            whiteSpace: "pre-wrap",
          }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(this.question.locParagraph.textOrHtml),
          }}
        ></div>
      );
    }
  }
}

ReactQuestionFactory.Instance.registerQuestion(
  CUSTOM_PARAGRAPHQUESTION_TYPE,
  (props) => {
    return React.createElement(SurveyQuestionParagraph, props);
  }
);
