import { useState } from "react";
import PerformanceValidationView from "./performance-validation/PerformanceValidationView";
import classes from "./PerformanceValidationHome.module.css";

function PerformanceValidationHome() {
  const [selectedActivityId, setSelectedActivityId] = useState<string>("");

  return (
    <div className={classes.container}>
      <div className={classes.container_header}>
        <div className={classes.container_header_title}>
          <h1>Performance Validation</h1>
        </div>
      </div>
      <div className={classes.container_content}>
        <PerformanceValidationView
          selectedActivityId={selectedActivityId}
          onActivityChange={setSelectedActivityId}
        />
      </div>
    </div>
  );
}

export default PerformanceValidationHome;
