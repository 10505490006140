import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { NavLink } from "react-router-dom";

export interface BackNavLinkProps {
  to: string;
  text: string;
}

function BackNavLink(props: any): JSX.Element {
  const linkStyle = {
    display: "flex",
    color: "#000000", // Set the text color to black
    textDecoration: "none", // Remove underline
  };
  return (
    <span
      style={{
        display: "flex",
      }}
    >
      <NavLink to={props.to} style={linkStyle}>
        <ArrowBackOutlinedIcon fontSize="small" />
        {props.text}
      </NavLink>
    </span>
  );
}

export default BackNavLink;
