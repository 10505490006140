import classes from "./EnrollmentHome.module.css";
import EnrollmentView from "./enrollment/EnrollmentView";

export default function EnrollmentHome() {
  return (
    <div className={classes.container}>
      <div className={classes.container_header}>
        <div className={classes.container_header_title}>
          <h1>Enrollment</h1>
        </div>
      </div>
      <div className={classes.enrollment_container}>
        <EnrollmentView />
      </div>
    </div>
  );
}
