export interface User {
    emailAddress: string;
    firstName: string;
    id: string;
    lastName: string;
    physicianGroupId: string;
    physicianGroupName: string;
    siteId: string;
    siteName: string;
    userAssignedRoleId: string;
    userId: string;
    userRoleName: string;
    userStatusId: string;
    userStatusName: string;
  }
export interface MappedUser {
    emailAddress: string;
    firstName: string;
    lastName: string;
    id: string;
    physicianGroupId: string;
    physicianGroupName: string;
    siteId: string;
    siteName: string;
    userAssignedRoleId: string;
    userId: string;
    userRoleName: string;
    userStatusId: string;
    userStatusName: string;
  }

export const mappedFilteredUsers = (users: User[]): MappedUser[] => {
  return users?.map((user) => {
    return {
      emailAddress: user.emailAddress,
      firstName: user.firstName,
      lastName: user.lastName,
      id: user.id,
      physicianGroupId: user.physicianGroupId,
      physicianGroupName: user.physicianGroupName,
      siteId: user.siteId,
      siteName: user.siteName,
      userAssignedRoleId: user.userAssignedRoleId,
      userId: user.userId,
      userRoleName: user.userRoleName,
      userStatusId: user.userStatusId,
      userStatusName: user.userStatusName,
    };
  });
}