import { useState } from "react";
import { GetLastUpdated } from "../../../../helpers/api-util";
import {
  getUIDateTimeFormat,
  handleErrorReponse,
} from "../../../../helpers/ui-util";
import useAppData from "../../../../hooks/useAppData";
import WaitIndicator from "../../../atoms/WaitIndicator";
import classes from "./LatestUpdatedDate.module.css";

function LatestUpdatedDate() {
  const [refreshData, setRefreshData] = useState(false);
  const latestUpdateddateAPI = useAppData(async () => {
    return await GetLastUpdated();
  }, refreshData);

  if (latestUpdateddateAPI?.loading) {
    return (
      <>
        <div>
          <WaitIndicator show={true}></WaitIndicator>
        </div>
      </>
    );
  }
  if (latestUpdateddateAPI?.error) {
    const errorJSX = handleErrorReponse(latestUpdateddateAPI.error);
    return (
      <>
        <div>{errorJSX}</div>
      </>
    );
  } else {
    const formattedSitesDate = getUIDateTimeFormat(
      latestUpdateddateAPI?.appData &&
        latestUpdateddateAPI.appData[0]?.lastUpdatedDateTime
    );
    const formattedUsersDate = getUIDateTimeFormat(
      latestUpdateddateAPI?.appData &&
        latestUpdateddateAPI.appData[1]?.lastUpdatedDateTime
    );

    return (
      <>
        <div className={classes.latestUpdatedDates_label}>
          <div>
            <strong>Latest Sites Update:</strong> {formattedSitesDate}
          </div>
        </div>
        <div className={classes.latestUpdatedDates_label}>
          <div>
            <strong>Latest Users Update:</strong> {formattedUsersDate}
          </div>
        </div>
      </>
    );
  }
}

export default LatestUpdatedDate;
