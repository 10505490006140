import WizardAction from "../../../atoms/WizardAction";
function ActivityBuilderAction(props: any) {
  return (
    <WizardAction
      backButtonText="Back"
      continueButtonText="Save and Continue"
      backOnClick={props.backOnClick}
      continueOnClick={props.continueOnClick}
      continueDisabled={!props.canContinue}
    />
  );
}

export default ActivityBuilderAction;
