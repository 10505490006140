import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreVert } from "@mui/icons-material";
import AdminContext from "../../../../store/admin-context";
import { useNavigate } from "react-router-dom";
import DeleteConfirmDialog from "../../../atoms/dialogs/DeleteConfirmDialog";
import {
  activateInitiative,
  deActivateInitiative,
  deleteInitiativeData,
  duplicateInitiative,
} from "../../../../helpers/api-util";
import WaitIndicator, { WaitIndicatorRef } from "../../../atoms/WaitIndicator";
import { useRef, useState } from "react";
import { scrollToTop } from "../../../../helpers/ui-util";

interface InitiativeListItemActionProps {
  initiativeId: string;
  onDataRefresh: () => void;
  initiativeStatus: string;
}
function InitiativeListItemAction(props: InitiativeListItemActionProps) {
  const menuDisplay = {
    Duplicate: false,
    Delete: false,
    Deactivate: false,
    Activate: false,
    Edit: false,
  };

  const { initiativeStatus } = props;
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const navigate = useNavigate();
  const adminContext = React.useContext(AdminContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);

  const open = Boolean(anchorEl);

  const actionButtonClickHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function editClickHandler() {
    setAnchorEl(null);
    adminContext.setInitiativeId(props.initiativeId);
    navigate("/admin/initiative/update");
  }

  async function duplicateClickHandler() {
    try {
      waitIndicatorLoaderRef.current?.show();
      setAnchorEl(null);
      const response = await duplicateInitiative(props.initiativeId);
      waitIndicatorLoaderRef.current?.hide();
      handleActionResponse(
        response,
        "Initiative duplicated successfully!",
        "Error duplicating initiative!"
      );
      scrollToTop();
    } catch (ex) {
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  function deleteClickHandler() {
    setAnchorEl(null);
    setConfirmOpen(true);
  }

  function handleActionResponse(
    response: any,
    successMessage: string,
    errorMessage: string
  ) {
    if (response.data != null) {
      adminContext.onRecordSave(true, successMessage);
      props.onDataRefresh();
    } else {
      adminContext.onError(true, errorMessage);
    }
  }

  async function deleteInitiative() {
    try {
      waitIndicatorLoaderRef.current?.show();
      const response = await deleteInitiativeData(props.initiativeId);
      waitIndicatorLoaderRef.current?.hide();
      handleActionResponse(
        response,
        "Initiative deleted successfully!",
        "Error deleting initiative!"
      );
    } catch (ex) {
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  async function onDeactivateClickHandler() {
    try {
      waitIndicatorLoaderRef.current?.show();
      setAnchorEl(null);
      const response = await deActivateInitiative(props.initiativeId);
      waitIndicatorLoaderRef.current?.hide();
      handleActionResponse(
        response,
        "Initiative deactivated successfully!",
        "Error deactivating initiative!"
      );     
    } catch (ex) {
      waitIndicatorLoaderRef.current?.hide();
    }
  }
  async function onActivateClickHandler() {
    try {
      waitIndicatorLoaderRef.current?.show();
      setAnchorEl(null);
      const response = await activateInitiative(props.initiativeId);
      waitIndicatorLoaderRef.current?.hide();
      handleActionResponse(
        response,
        "Initiative reactivated successfully!",
        "Error reactivating initiative!"
      );     
    } catch (ex) {
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  if (initiativeStatus === "Draft") {
    menuDisplay.Edit = true;
    menuDisplay.Duplicate = true;
    menuDisplay.Delete = true;
  }
  if (initiativeStatus === "Published") {
    menuDisplay.Edit = true;
    menuDisplay.Duplicate = true;
    menuDisplay.Deactivate = true;
  }
  if (initiativeStatus === "Deactivated") {
    menuDisplay.Edit = true;
    menuDisplay.Duplicate = true;
    menuDisplay.Activate = true;
  }
  if (initiativeStatus === "Expired") {
    menuDisplay.Duplicate = true;
    menuDisplay.Edit = true;
  }

  return (
    <div>
      <DeleteConfirmDialog
        title="Are you sure?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deleteInitiative}
      />
      <Button
        id="action-button"
        aria-controls={open ? "action-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={actionButtonClickHandler}
        style={{ color: "black" }}
      >
        <MoreVert />
      </Button>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        MenuListProps={{
          "aria-labelledby": "action-button",
        }}
      >
        <div
          style={{
            display: menuDisplay.Edit ? "block" : "none",
          }}
        >
          <MenuItem onClick={editClickHandler}>Edit</MenuItem>
        </div>

        <div
          style={{
            display: menuDisplay.Duplicate ? "block" : "none",
          }}
        >
          <MenuItem onClick={duplicateClickHandler}>Duplicate</MenuItem>
        </div>
        <div
          style={{
            display: menuDisplay.Delete ? "block" : "none",
          }}
        >
          <MenuItem onClick={deleteClickHandler}>Delete</MenuItem>
        </div>

        <div style={{ display: menuDisplay.Deactivate ? "block" : "none" }}>
          <MenuItem onClick={onDeactivateClickHandler}>Deactivate</MenuItem>
        </div>
        <div style={{ display: menuDisplay.Activate ? "block" : "none" }}>
          <MenuItem onClick={onActivateClickHandler}>Reactivate</MenuItem>
        </div>
      </Menu>
      <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
    </div>
  );
}

export default InitiativeListItemAction;
