import { useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  getInitiativeById,
  saveInitiativeIcon,
  updateInitiative,
} from "../../../helpers/api-util";
import AdminContext from "../../../store/admin-context";
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";
import WizardHeader from "../../atoms/WizardHeader";
import InitiativeForm from "./InitiativeForm";
import useAppData from "../../../hooks/useAppData";
import { handleErrorReponse } from "../../../helpers/ui-util";

function UpdateInitiative() {
  const [refreshData, setrefreshData] = useState(false);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);

  const initiativeData = useAppData(async () => {
    var initiativeResponse = await getInitiativeById(adminContext.initiativeId);
    if (initiativeResponse.data != null) {
      adminContext.updateInitiative(initiativeResponse.data);
    } else {
      adminContext.onError(true, "Unable to fetch Initiative Information!");
    }
    return initiativeResponse;
  }, refreshData);

  const adminContext = useContext(AdminContext);
  const navigate = useNavigate();

  async function updateInitiativeInfo(initiativeData: any, iconFile: File[]) {
    let response = await updateInitiative(initiativeData);
    if (response.error && response.error.response) {
      const errorData = response.error.response.data as {
        errors: { LongSummary: string[] };
      };

      if (errorData && errorData.errors && errorData.errors.LongSummary) {
        const longSummaryError = errorData.errors.LongSummary[0];

        adminContext.onError(true, longSummaryError);
      }

      return response;
    }
    if (response.error != null) {
      adminContext.onError(true, "Unable to save Initiative Information!");
      return response;
    }

    if (response.data != null && iconFile && iconFile.length > 0) {
      response = await saveInitiativeIcon(
        initiativeData,
        response.data.id,
        iconFile[0]
      );
      if (response.error != null) {
        const errorString = JSON.stringify(response.error.response?.data);
        if (errorString !== null && errorString !== "") {
          adminContext.onError(true, errorString);
        } else {
          adminContext.onError(true, "Unable to save Initiative Icon!");
        }

        return response;
      }
    }

    if (response.data != null) {
      adminContext.onRecordSave(true, "Initiative saved successfully!");
      adminContext.updateInitiative(response.data);
      return response;
    }
    return response;
  }

  const onSaveHandler = async (initiativeData: any, iconFile: File[]) => {
    try {
      waitIndicatorLoaderRef.current?.show();
      await updateInitiativeInfo(initiativeData, iconFile);
      waitIndicatorLoaderRef.current?.hide();
      setrefreshData(!refreshData);
    } catch (ex) {
      waitIndicatorLoaderRef.current?.hide();
    }
  };

  const onSaveAndContinueHandler = async (
    initiativeData: any,
    iconFile: File[]
  ) => {
    try {
      waitIndicatorLoaderRef.current?.show();
      let response = await updateInitiativeInfo(initiativeData, iconFile);
      waitIndicatorLoaderRef.current?.hide();
      if (response.data != null) {
        navigate("/admin/initiative/activity/list");
        return;
      }
    } catch (ex) {
      waitIndicatorLoaderRef.current?.hide();
    }
  };

  const onBackClickHandler = (): void => {
    navigate("/admin/initiative/list");
  };

  if (initiativeData.loading === true) {
    return (
      <>
        <WizardHeader title="Initiative Settings" />
        <WaitIndicator show={true} />
      </>
    );
  }

  if (initiativeData.error) {
    const errorJSX = handleErrorReponse(initiativeData.error);
    waitIndicatorLoaderRef.current?.hide();
    return <>errorJSX</>;
  }

  return (
    <>
      <InitiativeForm
        initiative={initiativeData.appData}
        onSave={onSaveHandler}
        onSaveAndContinue={onSaveAndContinueHandler}
        onBackClick={onBackClickHandler}
      />
      <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
    </>
  );
}

export default UpdateInitiative;
