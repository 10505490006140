import React from "react";
import ConfirmDialog from "./ConfirmDialog";

export interface MessageConfirmDialogRef {
  openDialog: () => void;
}

export interface MessageConfirmDialogProps {
  title?: string;
  children?: string;
  onConfirm?: () => void;
  onClose?: () => void;
}

const MessageConfirmDialog = React.forwardRef<
  MessageConfirmDialogRef,
  MessageConfirmDialogProps
>((props, ref) => {
  const [open, setOpen] = React.useState(false);
  let { title, children, onConfirm, onClose } = props;
  const openDialog = () => {
    setOpen(true);
  };
  React.useImperativeHandle(ref, () => ({
    openDialog,
  }));

  if (!title) {
    title = "Error";
  }
  if (!children) {
    children = "An error has occurred.";
  }

  return (
    <ConfirmDialog
      title={title}
      children={children}
      open={open}
      setOpen={setOpen}
      onConfirm={onConfirm}
      onClose={onClose}
      showCancelButton={false}
      confirmButtonTitle="Close"
      style={{
        DialogTitle: {         
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "40px",
        },
        DialogContent: {        
          fontWeight: 400,
          fontSize: "16px",               
        },
      }}
    />
  );
});

export default MessageConfirmDialog;
