import React, { useState, useEffect, useRef } from "react";
import {
  getPerformanceValidationSiteData,
  deleteFilesToAzureStorageContainer,
} from "../../../helpers/api-util";
import ReactDataTable from "../../atoms/tables/ReactDataTable";
import { getUIFormattedDate } from "../../../helpers/ui-util";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";

interface Props {
  activityData: any;
  disableUpload: (show: boolean) => void;
}

const PerformanceValidationTable: React.FC<Props> = ({
  activityData,
  disableUpload,
}) => {
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const [performanceValidationName, setPerformanceValidationName] =
    useState<string>("");

  const fetchData = async () => {
    try {
      waitIndicatorLoaderRef.current?.show();
      const activityId = activityData.toString();

      const siteData = await getPerformanceValidationSiteData(activityId);
      if (siteData.data.length > 0)
        setPerformanceValidationName(
          siteData.data[0]?.performanceValidationName
        );
      const siteDataArray = Array.isArray(siteData.data)
        ? siteData.data.map((site: any) => {
            const updateDate = site?.lastModified
              ? getUIFormattedDate(site.lastModified)
              : "";
            setPerformanceValidationName(site.performanceValidationName);
            return {
              siteNumber: site.siteNumber,
              siteName: site.siteName,
              fileName: site.fileName,
              lastModified: updateDate,
              fileUrl: site.fileUrl,
              fileSasUrl: site.fileSasUrl,
            };
          })
        : [];
      setData(siteDataArray);
      waitIndicatorLoaderRef.current?.hide();
    } catch (error) {
      waitIndicatorLoaderRef.current?.hide();
    }
  };

  useEffect(() => {
    fetchData();
  }, [activityData, refreshData]);

  const columns = [
    {
      name: "Site",
      selector: (row: { siteName: string }) => row.siteName,
      cell: (row: { siteName: string }) => (
        <Tooltip title={row.siteName} arrow>
          <div className="ellipsis">{row.siteName}</div>
        </Tooltip>
      ),
      sortable: true,
      width: "38%",
      adjustWidthOnSmallerDevice : true,
    },
    {
      name: "Site Number",
      selector: (row: { siteNumber: string }) => row.siteNumber,
      sortable: true,
      width: "135px",
    },
    {
      name: performanceValidationName,
      selector: (row: { fileName: string }) => row.fileName,
      cell: (row: {
        fileName: string;
        fileSasUrl: string;
        fileUrl: string;
      }) => (
        <a
          target="_blank"
          className="ellipsis"
          style={{ color: "#1976D0" }}
          href={row.fileSasUrl ? row.fileSasUrl : row.fileUrl}
        >
          {row.fileName}
        </a>
      ),
      width: "25%",
      sortable: true,
      adjustWidthOnSmallerDevice : true,
    },
    {
      name: "Upload Date",
      selector: (row: { lastModified: string }) => row.lastModified,
      showOnMobileDevice: false,
      showOnTabletDevice: false,
      
    },
    {
      name: "Delete",
      cell: (row: { fileUrl: string }) => {
        if (row.fileUrl) {
          return (
            <IconButton
              onClick={async () => {
                waitIndicatorLoaderRef.current?.show();
                await deleteFilesToAzureStorageContainer(row.fileUrl);
                waitIndicatorLoaderRef.current?.hide();
                setRefreshData(!refreshData);
              }}
              sx={{ color: "#000000" }}
            >
              <CloseIcon />
            </IconButton>
          );
        }
        return null;
      },
      right: true,
      width: "78px",
    },
  ];

  const lastModifiedRowCount = data.filter(
    (row: { fileUrl: string }) => row.fileUrl !== null
  ).length;

  if (
    (lastModifiedRowCount > 0 && data.length > 0) ||
    (lastModifiedRowCount === 0 && data.length > 0)
  ) {
    disableUpload(false);
  } else {
    disableUpload(true);
  }
  return (
    <Grid container alignItems="center">
      <Grid item>
        <Typography variant="h3" sx={{ marginLeft: "10px" }}>
          {lastModifiedRowCount}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h5" sx={{ marginLeft: "10px" }}>
          Total Uploads
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h3" sx={{ marginLeft: "40px" }}>
          {data.length}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h5" sx={{ marginLeft: "10px" }}>
          Sites Completed
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          sx={{ marginTop: "8px", fontSize: "16px", lineHeight: "20px" }}
        >
          Please name your file with the Site Number and Performance Validation
          Name, example A123456_capabilities.
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "40px" }}>
        <ReactDataTable data={data} columns={columns} noDataComponent={""} />
      </Grid>
      <WaitIndicator ref={waitIndicatorLoaderRef} />
    </Grid>
  );
};

export default PerformanceValidationTable;
