import classes from "./WizardHeader.module.css";

export interface ActivityBuilderHeaderProps {
  title: string;
}

function WizardHeader(props: ActivityBuilderHeaderProps) {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>
          <h2>{props.title} </h2>
        </div>
      </div>
    </div>
  );
}

export default WizardHeader;
