import { useLocation } from "react-router-dom";
import { useState } from "react";
import BackNavLink from "../../atoms/BackNavLink";
import useAppData from "../../../hooks/useAppData";
import {
  getSiteActivitiesBySiteInitiativeEnrollmentId,
  getInitiativeActivities,
} from "../../../helpers/api-util";
import ActivityQuestionnaireList from "./activity-questionnaires/ActivityQuestionnaireList";
import WaitIndicator from "../../atoms/WaitIndicator";
import { Select, MenuItem, setRef } from "@mui/material";
import classes from "./InitiativeQuestionnaires.module.css";

function InitiativeQuestionnaires() {
  const location = useLocation();
  const [refreshData, setRefreshData] = useState(false);
  const [initiativeEnrollmentInfo, setInitiativeEnrollmentInfo] =
    useState<InitiativesEnrollment>(location.state.initiativeEnrollmentInfo);
  const [sameSiteEnrollmentData, setSameSiteEnrollmentData] = useState<
    InitiativesEnrollment[]
  >(location.state.sameSiteEnrollmentData);
  const [expandedActivity, setExpandedActivity] = useState<any>(null);

  const initiativeActivityAnswers = useAppData(async () => {
    return await getSiteActivitiesBySiteInitiativeEnrollmentId(
      initiativeEnrollmentInfo.id
    );
  }, refreshData);

  const initiativeActivities = useAppData(async () => {
    return await getInitiativeActivities(initiativeEnrollmentInfo.initiativeId);
  }, refreshData);

  function onSubmit(enrollData: any, expandedActivity: any) {
    setExpandedActivity(expandedActivity);
    setRefreshData(!refreshData);
  }

  function handleSiteChange(event: any) {
    const selectedSiteId = event.target.value as string;
    const selectedSiteEnrollmentData = sameSiteEnrollmentData.find(
      (site) => site.id === selectedSiteId
    );
    if (selectedSiteEnrollmentData) {
      setInitiativeEnrollmentInfo(selectedSiteEnrollmentData);
      setRefreshData(!refreshData);
    }
  }

  if (initiativeActivities.loading || initiativeActivityAnswers.loading) {
    return <WaitIndicator show={true} />;
  }
  return (
    <div className="initiativeQues">
      <BackNavLink to="/champion/initiatives" text="Back to Initiatives" />
      <h2>{initiativeEnrollmentInfo.initiativeName}</h2>
      {sameSiteEnrollmentData && sameSiteEnrollmentData.length === 1 ? (
        <div className={classes.site_container}>
          <h2 className={classes.site_font}>
            {initiativeEnrollmentInfo.siteName}
          </h2>
        </div>
      ) : (
        <div className={classes.site_container}>
          <h2 className={classes.site_font}>
            {initiativeEnrollmentInfo.siteName}
          </h2>
          <div style={{ alignSelf: "center" }}>
            <Select
              value={
                initiativeEnrollmentInfo ? initiativeEnrollmentInfo.id : ""
              }
              onChange={handleSiteChange}
              MenuProps={{
                PaperProps: {
                  elevation: 0,
                },
              }}
              className={classes.select}
            >
              {sameSiteEnrollmentData &&
                sameSiteEnrollmentData.map((initiativeInfo) => (
                  <MenuItem
                    key={initiativeInfo.id}
                    value={initiativeInfo.id}
                    className={classes.menuItem}
                  >
                    {initiativeInfo.siteName}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
      )}
      <ActivityQuestionnaireList
        selectedRow={initiativeEnrollmentInfo}
        activities={initiativeActivities.appData}
        answers={initiativeActivityAnswers.appData}
        onSubmit={onSubmit}
        isDisabled={initiativeEnrollmentInfo.initiativeStatusName === "Expired"}
        expandedActivity={expandedActivity}
      />
    </div>
  );
}

export default InitiativeQuestionnaires;
