import React, { useState, useEffect, useRef, useContext } from "react";
import {
  getPerformanceValidationSiteData,
  processError,
} from "../../../helpers/api-util";
import ReactDataTable from "../../atoms/tables/ReactDataTable";
import { getUIFormattedDate } from "../../../helpers/ui-util";
import { Grid, Tooltip } from "@mui/material";
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";
import AdminContext from "../../../store/admin-context";

interface Props {
  activityData: any;
  recordsFound: (show: boolean) => void;
}

const PerformanceValidationTable: React.FC<Props> = ({
  activityData,
  recordsFound,
}) => {
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const [performanceValidationName, setPerformanceValidationName] =
    useState<string>("");

  const adminContext = useContext(AdminContext);

  const fetchData = async () => {
    try {
      waitIndicatorLoaderRef.current?.show();
      const activityId = activityData.toString();

      const siteData = await getPerformanceValidationSiteData(activityId);
      if (siteData.error) {
        var errorString = processError(siteData.error);
        adminContext.onError(true, errorString);
        waitIndicatorLoaderRef.current?.hide();
        return;
      }
      if (siteData.data.length > 0)
        setPerformanceValidationName(
          siteData.data[0]?.performanceValidationName
        );
      const siteDataArray = Array.isArray(siteData.data)
        ? siteData.data.map((site: any) => {
            const updateDate = site?.lastModified
              ? getUIFormattedDate(site.lastModified)
              : "";
            setPerformanceValidationName(site.performanceValidationName);
            return {
              siteNumber: site.siteNumber,
              siteName: site.siteName,
              fileName: site.fileName,
              lastModified: updateDate,
              fileUrl: site.fileUrl,
              fileSasUrl: site.fileSasUrl,
            };
          })
        : [];
      setData(siteDataArray);
      waitIndicatorLoaderRef.current?.hide();
    } catch (error) {
      waitIndicatorLoaderRef.current?.hide();
    }
  };

  useEffect(() => {
    fetchData();
  }, [activityData, refreshData]);

  const columns = [
    {
      name: "Site",
      selector: (row: { siteName: string }) => row.siteName,
      cell: (row: { siteName: string }) => (
        <Tooltip title={row.siteName} arrow>
          <div className="ellipsis">{row.siteName}</div>
        </Tooltip>
      ),
      sortable: true,
      width: "28%",
    },
    {
      name: "Site Number",
      selector: (row: { siteNumber: string }) => row.siteNumber,
      sortable: true,
    },
    {
      name: performanceValidationName,
      selector: (row: { fileName: string }) => row.fileName,
      cell: (row: {
        fileName: string;
        fileSasUrl: string;
        fileUrl: string;
      }) => (
        <a
          target="_blank"
          className="ellipsis"
          style={{ color: "#1976D0" }}
          href={row.fileSasUrl ? row.fileSasUrl : row.fileUrl}
        >
          {row.fileName}
        </a>
      ),
      width: "24%",
      sortable: true,
    },
    {
      name: "Upload Date",
      selector: (row: { lastModified: string }) => row.lastModified,
    },
  ];

  const downloadUrlsCount = data.filter(
    (row: { fileSasUrl: string }) => row.fileSasUrl !== null
  ).length;

  if (data.length > 0) {
    if (downloadUrlsCount > 0) {
      recordsFound(true);
    } else {
      recordsFound(false);
    }
  } else {
    recordsFound(false);
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} style={{ marginTop: "40px" }}>
        <ReactDataTable data={data} columns={columns} noDataComponent={""} />
      </Grid>
      <WaitIndicator ref={waitIndicatorLoaderRef} />
    </Grid>
  );
};

export default PerformanceValidationTable;
