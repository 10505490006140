import { useContext, useRef } from "react";
import WaitIndicator, { WaitIndicatorRef } from "../../../atoms/WaitIndicator";
import WizardHeader from "../../../atoms/WizardHeader";
import Questionnaire from "../../questionnaire/Questionnaire";
import AdminContext from "../../../../store/admin-context";
import WizardAction from "../../../atoms/WizardAction";
import { useNavigate } from "react-router-dom";
import WarningMessage from "../../../atoms/WarningMessage";
import { useEnrollmentQuestionnaireData } from "./ManageEnrollmentQuestionnaireLayout";
import { handleErrorReponse, scrollToTop } from "../../../../helpers/ui-util";

function EnrollmentQuestionnaireBuilder() {
  var navigate = useNavigate();
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const adminContext = useContext(AdminContext);
  const enrollmentQuesData = useEnrollmentQuestionnaireData();

  function saveOnClickHandler() {
    enrollmentQuesData?.refreshData();
    scrollToTop();
  }

  function cancelOnClickHandler() {
    backOnClickHandler();    
  }

  function backOnClickHandler() {
    if (adminContext.enrollmentId === "") {
      navigate("/admin/questionnaire/create");
    } else {
      navigate("/admin/questionnaire/update");
    }
  }

  function continueOnClickHandler() {
    enrollmentQuesData?.refreshData();
    navigate("/admin/questionnaire/publish");
  }

  function canContinue() {
    if (
      enrollmentQuesData.enrollmentQuesData.appData &&
      enrollmentQuesData.enrollmentQuesData.appData.initiativeActivity &&
      enrollmentQuesData.enrollmentQuesData.appData.initiativeActivity
        .hasQnDefinitions
    ) {
      return true;
    }
    return false;
  }

  if (enrollmentQuesData.enrollmentQuesData?.loading) {
    return (
      <>
        <WizardHeader title="Questionnaire Builder" />
        <WaitIndicator ref={waitIndicatorLoaderRef} show={true} />
      </>
    );
  }

  if (enrollmentQuesData.enrollmentQuesData?.error) {
    const errorJSX = handleErrorReponse(
      enrollmentQuesData.enrollmentQuesData.error
    );
    return (
      <>
        <WizardHeader title="Questionnaire Builder" />
        {errorJSX}
        <WaitIndicator ref={waitIndicatorLoaderRef} show={true} />
      </>
    );
  }

  if (
    enrollmentQuesData.enrollmentQuesData.appData &&
    enrollmentQuesData.enrollmentQuesData.appData.initiativeActivity
  ) {
    var isPublished =
      enrollmentQuesData.enrollmentQuesData.appData.enrollmentStatus?.name ===
      "Published";

    return (
      <>
        <WizardHeader title="Questionnaire Builder" />
        <h2>
          {enrollmentQuesData.enrollmentQuesData.appData.year}{" "}
          {enrollmentQuesData.enrollmentQuesData.appData.title}
        </h2>

        <WarningMessage
          showWarning={isPublished}
          message="Changing questions to a published questionnaire can result in answers
          already received becoming irrelevant and unrelated."
        />
        <Questionnaire
          activityId={
            enrollmentQuesData.enrollmentQuesData.appData.initiativeActivity.id
          }
          saveOnClick={saveOnClickHandler}
          cancelOnClick={cancelOnClickHandler}
        />
        <WizardAction
          backButtonText="Back"
          continueButtonText="Save and Continue"
          backOnClick={backOnClickHandler}
          continueOnClick={continueOnClickHandler}
          continueDisabled={!canContinue()}
        />
        <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
      </>
    );
  }

  return <></>;
}

export default EnrollmentQuestionnaireBuilder;
