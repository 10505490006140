import ManageInitiativeTabs from "./initiative/manage-initiative/ManageInitiativeTabs";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AdminContext from "../../store/admin-context";

import {
  ContainerHeader,
  ContainerButton,
  ContainerContent,
  MainContainer,
} from "../atoms/container/ContentContainer";

export default function ManageInitiativeHome(props: any) {
  const adminContext = useContext(AdminContext);
  const navigate = useNavigate();
  function btnCreateNewInitiativeClickHandler(event: any) {
    adminContext.setInitiativeId("");
    adminContext.updateInitiative({});
    navigate("/admin/initiative/create");
  }

  return (
    <MainContainer>
      <ContainerHeader text="Manage Initiatives" />
      <ContainerButton
        name="btnCreateNewInitiative"
        text="Create New Initiative"
        onClick={btnCreateNewInitiativeClickHandler}
        buttonType="primary"
      />

      <ContainerContent>
        <ManageInitiativeTabs />
      </ContainerContent>
    </MainContainer>
  );
}
