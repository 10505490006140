import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import classes from "./PerformanceValidationUploader.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { FileWithPath, useDropzone } from "react-dropzone";
import { uploadPeformanceValidationReports } from "../../../helpers/api-util";
import { batchFiles } from "../../../helpers/ui-util";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import perfValidationOptions from "../../../store/performanceValidatorUploadOptions.json";

export interface PerformanceValidationUploaderRef {
  openDialog: () => void;
  //setUploadLocation: (containerName: string, folderStructure: string) => void;
  init: (initiativeId: string, activityId: string) => void;
}

export interface PerformanceValidationUploaderProps {
  onUploadComplete: () => void;
}

const PerformanceValidationUploader = React.forwardRef<
  PerformanceValidationUploaderRef,
  PerformanceValidationUploaderProps
>((props, ref) => {
  const [open, setOpen] = React.useState(false);
  //const [containerName, setContainerName] = useState<string>("");
  //const [folderStructure, setFolderStructure] = useState<string>("");
  const [initiativeId, setInitiativeId] = useState<string>("");
  const [activityId, setActivityId] = useState<string>("");
  const [acceptedFiles, setAcceptedFiles] = useState<FileWithPath[]>([]);
  const [processedFilesCount, setProcessedFilesCount] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [allFilesCount, setAllFilesCount] = useState<number>(0);
  const [uploadedFilesCount, setUploadedFilesCount] = useState<number>(0);
  const [acceptedFilesCount, setAcceptedFilesCount] = useState<number>(0);
  const [failedFilesCount, setFailedFilesCount] = useState<number>(0);
  const isMountedRef = useRef(true);

  const openDialog = () => {
    // Open the dialog
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
    if (processedFilesCount > 0) {
      props.onUploadComplete();
    }
  };

  const resetState = () => {
    setAllFilesCount(0);
    setUploadedFilesCount(0);
    setFailedFilesCount(0);
    setAcceptedFiles([]);
    setProcessedFilesCount(0);
    setProgress(0);
    setAcceptedFilesCount(0);
  };

  // const setUploadLocation = (contName: string, folName: string) => {
  //   setContainerName(contName);
  //   setFolderStructure(folName);
  //   // Reset the state
  //   resetState();
  // };

  const init = (initId: string, actId: string) => {
    setInitiativeId(initId);
    setActivityId(actId);
    // Reset the state
    resetState();
  };

  React.useImperativeHandle(ref, () => ({
    openDialog,
    init,
  }));

  useEffect(() => {
    // Set the mounted flag to true
    isMountedRef.current = true;
    // Set the mounted flag to false when the component is unmounted
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const FilterInValidFiles = (files: FileWithPath[]) => {
    // filter only pdf files
    files = files.filter((file) => {
      return perfValidationOptions.options.allowedFileMimeTypes.includes(
        file.type
      );
    });

    // filter only files that are 5 mb or less
    files = files.filter((file) => {
      return file.size <= perfValidationOptions.options.maxFileSizeInBytes;
    });
    return files;
  };

  const onDrop = useCallback(
    (allFiles: FileWithPath[]) => {
      setAllFilesCount(allFiles.length);
      var filteredFiles = FilterInValidFiles(allFiles);
      setFailedFilesCount(allFiles.length - filteredFiles.length);
      setAcceptedFiles(filteredFiles);
      setAcceptedFilesCount(filteredFiles.length);
      // if there are no valid files. just show the progress bar and messages
      if (filteredFiles.length === 0 && allFiles.length > 0) {
        setProcessedFilesCount(allFiles.length);
        setAcceptedFilesCount(allFiles.length);
        setProgress(100);
      }
      uploadPerformanceValidation(filteredFiles);
    },
    [acceptedFiles]
  );

  const uploadPerformanceValidation = async (filesToUpload: FileWithPath[]) => {
    const batches = batchFiles(
      filesToUpload,
      perfValidationOptions.options.batchSize
    );

    for (let i = 0; i < batches.length; i++) {
      if (!isMountedRef.current) {
        return;
      }
      const batch = batches[i];
      const fileData = await uploadPeformanceValidationReports(
        initiativeId,
        activityId,
        batch
      );
      if (fileData && fileData.data) {
        // filter the batch with the matching filedata  where have fileuri
        var uploadedBatchFiles = batch.filter((file: any) => {
          return fileData.data.files.some((uploadedFile: any) => {
            return (
              uploadedFile.fileName === file.name &&
              uploadedFile.fileUri !== null
            );
          });
        });

        var failedBatchFiles = batch.filter((file: any) => {
          return !uploadedBatchFiles.some((uploadedFile: any) => {
            return uploadedFile.name === file.name;
          });
        });

        setUploadedFilesCount((prevCount) => {
          const newCount = prevCount + uploadedBatchFiles.length;
          return newCount;
        });

        setFailedFilesCount((prevCount) => {
          const newCount = prevCount + failedBatchFiles.length;
          return newCount;
        });
      } else {
        setFailedFilesCount((prevCount) => {
          const newCount = prevCount + batch.length;
          return newCount;
        });
      }

      setProcessedFilesCount((prevCount) => {
        const newCount = prevCount + batch.length;
        setProgress((prevProgress) => {
          const progress = (newCount / filesToUpload.length) * 100;
          return progress;
        });
        return newCount;
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // maxSize: perfValidationOptions.options.maxFileSizeInBytes,
    // accept: {
    //   ...perfValidationOptions.options.acceptedTypes,
    // },
  });

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeDialog}
        className={classes.container}
        maxWidth="md"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle
            style={{
              fontWeight: 600,
              fontSize: "32px",
              lineHeight: "38px",
              padding: "48px 0px 20px 55px",
            }}
          >
            Upload
          </DialogTitle>
          <IconButton onClick={closeDialog} className={classes.closeIcon}>
            <CloseIcon style={{ color: "#000000" }} />
          </IconButton>
        </div>

        <div>
          <DialogContent
            style={{
              padding: "0px 55px 0px 55px",
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: 400,
              marginBottom: "50px",
            }}
          >
            {
              // Show the progress section if there are files to upload
              allFilesCount > 0 && (
                <UploadProgressSection
                  progress={progress}
                  processedFilesCount={processedFilesCount}
                  acceptedFilesCount={acceptedFilesCount}
                  uploadedFilesCount={uploadedFilesCount}
                  allFilesCount={allFilesCount}
                  failedFilesCount={failedFilesCount}
                />
              )
            }

            {
              // Show the upload section if there are no files to upload
              allFilesCount === 0 && (
                <UploadSection
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                />
              )
            }
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
});

export function UploadMessage(props: { style: any; message: string }) {
  const { style, message } = props;
  return (
    <Typography
      style={{
        fontSize: 20,
        fontWeight: 500,
        paddingLeft: "10px",
        lineHeight: "24px",
        ...style,
      }}
    >
      {message}
    </Typography>
  );
}

export function UploadProgressMessage(props: {
  processedFilesCount: number;
  acceptedFilesCount: number;
  uploadedFilesCount: number;
  allFilesCount: number;
}) {
  const {
    processedFilesCount,
    acceptedFilesCount,
    uploadedFilesCount,
    allFilesCount,
  } = props;
  const allFilesText = allFilesCount === 1 ? "file" : "files";

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <FileUploadOutlinedIcon sx={{ color: "#6B7280" }} />
      {processedFilesCount === acceptedFilesCount && (
        <UploadMessage
          style={{
            fontSize: 20,
            fontWeight: 500,
            paddingLeft: "10px",
            lineHeight: "24px",
          }}
          message={
            "Uploaded " +
            uploadedFilesCount +
            " of " +
            allFilesCount +
            " " +
            allFilesText
          }
        />
      )}
      {processedFilesCount !== acceptedFilesCount && (
        <UploadMessage
          style={{
            fontSize: 20,
            fontWeight: 500,
            paddingLeft: "10px",
            lineHeight: "24px",
          }}
          message={
            "Uploading " +
            processedFilesCount +
            " of " +
            allFilesCount +
            " " +
            allFilesText
          }
        />
      )}
    </div>
  );
}

export function UploadFailedMessage(props: { failedFilesCount: number }) {
  const { failedFilesCount } = props;
  const fileText = failedFilesCount === 1 ? "file" : "files";

  return (
    <UploadMessage
      style={{ color: "#C71F1F", marginTop: "10px" }}
      message={`Unable to upload ${failedFilesCount} ${fileText}.`}
    />
  );
}

export function UploadProgressSection(props: {
  progress: number;
  processedFilesCount: number;
  acceptedFilesCount: number;
  uploadedFilesCount: number;
  allFilesCount: number;
  failedFilesCount: number;
}) {
  const {
    progress,
    processedFilesCount,
    acceptedFilesCount,
    uploadedFilesCount,
    allFilesCount,
    failedFilesCount,
  } = props;

  return (
    <section>
      <Box sx={{ width: "100%", minHeight: "100px" }}>
        <UploadProgressMessage
          processedFilesCount={processedFilesCount}
          acceptedFilesCount={acceptedFilesCount}
          uploadedFilesCount={uploadedFilesCount}
          allFilesCount={allFilesCount}
        />
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ borderRadius: "100px" }}
        />
        {failedFilesCount > 0 && (
          <UploadFailedMessage failedFilesCount={failedFilesCount} />
        )}
      </Box>
    </section>
  );
}

export function UploadSection(props: {
  getRootProps: any;
  getInputProps: any;
}) {
  const { getRootProps, getInputProps } = props;

  return (
    <section className={classes.uploadContainer}>
      <div {...getRootProps({ className: classes.dropzone })}>
        <input {...getInputProps()} />
        <p
          style={{
            fontWeight: "600",
            color: "black",
            fontSize: "16px",
            fontFamily: "Source Sans Pro",
          }}
        >
          Drop files here or Browse
        </p>
      </div>
      <Typography
        style={{
          fontSize: 12,
        }}
      >
        Maximum File Size 5 MB. File extensions permitted: pdf.
      </Typography>
    </section>
  );
}

export default PerformanceValidationUploader;
