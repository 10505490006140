import { InputLabel, TextField } from "@mui/material";
import { useState } from "react";
import RequiredError from "../required-error/RequiredError";
import classes from "./LabelStyle.module.css";

interface LabelTextFieldProps {
  displayName: string;
  name: string;
  value?: string;
  onTextChange?: (e: any) => void;
  onValidate?: () => boolean;
  TextFieldStyle?: React.CSSProperties;
}

const LabelTextField = (props: LabelTextFieldProps) => {
  const [isValid, setIsValid] = useState(true);

  const validateData = () => {
    if (props.onValidate) {
      setIsValid(props.onValidate());
    }
  };

  const labelId = `${props.name}-label`;
  return (
    <>
      <InputLabel id={labelId} className={classes.commonInputStyle} >
        {props.displayName}{" "}
      </InputLabel>
      <TextField
        InputProps={{
          inputProps: {
            style: { padding: 10, borderRadius: 0 },
          },
          style: { borderRadius: 20,...props.TextFieldStyle },
          className: classes.commonInputStyle,
        }}
        required
        id={props.name}
        name={props.name}
        value={props.value}
        fullWidth
        onChange={props.onTextChange}
        onBlur={validateData}
      />
      {isValid === false && <RequiredError />}
    </>
  );
};

export default LabelTextField;
