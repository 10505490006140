import { useState } from "react";
import { Collapse, ListItemButton } from "@mui/material";
import classes from "./ActivityListItem.module.css";
import { ExpandMoreIcon, ExpandLessIcon } from "../../../atoms/Icons/Icon";
import Avatar from "@mui/material/Avatar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";

export interface ActivityListItemProps {
  activity: any;
  children?: React.ReactNode;
  isCompleted?: boolean;
  isSkipped?: boolean;
  isAnyActivityExpanded?: () => boolean;
  isExpanded: boolean;
}

export interface ActivityListItemRef {
  isExpanded: () => boolean;
}

const ActivityListItem = React.forwardRef<
  ActivityListItemRef,
  ActivityListItemProps
>((props, ref) => {
  const activity = props.activity ? props.activity : {};
  const [expanded, setExpanded] = useState(props.isExpanded);

  const { isCompleted, isAnyActivityExpanded, isSkipped } = props;

  const isExpanded = () => {
    return expanded;
  };
  const handleExpandCollapseButtonClick = (event: any) => {
    event.preventDefault();
    if (expanded) {
      setExpanded(!expanded);
      return;
    }

    if (isAnyActivityExpanded && isAnyActivityExpanded()) {
      return;
    }
    setExpanded(!expanded);
  };

  React.useImperativeHandle(ref, () => ({
    isExpanded,
  }));

  return (
    <div className={classes.ListItem} key={activity.id}>
      <div
        className={classes.ListItemRow}
        style={{
          backgroundColor: expanded ? "#595959" : "white",
          border: expanded ? "1px solid #DDDDDD;" : "",
          borderRadius: expanded ? "10px 10px 0px 0px" : "10px",
          color: expanded ? "white" : "black",
        }}
      >
        {isCompleted && (
          <div className={classes.circleIcon}>
            <CheckCircleIcon
              style={{
                width: "25px",
                height: "25px",
                margin: "10px 0 0 10px",
                color: "#1EA446",
                padding: "0px",
              }}
            />
          </div>
        )}
        {isSkipped && (
          <div className={classes.circleIcon}>
            <CheckCircleIcon
              style={{
                width: "25px",
                height: "25px",
                margin: "10px 0 0 10px",
                color: "gray",
                padding: "0px",
              }}
            />
          </div>
        )}
        <div
          className={
            isCompleted || isSkipped
              ? classes.container
              : classes.container_completed
          }
        >
          <p className={classes.name}> {activity.name}</p>
          <p>{activity.longSummary}</p>
        </div>
        <div>
          <ListItemButton
            onClick={handleExpandCollapseButtonClick}
            className={classes.ListItemButton}
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
            disableRipple
          >
            {expanded ? (
              <Avatar
                sx={{
                  bgcolor: "white",
                  border: "1px solid",
                  borderColor: "#dddddd",
                }}
              >
                <ExpandMoreIcon />
              </Avatar>
            ) : (
              <Avatar
                sx={{
                  bgcolor: "white",
                  border: "1px solid",
                  borderColor: "#dddddd",
                }}
              >
                <ExpandLessIcon />
              </Avatar>
            )}
          </ListItemButton>
        </div>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </div>
  );
});

export default ActivityListItem;
