import * as React from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { styled } from "@mui/system";

export type PieChartDataItem = {
  label: string;
  value: number;
  color: string;
};

type PieChartProps = {
  data: PieChartDataItem[];
  sizing: {
    margin?: { top?: number; bottom?: number; left?: number; right?: number };
    legend?: {
      hidden: boolean;
    };
  };
};

const PieChartWithCustomizedLabel: React.FC<PieChartProps> = ({
  data,
  sizing,
}) => {
  const LegendContainer = styled("ul")({
    display: "flex",
    padding: 0,
    margin: 0,
  });

  const LegendItem = styled("li")({
    display: "inline-flex",
    alignItems: "center",
    margin: "4px",
  });

  const LegendColor = styled("div")({
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    marginRight: "8px",
  });

  const filteredData = data.filter((item) => item.value !== 0);

  const legendItems = filteredData.map((item) => (
    <LegendItem key={item.label}>
      <LegendColor style={{ backgroundColor: item.color }} />
      <span>{item.label}</span>
    </LegendItem>
  ));

  //const filteredData = data.filter(item => item.value !== 0);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "280px" }}>
          <PieChart
            series={[
              {
                data: filteredData,
                arcLabel: (params) => `${params.value}%`,
              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "black",
                fontSize: 14,
                fontWeight: 600,
              },
            }}
            {...sizing}
          />
        </div>
        <div
          style={{ marginLeft: "10px" }}
        >
          <LegendContainer>{legendItems}</LegendContainer>
        </div>
      </div>
    </>
  );
};

export default PieChartWithCustomizedLabel;
