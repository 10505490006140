import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import RoundedButton from "../atoms/formElements/RoundedButton";
import classes from "./InitiativeEnrollmentPopup.module.css";
import React, { useState } from "react";
import { SiteToEnroll, getInitiativesToEnroll } from "../../helpers/api-util";
import AdminContext from "../../store/admin-context";
import useAppData from "../../hooks/useAppData";
import CloseIcon from "@mui/icons-material/Close";
import MessageConfirmDialog, {
  MessageConfirmDialogRef,
} from "../atoms/dialogs/MessageConfirmDialog";
import WaitIndicator, { WaitIndicatorRef } from "../atoms/WaitIndicator";
import { handleErrorReponse } from "../../helpers/ui-util";

export interface InitiativeEnrollmentPopupProps {
  onClose: () => void;
  //selectedInitiatives: any[];
  selectedSite: any;
  selectedYear: number;
  //setInitiativeDialogOpen: any;
  enrollmentType: "enroll" | "unenroll";
  onsuccess: any;
  handleEnrollInitiatives?: () => void;
  userRole: string;
}

export interface InitiativeEnrollmentPopupRef {
  openDialog: () => void;
  setEnrolledInitiatives: (initiatives: any) => void;
}

const InitiativeEnrollmentPopup = React.forwardRef<
  InitiativeEnrollmentPopupRef,
  InitiativeEnrollmentPopupProps
>((props, ref) => {
  const { selectedSite, selectedYear, onsuccess, userRole } = props;

  const [refreshData, setRefreshData] = React.useState(false);
  const [initiativeYear, setInitiativeYear] = React.useState(selectedYear);
  const [enrolledInitiatives, updateEnrolledInitiatives] = React.useState<
    Array<any>
  >([]);
  const [userSelectedInitiatives, setUserSelectedInitiatives] = React.useState<
    Array<any>
  >([]);
  const [open, setOpen] = React.useState(false);
  const [isQuestionnaireeFilled, setIsQuestionnaireeFilled] = useState(false);
  const adminContext = React.useContext(AdminContext);
  const waitIndicatorLoaderRef = React.useRef<WaitIndicatorRef>(null);
  const ErrorConfirmPopupRef = React.useRef<MessageConfirmDialogRef>(null);

  if (initiativeYear !== selectedYear) {
    setRefreshData(!refreshData);
    setInitiativeYear(selectedYear);
  }

  const initiativesToEnrollAPI = useAppData(async () => {
    return await getInitiativesToEnroll(selectedYear);
  }, refreshData);

  const openDialog = () => {
    setUserSelectedInitiatives([]);
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const setEnrolledInitiatives = (initiatives: any) => {
    updateEnrolledInitiatives(initiatives);
  };

  React.useImperativeHandle(ref, () => ({
    openDialog,
    setEnrolledInitiatives,
  }));

  const initiativeCheckboxChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const initiativeId = event.target.value;
    if (event.target.checked === true) {
      setUserSelectedInitiatives([...userSelectedInitiatives, initiativeId]);
    } else {
      const filteredInitiativeIds = userSelectedInitiatives.filter(
        (id) => id !== initiativeId
      );
      setUserSelectedInitiatives(filteredInitiativeIds);
    }
  };

  function handleActionResponse(
    response: any,
    successMessage: string,
    errorMessage: string
  ) {
    if (response.data != null) {
      setOpen(false);
      adminContext.onRecordSave(true, successMessage);
      if (userRole === "admin") {
        ErrorConfirmPopupRef.current?.openDialog();
      } else {
        onsuccess();
      }
      //onsuccess();
    } else {
      if (response.error != null) {
        if (response.error.response?.status === 400) {
          const error = (
            response.error.response?.data as { value: { error: string } }
          )?.value?.error;         
          adminContext.onError(true, error);
          //return response;
        } else {
          adminContext.onError(true, errorMessage);
        }
        //return response;
      }
    }
    waitIndicatorLoaderRef.current?.hide();
  }

  const buildSiteInitiativeToEnrollData = () => {
    let siteInitiativeToEnrollData: any[] = [];
    if (selectedSite.length > 0) {
      selectedSite.forEach((element: any) => {
        if (userSelectedInitiatives.length > 0) {
          userSelectedInitiatives.forEach((ele: any) => {
            siteInitiativeToEnrollData.push({
              siteId: element,
              initiativeId: ele,
            });
          });
        }
      });
    }
    return siteInitiativeToEnrollData;
  };

  const enrollInitiativeButtonClickHandler = async (e: any) => {
    e.preventDefault();
    waitIndicatorLoaderRef.current?.show();
    const siteInitiativeToEnrollData = buildSiteInitiativeToEnrollData();
    let enrollmentResponse = await SiteToEnroll(siteInitiativeToEnrollData);
    if (enrollmentResponse.data && Array.isArray(enrollmentResponse.data)) {
      let foundNullValue = false;

      for (let i = 0; i < enrollmentResponse.data.length; i++) {
        const item = enrollmentResponse.data[i];
        if (item[0] === null || item[0] === undefined) {
          foundNullValue = true;
          break;
        }

        if (
          item[0].questionnaireAnswerId === null ||
          item[0].questionnaireAnswerId === undefined
        ) {
          foundNullValue = true;
          break;
        }
      }
      setIsQuestionnaireeFilled(!foundNullValue);
      onsuccess();
    }

    handleActionResponse(
      enrollmentResponse,
      "Sites enrolled successfully!",
      "Error enrolling site!"
    );
  };

  const handleCloseAction = () => {
    onsuccess();
  };

  function isValidInitiativeSelection() {
    if (userSelectedInitiatives.length > 0) {
      return false;
    }
    if (
      initiativesToEnrollAPI.appData &&
      initiativesToEnrollAPI.appData.length === 0
    ) {
      return true; // Disable the enroll button if there are no initiatives
    }
    return true;
  }

  let returnJSX = <></>;
  if (initiativesToEnrollAPI.loading) {
    returnJSX = <></>;
  } else if (initiativesToEnrollAPI.error) {
    returnJSX = handleErrorReponse(initiativesToEnrollAPI.error);
  } else if (initiativesToEnrollAPI.appData) {
    if (initiativesToEnrollAPI.appData.length > 0) {
      returnJSX = (
        <div>
          <Dialog
            open={open}
            onClose={closeDialog}
            className={classes.enrollContatiner}
          >
            <IconButton
              onClick={closeDialog}
              aria-label="Close"
              className={classes.closeIconUnenroll}
            >
              <CloseIcon style={{ color: "#000000" }} />
            </IconButton>
            <DialogTitle
              style={{
                fontWeight: 600,
                fontSize: "32px",
                lineHeight: "40px",
                padding: "35px 0px 0px 55px",
              }}
            >
              Initiative Enrollment
            </DialogTitle>
            <div>
              {/* <form onSubmit={enrollInitiativeFormSubmitHandler}> */}
              <form>
                <DialogContent
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    marginTop: 30,
                    padding: "0px 0px 0px 55px",
                  }}
                >
                  {initiativesToEnrollAPI.appData.map((initiative: any) => (
                    <div
                      key={initiative.id}
                      className={classes.initiativeCheckbox}
                    >
                      <FormControlLabel
                        key={initiative.id}
                        control={
                          <Checkbox
                            disabled={
                              enrolledInitiatives.includes(initiative.id)
                                ? true
                                : false
                            }
                            checked={
                              enrolledInitiatives.includes(initiative.id)
                                ? true
                                : userSelectedInitiatives.includes(
                                    initiative.id
                                  )
                                ? true
                                : false
                            }
                            //checked={ids.includes(initiative.id) ? true : false}
                            onChange={initiativeCheckboxChangeHandler}
                            value={initiative.id}
                          />
                        }
                        label={initiative.initiativeName}
                      />
                    </div>
                  ))}
                </DialogContent>
                <DialogActions style={{ padding: "55px 55px 55px 0px" }}>
                  <RoundedButton
                    name="btnEnrollCancel"
                    text="Cancel"
                    buttonType="secondary"
                    onClick={closeDialog}
                  />
                  <RoundedButton
                    name="btnEnroll"
                    type="submit"
                    text="Enroll"
                    onClick={enrollInitiativeButtonClickHandler}
                    disabled={isValidInitiativeSelection()}
                  />
                </DialogActions>
                <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
              </form>
            </div>
          </Dialog>
          {!isQuestionnaireeFilled && (
            <MessageConfirmDialog
              ref={ErrorConfirmPopupRef}
              onConfirm={handleCloseAction}
              title="Action Required"
              children="To complete enrollment, 
            champion must submit enrollment questionnaire for each site."
            />
          )}
        </div>
      );
    } else {
      // If there are no initiatives, render the dialog content with the "close" button
      returnJSX = (
        <div>
          <Dialog
            open={open}
            onClose={closeDialog}
            className={classes.enrollContatiner}
          >
            <IconButton
              onClick={closeDialog}
              aria-label="Close"
              className={classes.closeIconUnenroll}
            >
              <CloseIcon style={{ color: "#000000" }} />
            </IconButton>
            <DialogTitle
              style={{
                fontWeight: 600,
                fontSize: "32px",
                padding: "55px 0px 0px 55px",
              }}
            >
              Initiative Enrollment
            </DialogTitle>
            <div>
              <DialogContent
                style={{
                  paddingLeft: "55px",
                  fontSize: "16px",
                  lineHeight: "18px",
                  fontWeight: 400,
                }}
              >
                <div>There are no initiatives open for enrollment.</div>
              </DialogContent>
              <DialogActions
                style={{
                  padding: "55px 55px 55px 0px",
                }}
              >
                <RoundedButton
                  name="btnClose"
                  text="Close"
                  buttonType="secondary"
                  onClick={closeDialog}
                />
              </DialogActions>
            </div>
          </Dialog>
        </div>
      );
    }
  } else {
    returnJSX = handleErrorReponse(initiativesToEnrollAPI.error);
  }

  return <>{returnJSX}</>;
});

export default InitiativeEnrollmentPopup;
