import { Alert, Snackbar } from "@mui/material";
import { useContext } from "react";
import AdminContext from "../../../store/admin-context";

function SuccessAlert() {
  const adminContext = useContext(AdminContext);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={adminContext.isSaved}
      autoHideDuration={3000}
      onClose={() => adminContext.onRecordSave(false)}
    >
      <Alert
        severity="success"
        onClose={() => adminContext.onRecordSave(false)}
      >
        {adminContext.successMessage
          ? adminContext.successMessage
          : "Record saved successfully!"}
      </Alert>
    </Snackbar>
  );
}
export default SuccessAlert;
