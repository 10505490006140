import classes from "./ListItemStatus.module.css";

export interface ListItemStatusProps {
  status: string;
}

function ListItemStatus(props: ListItemStatusProps) {
  const status = props.status;

  let statusClass = "";
  if (status === "Draft") {
    statusClass = classes.statusDraft;
  } else if (status === "Published") {
    statusClass = classes.statusPublished;
  } else if (status === "Deactivated") {
    statusClass = classes.statusDeactivated;
  } else if (status === "Expired") {
    statusClass = classes.statusExpired;
  } else if (status === "Pending") {
    statusClass = classes.statusPending;
  } else if (status === "Enrolled") {
    statusClass = classes.statusEnrolled;
  }

  return (
    <>
      <span
        className={statusClass}
        style={{ fontSize: "13.52px", lineHeight: "23.52px" }}
      >
        {status}
      </span>
    </>
  );
}

export default ListItemStatus;
