import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import RoundedButton from "../formElements/RoundedButton";

function ConfirmDialog(props: any) {
  let {
    title,
    children,
    open,
    setOpen,
    onConfirm,
    onClose,
    confirmButtonTitle,
    showConfirmButton,
    showCancelButton,
    style,
  } = props;

  function closeClickHandler(event: any, reason: any) {
    if (reason && reason === "backdropClick") {
      return;
    }

    setOpen(false);
    if (onClose) {
      onClose();
    }
  }

  if (showConfirmButton === undefined) {
    showConfirmButton = true;
  }
  if (showCancelButton === undefined) {
    showCancelButton = true;
  }

  let DialogTitleStyle = {};
  if (style) {
    DialogTitleStyle = style.DialogTitle;
  }
  let DialogContentStyle = {};
  if (style) {
    DialogContentStyle = style.DialogContent;
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={(event: any, reason: any) => {
        closeClickHandler(event, reason);
      }}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog" style={{ padding: "55px 0px 0px 55px" }}>
        <Typography style={{ ...DialogTitleStyle }}>{title}</Typography>
      </DialogTitle>
      <Box position="absolute" top={25} right={25}> 
        <IconButton
          onClick={(event) => {
            closeClickHandler(event, "");
          }}
        >
          <Close style={{ width: "35px", height: "35px",color: "#000000" }} />
        </IconButton>
      </Box>
      <DialogContent style={{ padding: "30px 0px 0px 55px", fontSize:"16px", fontWeight:400, color: "#000000",maxWidth: "510px" }}>
        <Typography style={{ ...DialogContentStyle }}>{children}</Typography>
      </DialogContent>
      <DialogActions
        style={{ padding: "55px 55px 55px 0px" }}
      >
        <Grid container justifyContent={"flex-end"} spacing={1}>
          <Grid item display={showCancelButton}>
            <RoundedButton
              name="btnCancel"
              onClick={(event) => {
                closeClickHandler(event, "");
              }}
              text="Cancel"
              buttonType="secondary"
              display={showCancelButton === true ? "block" : "none"}
            />
          </Grid>
          <Grid item display={showConfirmButton}>
            <RoundedButton
              name="btnYes"
              onClick={() => {               
                onConfirm();
                setOpen(false);
              }}
              text={confirmButtonTitle}
              buttonType={confirmButtonTitle === "Close" ? "secondary" : "primary"} 
              display={showConfirmButton === true ? "block" : "none"}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
