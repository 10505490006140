import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import UpdateActivity from "../../update-activity/UpdateActivity";
import classes from "./ActivityListItem.module.css";
import Typography from "@mui/material/Typography";
import { getUIFormattedDate } from "../../../../../helpers/ui-util";
import CircleExpandIcon from "../../../../atoms/Icons/Icon";

interface ActivityListItemProps {
  activity: any;
  saveOnClick: (data: any) => void;
  cancelOnClick: () => void;
  deleteOnClick: () => void;
}

function ActivityListItem(props: ActivityListItemProps) {
  const activity = props.activity;

  return (
    <>
      <Accordion key={activity.id} className={classes.container}>
        <AccordionSummary
          expandIcon={<CircleExpandIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <div className={classes.content}>
            <div className={classes.tableHeader}>
              <div className={classes.tableHeaderName} >
                <h5 style={{ overflowWrap:"anywhere", paddingRight:"20px"}}>{activity.name}</h5>
              </div>
              <div className={classes.tableHeaderStartDate}>
                <span>{getUIFormattedDate(activity.startDate)}</span>
              </div>
              <div className={classes.tableHeaderEndDate}>
                <span>{getUIFormattedDate(activity.endDate)}</span>
              </div>              
            </div>
           
            <Typography style={{ overflowWrap:"anywhere", paddingRight:"30px"}}>{activity.longSummary}</Typography>
            
            {/* <p>{activity.longSummary}</p> */}
            
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <UpdateActivity
            saveOnClick={props.saveOnClick}
            cancelOnClick={props.cancelOnClick}
            deleteOnClick={props.deleteOnClick}
            activity={activity}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default ActivityListItem;
