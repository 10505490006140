import UpcomingDatesHome from "../common/analytics/upcoming-dates/UpcomingDatesHome";
import EnrollmentSummary from "./analytics/enrollment-summary/EnrollmentSummary";
import classes from "./AdminAnalyticsDashboard.module.css";
import { useState } from "react";
import InitiativesStatusForAllSites from "./analytics/initiative-status/InitiativesStatusForAllSites";
import EnrollmentSiteLocations from "./analytics/enrollment-site-locations/EnrollmentSiteLocations";
import PerformanceValidationSummary from "./analytics/performance-validation-summary/PerformanceValidationSummary";
import LatestUpdatedDate from "./analytics/latest-updated-date/LatestUpdatedDate";

export default function AdminAnalyticsHome() {
  const [showEnrollmentSummary, setShowEnrollmentSummary] = useState(true);
  const [showEnrollmentSiteLocations, setShowEnrollmentSiteLocations] = useState(true);
  const [showPerformanceValidationSummary, setShowPerformanceValidationSummary] = useState(true);

  function displayEnrollmentSummary(show: boolean) {
    setShowEnrollmentSummary(show);
  }
  function displayEnrollmentSiteLocations(show: boolean) {
    setShowEnrollmentSiteLocations(show);
  }
  function displayPerformanceValidationSummary(show: boolean) {
    setShowPerformanceValidationSummary(show);
  }

  return (
    <>
      <div className={classes.Main_container}>
        <div className={classes.UpcomingDatesHome_container}>
          <UpcomingDatesHome />
        </div>
        <div className={classes.InitiativeStatus_container}>
          <InitiativesStatusForAllSites />
        </div>
        <div className={classes.EnrollmentSummary_container}>
          {showEnrollmentSummary && (
            <div >
              <EnrollmentSummary display={displayEnrollmentSummary} />
            </div>
          )}
        </div>
        {showEnrollmentSiteLocations && (
          <div className={classes.EnrollmentSiteLocations_container}>
            <EnrollmentSiteLocations display={displayEnrollmentSiteLocations} />
          </div>
        )}
        {showPerformanceValidationSummary && (
          <div className={classes.PerformanceValidationSummary_container}>
            <PerformanceValidationSummary display={displayPerformanceValidationSummary} />
          </div>
        )}
        <LatestUpdatedDate/>
      </div>
    </>
  );
}
