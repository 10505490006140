import { Button } from "@mui/material";

interface RoundedButtonProps {
  text: string;
  onClick: (e: any) => void;
  name: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  display?: string;
  className?: string;
  buttonType?: "primary" | "secondary" | "Delete";
}

function RoundedButton(props: RoundedButtonProps) {
  const Type = props.type || "button";
  const disabled = props.disabled || false;
  const buttonType = props.buttonType || "primary";
  let backgroundColor = "#1976D2";
  let color = "#FFFFFF"; 
  let border="2px solid #1976D2";

  if (buttonType === "secondary") {
    backgroundColor = "#FFFFFF";
    border="2px solid #1976D2";
    color = "#1976D2";
  } else if (props.text === "Delete") {
    backgroundColor = "#E44040";
    border="2px solid #E44040";
  }

  return (
    <Button
      type={props.type}
      name={Type}
      onClick={props.onClick}
      disableElevation
      disableRipple
      disableFocusRipple
      className={props.className}
      style={{
        padding: "5px 30px",
        fontSize: "14px",
        lineHeight: "24px", 
        fontWeight: 600,
        textTransform: "none",
        backgroundColor: backgroundColor,
        color: color,
        borderRadius: "100px",
        border: border,
        opacity: disabled ? 0.6 : 1,
        display: props.display ? props.display : "block",
      }}
      variant="contained"
      disabled={disabled}
    >
      {props.text}
    </Button>
  );
}

export default RoundedButton;
