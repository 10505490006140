import WizardAction from "../../../atoms/WizardAction";

function ActivitySettingsAction(props: any) {
  return (
    <WizardAction
      backButtonText="Back"
      backOnClick={props.backOnClick}
      continueButtonText="Save and Continue"
      continueOnClick={props.continueOnClick}
      continueDisabled={!props.enableContinue}
    />
  );
}

export default ActivitySettingsAction;
