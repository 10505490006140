import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreVert } from "@mui/icons-material";
import AdminContext from "../../store/admin-context";
import {
  getSiteActivityBySiteEnrollmentId,
  SiteUnenroll,
  getEnrollmentQuestionnaireById,
  getEnrollmentQuestionnaireByYear,
} from "../../helpers/api-util";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RoundedButton from "../atoms/formElements/RoundedButton";
import classes from "./EnrollmentListItemAction.module.css";
import { getSiteEnrollmentInitiativesById } from "../../helpers/api-util";
import { useState } from "react";
import WaitIndicator, { WaitIndicatorRef } from "../atoms/WaitIndicator";
import EnrollmentQnASheet from "../admin/questionnaire/EnrollmentQnASheet";

interface EnrollmentListItemActionProps {
  siteId: any;
  acepSiteNumber: any;
  enrollmentDataId: string;
  setRefreshData: () => void;
  enrollmentStatus: string;
  type: any;
  siteEnrollmentId?: any;
  enrollmentId?: any;
  enrollmentYear: number;
  openInitiatives?: any;
  selectedSitePhysicianGroupId: any;
  selectedSitePhysicianGroupName: any;
}
function EnrollmentListItemAction(props: EnrollmentListItemActionProps) {
  const menuDisplay = {
    Unenroll: false,
  };

  const { enrollmentStatus } = props;
  const [enrollmentQuesData, setEnrollmentQuesData] = useState<any>(null);
  const [enrollmentQuesAnswerData, setEnrollmentQuesAnswerData] =
    useState<any>(null);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [isQuestionnaireDialogOpen, setQuestionnaireDialogOpen] =
    React.useState(false);
  const [isUnrollDialogOpen, setIsUnrollDialogOpen] = React.useState(false);
  const [isInitiativeDialogOpen, setInitiativeDialogOpen] =
    React.useState(false);

  const adminContext = React.useContext(AdminContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [initiativePopupType, setInitiativePopupType] = React.useState<
    "enroll" | "unenroll"
  >("enroll"); // Added initiativePopupType state
  const [siteId, setSiteId] = React.useState("");
  const [data, setData] = React.useState<any[]>([]);
  const [ids, setIds] = React.useState<Array<any>>([]);
  const [disable, setDisable] = React.useState(true);
  const waitIndicatorLoaderRef = React.useRef<WaitIndicatorRef>(null);

  const actionButtonClickHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsUnrollDialogOpen(false);
  };

  let initiativeArray: any = [];
  let initiativeIdArray: any = [];

  async function onUnenrollClickHandler() {
    const response = await getSiteEnrollmentInitiativesById(
      props.siteEnrollmentId
    );
    if (response != null && response.data != null) {
      response.data.forEach((element: any) => {
        if (element.isEnrolled === true) {
          var isOpenInitiative = props.openInitiatives.find(
            (x: any) => x.id === element.initiativeId
          );
          if (isOpenInitiative) {
            setSiteId(element.siteId);
            initiativeArray.push({
              id: element.initiativeId,
              name: element.initiativeName,
            });
          }
        }
      });
    } else {
      setData([]);
    }
    if (initiativeArray != null) {
      setData(initiativeArray);
    }
    setIsUnrollDialogOpen(true);
  }

  const handleUnenrollClose = () => {
    setAnchorEl(null);
    setIsUnrollDialogOpen(false);
    setIds([]);
    setDisable(true);
  };

  async function onQuestionnaireClickHandler() {
    try {
      if (props.enrollmentId != null || props.enrollmentYear != null) {
        waitIndicatorLoaderRef.current?.show();
        const enrollmentQuesData = props.enrollmentId
          ? await getEnrollmentQuestionnaireById(props.enrollmentId)
          : await getEnrollmentQuestionnaireByYear(props.enrollmentYear);

        setEnrollmentQuesData(enrollmentQuesData.data);
        const enrollmentQuesAnswerData =
          await getSiteActivityBySiteEnrollmentId(props.siteEnrollmentId);
        setEnrollmentQuesAnswerData(enrollmentQuesAnswerData.data);
        waitIndicatorLoaderRef.current?.hide();
        setQuestionnaireDialogOpen(true);
      }
    } catch (error) {
      console.log(error);
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  const onConfirmClose = () => {
    setConfirmOpen(false);
  };

  const onConfirmSubmit = () => {
    setConfirmOpen(false);
    props.setRefreshData();
  };

  if (enrollmentStatus === "Enrolled" || enrollmentStatus === "Pending") {
    menuDisplay.Unenroll = true;
  }

  function handleCloseDialogs() {
    setQuestionnaireDialogOpen(false);
    setInitiativeDialogOpen(false);
  }

  function handleSubmitQuestionnaire(formData: any) {
    const answers = formData;    
    // Perform any necessary actions with the questionnaire answers
    if (answers === null || answers === undefined) {
      return false;
    }
    // Here you can record the answers or handle the submission as needed
    handleCloseDialogs();
    props.setRefreshData();
  }

  function handleActionResponse(
    response: any,
    successMessage: string,
    errorMessage: string
  ) {
    if (response.data != null) {
      adminContext.onRecordSave(true, successMessage);
      // props.onsuccess();
    } else {
      adminContext.onError(true, errorMessage);
    }
    waitIndicatorLoaderRef.current?.hide();
  }

  interface NewUserFormDataType {
    siteId: string;
    initiativeId: string;
  }
  const formData: NewUserFormDataType = {
    siteId: "",
    initiativeId: "",
  };
  const OnSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      waitIndicatorLoaderRef.current?.show();
      e.preventDefault();
      if (ids != null) {                
        let initiativesToUnEnroll: any[] = [];
        ids.forEach((element) => {
          initiativesToUnEnroll.push({
            siteId: siteId,
            initiativeId: element,
          });
        });
        if (initiativesToUnEnroll != null) {         
          let res = await SiteUnenroll(initiativesToUnEnroll);         
          if (res != null) {
            setIsUnrollDialogOpen(false);
            handleActionResponse(
              res,
              "Site Unenrolled successfully!",
              "Error Unenrolling site!"
            );
          }
        }
      }
      props.setRefreshData();
    } catch (ex) {
      console.log(ex);
      waitIndicatorLoaderRef.current?.hide();
    }
  };

  let SelectedIdArray: any[] = [];
  const selectInitiatve = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = event.target.value;
    if (event.target.checked === true) {
      SelectedIdArray.push(selectedId);
    } else {
      SelectedIdArray.pop();
    }   
    if (SelectedIdArray.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    if (ids.includes(selectedId)) {
      const newIds = ids.filter((id) => id !== selectedId);
      // console.log("newId", newIds);
      setIds(newIds);
    } else {
      const newIds = [...ids];
      newIds.push(selectedId);
      setIds(newIds);
    }
  };

  let buttonEnabled = false;
  let buttonStyle = { color: "#DDDDDD" };

  if (props.type === "champion") {
    if (
      props.enrollmentStatus === "Enrolled" ||
      props.enrollmentStatus === "Pending"
    ) {
      buttonEnabled = true;
      buttonStyle.color = "black";
    }
  }

  if (props.type === "admin") {
    buttonEnabled = true;
    buttonStyle.color = "black";
  }

  return (
    <div>
      <Button
        id="action-button"
        disabled={!buttonEnabled}
        aria-controls={open ? "action-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={actionButtonClickHandler}
        style={{ color: buttonStyle.color }}
      >
        <MoreVert />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        MenuListProps={{
          "aria-labelledby": "action-button",
        }}
      >
        <div style={{ display: props.type === "admin" ? "block" : "none" }}>
          <MenuItem
            onClick={onQuestionnaireClickHandler}
            style={{ fontWeight: 600, fontSize: 16 }}
          >
            Questionnaire
          </MenuItem>
        </div>

        <div style={{ display: menuDisplay.Unenroll ? "block" : "none" }}>
          <MenuItem
            onClick={onUnenrollClickHandler}
            style={{ fontWeight: 600, fontSize: 16 }}
          >
            Unenroll
          </MenuItem>
        </div>
      </Menu>

      <Dialog
        open={isQuestionnaireDialogOpen}
        onClose={handleCloseDialogs}
        fullWidth
        maxWidth="md"
      >
        {/* <DialogTitle
          style={{
            fontWeight: 600,
            fontSize: "32px",
            padding: "55px 0px 0px 55px",
          }}
        >
          Enrollment Questionnaire
        </DialogTitle> */}
        {/* <QuestionnaireForm
          onSubmit={handleSubmitQuestionnaire}
          onCancel={handleCloseDialogs}
          siteData={[props.enrollmentDataId]}
          enrollId={null}
          initQuestionnaireData={enrollmentQuestionnairee}
          sitePhysicianGroupId={
            enrollmentQuestionnairee == null
              ? props.selectedSitePhysicianGroupId
              : null
          }
        /> */}

        <EnrollmentQnASheet
          siteIds={[props.siteId]}
          acepSiteNumber={[props.acepSiteNumber]}
          enrollmentYear={props.enrollmentYear}
          onSubmit={handleSubmitQuestionnaire}
          onCancel={handleCloseDialogs}
          enrollmentId={props.enrollmentId}
          siteEnrollmentId={props.siteEnrollmentId}
          initiativeActivityId=""
          enrollmentQuesData={enrollmentQuesData}
          enrollmentQuesAnswerData={enrollmentQuesAnswerData}
          physicianGroupName={props.selectedSitePhysicianGroupName}
        />
      </Dialog>

      <Dialog
        open={isUnrollDialogOpen}
        onClose={handleClose}
        aria-labelledby="add-users"
        fullWidth
        maxWidth="sm"
      >
        <IconButton
          onClick={handleUnenrollClose}
          aria-label="Close"
          className={classes.closeIconUnenroll}
        >
          <CloseIcon style={{ color: "#000000" }} />
        </IconButton>
        <DialogTitle
          id="initiative-unenroll"
          style={{
            fontWeight: 600,
            fontSize: "32px",
            lineHeight: "40px",
            padding: "35px 0px 0px 55px",
          }}
        >
          Select Initiative(s) to Unenroll
        </DialogTitle>

        <div>
          <form onSubmit={OnSubmitHandler}>
            <DialogContent
              style={{
                padding: "30px 0px 0px 55px",
                fontWeight: 600,
                fontSize: "16px",
              }}
            >
              {data.length === 0 ? (
                <p>Initiative(s) not available to unenroll!</p>
              ) : (
                data.map((initiative: any) => (
                  <div key={initiative.id}>
                    <FormControlLabel
                      key={initiative.id}
                      control={
                        <Checkbox
                          name="intiative"
                          checked={ids.includes(initiative.id) ? true : false}
                          onChange={selectInitiatve}
                          value={initiative.id}
                        />
                      }
                      label={initiative.name}
                    />
                  </div>
                ))
              )}
            </DialogContent>
            <DialogActions
              style={{
                padding: "55px 55px 55px 0px",
              }}
            >
              <RoundedButton
                name="unenrollcancel"
                text="Cancel"
                buttonType="secondary"
                onClick={handleUnenrollClose}
              />
              <RoundedButton
                type="submit"
                name="Unenrollsubmit"
                text="Unenroll"
                onClick={OnSubmitHandler}
                disabled={disable}
              />
            </DialogActions>
          </form>
        </div>
        <WaitIndicator ref={waitIndicatorLoaderRef} />
      </Dialog>
    </div>
  );
}

export default EnrollmentListItemAction;
