import ActivityBuilderAction from "./ActivityBuilderAction";
import ActivityBuilderList from "./activity-list/ActivityBuilderList";
import AdminContext from "../../../../store/admin-context";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { getInitiativeById } from "../../../../helpers/api-util";
import WizardHeader from "../../../atoms/WizardHeader";
import WaitIndicator from "../../../atoms/WaitIndicator";
import useAppData from "../../../../hooks/useAppData";
import useActivitiesData from "../../../../hooks/useActivitiesData";
import { handleErrorReponse, scrollToTop } from "../../../../helpers/ui-util";

function ActivityBuilderHome() {
  const navigate = useNavigate();
  const adminContext = useContext(AdminContext);
  const [refreshData, setrefreshData] = useState(false);

  const activitiesData = useActivitiesData(refreshData);
  const { appData, loading, error } = useAppData(async () => {
    return await getInitiativeById(adminContext.initiativeId);
  }, refreshData);
  if (loading) {
    return (
      <div>
        <WaitIndicator show={true}></WaitIndicator>
      </div>
    );
  }
  if (error) {
    const errorJSX = handleErrorReponse(error);
    return <>{errorJSX}</>;
  }
  const initiativeName = appData.name;

  function canContinue() {
    if (
      adminContext.initiative != null &&
      adminContext.initiative.id != null &&
      adminContext.initiative.initiativeActivities.length > 0
    ) {
      return adminContext.initiative.initiativeActivities.some((activity) => {
        if (activity.hasQnDefinitions) {
          return true;
        }
      });
    }
    return false;
  }

  function continueOnClickHandler(event: any) {
    navigate("/admin/initiative/activity/publish");
  }
  function onBackClickHandler(event: any) {
    navigate("/admin/initiative/activity/list");
  }

  function saveOnClickHandler() {
    scrollToTop();
    setrefreshData(!refreshData);    
  }

  function cancelOnClickHandler() {
    scrollToTop();
    setrefreshData(!refreshData);   
  }

  if (activitiesData.loading) {
    return (
      <>
        <WizardHeader title="Activity Builder" />
        <WaitIndicator show={true} />
      </>
    );
  }

  if (activitiesData.error) {
    const errorJSX = handleErrorReponse(activitiesData.error);
    return (
      <>
        <WizardHeader title="Activity Builder" />
        {errorJSX}
      </>
    );
  }

  return (
    <>
      <WizardHeader title="Activity Builder" />
      <h3>{initiativeName}</h3>
      <ActivityBuilderList
        activities={activitiesData.appData}
        saveOnClick={saveOnClickHandler}
        cancelOnClick={cancelOnClickHandler}
      />
      <ActivityBuilderAction
        continueOnClick={continueOnClickHandler}
        canContinue={canContinue()}
        backOnClick={onBackClickHandler}
      />
    </>
  );
}

export default ActivityBuilderHome;
