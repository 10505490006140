export const mapUpcomingDatesHomeData = (resultData: any): Activity[] => {
  let ActivityData: Activity[] = [];

  try {
    if (Array.isArray(resultData) && resultData.length > 0) {
      ActivityData = resultData.map((result: any, index: number) => {
        const initiativeNameData = result.initiativeName.length > 40
          ? result.initiativeName.substring(0, 40) + "..."
          : result.initiativeName;

        return {
         
          initiativeId: result.initiativeId,
          initiativeName: initiativeNameData,
          
          activityId: result.activityId,
          activityName: result.activityName,
          activityEndDate: result.activityEndDate,
        };
      });
    }
  } catch (error) {
    console.error("Error occurred while fetching Data:", error);
  }
  return ActivityData;
};
