import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminContext from "../../store/admin-context";
import {
  ContainerHeader,
  ContainerButton,
  ContainerContent,
  MainContainer,
} from "../atoms/container/ContentContainer";
import { EnrollmentQuestionnaireList } from "./enrollment/manage-enrollment/EnrollmentQuestionnaireList";
import useAppData from "../../hooks/useAppData";
import { getEnrollmentQuestionnaires } from "../../helpers/api-util";
import WaitIndicator from "../atoms/WaitIndicator";
import { mapData } from "./enrollment/manage-enrollment/manageEnrollment";
import { handleErrorReponse } from "../../helpers/ui-util";

export default function ManageEnrollmentQuestionnaireHome(props: any) {
  const navigate = useNavigate();
  const adminContext = useContext(AdminContext);

  const [refreshData, setRefreshData] = useState(false);
  const enrollmentQuesData = useAppData(async () => {
    return await getEnrollmentQuestionnaires();
  }, refreshData);

  function onDataRefresh() {
    setRefreshData(!refreshData);
  }

  function getUsedYears() {
    const enrollmentData = mapData(enrollmentQuesData);
    if (enrollmentData.length > 0) {
      //filter the used years for published questionnaires
      return enrollmentData
        .filter(
          (item: any) => item.status === "Published" || item.status === "Draft"
        )
        .map((item: any) => item.year);
    }
    return [];
  }

  function btnCreateNewQuestionnaireClickHandler(event: any) {
    event.preventDefault();
    adminContext.setEnrollmentId("");
    navigate("/admin/questionnaire/create", {
      replace: true,
      state: { usedYears: getUsedYears() },
    });
  }

  if (enrollmentQuesData.loading) {
    return (
      <MainContainer>
        <ContainerHeader text="Manage Enrollment Questionnaires" />
        <ContainerContent>
          <WaitIndicator show={true} />
        </ContainerContent>
      </MainContainer>
    );
  }

  if (enrollmentQuesData.error) {
    const error = handleErrorReponse(enrollmentQuesData.error);
    return (
      <>
        <MainContainer>
          <ContainerHeader text="Manage Enrollment Questionnaires" />
          <ContainerContent>{error}</ContainerContent>
        </MainContainer>
      </>
    );
  }

  const enrollmentQuesListData = mapData(enrollmentQuesData);

  return (
    <MainContainer>
      <ContainerHeader text="Manage Enrollment Questionnaires" />
      <ContainerButton
        name="btnCreateNewQuestionnaire"
        text="Create New Questionnaire"
        onClick={btnCreateNewQuestionnaireClickHandler}
        buttonType="primary"
      />
      <ContainerContent
        style={{
          marginTop: "30px",
          width: "99%",
        }}
      >
        <EnrollmentQuestionnaireList
          enrollmentQuesListData={enrollmentQuesListData}
          onDataRefresh={onDataRefresh}
        />
      </ContainerContent>
    </MainContainer>
  );
}
