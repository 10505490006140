import React from "react";
import {
    LinearProgress,
    LinearProgressProps,
    linearProgressClasses,
    Box,
} from "@mui/material";
import { styled } from '@mui/material/styles';
export interface GenericLinearProgressProps {
    color?: LinearProgressProps["color"];
    value?: number;
    variant?: LinearProgressProps["variant"];
    outerDivStyle?: React.CSSProperties;
    ProgressWithLabelDiv?: React.CSSProperties;
    ProgressDiv?: React.CSSProperties;
    LabelDiv?: React.CSSProperties
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#E7E7E7',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#0084FF'
    },
}));

const CompleteLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "#00D16D",
    },
}));

const GenericLinearProgress: React.FC<GenericLinearProgressProps> = ({
    color = "primary",
    variant = "determinate",
    value = 40,
    outerDivStyle = {
        width: "calc(100% - 50px)",        
        textAlign: "center",
        //boxShadow: "2px 4px 8px 2px rgba(0, 0, 0, 0.2)",
        position: "absolute",
        // top: "40%",
        //left: "50%",
        // transform: "translate(-50%, 50%)",
        //paddingTop: "2rem",
        // paddingLeft: "1rem",
        paddingRight: "0.5rem",
    },

    ProgressWithLabelDiv = {
        display: "flex", alignItems: "center"
    },

    ProgressDiv = {
        width: "100%",
        mr: 1
    },

    LabelDiv = {
        minWidth: "50px"
    }
}) => {

    if (variant === "indeterminate") {
        return (
            <Box style={outerDivStyle}>
                <LinearProgress variant="indeterminate" />
            </Box>
        );
    } else {
        return (
            <Box style={outerDivStyle}>
                <Box style={ProgressWithLabelDiv}>
                    <Box sx={ProgressDiv}>
                        {value < 99 && (
                            <BorderLinearProgress variant="determinate" value={value} />
                        )}
                        {value >= 100 && (

                            <CompleteLinearProgress variant="determinate" value={value} />
                        )}
                    </Box>
                    <Box sx={LabelDiv}>
                        {`${value}%`}
                    </Box>
                </Box>
            </Box>
        );
    }
};

export default GenericLinearProgress;
