import React, {
  ChangeEvent,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  Grid,
  SelectChangeEvent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Typography,
} from "@mui/material";
import RoundedButton from "../../atoms/formElements/RoundedButton";
import LabelTextField from "../../atoms/formElements/LabelTextField";
import {
  updateUsers,
  createUser,
  getSearchUsers,
} from "../../../helpers/api-util";
import AdminContext from "../../../store/admin-context";
import WaitIndicator from "../../atoms/WaitIndicator";
import { WaitIndicatorRef } from "../../atoms/WaitIndicator";
import { UserSearchSetting } from "../../../helpers/ui-util";
import classes from "./UserFormDialog.module.css";

export interface UserFormDialogProps {
  roles: any;
  sites: any;
  physician: any;
  onsuccess: any;
}

export interface UserFormDialogRef {
  openDialog: () => void;
}

let searchTimer: any = null;

const UserFormDialog = forwardRef<UserFormDialogRef, UserFormDialogProps>(
  (props, ref) => {
    const [open, setOpen] = React.useState(false);
    const roles = props.roles.appData ? props.roles.appData : [];

    const sites = props.sites.appData ? props.sites.appData : [];
    const physician = props.physician.appData ? props.physician.appData : [];

    const [display, setDisplay] = React.useState("none");
    const [Physiciandisplay, setPhysiciandisplay] = React.useState("none");
    const [Sitesdisplay, setSitesdisplay] = React.useState("none");
    const [PhysicianAssigndisplay, setPhysicianAssigndisplay] =
      React.useState("none");
    const [SitesAssigndisplay, setSitesAssigndisplay] = React.useState("none");
    const [champDisplay, setchampDisplay] = React.useState("none");
    const [role, setRole] = useState("");
    const [SearchQuery, setSearchQuery] = useState("");
    const [PhysicianSearchQuery, setPhysicianSearchQuery] = useState("");
    const [SitesSearchQuery, setSitesSearchQuery] = useState("");
    const [userId, setUserId] = React.useState("");
    const [userRoleId, setUserRoleId] = React.useState("");
    const [siteId, setSiteId] = React.useState("");
    const [physicianGroupId, setPhysicianGroupId] = React.useState("");
    const [newUserdisplay, setNewUserdisplay] = React.useState("none");
    const [existingUserdisplay, setExistingUserdisplay] =
      React.useState("block");
    const [newheight, setNewheight] = React.useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [dataFiltered, setDataFiltered] = useState<any[]>([]);
    const [physicianDataFiltered, setPhysicianDataFiltered] = useState<any[]>(
      []
    );
    const [sitesDataFiltered, setSitesDataFiltered] = useState<any[]>([]);
    const [selectedRedioValue, setSelectedRedioValue] = React.useState("");
    const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);

    const adminContext = React.useContext(AdminContext);

    interface NewUserFormDataType {
      firstName: string;
      lastName: string;
      emailAddress: string;
    }

    const openDialog = () => {
      setOpen(true);
    };

    useImperativeHandle(ref, () => ({
      openDialog,
    }));

    useEffect(() => {
      if (
        dataFiltered.length > 0 &&
        SearchQuery.length >= UserSearchSetting.userSearchMinLength
      ) {
        setDisplay("block");
      } else {
        setDisplay("none");
      }
    }, [dataFiltered]);

    function handleActionResponse(
      response: any,
      successMessage: string,
      errorMessage: string,
      userSelectedRole: string
    ) {
      try {
        if (response.status === 200 && response.data === true) {
          adminContext.onRecordSave(true, successMessage);
          props.onsuccess();
        } //Status 200, and data is false. Sites have already been assigned to the champion.
        else if (response.status === 200 && response.data === false) {
          if (userSelectedRole === "admin") {
            //This meassege is if user has admin is already assigned
            adminContext.onRecordSave(true, successMessage);
          } else adminContext.onError(true, errorMessage);
        } else {
          adminContext.onError(true, errorMessage);
        }
      } catch (ex) {
        // console.log(ex);
      }
    }

    function isFormValid() {
      if (validateName() && validateRole() && validateSiteOrPhysician()) {
        return true;
      }
      return false;
    }

    const validateName = () => {
      return userId.length > 0;
    };
    const validateRole = () => {
      return userRoleId.length > 0;
    };
    const validateSiteOrPhysician = () => {
    
      const roleName = role.toLowerCase();
      if (roleName === "admin") {
        return true;
      } else if (roleName === "champion") {
        return physicianGroupId.length > 0 || siteId.length > 0;
      }
      return false;
    };

    const OnSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
      try {
        e.preventDefault();
        let roleExistMessage = "Role is already assigned.";
        waitIndicatorLoaderRef.current?.show();
        //get roleName from roles using userRoleId
        let selectedRole = roles.find((role: any) => role.name === userRoleId);

        var userSelectedRole = selectedRole.name.toLowerCase();
        let myObject: any;
        if (userSelectedRole === "champion") {
          if (physicianGroupId) {
            myObject = {
              physicianGroupId: physicianGroupId,
            };
            // roleExistMessage="Champion is already assigned to all sites in the physician group.";
          } else if (siteId) {
            myObject = {
              siteId: siteId,
            };
          }
          roleExistMessage = "Champion is already assigned to the site(s).";
        } else if (userSelectedRole === "admin") {
          myObject = {};
          roleExistMessage = "Role assigned successfully!";
        } else {
          myObject = null;
        }
        if (myObject != null) {
          let res = await updateUsers(myObject, userId, userSelectedRole);

          if (res.status == 200) {
            setOpen(false);
            resetform();
            handleActionResponse(
              res,
              "Role assigned successfully!",
              roleExistMessage,
              userSelectedRole
            );
          } else {
            waitIndicatorLoaderRef.current?.hide();
            resetform();
            handleActionResponse(
              res,
              "Role assigned successfully!",
              "Error assigning role!",
              userSelectedRole
            );
          }
        } else {
          resetform();
          waitIndicatorLoaderRef.current?.hide();
        }
      } catch (ex) {
        resetform();
        waitIndicatorLoaderRef.current?.hide();
      }
    };

    const handleChange = (event: SelectChangeEvent) => {
      try {
        setUserRoleId(event.target.value);
        setRole(event.target.value as string);
        if (event.target.value === "Champion") {
          setchampDisplay("block");
        } else {
          setchampDisplay("none");
          setPhysicianAssigndisplay("none");
          setSitesAssigndisplay("none");
          setPhysicianGroupId("");
          setSiteId("");
        }
      } catch (ex) {
        // console.log(ex);
      }
    };

    const handleClose = () => {
      waitIndicatorLoaderRef.current?.show();
      resetform();
      waitIndicatorLoaderRef.current?.hide();
    };

    const resetform = () => {
      setExistingUserdisplay("block");
      setNewUserdisplay("none");
      setSearchQuery("");
      setRole("");
      setPhysicianSearchQuery("");
      setSitesSearchQuery("");
      setPhysiciandisplay("none");
      setDisplay("none");
      setSitesdisplay("none");
      setOpen(false);
      setUserRoleId("");
      setchampDisplay("none");
      setSitesAssigndisplay("none");
      setPhysicianAssigndisplay("none");
      setSelectedRedioValue("");
      setUserId("");
      setPhysicianGroupId("");
      setSiteId("");
    };

    const userSearchChangeHandler = async (
      e: ChangeEvent<HTMLInputElement>
    ) => {
      try {
        const textFieldSearchQuery = e.target?.value ? e.target?.value : "";
        setSearchQuery(textFieldSearchQuery);

        if (
          textFieldSearchQuery.length >= UserSearchSetting.userSearchMinLength
        ) {
          clearTimeout(searchTimer);
          searchTimer = setTimeout(async () => {
          
            const appUsersSearchDataObject: any = await getSearchUsers(
              textFieldSearchQuery
            );
            const appUsersSearchData = appUsersSearchDataObject.data;
            if (appUsersSearchData) {
              setUserId("");
              setDataFiltered(appUsersSearchData);
              if (textFieldSearchQuery) {
                setDisplay("block");
                setSearchQuery(e.target.value);
              } else {
                setSearchQuery("");
                setDisplay("none");
              }
              if (dataFiltered.length === 0) {
                setDisplay("none");
                setUserId("");
              }
            } else {
              setDisplay("none");
              setUserId("");
            }
          }, UserSearchSetting.userSearchDebounceTime);
        } else {
          clearTimeout(searchTimer);
          setDataFiltered([]);
          setDisplay("none");
          setUserId("");
        }
      } catch (ex) {
        // console.log(ex);
      }
    };

    const SetValueHandler = (e: any) => {
      try {
        setSearchQuery(e.target.innerText);
        setDisplay("none");
        setUserId(e.target.id);
      } catch (ex) {
        // console.log(ex);
      }
    };

    const handlePhysicianTextFieldChange = (
      e: ChangeEvent<HTMLInputElement>
    ) => {
      try {
        const physicianSearchQuery = e.target?.value ? e.target?.value : "";
        let physicianDataFiltered = filterPhysicianData(
          physicianSearchQuery,
          physician
        );
        setPhysicianDataFiltered(physicianDataFiltered);
        if (physicianSearchQuery) {
          setPhysiciandisplay("block");
          setPhysicianSearchQuery(e.target.value);
        } else {
          setPhysicianSearchQuery("");
          setPhysiciandisplay("none");
        }
        if (physicianDataFiltered.length === 0) {
          setPhysiciandisplay("none");
          setPhysicianGroupId("");
        }
      } catch (ex) {
        // console.log(ex);
      }
    };

    const SetPhysicianValueHandler = (e: any) => {
      try {
        setPhysicianSearchQuery(e.target.innerText);
        setPhysiciandisplay("none");
        setPhysicianGroupId(e.target.id);
        setSiteId("");
      } catch (ex) {
        // console.log(ex);
      }
    };

    const handleSitesTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
      try {
        const SitesSearchQuery = e.target?.value ? e.target?.value : "";
        const SitesdataFiltered = filterSitesData(SitesSearchQuery, sites);
        setSitesDataFiltered(SitesdataFiltered);
        if (e.target.value) {
          setSitesdisplay("block");
          setSitesSearchQuery(e.target.value);
        } else {
          setSitesSearchQuery("");
          setSitesdisplay("none");
        }
        if (SitesdataFiltered.length === 0) {
          setSitesdisplay("none");
          setSiteId("");
        }
      } catch (ex) {
        // console.log(ex);
      }
    };

    const SetSitesValueHandler = (e: any) => {
      setSitesSearchQuery(e.target.innerText);
      setSiteId(e.target.id);
      setPhysicianGroupId("");
      setSitesdisplay("none");
    };

    const filterPhysicianData = (query: any, data: any[]) => {
      if (!query) {
        return data;
      } else {
        return data.filter((d) =>
          d.name.toLowerCase().includes(query.toLowerCase())
        );
      }
    };

    const filterSitesData = (query: any, data: any[]) => {
      if (!query) {
        return data;
      } else {
        return data.filter((d) =>
          d.name.toLowerCase().includes(query.toLowerCase())
        );
      }
    };

    function CheckSite(e: any) {
      if (e.target.value === "1") {
        setPhysicianAssigndisplay("block");
        setSitesAssigndisplay("none");
        setSitesSearchQuery("");
        setSelectedRedioValue("1");
      } else {
        setSitesAssigndisplay("block");
        setPhysicianAssigndisplay("none");
        setPhysicianSearchQuery("");
        setSelectedRedioValue("2");
      }
      setSiteId("");
      setPhysicianGroupId("");
    }

    function OpenNewUserHandler(e: any) {
      if (e) {
        setNewUserdisplay("block");
        setExistingUserdisplay("none");
      } else {
        setNewUserdisplay("none");
        setExistingUserdisplay("block");
      }
    }

    const formData: NewUserFormDataType = {
      firstName: "",
      lastName: "",
      emailAddress: "",
    };

    const inputChangeHandler = (
      setFunction: React.Dispatch<React.SetStateAction<string>>,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setFunction(event.target.value);
    };

    const OnNewUserSubmitHandler = (
      event: React.FormEvent<HTMLFormElement>
    ) => {
      event.preventDefault();
      waitIndicatorLoaderRef.current?.show();
      formData.firstName = firstName;
      formData.lastName = lastName;
      formData.emailAddress = emailAddress;
      let res = createUser(formData);
      if (res != null) {
        setOpen(false);
        handleActionResponse(
          res,
          "User added successfully!",
          "Error adding user!",
          ""
        );
      } else {
        waitIndicatorLoaderRef.current?.hide();
      }
    };

    return (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="add-users"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle className={classes.FormTitle}>
            Add User
          </DialogTitle>
          <div id="add-existing" style={{ display: existingUserdisplay }}>
            <form onSubmit={OnSubmitHandler}>
              <DialogContent className={classes.FormContent} >
                <Grid item xs={12} sm={12}>
                  <LabelTextField
                    displayName="Name"
                    name="user"
                    onTextChange={userSearchChangeHandler}
                    value={SearchQuery}
                  />
                  <div
                    style={{
                      padding: 3,
                      display: display,
                      border: "1px solid #cccc",
                      borderRadius: 20,
                    }}
                  >
                    <ul
                      style={{
                        listStyle: "none",
                        overflowY: "scroll",
                        overflowX: "hidden",
                        height: newheight ? "auto" : "120px",
                        scrollbarWidth: "thin",
                        margin: "6px 0px 6px 0px",
                      }}
                    >
                      {dataFiltered?.map((d) =>
                        d === "I do not see who I'm looking for." ? (
                          <li className={classes.FormListItem}
                          >
                            <div
                              style={{
                                color: "#000000",
                                backgroundColor: "#ccc",
                                fontWeight: 600,
                              }}
                              id="notFound"
                              onClick={(e) => OpenNewUserHandler(e)}
                            >
                              {d}
                            </div>
                          </li>
                        ) : (
                          <li className={classes.FormListItem}
                            key={d.userId}
                          >
                            <div
                              id={d.userId}
                              onClick={(e) => SetValueHandler(e)}
                            >
                              {d.firstName +
                                " " +
                                d.lastName +
                                " (" +
                                d.userNumber +
                                ")"}
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                  <InputLabel className={classes.FormLabel}
                  >
                    Role
                  </InputLabel>
                  <Select
                    className={classes.FormSelect}
                    value={role}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={{
                      PaperProps: {
                        elevation: 0,
                      },
                    }}
                    fullWidth
                  >
                    {roles.map((roles: any) => (
                      <MenuItem
                        className={classes.FormMenuItem}
                        id={roles.id}
                        value={roles.name}
                      >
                        {roles.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ marginTop: 20, display: champDisplay }}
                >
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="assignTo"
                      onChange={CheckSite}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={
                          <Typography
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color:
                                selectedRedioValue === "1"
                                  ? "#1976d2"
                                  : "#000000",
                            }}
                          >
                            Assign to all sites in Physician Group
                          </Typography>
                        }
                        style={{
                          color:
                            selectedRedioValue === "1" ? "#1976d2" : "#000000",
                        }}
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label={
                          <Typography
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              color:
                                selectedRedioValue === "2"
                                  ? "#1976d2"
                                  : "#000000",
                            }}
                          >
                            Assign to site
                          </Typography>
                        }
                        style={{
                          color:
                            selectedRedioValue === "2" ? "#1976d2" : "#000000",
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ marginTop: 20, display: PhysicianAssigndisplay }}
                >
                  <LabelTextField
                    displayName="Physician Group"
                    name="physician_group"
                    onTextChange={handlePhysicianTextFieldChange}
                    value={PhysicianSearchQuery}
                  />
                  <div
                    style={{
                      padding: 3,
                      display: Physiciandisplay,
                      border: "1px solid #cccc",
                      borderRadius: 20,
                    }}
                  >
                    <ul className={classes.FormList}>
                      {physicianDataFiltered.map(
                        (physicianDataFiltered: any) => (
                          <li className={classes.FormListItem}
                            key={physicianDataFiltered.id}
                          >
                            <div
                              id={physicianDataFiltered.id}
                              onClick={(e) => SetPhysicianValueHandler(e)}
                              style={{ color: "#000000" }}
                            >
                              {physicianDataFiltered.name}
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{ marginTop: 20, display: SitesAssigndisplay }}
                >
                  <LabelTextField
                    displayName="Site"
                    name="sites"
                    onTextChange={handleSitesTextFieldChange}
                    value={SitesSearchQuery}
                  />
                  <div
                    style={{
                      padding: 3,
                      display: Sitesdisplay,
                      border: "1px solid #cccccc",
                      borderRadius: 20,
                    }}
                  >
                    <ul className={classes.FormList}>
                      {sitesDataFiltered.map((SitesdataFiltered: any) => (
                        <li className={classes.FormListItem}
                          key={SitesdataFiltered.id}
                        >
                          <div
                            id={SitesdataFiltered.id}
                            onClick={(e) => SetSitesValueHandler(e)}
                            style={{ color: "#000000" }}
                          >
                            {SitesdataFiltered.name}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Grid>
              </DialogContent>
              <DialogActions className={classes.FormAction}>
                <RoundedButton
                  name="cancel"
                  text="Cancel"
                  buttonType="secondary"
                  onClick={handleClose}
                />
                <RoundedButton
                  name="submit"
                  text="Submit"
                  disabled={!isFormValid()}
                  onClick={OnSubmitHandler}
                />
              </DialogActions>
            </form>
          </div>
          <div id="add-new" style={{ display: newUserdisplay }}>
            <form onSubmit={OnNewUserSubmitHandler}>
              <DialogContent>
                <Grid item xs={12} sm={12}>
                  <LabelTextField
                    displayName="First Name"
                    name="firstName"
                    onTextChange={(e) => inputChangeHandler(setFirstName, e)}
                  />
                  <LabelTextField
                    displayName="Last Name"
                    name="lastName"
                    onTextChange={(e) => inputChangeHandler(setLastName, e)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <LabelTextField
                    displayName="Email"
                    name="emailAddress"
                    onTextChange={(e) => inputChangeHandler(setEmailAddress, e)}
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <RoundedButton
                  name="cancel"
                  text="Cancel"
                  buttonType="secondary"
                  onClick={handleClose}
                />
                <RoundedButton
                  name="submit"
                  text="Submit"
                  onClick={OnNewUserSubmitHandler}
                />
              </DialogActions>
            </form>
          </div>
          <WaitIndicator ref={waitIndicatorLoaderRef} />
        </Dialog>
      </div>
    );
  }
);

export default UserFormDialog;
