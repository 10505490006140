import classes from "./WizardAction.module.css";
import RoundedButton from "./formElements/RoundedButton";

export interface WizardActionProps {
  backButtonText: string;
  continueButtonText: string;
  backOnClick: (e: any) => void;
  continueOnClick: (e: any) => void;
  backDisabled?: boolean;
  continueDisabled?: boolean;
}

function WizardAction(props: WizardActionProps) {
  return (
    <div className={classes.container}>
      <div>
        <RoundedButton
          name="btnBack"
          text={props.backButtonText}
          onClick={props.backOnClick}
          buttonType="secondary"
          disabled={props.backDisabled}
        />
      </div>
      <div>
        <RoundedButton
          name="btnContinue"
          text={props.continueButtonText}
          onClick={props.continueOnClick}
          disabled={props.continueDisabled}
        />
      </div>
    </div>
  );
}

export default WizardAction;
