import ActivityBuilderHome from "../../../../components/admin/activity/activity-builder/ActivityBuilderHome";

function ActivityBuilderPage() {
  return (
    <>
      <ActivityBuilderHome />
    </>
  );
}
export default ActivityBuilderPage;
