import { useEffect, useState } from "react";

function useAppData(dataFn: any, refresh: boolean = false) {
  const [appData, setAppData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let loadData = true;
    const executeDataFn = async () => {
      try {
        setLoading(true);
        const response = await dataFn();
        const data = response.data;
        if (data) {
          setAppData(data);
        }
        if (response.error) {
          setError(response.error);
        }
        setLoading(false);
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    if (loadData) {
      executeDataFn();
    }
    return () => {
      loadData = false;
    };
  }, [refresh]);

  return { appData, loading, error };
}

export default useAppData;
