import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
interface ManageUserSearchBoxProps {
  searchInputBoxRef: any;
  initialSearchTerm?: string;
  clearSearchFun?:any;
  placeHolderText?:string;
}

function ManageUserSearchBox(props: ManageUserSearchBoxProps) {
  const [searchTerm, setSearchTerm] = useState<string>(
    props.initialSearchTerm || ""
  );
function clearSearchFun()
{ 
  setSearchTerm(""); // Clear the search term
  if (props.searchInputBoxRef?.current) {
    // Check if the ref is available and it has a current value
    props.searchInputBoxRef.current.value = ""; // Clear the input value
  }
  if (props.clearSearchFun) {
    props.clearSearchFun(); // Call the provided clearSearchFun if available
  }

}

  return (
    <>
      <TextField
        InputProps={{
          inputProps: {
            style: { padding: 5, borderRadius: 0, marginLeft: "10px",fontSize: "16px",lineHeight:"18px",opacity:0.6 },
          },
          style: { borderRadius: 20, width: "100%",height:"38px" },
          endAdornment: (
            <InputAdornment position="end">
              {searchTerm && (
                <IconButton
                  aria-label="clear search"
                  onClick={clearSearchFun}
                  edge="end"
              >                  
              <CloseRoundedIcon
                    sx={{color: "#000000"}}
                  />
                </IconButton>
              )}
            </InputAdornment>
          ),

        }}

        autoFocus={true}
        required
        id="search"
        name="search"
        inputRef={props.searchInputBoxRef}
        fullWidth
        placeholder= {props.placeHolderText} //{"Search by User Name, Site and Physician Group" }
        value={searchTerm}
        onChange={(r) => setSearchTerm(r.target.value)}
      />
    </>
  );
}

export default ManageUserSearchBox;
