import { useState } from "react";
import { getPerformanceValidationSummary } from "../../../../helpers/api-util";
import useAppData from "../../../../hooks/useAppData";
import WaitIndicator from "../../../atoms/WaitIndicator";
import ReactDataTable from "../../../atoms/tables/ReactDataTable";
import { Box } from "@mui/material";
import classes from "./PerformanceValidationSummary.module.css";
import { handleErrorReponse } from "../../../../helpers/ui-util";

export interface performanceValidationSummaryProps {
  display: (show: boolean) => void;
}
function PerformanceValidationSummary(
  props: performanceValidationSummaryProps
) {
  const [refreshData, setRefreshData] = useState(false);

  const performanceValidationDataFromAPI = useAppData(async () => {
    return await getPerformanceValidationSummary();
  }, refreshData);

  if (performanceValidationDataFromAPI?.loading) {
    return (
      <>
        <div>
          <WaitIndicator show={true}></WaitIndicator>
        </div>
      </>
    );
  }
  if (performanceValidationDataFromAPI?.error) {
    const errorJSX = handleErrorReponse(performanceValidationDataFromAPI.error);
    return (
      <>
        <div>{errorJSX}</div>
      </>
    );
  } else {
    const data = performanceValidationDataFromAPI?.appData?.map((data: any) => {
      return {
        initiativeName: data.initiativeName,
        activityName: data.activityName,
        totalSitesCompleted: data.totalSitesCompleted,
        totalSitesUploads: data.totalSitesUploads,
      };
    });
    if (!data || data?.length === 0) {
      props.display(false);
    } else {
      props.display(true);
    }
    const columns = [
      {
        name: "Initiative",
        selector: (row: { initiativeName: string }) => row.initiativeName,
        sortable: true,
        width: "35%",
        adjustWidthOnSmallerDevice: true,
      },
      {
        name: "Activity",
        selector: (row: { activityName: string }) => row.activityName,
        sortable: true,
        width: "32%",
        showOnMobileDevice: false,
        showOnTabletDevice: true,  
        adjustWidthOnSmallerDevice: true,      
      },
      {
        name: "Site Completed",
        selector: (row: { totalSitesCompleted: number }) =>
          row.totalSitesCompleted,
        sortable: true,
        center: true,
        width:'170px',
        showOnMobileDevice: true,
        showOnTabletDevice: true,
      },
      {
        name: "Total Uploads",
        selector: (row: { totalSitesUploads: number }) => row.totalSitesUploads,
        sortable: true,
        center: true,
        width:'170px',
        showOnMobileDevice: false,
        showOnTabletDevice: false,
      },
    ];
    return (
      <Box className={classes.container_head}>
        <div className={classes.container_header}>
          <div className={classes.title}>Performance Validation</div>
          <div className={classes.gridContainer}>
            <ReactDataTable
              data={data}
              columns={columns}
              fixedHeader={true}
              fixedHeaderScrollHeight="310px"
            />
          </div>
        </div>
      </Box>
    );
  }
}

export default PerformanceValidationSummary;
