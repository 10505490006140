import ReactDataTable from "../../../atoms/tables/ReactDataTable";
import InitiativeActivityList from "./InitiativeActivityList";
import InitiativeListItemAction from "./InitiativeListItemActionMenu";
import { getUIFormattedDate } from "../../../../helpers/ui-util";
import ListItemStatus from "../../../common/ListItemStatus";
import ListDetailContainer from "../../../common/ListDetailContainer";
import { ListDataTableStyle } from "../../../../helpers/ui-util";
import { InitiativeView } from "../../../../models/Initiative";
import { Tooltip } from "@mui/material";

export interface InitiativeListProps {
  initiatives: InitiativeView[] | undefined | null;
  onDataRefresh: () => void;
}

interface InitiativeViewData {
  id: string;
  name: string;
  initiativeStatus: string;
  initiativeStartDate: string;
  initiativeEndDate: string;
  updatedOn: string;
  initiativeActivities: any;
}

function InitiativeList(props: InitiativeListProps) {
  const { initiatives } = props;
  if (!initiatives) {
    return <div>No initiatives available.</div>;
  }

  const initiativesData: InitiativeViewData[] = [];
  for (let i = 0; i < initiatives.length; i++) {
    const initiative = initiatives[i];
    const initiativeData: InitiativeViewData = {
      id: initiative.id,
      name: initiative.name,
      initiativeStatus: initiative.initiativeStatus.name, // Assuming you want the status from each initiative
      initiativeStartDate: getUIFormattedDate(initiative.initiativeStartDate),
      initiativeEndDate: getUIFormattedDate(initiative.initiativeEndDate),
      updatedOn: getUIFormattedDate(initiative.updatedOn),
      initiativeActivities: initiative.initiativeActivities,
    };
    initiativesData.push(initiativeData);
  }

  const columns = [
    {
      name: "Initiative",
      selector: (row: InitiativeViewData) => row.name,
      cell: (row: InitiativeViewData) => (
        <Tooltip title={row.name} placement="top" arrow>
          <div className={"ellipsis"}>{row.name}</div>
        </Tooltip>
      ),
      sortable: true,
      adjustWidthOnSmallerDevice: true,
      width: "30%",
    },
    {
      name: "Start Date",
      selector: (row: InitiativeViewData) => row.initiativeStartDate,
      showOnMobileDevice: false,
      showOnTabletDevice: false,
    },
    {
      name: "End Date",
      selector: (row: InitiativeViewData) => row.initiativeEndDate,
      showOnMobileDevice: false,
      showOnTabletDevice: false,
    },
    {
      name: "Status",
      selector: (row: InitiativeViewData) => (
        <ListItemStatus status={row.initiativeStatus} />
      ),
      showOnMobileDevice: false,
      showOnTabletDevice: false,
    },
    {
      name: "Last Updated",
      selector: (row: InitiativeViewData) => row.updatedOn,
      showOnMobileDevice: false,
      showOnTabletDevice: false,
    },
    {
      name: "Actions",
      cell: (row: InitiativeViewData) => (
        <InitiativeListItemAction
          initiativeStatus={row.initiativeStatus}
          onDataRefresh={props.onDataRefresh}
          initiativeId={row.id}
        />
      ),
      center: true,
    },
  ];

  return (
    <>
      <ReactDataTable
        columns={columns}
        data={initiativesData}
        expandableRows={true}
        expandableRowsComponent={(props: any) => (
          <ListDetailContainer>
            <InitiativeActivityList
              initiativeActivities={props.data.initiativeActivities}
            />
          </ListDetailContainer>
        )}
        dataTableStyles={ListDataTableStyle}
      />
    </>
  );
}

export default InitiativeList;
