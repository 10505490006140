import {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  CSSProperties,
} from "react";
import Backdrop from "@mui/material/Backdrop";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";

export interface WaitIndicatorProps {
  show?: boolean;
  style?: CSSProperties | undefined;
}

export interface WaitIndicatorRef {
  show: () => void;
  hide: () => void;
}

const spinKeyframes = css`
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`;

const AnimatedDiv = styled("div")({
  width: "90px",
  height: "90px",
  border: "14px solid",
  borderColor: "#3d5af1 transparent #3d5af1 transparent",
  borderRadius: "50%",
  animation: "spin 1.2s linear infinite",
  animationName: "spin",
  position: "static",
  top: "0",
  left: "0",
  bottom:'0',
  right:'0',
  
  zIndex: "99999",
});

const WaitIndicator = forwardRef<WaitIndicatorRef, WaitIndicatorProps>(
  (props, ref) => {
    const [show, setShow] = useState(props.show);

    useImperativeHandle(ref, () => ({
      show: () => {
        setShow(true);
      },
      hide: () => {
        setShow(false);
      },
    }));

    useEffect(() => {
      setShow(props.show);
    }, [props.show]);

    if (show === true) {
      return (
        <>   
        <Global styles={spinKeyframes} />
        <Backdrop
          sx={{
            backgroundColor: "rgba(255,255,255,0.5)",
            zIndex: (theme) => 99999,
          }}
          open={show}
        >
         
           <AnimatedDiv style={props.style}></AnimatedDiv>
        </Backdrop>
        </>
      );
    }

    return <></>;
  }
);

export default WaitIndicator;
