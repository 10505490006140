const CircleExpandIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="white" />
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#DDDDDD" />
    <path
      d="M19.825 12.9126L16 16.7293L12.175 12.9126L11 14.0876L16 19.0876L21 14.0876L19.825 12.9126Z"
      fill="black"
    />
  </svg>
);

export default CircleExpandIcon;
export const ExpandMoreIcon = () => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 5.5L5 0.5L0 5.5L10 5.5Z" fill="black" />
  </svg>
);

export const ExpandLessIcon = () => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0.5L5 5.5L10 0.5H0Z" fill="black" />
  </svg>
);

export const CheckBoxIcon = () => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.79508 10.8749L1.62508 6.70492L0.205078 8.11492L5.79508 13.7049L17.7951 1.70492L16.3851 0.294922L5.79508 10.8749Z"
      fill="black"
    />
  </svg>
);

export const UploadIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 9.90039C0.632608 9.90039 0.759785 9.95307 0.853553 10.0468C0.947322 10.1406 1 10.2678 1 10.4004V12.9004C1 13.1656 1.10536 13.42 1.29289 13.6075C1.48043 13.795 1.73478 13.9004 2 13.9004H14C14.2652 13.9004 14.5196 13.795 14.7071 13.6075C14.8946 13.42 15 13.1656 15 12.9004V10.4004C15 10.2678 15.0527 10.1406 15.1464 10.0468C15.2402 9.95307 15.3674 9.90039 15.5 9.90039C15.6326 9.90039 15.7598 9.95307 15.8536 10.0468C15.9473 10.1406 16 10.2678 16 10.4004V12.9004C16 13.4308 15.7893 13.9395 15.4142 14.3146C15.0391 14.6897 14.5304 14.9004 14 14.9004H2C1.46957 14.9004 0.960859 14.6897 0.585786 14.3146C0.210714 13.9395 0 13.4308 0 12.9004V10.4004C0 10.2678 0.0526784 10.1406 0.146447 10.0468C0.240215 9.95307 0.367392 9.90039 0.5 9.90039Z"
      fill="#6B7280"
    />
    <path
      d="M7.64599 1.14689C7.69244 1.10033 7.74761 1.06339 7.80836 1.03818C7.8691 1.01297 7.93422 1 7.99999 1C8.06576 1 8.13088 1.01297 8.19162 1.03818C8.25237 1.06339 8.30755 1.10033 8.35399 1.14689L11.354 4.14689C11.4479 4.24078 11.5006 4.36812 11.5006 4.50089C11.5006 4.63367 11.4479 4.76101 11.354 4.85489C11.2601 4.94878 11.1328 5.00153 11 5.00153C10.8672 5.00153 10.7399 4.94878 10.646 4.85489L8.49999 2.70789V11.5009C8.49999 11.6335 8.44731 11.7607 8.35354 11.8544C8.25978 11.9482 8.1326 12.0009 7.99999 12.0009C7.86738 12.0009 7.74021 11.9482 7.64644 11.8544C7.55267 11.7607 7.49999 11.6335 7.49999 11.5009V2.70789L5.35399 4.85489C5.3075 4.90138 5.25231 4.93826 5.19157 4.96342C5.13084 4.98858 5.06573 5.00153 4.99999 5.00153C4.93425 5.00153 4.86915 4.98858 4.80841 4.96342C4.74767 4.93826 4.69248 4.90138 4.64599 4.85489C4.5995 4.80841 4.56263 4.75322 4.53747 4.69248C4.51231 4.63174 4.49936 4.56664 4.49936 4.50089C4.49936 4.43515 4.51231 4.37005 4.53747 4.30931C4.56263 4.24857 4.5995 4.19338 4.64599 4.14689L7.64599 1.14689Z"
      fill="#6B7280"
    />
  </svg>
);
