export const mapSiteInitiativesGridData = (resultData: any, progress: string): InitiativesEnrollment[] => {
  try {
    if (Array.isArray(resultData) && resultData.length > 0) {
      const filteredInitiativesGridData = resultData
        .map((result: any) => {
          const progressPercentage = result.progressPercentage || 0;
          const recordProgress = progressPercentage === 0 ? 'Not Started' : (progressPercentage < 100 ? 'In Progress' : 'Completed');

          return {
            id: result.id,
            siteName: result.siteName,
            acepSiteNumber: result.acepSiteNumber,
            physicianGroupName: result?.physicianGroupName ?? "",
            initiativeId: result.initiativeId,
            initiativeName: result.initiativeName,
            initiativeStatusName: result.initiativeStatusName,
            openToActivity: result.openToActivity,
            siteId: result.siteId,
            siteEnrollmentId: result.siteEnrollmentId,
            lastUpdated: result.lastUpdated || "",
            progressPercentage,
            progress: recordProgress,
          };
        })
        .filter(record => (!progress || record.progress === progress) && record.initiativeStatusName !== "Deactivated"); 
    
      return filteredInitiativesGridData;
    }
  } catch (error) {
    console.error("Error occurred while fetching Data:", error);
  }

  return [];
};
