import { Box, Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import TabPanel from "../../../atoms/TabPanel";
import { useNavigate } from "react-router-dom";
import AdminContext from "../../../../store/admin-context";
import classes from "./ManageEnrollmentQuestionnaireLayout.module.css";
import BackNavLink from "../../../atoms/BackNavLink";
import {
  ActiveNumberSVG,
  DisabledNumberSVG,
} from "../../../atoms/Icons/LooksRounded";
import useAppData from "../../../../hooks/useAppData";
import { getEnrollmentQuestionnaireById } from "../../../../helpers/api-util";
import WaitIndicator, { WaitIndicatorRef } from "../../../atoms/WaitIndicator";

function a11yProps(index: number) {
  return {
    id: `questionnaire-tab-${index}`,
    "aria-controls": `questionnaire-tabpanel-${index}`,
  };
}

type ContextType = {
  refreshData: () => void;
  enrollmentQuesData: any;
};

function ManageEnrollmentQuestionnaireLayout() {
  const location = useLocation();
  const path = location.pathname;
  const adminContext = useContext(AdminContext);
  const navigate = useNavigate();
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [refreshData, setRefreshData] = React.useState(false);

  useEffect(() => {
    let tab = 0;
    if (path === "/admin/questionnaire/builder") {
      tab = 1;
    } else if (path === "/admin/questionnaire/publish") {
      tab = 2;
    }
    setPageIndex(tab);
  }, [path]);

  const enrollmentQuesData = useAppData(async () => {
    if (adminContext.enrollmentId === "") {
      return {
        loading: false,
        error: null,
        data: null,
      };
    }
    return await getEnrollmentQuestionnaireById(adminContext.enrollmentId);
  }, refreshData);

  function hasQnDefinitions() {
    if (
      enrollmentQuesData.appData &&
      enrollmentQuesData.appData.initiativeActivity &&
      enrollmentQuesData.appData.initiativeActivity.hasQnDefinitions
    ) {
      return true;
    }
    return false;
  }

  function canTabBeEnabled(tabIndex: number) {
    if (tabIndex === 0) {
      return true;
    }
    if (tabIndex == 1) {
      if (adminContext.enrollmentId === "") {
        return false;
      }
      return true;
    }
    if (tabIndex === 2) {
      return hasQnDefinitions();
    }
    return false;
  }

  const handleWizardPageChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    if (newValue === 0) {
      if (adminContext.enrollmentId === "") {
        navigate("/admin/questionnaire/create");
      } else {
        navigate("/admin/questionnaire/update");
      }
    } else if (newValue === 1) {
      navigate("/admin/questionnaire/builder");
    } else if (newValue === 2) {
      navigate("/admin/questionnaire/publish");
    }
    setRefreshData(!refreshData);
    setPageIndex(newValue);
  };

  if (enrollmentQuesData.loading) {
    return (
      <>
        <WaitIndicator ref={waitIndicatorLoaderRef} show={true} />
      </>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.container_back}>
        <BackNavLink
          to="/admin/enrollment-questionnaires"
          text="Back to Manage Enrollment Questionnaires"
        />
      </div>
      <div className={classes.container_content}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginLeft: "24px",
              marginRight: "24px",
            }}
          >
            <Tabs value={pageIndex} onChange={handleWizardPageChange}>
              <Tab
                label={
                  <div className={classes.tabLabel}>
                    <div className={classes.iconContainer}>
                      {canTabBeEnabled(0) ? (
                        <ActiveNumberSVG number={1} />
                      ) : (
                        <DisabledNumberSVG number={1} />
                      )}
                    </div>
                    <span
                      className={`${classes.tabText} ${
                        canTabBeEnabled(0)
                          ? classes.activeTab
                          : classes.disabledTab
                      }`}
                    >
                      Settings
                    </span>
                  </div>
                }
                {...a11yProps(0)}
                style={{
                  textTransform: "none",
                  width: "33%",
                  paddingLeft: "17%",
                  paddingRight: "16%",
                }}
              />
              <Tab
                disabled={!canTabBeEnabled(1)}
                label={
                  <div className={classes.tabLabel}>
                    <div className={classes.iconContainer}>
                      {canTabBeEnabled(1) ? (
                        <ActiveNumberSVG number={2} />
                      ) : (
                        <DisabledNumberSVG number={2} />
                      )}
                    </div>
                    <span
                      className={`${classes.tabText} ${
                        canTabBeEnabled(1)
                          ? classes.activeTab
                          : classes.disabledTab
                      }`}
                    >
                      Questionnaire Builder
                    </span>
                  </div>
                }
                {...a11yProps(1)}
                style={{
                  textTransform: "none",
                  width: "33%",
                  paddingLeft: "17%",
                  paddingRight: "16%",
                  whiteSpace: "nowrap",
                }}
              />
              <Tab
                disabled={!canTabBeEnabled(2)}
                label={
                  <div className={classes.tabLabel}>
                    <div className={classes.iconContainer}>
                      {canTabBeEnabled(2) ? (
                        <ActiveNumberSVG number={3} />
                      ) : (
                        <DisabledNumberSVG number={3} />
                      )}
                    </div>
                    <span
                      className={`${classes.tabText} ${
                        canTabBeEnabled(2)
                          ? classes.activeTab
                          : classes.disabledTab
                      }`}
                    >
                      Preview & Publish
                    </span>
                  </div>
                }
                {...a11yProps(2)}
                style={{
                  textTransform: "none",
                  width: "34%",
                  paddingLeft: "17%",
                  paddingRight: "17%",
                  whiteSpace: "nowrap",
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={pageIndex} index={0}>
            <Outlet
              context={{
                refreshData: () => {
                  setRefreshData(!refreshData);
                },
                enrollmentQuesData: enrollmentQuesData,
              }}
            />
          </TabPanel>
          <TabPanel value={pageIndex} index={1}>
            <Outlet
              context={{
                refreshData: () => {
                  setRefreshData(!refreshData);
                },
                enrollmentQuesData: enrollmentQuesData,
              }}
            />
          </TabPanel>
          <TabPanel value={pageIndex} index={2}>
            <Outlet
              context={{
                refreshData: () => {
                  setRefreshData(!refreshData);
                },
                enrollmentQuesData: enrollmentQuesData,
              }}
            />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}

export function useEnrollmentQuestionnaireData(): ContextType {
  return useOutletContext<ContextType>();
}

export default ManageEnrollmentQuestionnaireLayout;
