import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreVert } from "@mui/icons-material";
import AdminContext from "../../../store/admin-context";
import { useNavigate } from "react-router-dom";
import DeleteConfirmDialog from "../dialogs/DeleteConfirmDialog";
import { RemoveUserData } from "../../../helpers/api-util";
import WaitIndicator,{ WaitIndicatorRef } from "../WaitIndicator";

interface InitiativeListItemActionProps {
  userRoleId: string;
  userStatus: string;
  onsuccess: any;
}
function UsersListItemAction(props: InitiativeListItemActionProps) {
  const menuDisplay = {
    Remove: false,
  };

  const { userStatus, onsuccess } = props;
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const adminContext = React.useContext(AdminContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const waitIndicatorLoaderRef = React.useRef<WaitIndicatorRef>(null);
  
  const actionButtonClickHandler = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleActionResponse(
    response: any,
    successMessage: string,
    errorMessage: string
  ) {
    if (response.data != null) {
      adminContext.onRecordSave(true, successMessage);
      props.onsuccess();
    } else {
      adminContext.onError(true, errorMessage);
    }
  }

  function onRemoveClickHandler() {
    setAnchorEl(null);
   // console.log("RemoveClickHandler");
    setConfirmOpen(true);
  }

  async function RemoveUser() {
    waitIndicatorLoaderRef.current?.show();
    const response = await RemoveUserData(props.userRoleId);
    handleActionResponse(
      response,
      "User Removed successfully!",
      "Error Removing user!"
    );
    waitIndicatorLoaderRef.current?.hide();
    //onsuccess();
  }

  if (userStatus === "remove") {
    menuDisplay.Remove = true;
  }

  return (
    <div>
      <DeleteConfirmDialog
        title="Are you sure?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={RemoveUser}
        //children={true}
      />
      <Button
        id="action-button"
        aria-controls={open ? "action-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={actionButtonClickHandler}
        style={{ color: "black" }}
      >
        <MoreVert />
      </Button>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        MenuListProps={{
          "aria-labelledby": "action-button",
        }}
      >
        <div
          style={{
            cursor: "not-allowed",
            display: menuDisplay.Remove ? "block" : "none",
          }}
        >
          <MenuItem
            disabled={(props.userRoleId === null || props.userRoleId === "00000000-0000-0000-0000-000000000000" )? true: false }
            onClick={onRemoveClickHandler}
            style={{fontWeight: 600}}
          >
            Remove
          </MenuItem>
        </div>
      </Menu> 
      <WaitIndicator ref={waitIndicatorLoaderRef} show={false}/>
    </div>
  );
}

export default UsersListItemAction;
