import List from "@mui/material/List";

export interface ActivityListProps {
  activities: any;
  children?: React.ReactNode;
}

function ActivityList(props: ActivityListProps) {
  const activities = props.activities ? props.activities : [];
  return (
    <>
      {activities.length > 0 ? (
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          component="nav"
        >
          {props.children}
        </List>
      ) : (
        <h4>No Data Found!</h4>
      )}
    </>
  );
}

export default ActivityList;
