import InitiativeQuestionnaires from "../../../components/champion/initiatives/InitiativeQuestionnaires";

function InitiativeQuestionnairesPage() {
  return (
    <div>
      <InitiativeQuestionnaires />
    </div>
  );
}
export default InitiativeQuestionnairesPage;
