import ManageInitiativeHome from "../../../components/admin/ManageInitiativeHome";

function ManageInitiativesPage() {
  return (
    <>
      <ManageInitiativeHome />
    </>
  );
}

export default ManageInitiativesPage;
