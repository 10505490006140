import { forwardRef, useImperativeHandle } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { MatrixDropdownColumn } from "survey-core";
import questionOptions from "../../store/questionOptions.json";

export interface QnAComponentRef {
  getData: () => any;
  validate: () => boolean;
}

export interface QnAComponentProps {
  activity: any;
  answer?: any;
  isFormDisabled?: boolean;
  onQuestionValueChanged?: (sender: any, options: any) => void;
  onQuestionFileUpload?: (sender: any, options: any) => void;
  onQuestionClearFiles?: (sender: any, options: any) => void;
}

const QnAComponent = forwardRef<QnAComponentRef, QnAComponentProps>(
  (props, ref) => {
    const { activity, answer } = props;
    const enableCustomMatrixValidator =
      questionOptions.dynamicMatrixOptions.enableCustomMatrixValidator;

    function getSurveyData() {
      return survey.data;
    }
    function validateSurvey() {
      return survey.validate();
    }

    useImperativeHandle(ref, () => ({
      getData: () => {
        return getSurveyData();
      },
      validate: () => {
        return validateSurvey();
      },
    }));

    let surveyJson = {
      elements: [],
      widthMode: "responsive",
    };

    if (!activity) {
      return <p>Activity information not found! </p>;
    }
    if (activity && activity.definition) {
      surveyJson = JSON.parse(activity.definition);
    } else {
      surveyJson = {
        elements: [],
        widthMode: "responsive",
      };
    }

    function addIndexColumnForDynamicMatrixQuestion(survey: any) {
      const showRowIndexes =
        questionOptions.dynamicMatrixOptions.showRowIndexes;
      const showTotalRowsCount =
        questionOptions.dynamicMatrixOptions.showTotalRowsCount;

      if (!showRowIndexes && !showTotalRowsCount) {
        return;
      }
      survey.getAllQuestions().forEach((question: any) => {
        try {
          if (question.getType() === "matrixdynamic") {
            const rowIndexCol: any = new MatrixDropdownColumn(
              "rowIndexCol",
              " "
            );
            question.columns.splice(0, 0, rowIndexCol);
            rowIndexCol.cellType = "expression";
            if (showRowIndexes) {
              rowIndexCol.expression = "{rowIndex}";
            }
            if (showTotalRowsCount) {
              rowIndexCol.totalType = "count";
              rowIndexCol.totalFormat = "Total = {0}";
            }
          }
        } catch (ex) {
          console.log(ex);
        }
      });
    }

    function validateDynamicMatrixQuestion(sender: any, options: any) {
      if (options.question.getType() !== "matrixdynamic") {
        return; // we are interested only in dynamic matrix question
      }
      var isRequired = options.question.isRequired;
      if (!isRequired) {
        return; // if question is not required then we do not need to validate it
      }
      var answers = options.value;
      if (!answers || answers.length === 0) {
        options.error = "Please fill in at least 1 row(s).";
        return;
      }
      //check if all rows just have rowIndexCol
      var hasData = false;
      for (var i = 0; i < answers.length; i++) {
        if (Object.keys(answers[i]).length > 1) {
          hasData = true;
        }
      }
      if (!hasData) {
        options.error = "Please fill in at least 1 row(s).";
        return;
      }
    }

    const survey = new Model(surveyJson);
    addIndexColumnForDynamicMatrixQuestion(survey);

    if (enableCustomMatrixValidator) {
      survey.onValidateQuestion.add(validateDynamicMatrixQuestion);
    }

    survey.checkErrorsMode = "onValueChanged";
    if (answer) {
      survey.data = JSON.parse(answer);
    }

    if (props.onQuestionValueChanged) {
      survey.onValueChanged.add(props.onQuestionValueChanged);
    }
    if (props.onQuestionFileUpload) {
      survey.onUploadFiles.add(props.onQuestionFileUpload);
    }
    if (props.onQuestionClearFiles) {
      survey.onClearFiles.add(props.onQuestionClearFiles);
    }
    if (props.isFormDisabled) {
      survey.mode = "display";
    }

    return (
      <div>
        <Survey model={survey} showNavigationButtons={false} />
      </div>
    );
  }
);
export default QnAComponent;
