export const mapinitiativesCardData = (resultData: any): InitiativesCard[] => {
  let initiativesCardData: InitiativesCard[] = [];

  try {
    if (Array.isArray(resultData) && resultData.length > 0) {
      initiativesCardData = resultData.map((result: any, index: number) => {
        let logoData = result.logo;
        if (typeof logoData === "undefined") {
          logoData = null;
        }
        if (typeof result.longSummary === "undefined" || result.longSummary === null) {
          result.longSummary = "";
        }
        if (typeof result.name === "undefined" || result.name === null) {
          result.name = "";
        }
     

        const initiativeName = result.name.length > 40
          ? result.name.substring(0, 40) + "..."
          : result.name;

        return {
          id: result.id,
          name: initiativeName,
          logo: logoData,
          description: result.longSummary,
        };
      });
    }
  } catch (error) {
    console.error("Error occurred while fetching Data:", error);
  }
  return initiativesCardData;
};
