import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ReactDataTable from "../../../atoms/tables/ReactDataTable";
import { useState } from "react";
import { Grid, Tooltip } from "@mui/material";
import classes from "./SiteInitiativesGridView.module.css";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  getFilteredDataByInitiativeId,
  getSameSiteEnrollmentData,
  mapInitiativesGridData,
} from "../../initiatives/InitiativesGrid";

interface SiteInitiativesGridViewProps {
  data: InitiativesEnrollment[];
  noDataMessage?: string;
  onViewAllClick: (activity: InitiativesEnrollment) => void;
  siteInitiativeColdWidth: string;
  totalMappedData: InitiativesEnrollment[];
}
function SiteInitiativesGridView({
  data,
  noDataMessage,
  onViewAllClick,
  siteInitiativeColdWidth,
  totalMappedData,
}: SiteInitiativesGridViewProps) {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<InitiativesEnrollment[]>([]);

  const mappedData: InitiativesEnrollment[] =
    mapInitiativesGridData(totalMappedData);

  function onArrowButtonClickHandler(row: InitiativesEnrollment) {
    if (row.openToActivity === true) {
      setSelectedRows([row]);
      var sameSiteEnrollmentData = getSameSiteEnrollmentData(
        mappedData,
        row.initiativeId
      );

      navigate("/champion/initiative-questionnaires", {
        replace: true,
        state: {
          initiativeEnrollmentInfo: row,
          sameSiteEnrollmentData: sameSiteEnrollmentData,
        },
      });
    }
  }

  const columns = [
    {
      name: "Site",
      selector: (row: InitiativesEnrollment) => row.siteName,
      cell: (row: InitiativesEnrollment) => (
        <Tooltip title={row.siteName} placement="top" arrow>
          <div className={"ellipsis"}>{row.siteName}</div>
        </Tooltip>
      ),
      sortable: true,
      width: siteInitiativeColdWidth,
    },
    {
      name: "Initiative",
      selector: (row: InitiativesEnrollment) => row.initiativeName,
      cell: (row: InitiativesEnrollment) => (
        <Tooltip title={row.initiativeName} placement="top" arrow>
          <div className={"ellipsis"}>{row.initiativeName}</div>
        </Tooltip>
      ),
      width: siteInitiativeColdWidth,
    },

    {
      name: "",
      cell: (row: InitiativesEnrollment) => (
        <button
          style={{
            border: "none",
            background: "none",
            cursor:
              row.initiativeStatusName === "Deactivated" ||
              row.openToActivity === false
                ? "default"
                : "pointer",
          }}
          onClick={(e: React.MouseEvent) => {
            onArrowButtonClickHandler(row);
          }}
        >
          <KeyboardArrowRightIcon
            style={{
              color:
                row.initiativeStatusName === "Deactivated" ||
                row.openToActivity === false
                  ? "#cccccc"
                  : "",
            }}
          />
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "1%",
    },
  ];

  const newStyle = {
    headRow: {
      border: "none",
      borderBottom: "3px solid #000000",
      paddingTop: "23px",
    },
    rows: {
      paddingBottom: "10px",
      paddingTop: "10px",
    },
  };

  return (
    <Grid>
      {data.length > 0 && (
        <>
          <ReactDataTable
            columns={columns}
            data={data.slice(0, 5)}
            selectable={false}
            striped
            pointerOnHover
            dataTableStyles={newStyle}
          />
          {data.length > 5 && (
            <div
              className={classes.viewAllLink}
              onClick={() => onViewAllClick(data[0])}
            >
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#1F78B4",
                }}
              >
                View More{" "}
                <ArrowForwardIcon
                  style={{ marginLeft: "10px", color: "#1F78B4" }}
                />
              </span>
            </div>
          )}
        </>
      )}
    </Grid>
  );
}

export default SiteInitiativesGridView;
