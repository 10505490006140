import { PieChartDataItem } from "../../../atoms/charts/Pie";

export interface InitiativeData {
    initiativeId: string;
    initiativeName: string;
    completedSites: number;
    incompleteSites: number;
    notStartedSites: number;
    totalSites: number;
    completedPercentage: number;
    incompletePercentage: number;
    notStartedPercentage: number;
}
export interface mappedInitiativeData{
    initiativeId: string;
    initiativeName: string;
    totalSites: number;
    PieChartDataItem: PieChartDataItem[];
}

export const mapInitiativesData = (data: InitiativeData[]): mappedInitiativeData[] => {
   
    return data?.map((initiative) => {
        const initiativeTruncName = initiative.initiativeName.length > 32
            ? initiative.initiativeName.substring(0, 32) + "..."
            : initiative.initiativeName;
        return {
            initiativeId: initiative.initiativeId,
            initiativeName: initiativeTruncName,
            totalSites: initiative.totalSites,
            PieChartDataItem: [
                {
                    label: "Completed",
                    value: initiative.completedPercentage,
                    color: "#8AF28E",
                },
                {
                    label: "In Progress",
                    value: initiative.incompletePercentage,
                    color: "#89B8FF",
                },
                {
                    label: "Not Started",
                    value: initiative.notStartedPercentage,
                    color: "#E7E7E7",
                },
            ],
        };
    });
    };
