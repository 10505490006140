import classes from "./SiteChampionsHome.module.css";
import SiteChampionsView, { Site } from "./site-champions/SiteChampionsView";
import useAppData from "../../hooks/useAppData";
import { getSiteChampions } from "../../helpers/api-util";
import WaitIndicator from "../atoms/WaitIndicator";
import { handleErrorReponse } from "../../helpers/ui-util";

function SiteChampionsPageHeader() {
  return (
    <>
      <div className={classes.container}>
        <div className={classes.container_header_title}>
          <h1>Sites & Champions</h1>
        </div>
      </div>
    </>
  );
}

function SiteChampionsHome() {
  const { appData, loading, error } = useAppData(async () => {
    return await getSiteChampions();
  }, false);

  if (loading) {
    return (
      <>
        <SiteChampionsPageHeader />
        <div>
          <WaitIndicator show={true}></WaitIndicator>
        </div>
      </>
    );
  }
  if (error) {
    var errorJSX = handleErrorReponse(error);
    return (
      <>
        <SiteChampionsPageHeader />
        {errorJSX}
      </>
    );
  }

  if (appData) {
    return (
      <>
        <SiteChampionsPageHeader />
        <SiteChampionsView siteData={appData as Site[]} />
      </>
    );
  }

  const unknownErrorJSX = handleErrorReponse(null);
  return (
    <>
      <SiteChampionsPageHeader />
      {unknownErrorJSX}
    </>
  );
}

export default SiteChampionsHome;
