import React, { Fragment, useContext, useRef } from "react";
import WizardHeader from "../../../atoms/WizardHeader";
import { EnrollmentQuestionnaireForm } from "./EnrollmentQuestionnaireForm";
import WaitIndicator, { WaitIndicatorRef } from "../../../atoms/WaitIndicator";
import { useNavigate, useLocation } from "react-router-dom";
import AdminContext from "../../../../store/admin-context";
import { createEnrollmentQuestionnaire } from "../../../../helpers/api-util";
import { useEnrollmentQuestionnaireData } from "./ManageEnrollmentQuestionnaireLayout";

export function CreateEnrollmentQuestionnaire() {
  const location = useLocation();
  const navigate = useNavigate();
  const adminContext = useContext(AdminContext);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  // const [usedYears, setUsedYears] = React.useState<any>(
  //   location.state.usedYears
  // );
  const enrollmentQuesData = useEnrollmentQuestionnaireData();

  async function createEnrollmentQuestionnaireInfo(formData: any) {
    let response = await createEnrollmentQuestionnaire(formData);
    if (response.data != null) {
      adminContext.setEnrollmentId(response.data.id);
    }

    if (response.error != null) {
      if (response.error.response?.status === 400) {
        const error = (
          response.error.response?.data as { value: { error: string } }
        )?.value?.error;
        adminContext.onError(true, error);
        return response;
      }

      adminContext.onError(true, "Unable to create enrollment questionnaire!");
      return response;
    }
    return response;
  }

  async function onSaveAndContinue(formData: any) {   
    try {
      waitIndicatorLoaderRef.current?.show();
      const response = await createEnrollmentQuestionnaireInfo(formData);
      if (response.data && response.data.id) {
        enrollmentQuesData?.refreshData();
        navigate("/admin/questionnaire/builder");
      }
      waitIndicatorLoaderRef.current?.hide();
    } catch (ex) {
      console.log(ex);
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  return (
    <>
      <WizardHeader title="Setting" />
      <EnrollmentQuestionnaireForm
        onSaveAndContinue={onSaveAndContinue}
        usedYears={location.state.usedYears}
      />
      <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
    </>
  );
}
