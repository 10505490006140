import classes from "./AdminManageUsersHome.module.css";
import ManageUsersView from "./manage-users/ManageUsersView";

function AdminManageUsersHeader() {

    return (
        <>
         <div className={classes.container}>
                <div className={classes.container_header_title}>
                    <h1>Manage Users</h1>
                </div>
        </div>
        </>
    );
}

function AdminManageUsers() {
    return (
        <>
            <AdminManageUsersHeader />
            <ManageUsersView />
        </>
    );
}

export default AdminManageUsers;