import AdminContext from "../../../../store/admin-context";
import { useContext, useRef } from "react";
import {
  getInitiativeActivities,
  getInitiativeById,
  publishInitiative,
} from "../../../../helpers/api-util";

import WizardHeader from "../../../atoms/WizardHeader";
import ActivityPublishAction from "./ActivityPublishAction";
import ActivityPublishList from "./ActivityPublishList";

import { useNavigate } from "react-router-dom";
import useAppData from "../../../../hooks/useAppData";
import WaitIndicator, { WaitIndicatorRef } from "../../../atoms/WaitIndicator";
import { handleErrorReponse } from "../../../../helpers/ui-util";

function ActivityPublishHome() {
  const navigate = useNavigate();
  const adminContext = useContext(AdminContext);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);

  const activitiesData = useAppData(async () => {
    return await getInitiativeActivities(adminContext.initiativeId);
  });
  const { appData, loading, error } = useAppData(async () => {
    return await getInitiativeById(adminContext.initiativeId);
  });
  if (loading) {
    return (
      <div>
        <WaitIndicator show={true}></WaitIndicator>
      </div>
    );
  }
  if (error) {
    const errorJSX = handleErrorReponse(error);
    return <>{errorJSX}</>;
  }
  const initiativeName = appData.name;

  async function publishOnClickHandler(event: any) {
    try {
      waitIndicatorLoaderRef.current?.show();
      const response = await publishInitiative(adminContext.initiativeId);
      if (response.error) {
        adminContext.onError(true, "Failed to publish initiative!");
      } else {
        adminContext.onRecordSave(true, "Initiative published successfully!");
        return navigate("/admin/manage-initiatives");
      }
      waitIndicatorLoaderRef.current?.hide();
    } catch (ex) {
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  function onBackClickHandler(event: any) {
    navigate("/admin/initiative/activity/builder");
  }

  function saveOnClickHandler(data: any) {}

  function cancelOnClickHandler(data: any) {}

  const headerJSX = <WizardHeader title="Preview & Publish" />;

  if (activitiesData.loading === true) {
    return (
      <>
        {headerJSX}
        <WaitIndicator show={true} />
      </>
    );
  }

  if (activitiesData.error) {
    const errorJSX = handleErrorReponse(activitiesData.error);
    return (
      <>
        {headerJSX}
        {errorJSX}
      </>
    );
  }

  return (
    <>
      {headerJSX}
      <h3>{initiativeName}</h3>
      <ActivityPublishList
        activities={activitiesData.appData}
        saveOnClick={saveOnClickHandler}
        cancelOnClick={cancelOnClickHandler}
      />
      <ActivityPublishAction
        continueOnClick={publishOnClickHandler}
        backOnClick={onBackClickHandler}
      />
      <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
    </>
  );
}

export default ActivityPublishHome;
