import AnalyticsDashboard from "../../components/champion/AnalyticsDashboard";

function ChampionHomePage() {
  return (
    <>
      <AnalyticsDashboard />
    </>
  );
}

export default ChampionHomePage;
