import RoundedButton from "../../../atoms/formElements/RoundedButton";
import classes from "./ActivityAction.module.css";

function ActivityAction(props: any) {
  return (
    <div className={classes.container}>
      <div>
        <RoundedButton
          name="btnCancel"
          text="Cancel"
          buttonType="secondary"
          onClick={props.cancelOnClick}
        />
      </div>
      <div>
        <RoundedButton
          disabled={props.disabled}
          type="submit"
          name="btnSave"
          text="Save"
          onClick={props.saveOnClick}
        />
      </div>
    </div>
  );
}

export default ActivityAction;
