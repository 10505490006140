import classes from "../common/EnrollmentListItemStatus.module.css";

export interface EnrollmentListItemStatusProps {
  activityStatus: string;
}

//TODO: remove this and use ListItemStatus
function EnrollmentListItemStatus(props: EnrollmentListItemStatusProps) {
  const activityStatus = props.activityStatus;

  let statusStyle = {};
  let color = undefined;
  let backgroundColor = undefined;
  if (activityStatus === "Pending") {
    color = "#1976D2";
    backgroundColor = "#D6EBFF";
  } else if (activityStatus === "Enrolled") {
    color = "#27AE60";
    backgroundColor = "#DCFAE9";
  }

  statusStyle = {
    color: color,
    backgroundColor: backgroundColor,
    fontWeight: 600,
    fontSize: "13.52px",
  };

  return (
    <span style={statusStyle} className={classes.StatusList}>
      {activityStatus}
    </span>
  );
}

export default EnrollmentListItemStatus;
