import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';

interface TabItem {
  label: string;
  content: React.ReactNode;
}

interface GenericTabsProps {
  tabs: TabItem[];
  activeTab: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const GenericTabs: React.FC<GenericTabsProps> = ({ tabs, activeTab, onChange }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={onChange}>
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.label} style={{ textTransform: "none",fontWeight: "bold",color:"black"}}            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <div key={index} hidden={activeTab !== index}>
          {activeTab === index && tab.content}
        </div>
      ))}
    </Box>
  );
};

export default GenericTabs;
