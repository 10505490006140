import { createTheme, ThemeProvider } from "@mui/material";
import { Outlet } from "react-router-dom";

import "./RootLayout.css";

const theme = createTheme({
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
    fontSize: 16,
  },
});

function RootLayout() {
  return (
    <ThemeProvider theme={theme}>      
      <div className="grid-container">
        <Outlet />
      </div>
    </ThemeProvider>
  );
}

export default RootLayout;
