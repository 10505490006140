export const mapPeerChampionsGridData = (resultData: any): PeerChampion[] => {
    let manageChampionsGridData: PeerChampion[] = [];
  
    try {
      if (Array.isArray(resultData) && resultData.length > 0) {
        manageChampionsGridData = resultData.map((result: any, index: number) => {
          
          return {
            id: result.id,//userAssignedRoleId
            siteName: result.siteName,
            physicianGroupName: result?.physicianGroupName!==null?result.physicianGroupName:"",
            firstName: result.firstName,
            lastName: result.lastName,
            emailAddress: result.emailAddress,
            userStatusName: result.userStatusName,                 
          };
        });
      }
    } catch (error) {
      console.error("Error occurred while fetching Data:", error);
    }
    return manageChampionsGridData;
  };
  