export interface NumberSVGProps {
  number: number;
}
export function ActiveNumberSVG(props: NumberSVGProps) {
  const number = props.number;
  if (number === 1) return <ActiveSVG1 />;
  if (number === 2) return <ActiveSVG2 />;
  if (number === 3) return <ActiveSVG3 />;
  if (number === 4) return <ActiveSVG4 />;

  return <></>;
}

export function DisabledNumberSVG(props: NumberSVGProps) {
  const number = props.number;
  if (number === 1) return <DisabledSVG1 />;
  if (number === 2) return <DisabledSVG2 />;
  if (number === 3) return <DisabledSVG3 />;
  if (number === 4) return <DisabledSVG4 />;

  return <></>;
}

export function ActiveSVG1() {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="30" height="30" rx="15" fill="#1976D2" />
      <path
        d="M12.184 20.5V18.996H14.456V12.308H12.584V11.156C13.0853 11.06 13.5227 10.948 13.896 10.82C14.2693 10.6813 14.616 10.516 14.936 10.324H16.312V18.996H18.312V20.5H12.184Z"
        fill="white"
      />
    </svg>
  );
}

export function DisabledSVG1() {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="30" height="30" rx="15" fill="#DADADA" />
      <path
        d="M12.184 20.5V18.996H14.456V12.308H12.584V11.156C13.0853 11.06 13.5227 10.948 13.896 10.82C14.2693 10.6813 14.616 10.516 14.936 10.324H16.312V18.996H18.312V20.5H12.184Z"
        fill="white"
      />
    </svg>
  );
}

export function ActiveSVG2() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="#1976D2" />
      <path
        d="M11.108 20.5V19.428C12.0573 18.5107 12.8733 17.6947 13.556 16.98C14.2493 16.2547 14.7827 15.5987 15.156 15.012C15.5293 14.4147 15.716 13.86 15.716 13.348C15.716 12.8147 15.572 12.388 15.284 12.068C14.996 11.748 14.58 11.588 14.036 11.588C13.6413 11.588 13.2787 11.7 12.948 11.924C12.628 12.148 12.3293 12.4147 12.052 12.724L11.028 11.7C11.4973 11.1987 11.9827 10.8147 12.484 10.548C12.9853 10.2707 13.5827 10.132 14.276 10.132C15.2573 10.132 16.036 10.4147 16.612 10.98C17.1987 11.5453 17.492 12.3027 17.492 13.252C17.492 13.8493 17.3213 14.4627 16.98 15.092C16.6493 15.7107 16.2013 16.3453 15.636 16.996C15.0813 17.6467 14.468 18.3293 13.796 19.044C14.0627 19.0227 14.3507 19.0013 14.66 18.98C14.9693 18.948 15.2467 18.932 15.492 18.932H17.988V20.5H11.108Z"
        fill="white"
      />
    </svg>
  );
}

export function DisabledSVG2() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="#DADADA" />
      <path
        d="M11.108 20.5V19.428C12.0573 18.5107 12.8733 17.6947 13.556 16.98C14.2493 16.2547 14.7827 15.5987 15.156 15.012C15.5293 14.4147 15.716 13.86 15.716 13.348C15.716 12.8147 15.572 12.388 15.284 12.068C14.996 11.748 14.58 11.588 14.036 11.588C13.6413 11.588 13.2787 11.7 12.948 11.924C12.628 12.148 12.3293 12.4147 12.052 12.724L11.028 11.7C11.4973 11.1987 11.9827 10.8147 12.484 10.548C12.9853 10.2707 13.5827 10.132 14.276 10.132C15.2573 10.132 16.036 10.4147 16.612 10.98C17.1987 11.5453 17.492 12.3027 17.492 13.252C17.492 13.8493 17.3213 14.4627 16.98 15.092C16.6493 15.7107 16.2013 16.3453 15.636 16.996C15.0813 17.6467 14.468 18.3293 13.796 19.044C14.0627 19.0227 14.3507 19.0013 14.66 18.98C14.9693 18.948 15.2467 18.932 15.492 18.932H17.988V20.5H11.108Z"
        fill="white"
      />
    </svg>
  );
}

export function ActiveSVG3() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="#1976D2" />
      <path
        d="M14.34 20.692C13.5187 20.692 12.8253 20.5533 12.26 20.276C11.7053 19.988 11.2467 19.6467 10.884 19.252L11.764 18.068C12.0733 18.3773 12.4253 18.644 12.82 18.868C13.2147 19.0813 13.668 19.188 14.18 19.188C14.7347 19.188 15.1827 19.0547 15.524 18.788C15.876 18.5107 16.052 18.132 16.052 17.652C16.052 17.3 15.9613 16.996 15.78 16.74C15.6093 16.4733 15.3053 16.2707 14.868 16.132C14.4413 15.9933 13.8387 15.924 13.06 15.924V14.58C14.0627 14.58 14.756 14.4253 15.14 14.116C15.524 13.8067 15.716 13.4173 15.716 12.948C15.716 12.5213 15.5827 12.1907 15.316 11.956C15.0493 11.7107 14.6813 11.588 14.212 11.588C13.8173 11.588 13.4547 11.6787 13.124 11.86C12.7933 12.0307 12.4733 12.26 12.164 12.548L11.22 11.412C11.6573 11.028 12.1213 10.7187 12.612 10.484C13.1133 10.2493 13.668 10.132 14.276 10.132C15.2573 10.132 16.052 10.3667 16.66 10.836C17.268 11.2947 17.572 11.956 17.572 12.82C17.572 13.3853 17.412 13.8653 17.092 14.26C16.7827 14.644 16.3507 14.9427 15.796 15.156V15.22C16.3933 15.38 16.8947 15.6733 17.3 16.1C17.7053 16.5267 17.908 17.076 17.908 17.748C17.908 18.3667 17.7427 18.8947 17.412 19.332C17.092 19.7693 16.66 20.1053 16.116 20.34C15.5827 20.5747 14.9907 20.692 14.34 20.692Z"
        fill="white"
      />
    </svg>
  );
}

export function DisabledSVG3() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="#DADADA" />
      <path
        d="M14.34 20.692C13.5187 20.692 12.8253 20.5533 12.26 20.276C11.7053 19.988 11.2467 19.6467 10.884 19.252L11.764 18.068C12.0733 18.3773 12.4253 18.644 12.82 18.868C13.2147 19.0813 13.668 19.188 14.18 19.188C14.7347 19.188 15.1827 19.0547 15.524 18.788C15.876 18.5107 16.052 18.132 16.052 17.652C16.052 17.3 15.9613 16.996 15.78 16.74C15.6093 16.4733 15.3053 16.2707 14.868 16.132C14.4413 15.9933 13.8387 15.924 13.06 15.924V14.58C14.0627 14.58 14.756 14.4253 15.14 14.116C15.524 13.8067 15.716 13.4173 15.716 12.948C15.716 12.5213 15.5827 12.1907 15.316 11.956C15.0493 11.7107 14.6813 11.588 14.212 11.588C13.8173 11.588 13.4547 11.6787 13.124 11.86C12.7933 12.0307 12.4733 12.26 12.164 12.548L11.22 11.412C11.6573 11.028 12.1213 10.7187 12.612 10.484C13.1133 10.2493 13.668 10.132 14.276 10.132C15.2573 10.132 16.052 10.3667 16.66 10.836C17.268 11.2947 17.572 11.956 17.572 12.82C17.572 13.3853 17.412 13.8653 17.092 14.26C16.7827 14.644 16.3507 14.9427 15.796 15.156V15.22C16.3933 15.38 16.8947 15.6733 17.3 16.1C17.7053 16.5267 17.908 17.076 17.908 17.748C17.908 18.3667 17.7427 18.8947 17.412 19.332C17.092 19.7693 16.66 20.1053 16.116 20.34C15.5827 20.5747 14.9907 20.692 14.34 20.692Z"
        fill="white"
      />
    </svg>
  );
}

export function ActiveSVG4() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="#1976D2" />
      <path
        d="M12.612 16.452H15.284V14.148C15.284 13.8493 15.2947 13.4973 15.316 13.092C15.3373 12.6867 15.3533 12.3347 15.364 12.036H15.3C15.172 12.3027 15.0387 12.564 14.9 12.82C14.7613 13.076 14.6173 13.3427 14.468 13.62L12.612 16.452ZM15.284 20.5V17.876H10.788V16.628L14.82 10.324H17.012V16.452H18.292V17.876H17.012V20.5H15.284Z"
        fill="white"
      />
    </svg>
  );
}

export function DisabledSVG4() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="15" fill="#DADADA" />
      <path
        d="M12.612 16.452H15.284V14.148C15.284 13.8493 15.2947 13.4973 15.316 13.092C15.3373 12.6867 15.3533 12.3347 15.364 12.036H15.3C15.172 12.3027 15.0387 12.564 14.9 12.82C14.7613 13.076 14.6173 13.3427 14.468 13.62L12.612 16.452ZM15.284 20.5V17.876H10.788V16.628L14.82 10.324H17.012V16.452H18.292V17.876H17.012V20.5H15.284Z"
        fill="white"
      />
    </svg>
  );
}
