import React from "react";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import classes from "./LabelStyle.module.css";

interface LabelSelectProps {
  name: string;
    label: string;
    value: string;
    options: any[];
    onChange?: (e: any) => void;
    // onValidate?: () => boolean;
    inputStyle?: React.CSSProperties;
  }
function LabelSelect(props:LabelSelectProps) {
    const {
      name,
        label,
        value,
        options,
        onChange,
        inputStyle,
        // onValidate = () => true, 
      } = props;
  return (
    <>
      <InputLabel className={classes.commonInputStyle} >
        {label}
      </InputLabel>
      <Select
        style={{height: 43, ...inputStyle }} 
        className={classes.commonInputStyle}    
        value={value}
        name={name}
        onChange={onChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        MenuProps={{
          PaperProps: {
            elevation: 0,
          },
        }}
        
        fullWidth
      >
        {options?.map((option) => (
          <MenuItem           
            style={{
              color: "#000000",
              fontSize: 16,
              fontWeight: 400,
            }}
            key={option.id}
            value={option.name}   
            
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export default LabelSelect;