import { useState } from "react";
import InitiativeList from "./InitiativeList";
import { getActiveInitiatives } from "../../../../helpers/api-util";
import useAppData from "../../../../hooks/useAppData";
import WaitIndicator from "../../../atoms/WaitIndicator";
import classes from "./InitiativeActivityList.module.css";
import { handleErrorReponse } from "../../../../helpers/ui-util";

function ActiveInitiativeList() {
  const [refreshData, setRefreshData] = useState<boolean>(false);

  const { appData, loading, error } = useAppData(async () => {
    return await getActiveInitiatives();
  }, refreshData);

  function onDataRefresh(): void {
    setRefreshData(!refreshData);
  }

  if (loading) {
    return <WaitIndicator show={true} />;
  }
  if (error) {
    return handleErrorReponse(error);
  }
  return (
    <div className={classes.marginTop}>
      <InitiativeList onDataRefresh={onDataRefresh} initiatives={appData} />
    </div>
  );
}

export default ActiveInitiativeList;
