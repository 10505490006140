import {
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useCallback, useState } from "react";
import LabelDateField from "../../atoms/formElements/LabelDateField";
import LabelMultilineTextField from "../../atoms/formElements/LabelMultilineTextField";
import LabelTextField from "../../atoms/formElements/LabelTextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import RoundedButton from "../../atoms/formElements/RoundedButton";
import { useDropzone, FileWithPath } from "react-dropzone";
import classes from "./InitiativeForm.module.css";
import { red } from "@mui/material/colors";
import {
  getFormattedDate,
  getYears,
  isValidDate,
} from "../../../helpers/ui-util";
import WizardHeader from "../../atoms/WizardHeader";
import { InitiativeFormType } from "../../../models/Initiative";

export interface InitiativeFormProps {
  initiative: InitiativeFormType;
  onSave: (data: any, iconFile: File[]) => void;
  onSaveAndContinue: (data: any, iconFile: File[]) => void;
  onBackClick: (data: any, iconFile: File[]) => void;
}

function InitiativeForm(props: InitiativeFormProps) {
  const navigate = useNavigate();
  const [isDirty, setIsDirty] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState<FileWithPath[]>([]);

  const onDrop = useCallback(
    (files: FileWithPath[]) => {
      setAcceptedFiles(files);
    },
    [acceptedFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 100000, // set max size to 100kb
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
  });

  const files = acceptedFiles.map((file: FileWithPath) => (
    <li key={file.path}>{file.path}</li>
  ));

  const { initiative } = props;

  let formInitialState: InitiativeFormType = {
    id: initiative.id,
    year: initiative.year,
    name: initiative.name,
    enrollmentOpenDate: getFormattedDate(initiative.enrollmentOpenDate),
    enrollmentEndDate: getFormattedDate(initiative.enrollmentEndDate),
    initiativeStartDate: getFormattedDate(initiative.initiativeStartDate),
    initiativeEndDate: getFormattedDate(initiative.initiativeEndDate),
    longSummary: initiative.longSummary,
    logo: initiative.logo,
  };

  const [formData, setFormData] = useState(formInitialState);

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleYearChange = (e: SelectChangeEvent): void => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const validateName = (): boolean => {
    return formData?.name?.length > 0;
  };

  const validateEnrollmentOpenDate = (): boolean => {
    return (
      formData.enrollmentOpenDate.length > 0 &&
      isValidDate(formData.enrollmentOpenDate)
    );
  };

  const validateEnrollmentEndDate = (): boolean => {
    return (
      formData.enrollmentEndDate.length > 0 &&
      isValidDate(formData.enrollmentEndDate)
    );
  };

  const validateInitiativeStartDate = (): boolean => {
    return (
      formData.initiativeStartDate.length > 0 &&
      isValidDate(formData.initiativeStartDate)
    );
  };

  const validateInitiativeEndDate = (): boolean => {
    return (
      formData.initiativeEndDate.length > 0 &&
      isValidDate(formData.initiativeEndDate)
    );
  };

  const validateLongSummary = (): boolean => {
    return formData.longSummary.length > 0;
  };

  const validateFormData = (): boolean => {
    if (
      validateName() &&
      validateEnrollmentOpenDate() &&
      validateEnrollmentEndDate() &&
      validateInitiativeStartDate() &&
      validateInitiativeEndDate() &&
      validateLongSummary()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const saveButtonClickHandler = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (validateFormData()) {
      props.onSave(formData, acceptedFiles);
    }
  };

  const continueButtonClickHandler = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (validateFormData()) {
      props.onSaveAndContinue(formData, acceptedFiles);
    }
  };

  const backButtonClickHandler = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (JSON.stringify(formData) != JSON.stringify(formInitialState)) {
      setIsDirty(true);
      props.onBackClick(formData, acceptedFiles);
    } else {
      navigate("/admin/manage-initiatives");
    }
  };

  function formOnSubmitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }

  const removeFile = (fileIndex: number) => {
    const newFiles = [...acceptedFiles];
    newFiles.splice(fileIndex, 1);
    setAcceptedFiles(newFiles);
  };

  const initiativeYears = getYears();

  let fileInfo;

  let savedLogoFileName = "";
  if (initiative.logo) {
    const pathArray = initiative.logo.split("/");
    savedLogoFileName = pathArray[pathArray.length - 1];
    // exclude the query string from the file name
    savedLogoFileName = savedLogoFileName.split("?")[0];
  }

  let uploadedLogoFileName;
  if (acceptedFiles && acceptedFiles.length > 0) {
    uploadedLogoFileName = (acceptedFiles[0] as FileWithPath).path;
  }

  if (uploadedLogoFileName && uploadedLogoFileName != "") {
    fileInfo = (
      <div>
        {uploadedLogoFileName}
        <IconButton aria-label="delete" onClick={() => removeFile(0)}>
          <DeleteIcon sx={{ color: red[500] }} />
        </IconButton>
      </div>
    );
  } else if (savedLogoFileName && savedLogoFileName != "") {
    fileInfo = <a href={initiative.logo}>{savedLogoFileName}</a>;
  } else {
    fileInfo = <></>;
  }

  return (
    <React.Fragment>
      <WizardHeader title="Initiative Settings" />

      <form onSubmit={formOnSubmitHandler}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputLabel
              id="year-label"
              style={{ maxHeight: "23px", color: "#09101D" }}
            >
              Year
            </InputLabel>
            <Select
              labelId="year-label"
              id="year"
              name="year"
              value={formData?.year?.toString()}
              fullWidth
              sx={{
                height: 43,
              }}
              MenuProps={{
                PaperProps: {
                  elevation: 0,
                },
              }}
              onChange={handleYearChange}
              style={{ padding: 0, borderRadius: 20 }}
            >
              {initiativeYears.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelTextField
              displayName="Name"
              name="name"
              value={formData.name}
              onTextChange={handleTextFieldChange}
              onValidate={validateName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDateField
              displayName="Enrollment Open Date"
              name="enrollmentOpenDate"
              value={formData.enrollmentOpenDate}
              onDateChange={handleTextFieldChange}
              onValidate={validateEnrollmentOpenDate}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDateField
              displayName="Enrollment End Date"
              name="enrollmentEndDate"
              value={formData.enrollmentEndDate}
              onDateChange={handleTextFieldChange}
              onValidate={validateEnrollmentEndDate}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDateField
              displayName="Initiative Start Date"
              name="initiativeStartDate"
              value={formData.initiativeStartDate}
              onDateChange={handleTextFieldChange}
              onValidate={validateInitiativeStartDate}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelDateField
              displayName="Initiative End Date"
              name="initiativeEndDate"
              value={formData.initiativeEndDate}
              onDateChange={handleTextFieldChange}
              onValidate={validateInitiativeEndDate}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <LabelMultilineTextField
              displayName="Description"
              name="longSummary"
              value={formData.longSummary}
              onTextChange={handleTextFieldChange}
              rows="4"
              onValidate={validateLongSummary}
              maxCharacter={1000}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <InputLabel id="InitiativeIcon" style={{ color: "#09101D" }}>
              Initiative Icon
            </InputLabel>
            <section className={classes.uploadContainer}>
              <div {...getRootProps({ className: classes.dropzone })}>
                <input {...getInputProps()} />
                <p
                  style={{
                    fontWeight: "600",
                    color: "black",
                    fontSize: "16px",
                    fontFamily: "Source Sans Pro",
                  }}
                >
                  Drop files here or Browse
                </p>
              </div>
              <Typography
                style={{
                  fontSize: 12,
                }}
              >
                Maximum File Size 100 KB (200 x 200 px). File extensions
                permitted: PNG, JPG, JPEG.
              </Typography>
              <aside>{fileInfo}</aside>
            </section>
          </Grid>
        </Grid>
        <Grid container spacing={1} className="bottomButtonContainer">
          <Grid item xs={4} sm={6}>
            <RoundedButton
              text="Back"
              buttonType="secondary"
              name="btnBack"
              onClick={backButtonClickHandler}
            />
          </Grid>
          <Grid item xs={8} sm={6}>
            <Grid container justifyContent={"flex-end"} spacing={2}>
              <Grid item>
                <RoundedButton
                  type="submit"
                  name="btnContinue"
                  text="Save and Continue"
                  disabled={!validateFormData()}
                  onClick={continueButtonClickHandler}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}

export default InitiativeForm;
