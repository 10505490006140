import { Card, CardContent, MenuItem, Select, Typography } from "@mui/material";
import { styled } from "@mui/system";
import classes from "./EnrollmentSummary.module.css";
import { useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import useAppData from "../../../../hooks/useAppData";
import {
  getInitiativeEnrollmentSummary,
  getActiveInitiatives,
} from "../../../../helpers/api-util";
import WaitIndicator from "../../../atoms/WaitIndicator";
import {
  GetMonthName,
  getInitiativeWithEarliestEnrollmentStartDate,
  handleErrorReponse,
} from "../../../../helpers/ui-util";
import { AxisConfig } from "@mui/x-charts";

//On small screnn, set the select element width upto 300px and for tablet or desktop set it upto 500px
const StyledSelect = styled(Select)(({ theme }) => ({
  maxWidth: '100%',
  [theme.breakpoints.between("xs", "md")]: {
    maxWidth: 300,
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: 500,
  },
}));

const valueFormatter = (value: number) => `${value} sites`;

export interface EnrollmentSummaryProps {
  display: (show: boolean) => void;
}

function EnrollmentSummary(props: EnrollmentSummaryProps) {
  const [refreshData, setRefreshData] = useState(false);
  const [selectedInitiative, setSelectedInitiative] = useState("");

  const initiativesFromAPI = useAppData(async () => {
    return await getActiveInitiatives();
  }, refreshData);

  const EnrollmentSummaryFromAPI = useAppData(async () => {
    return await getInitiativeEnrollmentSummary();
  }, refreshData);

  if (initiativesFromAPI?.loading || EnrollmentSummaryFromAPI?.loading) {
    return (
      <>
        <div>
          <WaitIndicator show={true}></WaitIndicator>
        </div>
      </>
    );
  }
  if (initiativesFromAPI?.error || EnrollmentSummaryFromAPI?.error) {
    const errorJSX = handleErrorReponse(
      initiativesFromAPI?.error || EnrollmentSummaryFromAPI?.error
    );
    return (
      <>
        <Card className={classes.card}>
          <CardContent className={classes.container}>
            <div className={classes.cardHeader}>
              <div className={classes.title}>Enrollment Summary</div>
            </div>
            <div className={classes.rowContainer}>{errorJSX}</div>
          </CardContent>
        </Card>
      </>
    );
  }
  const filteredInitiatives = initiativesFromAPI?.appData?.filter(
    (initiative: any) => initiative.initiativeStatus?.name === "Published"
  );

  if (!filteredInitiatives || filteredInitiatives?.length === 0) {
    props.display(false); // Hide Enrollment Summary if no initiatives are published
  }

  const earliestInitiative =
    getInitiativeWithEarliestEnrollmentStartDate(filteredInitiatives);

  const formattedEnrollmentSummary = EnrollmentSummaryFromAPI?.appData?.map(
    (data: any) => {
      return {
        initiativeId: data.initiativeId,
        month: GetMonthName(data.month, data.year),
        year: data.year,
        siteCount: data.siteCount,
      };
    }
  );

  if (
    earliestInitiative &&
    earliestInitiative?.length > 0 &&
    selectedInitiative === ""
  ) {
    if (selectedInitiative === "")
      setSelectedInitiative(earliestInitiative[0].id);
  }

  const filteredEnrollmentSummary = formattedEnrollmentSummary?.filter(
    (data: any) => {
      return data.initiativeId === selectedInitiative;
    }
  );

  const handleInitiativeChange = (event: any) => {
    setSelectedInitiative(event.target.value);
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.container}>
        <div className={classes.cardHeader}>
          <div className={classes.title}>Enrollment Summary</div>
          <div className={classes.initiatives}>
            {filteredInitiatives.length > 0 ? (
              <div >
                <StyledSelect
                  value={
                    selectedInitiative ||
                    (earliestInitiative && earliestInitiative[0].id)
                  }
                  MenuProps={{
                    PaperProps: {
                      elevation: 0,
                    },
                  }}
                  onChange={handleInitiativeChange}
                  className={classes.select}
                >
                  {earliestInitiative?.map((initiative: any) => (
                    <MenuItem
                      key={initiative?.id}
                      value={initiative?.id}
                      className={classes.menuItem}
                    >
                      {initiative?.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </div>
            ) : null}
          </div>
        </div>
        <div className={classes.rowContainer}>
          <InitiativeEnrollmentBarChart dataset={filteredEnrollmentSummary} />
        </div>
      </CardContent>
    </Card>
  );
}

export function InitiativeEnrollmentBarChart(props: any) {
  if (props.dataset.length === 0) {
    return (
      <Typography style={{ alignSelf: "center" }}>
        Enrollment Summary not available.
      </Typography>
    );
  }
  var dataset = props.dataset;
  return (
    <BarChart
      dataset={dataset}
      colors={["#0FE2FF"]}
      xAxis={[
        {
          scaleType: "band",
          dataKey: "month",
          categoryGapRatio: 0.3,
          barGapRatio: 0.1,
        } as AxisConfig<"band">,
      ]}
      slotProps={{
        axisTickLabel: {
          style: { fontSize: 16, fontWeight: 400, color: "#000000" },
        },
      }}
      series={[{ dataKey: "siteCount", valueFormatter }]}
    />
  );
}

export default EnrollmentSummary;
