import React, {
  ChangeEvent,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import LabelTextField from "./LabelTextField";

interface TypeAheadListProps {
  label: string;
  name: string;
  value?: string;
  options: any[];
  handleTextFieldChange?: (e: any) => void;
  onValidate?: () => boolean;
  inputStyle?: React.CSSProperties;
  setValueHandler?: (e: any) => void;
  notFoundMessage?: string;
}

// Define the custom ref type
export type TypeAheadListRef = {
  resetSearchQuery: () => void;
};

const TypeAheadList = forwardRef<TypeAheadListRef, TypeAheadListProps>(
  (props, ref) => {
    const {
      label,
      name,
      // value,
      options,
      setValueHandler,
      handleTextFieldChange,
      notFoundMessage,
    } = props;

    const [id, setId] = React.useState("");
    const [dataFiltered, setDataFiltered] = useState<any[]>([]);
    const [display, setDisplay] = React.useState("none");
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [textFieldStyle, setTextFieldStyle] = useState<React.CSSProperties>({
      fontWeight: 400,
    });

    // Attach the resetSearchQuery function to the ref
    useImperativeHandle(ref, () => ({
      resetSearchQuery,
    }));
    // Function to reset the search query
    const resetSearchQuery = () => {
      setSearchQuery("");
    };
    const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
      try {
        setTextFieldStyle({ fontWeight: 400 });
        let dataFiltered = filterData(searchQuery, options);
        // Check if the "I do not see who I'm looking for" option already exists in SitesdataFiltered
        const notSeeOptionExists = dataFiltered.some(
          (item) => item.name === notFoundMessage
        );
        if (!notSeeOptionExists) {
          dataFiltered.push({
            id: "notFound",
            siteNumber: null,
            name: notFoundMessage,
            countryId: null,
            stateId: null,
            address1: null,
            address2: null,
            city: null,
            zipCode: null,
            physicianGroupId: null,
            country: null,
            state: null,
            physicianGroup: null,
            siteEnrollments: [],
            userAssignedRoles: [],
          });
        }
        setDataFiltered(dataFiltered);
        if (e.target.value) {
          setDisplay("block");
          setSearchQuery(e.target.value);
        } else {
          setSearchQuery("");
          setDisplay("none");
        }
        if (dataFiltered.length === 0) {
          setDisplay("none");
          setId("");
        }
        handleTextFieldChange && handleTextFieldChange(e);
      } catch (ex) {
        // console.log(ex);
      }
    };
    const SetValueHandler = (e: any) => {
      setSearchQuery(e.target.innerText);
      const selectedItem = dataFiltered.find((item) => item.id === e.target.id);
      setId(e.target.id);
      setDisplay("none");
      if (e.target.innerText === notFoundMessage) {
        setTextFieldStyle({ fontWeight: 600 });
      } else {
        setTextFieldStyle({ fontWeight: 400 });
      }
      if (setValueHandler) setValueHandler(selectedItem);
    };
    const filterData = (query: any, data: any[]) => {
      if (!query) {
        return data;
      } else {
        return data.filter((d) =>
          d.name.toLowerCase().includes(query.toLowerCase())
        );
      }
    };
    return (
      <>
        <LabelTextField
          displayName={label}
          name={name}
          onTextChange={onTextChange}
          value={searchQuery}
          TextFieldStyle={textFieldStyle}
        />
        <div
          style={{
            padding: 3,
            display: display,
            borderRadius: 20,
            border: "1px solid #cccc",
          }}
        >
          <ul
            style={{
              listStyle: "none",
              overflowY: "scroll",
              overflowX: "hidden",
              height: " 104px",
              scrollbarWidth: "thin",
              margin: "6px 0px 6px 0px",
            }}
          >
            {dataFiltered.map((dataFiltered: any) => {
              // Calculate the fontWeight value based on the condition for each item
              const fontWeightValue =
                dataFiltered.id === "notFound" ? 600 : 400;
              return (
                <li
                  className="text"
                  style={{
                    padding: 5,
                    justifyContent: "normal",
                    fontSize: 16,
                    color: "#000000",
                    fontWeight: fontWeightValue,
                    margin: 1,
                    width: "100%",
                    borderWidth: "10px",
                    cursor: "pointer",
                  }}
                  key={dataFiltered.id}
                >
                  <div
                    id={dataFiltered.id}
                    onClick={(e) => SetValueHandler(e)}
                    style={{ color: "#000000" }}
                  >
                    {dataFiltered.name}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }
);

export default TypeAheadList;
