import { useNavigate } from "react-router-dom";
import { useContext, useRef, useState } from "react";
import WizardAction from "../../../atoms/WizardAction";
import WizardHeader from "../../../atoms/WizardHeader";
import QuestionaireViewerComponent from "../../questionnaire/QuestionaireViewerComponent";
import { publishEnrollmentQuestionnaire } from "../../../../helpers/api-util";
import AdminContext from "../../../../store/admin-context";
import WaitIndicator, { WaitIndicatorRef } from "../../../atoms/WaitIndicator";
import { useEnrollmentQuestionnaireData } from "./ManageEnrollmentQuestionnaireLayout";

function EnrollmentQuestionnairePublish() {
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const adminContext = useContext(AdminContext);
  const enrollmentQuesData = useEnrollmentQuestionnaireData();

  const navigate = useNavigate();
  const headerJSX = <WizardHeader title="Preview & Publish" />;

  function onBackClickHandler(event: any) {
    navigate("/admin/questionnaire/builder");
  }

  function canContinue() {
    if (
      enrollmentQuesData.enrollmentQuesData.appData &&
      enrollmentQuesData.enrollmentQuesData.appData.initiativeActivity &&
      enrollmentQuesData.enrollmentQuesData.appData.initiativeActivity
        .hasQnDefinitions
    ) {
      return true;
    }
    return false;
  }

  async function publishOnClickHandler(event: any) {
    try {
      waitIndicatorLoaderRef.current?.show();
      const response = await publishEnrollmentQuestionnaire(
        adminContext.enrollmentId
      );
      if (response.error) {
        adminContext.onError(true, "Failed to publish questionnaires!");
      } else {
        adminContext.onRecordSave(
          true,
          "Questionnaires published successfully!"
        );
        return navigate("/admin/enrollment-questionnaires");
      }
      waitIndicatorLoaderRef.current?.hide();
    } catch (ex) {
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  if (enrollmentQuesData.enrollmentQuesData.loading) {
    return (
      <>
        {headerJSX}
        <WaitIndicator show={true} />
      </>
    );
  }

  if (
    enrollmentQuesData.enrollmentQuesData.appData &&
    enrollmentQuesData.enrollmentQuesData.appData.initiativeActivity
  ) {
    const activity =
      enrollmentQuesData.enrollmentQuesData.appData.initiativeActivity;
    return (
      <>
        {headerJSX}
        <h2>
          {enrollmentQuesData.enrollmentQuesData.appData.year}{" "}
          {enrollmentQuesData.enrollmentQuesData.appData.title}
        </h2>

        <QuestionaireViewerComponent activity={activity} />
        <WizardAction
          backButtonText="Back"
          continueButtonText="Publish"
          backOnClick={onBackClickHandler}
          continueOnClick={publishOnClickHandler}
          //backDisabled={props.backDisabled}
          continueDisabled={!canContinue()}
        />
        <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
      </>
    );
  }

  return <></>;
}

export default EnrollmentQuestionnairePublish;
