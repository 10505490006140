import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormHelperText from '@mui/material/FormHelperText';
import ErrorIcon from '@mui/icons-material/Error';
import classes from './RadioButtonGroup.module.css'
import { Height } from '@mui/icons-material';
export default function RadioButtonGroup(props: any
) {
  const {
    name,
    label,
    value,
    options,
    onChange,
    error = null
  } = props;
  



  return (

    <FormControl component="fieldset" >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={onChange}
        style={{display:"flex", flexDirection:"row"}}
      >
        {options.map((options: { Id: string; Name: string }) => (
          <FormControlLabel
            key={options.Name}
            value={options.Name}
            control={<Radio id={`${name}-${options.Id}`} />}
            label={options.Name}
            onChange={onChange}               
            className={value === options.Name ? classes.selectedLabel : classes.normalLabel}
          />
        ))}
        {error ?
          (<FormHelperText {...(error ? { error: true, helpertext: <><ErrorIcon /> {error}</> } : null)} id="my-helper-text">
            <><ErrorIcon /> {error}</></FormHelperText>) : <></>
        }
      </RadioGroup>

    </FormControl>

  );
}
