import QuestionaireViewerComponent from "../../questionnaire/QuestionaireViewerComponent";
import ActivityList from "../activity-list/ActivityList";
import ActivityListItem from "../activity-list/ActivityListItem";

export interface ActivityBuilderListProps {
  activities: any;
  saveOnClick: (data: any) => void;
  cancelOnClick: (data: any) => void;
}

function ActivityPublishList(props: ActivityBuilderListProps) {
  const activities = props.activities ? props.activities : [];

  return (
    <>
      <ActivityList activities={props.activities}>
        {activities.map((activity: any) => (
          <ActivityListItem activity={activity} key={activity.id}>
            <QuestionaireViewerComponent activity={activity} />
          </ActivityListItem>
        ))}
      </ActivityList>
    </>
  );
}

export default ActivityPublishList;
