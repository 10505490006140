import EnrollmentHome from "../../../components/champion/EnrollmentHome";

function EnrollmentHomePage() {
  return (
    <>
      <EnrollmentHome />
    </>
  );
}

export default EnrollmentHomePage;