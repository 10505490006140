export interface InitiativeActivityListItemStatusProps {
  activityStatus: string;
}

function initiativeActivityListItemStatus(
  props: InitiativeActivityListItemStatusProps
) {
  const activityStatus = props.activityStatus;

  let statusStyle = {};
  if (activityStatus === "Input Not Allowed") {
    statusStyle = {
      color: "#888888",
    };
  } else if (activityStatus === "Input Allowed") {
    statusStyle = {
      color: "#27AE60",
    };
  } else if (activityStatus === "Deleted") {
    statusStyle = {
      color: "#888888",
    };
  } else {
    statusStyle = {};
  }

  return <span style={statusStyle}>{activityStatus}</span>;
}

export default initiativeActivityListItemStatus;
