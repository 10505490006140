import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getFormattedDate,
  getYears,
  isValidDate,
} from "../../../../helpers/ui-util";
import LabelTextField from "../../../atoms/formElements/LabelTextField";
import RoundedButton from "../../../atoms/formElements/RoundedButton";

interface EnrollmentQuestionnaireFormProps {
  usedYears?: any;
  enrollment?: any;
  onSaveAndContinue: (formData: any) => void;
}

export function EnrollmentQuestionnaireForm(
  props: EnrollmentQuestionnaireFormProps
) {
  const navigate = useNavigate();
  const [isDirty, setIsDirty] = useState(false);

  const { enrollment, usedYears } = props;

  let formInitialState = {
    id: enrollment?.id,
    year: enrollment?.year,
    title: enrollment?.title,
    enrollmentOpenDate: getFormattedDate(enrollment?.enrollmentOpenDate),
    enrollmentEndDate: getFormattedDate(enrollment?.enrollmentEndDate),
    status: enrollment?.enrollmentStatus?.name,
  };

  const enrollmentYears = getYears();
  if (usedYears) {
    const filteredYears = enrollmentYears.filter(
      (year: any) => !usedYears.includes(year)
    );
    enrollmentYears.length = 0;
    enrollmentYears.push(...filteredYears);
  }

  const [formData, setFormData] = useState(formInitialState);

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleYearChange = (e: SelectChangeEvent) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const validateTitle = () => {
    return formData.title?.length > 0;
  };

  const validateEnrollmentOpenDate = () => {
    return (
      formData.enrollmentOpenDate.length > 0 &&
      isValidDate(formData.enrollmentOpenDate)
    );
  };

  const validateEnrollmentEndDate = () => {
    return (
      formData.enrollmentEndDate.length > 0 &&
      isValidDate(formData.enrollmentEndDate)
    );
  };

  const validateFormData = () => {
    if (
      validateTitle() &&
      validateEnrollmentOpenDate() &&
      validateEnrollmentEndDate()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const backButtonClickHandler = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (JSON.stringify(formData) !== JSON.stringify(formInitialState)) {
      setIsDirty(true);
    } else {
      navigate("/admin/enrollment-questionnaires");
    }
  };

  const continueButtonClickHandler = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (validateFormData()) {
      props.onSaveAndContinue(formData);
    }
  };
  function formOnSubmitHandler(e: any) {
    e.preventDefault();
  }

  return (
    <>
      <form onSubmit={formOnSubmitHandler}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputLabel
              id="year-label"
              style={{ maxHeight: "23px", color: "#09101D" }}
            >
              Year
            </InputLabel>
            <Select
              labelId="year-label"
              id="year"
              name="year"
              value={formData.year}
              fullWidth
              sx={{
                height: 43,
              }}
              MenuProps={{
                PaperProps: {
                  elevation: 0,
                },
              }}
              onChange={handleYearChange}
              style={{ padding: 0, borderRadius: 20 }}
              //disabled if enrollment is published
              disabled={formData.status === "Published"}
            >
              {enrollmentYears.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LabelTextField
              displayName="Title"
              name="title"
              value={formData.title}
              onTextChange={handleTextFieldChange}
              onValidate={validateTitle}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} className="bottomButtonContainer">
          <Grid item xs={4} sm={6}>
            <RoundedButton
              text="Back"
              name="btnBack"
              buttonType="secondary"
              onClick={backButtonClickHandler}
            />
          </Grid>
          <Grid item xs={8} sm={6}>
            <Grid container justifyContent={"flex-end"} spacing={2}>
              <Grid item>
                <RoundedButton
                  type="submit"
                  name="btnContinue"
                  text="Save and Continue"
                  disabled={!validateFormData()}
                  onClick={continueButtonClickHandler}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
