import dayjs from "dayjs";
import { FileWithPath } from "react-dropzone";
// import { getSiteEnrollmentsById } from "./api-util";

const dateFormat = process.env.REACT_APP_DATECONTROL_DATE_FORMAT;
const uiDateFormat = process.env.REACT_APP_UI_DATE_FORMAT;

export function getFormattedDate(date: string) {
  return dayjs(date).format(dateFormat);
}

export function getUIFormattedDate(date: string) {
  return dayjs(date).format(uiDateFormat);
}

export function getUIDateTimeFormat(date: string) {
  return dayjs(date).format("MM/DD/YYYY h:mm A");
}

export function isValidDate(date: string) {
  return dayjs(date, dateFormat, true).isValid();
}

export function insertUniqueIdElementInArray(
  array: any[],
  index: number,
  element: any
) {
  // do only if any array element id is not matching with element id
  if (array.findIndex((x) => x.id === element.id) === -1) {
    array.splice(index, 0, element);
  }
  //array.splice(index, 0, element);
}

//write a async function with int return type
export async function mapQuestionnaireData(
  resultData: any
): Promise<siteEnrollmentQuestionnaire[]> {
  //   const resultData = await getSiteEnrollmentsById(siteId);
  //   const questionnaireData = mapQuestionnaireData(resultData);
  //   return questionnaireData[0];
  // }

  //  const async mapQuestionnaireData = (
  //   resultData: any
  // ): siteEnrollmentQuestionnaire[] => {
  let questionnaireData: siteEnrollmentQuestionnaire[] = [];
  try {   
    questionnaireData = resultData.map((resultData: any) => {
      let SiteQuestData = resultData.siteEnrollments;    
      const AssociatedPhysicianGroupIdData =
        SiteQuestData[0].siteEnrollmentQuestionaire.associatedPhysicianGroupId;
      const IsEDPartOfLargeGroupData =
        SiteQuestData[0].siteEnrollmentQuestionaire.isEDPartOfLargeGroup;
      const IsCriticalAccessConsideredData =
        SiteQuestData[0].siteEnrollmentQuestionaire.isCriticalAccessConsidered;
      const isSafetyNetConsideredData =
        SiteQuestData[0].siteEnrollmentQuestionaire.isSafetyNetConsidered;
      const isRuralConsideredData =
        SiteQuestData[0].siteEnrollmentQuestionaire.isRuralConsidered;
      const isGoverenmentConsideredData =
        SiteQuestData[0].siteEnrollmentQuestionaire.isGoverenmentConsidered;
      const isHospitalBaedEDConsideredData =
        SiteQuestData[0].siteEnrollmentQuestionaire.isHospitalBaedEDConsidered;
      const isFreestandingEDConsideredData =
        SiteQuestData[0].siteEnrollmentQuestionaire.isFreestandingEDConsidered;
      const isUrgentCareConsideredData =
        SiteQuestData[0].siteEnrollmentQuestionaire.isUrgentCareConsidered;
      const isEmergencyMedResHomeSiteData =
        SiteQuestData[0].siteEnrollmentQuestionaire.isEmergencyMedResHomeSite;
      const isEmergencyMedResAffiliatedSiteData =
        SiteQuestData[0].siteEnrollmentQuestionaire
          .isEmergencyMedResAffiliatedSite;
      const isResidentSupportEDStaffingData =
        SiteQuestData[0].siteEnrollmentQuestionaire.isResidentSupportEDStaffing;
      const NoResidentStaffingInEDData =
        SiteQuestData[0].siteEnrollmentQuestionaire.noResidentStaffingInED;

      return {
        SiteId: resultData.id,
        AssociatedPhysicianGroupId: AssociatedPhysicianGroupIdData,
        IsEDPartOfLargeGroup: IsEDPartOfLargeGroupData,
        IsCriticalAccessConsidered: IsCriticalAccessConsideredData,
        isSafetyNetConsidered: isSafetyNetConsideredData,
        isRuralConsidered: isRuralConsideredData,
        isGoverenmentConsidered: isGoverenmentConsideredData,
        isHospitalBaedEDConsidered: isHospitalBaedEDConsideredData,
        isFreestandingEDConsidered: isFreestandingEDConsideredData,
        isUrgentCareConsidered: isUrgentCareConsideredData,
        isEmergencyMedResHomeSite: isEmergencyMedResHomeSiteData,
        isEmergencyMedResAffiliatedSite: isEmergencyMedResAffiliatedSiteData,
        isResidentSupportEDStaffing: isResidentSupportEDStaffingData,
        NoResidentStaffingInED: NoResidentStaffingInEDData,
      } as siteEnrollmentQuestionnaire;
    });
  } catch (error) {
    // console.log("error", error);
  }
  // console.log("questionnaireData", questionnaireData);

  return questionnaireData;
}

// export async function getSiteEnrollmentById(enrollmentId: any) {
//   const siteEnrollmentData = await getSiteEnrollmentsById(enrollmentId);
//   if (siteEnrollmentData != null) {
//    // console.log("getSiteEnrollmentData", siteEnrollmentData);
//   }
//   let siteQuestionnaire = await mapQuestionnaireData(siteEnrollmentData.data);
//  // console.log("mapped Questionnaire", siteQuestionnaire);
//   return siteQuestionnaire;
// }

export function filterData(query: any, data: any[]) {
  if ("" === query || query.length < 3) return data;
  var words = query.match(/"[^"]+"|\w+/g);

  words = words.map((val: string) => val.replace(/\"/g, ""));
  //words.push(terms);

  return data.filter((data) => {
    const v = Object.values(data);
    const f = JSON.stringify(v).toLowerCase();

    return words.every((val: string) => f.includes(val.toLowerCase()));
  });
}

export const UserSearchSetting = {
  userSearchDebounceTime: 600,
  userSearchMinLength: 3,
};

export function getYears() {
  var futureYear = new Date().getFullYear() + 10,
    years = [];
  let startYear = new Date().getFullYear();
  while (startYear <= futureYear) {
    years.push(startYear++);
  }
  return years;
}

export const ListDataTableStyle = {
  headRow: {
    border: "none",
  },
  rows: {
    border: "1px solid #DDDDDD",
    borderRadius: "10px",
    marginBottom: "10px",
    marginTop: "0px",
  },
};
function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}
export function getGuid() {
  return guid();
}

export const getInitiativeWithEarliestStartDate = (initiatives: any) => {
  if (initiatives.length > 0) {
    const sortedInitiatives: Initiative[] = initiatives;
    // Sort the initiatives by their start dates in ascending order
    sortedInitiatives.sort((a: any, b: any) => {
      const dateA: Date = new Date(a.initiativeStartDate);
      const dateB: Date = new Date(b.initiativeStartDate);
      if (dateA.getTime() === dateB.getTime()) {
        // If dates are the same, sort by initiative names
        return a.name?.localeCompare(b?.name);
      }
      return dateA.getTime() - dateB.getTime();
    });
    // Retrieve the initiative with the earliest start date (first in the sorted array)
    return sortedInitiatives;
  } else {
    return null;
  }
};

export const getInitiativeWithEarliestEnrollmentStartDate = (
  initiatives: any
) => {
  if (initiatives.length > 0) {
    const sortedInitiatives: Initiative[] = initiatives;
    // Sort the initiatives by their start dates in ascending order
    sortedInitiatives.sort((a: any, b: any) => {
      const dateA: Date = new Date(a.enrollmentOpenDate);
      const dateB: Date = new Date(b.enrollmentOpenDate);
      if (dateA.getTime() === dateB.getTime()) {
        // If dates are the same, sort by initiative names
        return a.name?.localeCompare(b?.name);
      }
      return dateA.getTime() - dateB.getTime();
    });
    // Retrieve the initiative with the earliest start date (first in the sorted array)
    return sortedInitiatives;
  } else {
    return null;
  }
};

export function GetMonthName(month: number, year: number) {
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var monthName = monthNames[month - 1];
  return monthName + " " + year.toString();
}

export const sanatizeFolderName = (folderName: string) => {
  if (!folderName) {
    return "";
  }
  return folderName.replace(/[^a-zA-Z0-9]/g, "_").toLowerCase();
};

export const batchFiles = (files: FileWithPath[], batchSize: number) => {
  let batches = [];
  for (let i = 0; i < files.length; i += batchSize) {
    const batch = files.slice(i, i + batchSize);
    batches.push(batch);
  }
  return batches;
};

export function handleErrorReponse(error: any) {
  if (error) {
    return (
      <div>
        An error occured while processing your request. Please login and try
        again.
      </div>
    );
  }
  return <>An error occured while processing your request.</>;
}
// export function scrollToTop() {
//   window.scrollTo({
//     top: 0,
//     behavior: 'smooth'   
//     });
// }


export function scrollToTop(className?: string,currentElement?: Element): void {

    // Check if className is provided
    if (className) {

        if(currentElement){
          //this will scroll to the first element with the provided class name inside the currentElement
          currentElement.getElementsByClassName(className)[0]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          
        }
        else
        {
          // Find the element with the provided class name
          const element = document.querySelector('.' + className);
          // If element is found, scroll to it
          if (element instanceof Element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          } else {
              // If element is not found, scroll to the top of the page
              window.scrollTo({ top: 0, behavior: 'smooth' });
          }
      }

    } else {
        // If no className provided, scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });

    }

}
