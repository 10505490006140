import { useState } from "react";
import { getActiveInitiatives } from "../../helpers/api-util";
import useAppData from "../../hooks/useAppData";
import classes from "./ReportsHome.module.css";
import SiteDataReportsView from "./reports/SiteDataReportsView";
import CompletionReporView from "./reports/CompletionReportView";
import EnrollmentReportView from "./reports/EnrollmentReportView";
import { Grid } from "@mui/material";


function ReportsHomePage(){
 
  
  return(
    <div className={classes.container}>
    <div className={classes.container_header}>
      <div className={classes.container_header_title}>
        <h1>Reports</h1>
      </div>
    </div>
   
    <div className={classes.report_container}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <SiteDataReportsView />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <CompletionReporView />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <EnrollmentReportView />
        </Grid>
      </Grid>
    </div>   
    </div>
  
  );
}

export default ReportsHomePage;
