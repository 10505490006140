import Questionnaire from "../../../questionnaire/Questionnaire";
import ActivityList from "../../activity-list/ActivityList";
import ActivityListItem from "../../activity-list/ActivityListItem";

export interface ActivityBuilderListProps {
  activities: any;
  saveOnClick: (data: any) => void;
  cancelOnClick: (data: any) => void;
}

function ActivityBuilderList(props: ActivityBuilderListProps) {
  const activities = props.activities ? props.activities : [];

  return (
    <>
      <ActivityList activities={activities}>
        {activities.map((activity: any) => (
          <ActivityListItem activity={activity} key={activity.id}>
            <Questionnaire
              activityId={activity.id}
              saveOnClick={props.saveOnClick}
              cancelOnClick={props.cancelOnClick}
            />
          </ActivityListItem>
        ))}
      </ActivityList>
    </>
  );
}

export default ActivityBuilderList;
