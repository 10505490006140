import WarningAmberIcon from "@mui/icons-material/WarningAmber";

function WarningMessage({
  showWarning,
  className,
  message,
}: {
  showWarning: boolean;
  className?: string;
  message?: string;
}) {
  if (showWarning) {
    return (
      <div
        className={className}
        style={{
          color: "#FF0000",
          display: "flex",
          alignItems: "center",
          fontStyle: "italic",
          fontWeight: "400",
          fontSize: "18px",
        }}
      >
        <WarningAmberIcon />
        <span style={{paddingLeft:'10px'}} className="ml-2">
          {message || "This is a sample message. Please change it."}
        </span>
      </div>
    );
  }
  return null;
}

export default WarningMessage;
