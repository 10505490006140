import { getUIFormattedDate } from "../../../../helpers/ui-util";

export function mapData(enrollmentQuesData: any) {
  const enrollmentQues = enrollmentQuesData.appData;
  const enrollmentQuesList = [];
  for (let i = 0; i < enrollmentQues.length; i++) {
    const enrollmentQue = enrollmentQues[i];
    const enrollmentQueData = {
      id: enrollmentQue.id,
      year: enrollmentQue.year,
      status: enrollmentQue.enrollmentStatus.name,
      lastUpdatedBy:
        enrollmentQue.updatedBy.firstName +
        " " +
        enrollmentQue.updatedBy.lastName,
      lastUpdated: getUIFormattedDate(enrollmentQue.updatedOn),
      initiativeActivity: enrollmentQue.initiativeActivity,
    };

    enrollmentQuesList.push(enrollmentQueData);
  }
  return enrollmentQuesList;
}
