import ConfirmDialog from "./ConfirmDialog";

function SaveConfirmDialog(props: any) {
  let { title, children, open, setOpen, onConfirm } = props;

  if (!title) {
    title = "Are you sure?";
  }
  if (!children) {
    children =
      "You have unsaved changes. Would you like to save before exiting?";
  }
  return (
    <ConfirmDialog
      title={title}
      children={children}
      open={open}
      setOpen={setOpen}
      onConfirm={onConfirm}
      confirmButtonTitle="Save and Exit"
    />
  );
}

export default SaveConfirmDialog;
