import EnrollSite from "../../../components/admin/enrollment/EnrollSite";

function EnrollmentSitePage() {
  return (
    <>
      <EnrollSite />
    </>
  );
}

export default EnrollmentSitePage;