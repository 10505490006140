import UpdateInitiative from "../../../components/admin/initiative/UpdateInitiative";

function EditInitiativePage() {
  return (
    <>
      <UpdateInitiative />
    </>
  );
}

export default EditInitiativePage;
