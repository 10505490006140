import ActivityList from "./ActivityList";
import ActivityListItem, { ActivityListItemRef } from "./ActivityListItem";
import ActivityQnASheet from "./ActivityQnASheet";
import React from "react";

interface ActivityQuestionnaireListProps {
  activities: any;
  answers: any;
  selectedRow: any;
  onSubmit: (enrollData: any, expandedActivity: any) => void;
  isDisabled?: boolean;
  expandedActivity: any;
}

function ActivityQuestionnaireList(props: ActivityQuestionnaireListProps) {
  const activities = props.activities ? props.activities : [];
  const answers = props.answers ? props.answers : [];
  const selectedRow = props.selectedRow ? props.selectedRow : null;
  const isDisabled = props.isDisabled ? props.isDisabled : false;

  // create refs for each activity
  const activityRefs: any = [];
  activities.forEach((activity: any) => {
    activityRefs[activity.id] = React.createRef<ActivityListItemRef>();
  });

  // function to allow expand only one activity at a time
  function isAnyActivityExpanded() {
    for (let key in activityRefs) {
      if (activityRefs[key].current?.isExpanded()) {
        return true;
      }
    }
    return false;
  }

  function onSubmit(enrollData: any, keepExpanded: boolean, activityId: any) {
    if (keepExpanded) {
      props.onSubmit(enrollData, activityId);
    } else {
      props.onSubmit(enrollData, null);
    }
  }

  function getAnswer(activity: any) {
    const answer = answers.find(
      (x: any) => x.initiativeActivityId == activity.id
    );
    return answer ? answer : null;
  }

  function isAnswerSkipped(activity: any) {
    var answer = getAnswer(activity);
    return answer ? answer.status == 2 : false;
  }

  function isAnswerCompleted(activity: any) {
    var answer = getAnswer(activity);
    return answer ? answer.status == 1 : false;
  }

  return (
    <>
      <ActivityList activities={activities}>
        {activities.map((activity: any) => (
          <ActivityListItem
            ref={activityRefs[activity.id]}
            activity={activity}
            key={activity.id}
            isCompleted={isAnswerCompleted(activity)}
            isSkipped={isAnswerSkipped(activity)}
            isAnyActivityExpanded={isAnyActivityExpanded}
            isExpanded={props.expandedActivity == activity.id}
          >
            <ActivityQnASheet
              activity={activity}
              answer={getAnswer(activity)}
              selectedRow={selectedRow}
              onSubmit={(enrollData: any, keepExpanded: boolean) =>
                onSubmit(enrollData, keepExpanded, activity.id)
              }
              formDisabled={isDisabled}
              buttonDisabled={isDisabled}
            />
          </ActivityListItem>
        ))}
      </ActivityList>
    </>
  );
}
export default ActivityQuestionnaireList;
