import React from "react";
import {  useNavigate } from "react-router-dom";
import { getEQUALUserInfoFromStorage } from "../helpers/api-util";
import WaitIndicator from "../components/atoms/WaitIndicator";
import RequestToEnrollSite from "../components/common/RequestToEnrollSite";
import SuccessAlert from "../components/atoms/alerts/SuccessAlert";
import  { AdminContextProvider } from "../store/admin-context";
import HTMLErrorAlert from "../components/atoms/alerts/HTMLErrorAlert";

function HomePage() {
  const [userRoles, setUserRoles] = React.useState<string[]>([]); // ["Admin", "Champion"]
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const eQualUser_Info: IEQUALTokenInfo = getEQUALUserInfoFromStorage();
    if (
      eQualUser_Info &&
      eQualUser_Info.roles &&
      eQualUser_Info.roles.length > 0
    ) {
      setUserRoles(eQualUser_Info.roles);
    }
    setLoading(false);
  }, []);

  if (loading)
    return (
      <div>
        <WaitIndicator show={true}></WaitIndicator>{" "}
      </div>
    );

  const isAdmin =
    userRoles.find((role) => role.toLowerCase().includes("admin")) === "Admin";
  const isChampion =
    userRoles.find((role) => role.toLowerCase().includes("champion")) ===
    "Champion";
  const isGuest =
    userRoles.find((role) => role.toLowerCase().includes("guest")) ===
    "Guest";

  if (isAdmin) {
    navigate("/admin");
  } else if (isChampion) {
    navigate("/champion");
  }
  else if (isGuest) {
    return (
      <>
       <AdminContextProvider>     
        <RequestToEnrollSite/>    
        <SuccessAlert/>       
        <HTMLErrorAlert/>  
      </AdminContextProvider>
      </>
      );

  }

  return (
    <main>
      <h2>
        We are unable to determine you or your role for eQual, please contact
        the administrator.
      </h2>
    </main>
  );
  // if (
  //   eQualUser_Info &&
  //   eQualUser_Info.roles &&
  //   eQualUser_Info.roles.length > 0
  // ) {
  //   if (eQualUser_Info.roles.includes("Admin")) {
  //     navigate("/admin");
  //   } else if (eQualUser_Info.roles.includes("Champion")) {
  //     navigate("/champion");
  //   }
  // } else {
  //   return (
  //     <main>
  //       <h2>
  //         We are unable to determine your role, please contact the
  //         administrator.
  //       </h2>
  //     </main>
  //   );
  // }

  // // return (
  // //   <main>
  // //     <h1>Welcome to eQual 2.0</h1>
  // //     <h2>This is the default page</h2>
  // //     <p>
  // //       To Go to Admin Module <Link to="admin">Click here.</Link>
  // //     </p>
  // //     <p>
  // //       To Go to Champion Module <Link to="champion">Click here.</Link>
  // //     </p>
  // //   </main>
  // // );

  // return (
  //   <main>
  //     <h1>Welcome to eQual 2.0</h1>
  //     <p>loading...</p>
  //   </main>
  // );
}
export default HomePage;
