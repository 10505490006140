import WizardAction from "../../../atoms/WizardAction";

export interface ActivityPublishActionProps {
  backOnClick: (e: any) => void;
  continueOnClick: (e: any) => void;
  backDisabled?: boolean;
  continueDisabled?: boolean;
}

function ActivityPublishAction(props: ActivityPublishActionProps) {
  return (
    <WizardAction
      backButtonText="Back"
      continueButtonText="Publish"
      backOnClick={props.backOnClick}
      continueOnClick={props.continueOnClick}
      backDisabled={props.backDisabled}
      continueDisabled={props.continueDisabled}
    />
  );
}

export default ActivityPublishAction;
