import AdminAnalyticsHome from "../../components/admin/AdminAnalyticsDashboard";

function AdminHomePage() {
  return (
    <>
      <AdminAnalyticsHome />
    </>
  );
}

export default AdminHomePage;
