import { useContext, useRef } from "react";
import QnAComponent, { QnAComponentRef } from "../../../common/QnAComponent";
import RoundedButton from "../../../atoms/formElements/RoundedButton";
import { Grid } from "@mui/material";
import { updateSiteActivities } from "../../../../helpers/api-util";
import AdminContext from "../../../../store/admin-context";
import { getGuid, scrollToTop } from "../../../../helpers/ui-util";
import {
  onQuestionClearFiles,
  onQuestionFileUpload,
} from "../../../common/QnASheet";
import WaitIndicator, { WaitIndicatorRef } from "../../../atoms/WaitIndicator";
import productFeatues from "../../../../store/features-set.json";

export enum SiteActivityStatus {
  Saved = 0,
  Completed = 1,
  Skipped = 2,
}

export interface ActivityQnASheetProps {
  activity: any;
  answer?: any;
  selectedRow?: any;
  onQuestionValueChanged?: (sender: any, options: any) => void;
  onSubmit: (enrollData: any, keepExpanded: boolean) => void;
  formDisabled?: boolean;
  buttonDisabled?: boolean;
}

function ActivityQnASheet(props: ActivityQnASheetProps) {
  const {
    activity,
    answer,
    selectedRow,
    onSubmit,
    formDisabled,
    buttonDisabled,
  } = props;
  var isOptionalActivity = activity ? activity.isOptional : false;
  var isOptionalActivityEnabled = productFeatues.features.allowOptionalActivity;
  var isOptionalActivity = isOptionalActivity && isOptionalActivityEnabled;
  var answerData = answer ? answer.answer : null;
  var isCompleted = answer ? answer.status == 1 : false;
  var isSkipped = answer ? answer.status == 2 : false;
  // is read only if activity end date is less than today
  var isFormDisabled = false;
  var isButtonDisabled = false;
  var isTodayBetweenStartAndEndDate = false;

  var isSaveButtonDisabled = false;
  var isRevertToIncompleteButtonDisabled = false;
  var isCompleteActivityButtonDisabled = false;
  var isSkipActivityButtonDisabled = false;

  if (activity.startDate && activity.endDate) {
    const startOfDay = new Date().setHours(0, 0, 0, 0);
    const endOfDay = new Date().setHours(23, 59, 59, 999);

    isTodayBetweenStartAndEndDate =
      new Date(activity.startDate).getTime() <= endOfDay &&
      new Date(activity.endDate).getTime() >= startOfDay;
  }

  if (isTodayBetweenStartAndEndDate) {
    if (isCompleted) {
      isFormDisabled = true;
      isSaveButtonDisabled = true;
      isRevertToIncompleteButtonDisabled = false;
      isCompleteActivityButtonDisabled = true;
      isSkipActivityButtonDisabled = true;
    } else if (isSkipped) {
      isFormDisabled = true;
      isSaveButtonDisabled = true;
      isRevertToIncompleteButtonDisabled = false;
      isCompleteActivityButtonDisabled = true;
      isSkipActivityButtonDisabled = true;
    } else {
      isFormDisabled = false;
      isSaveButtonDisabled = false;
      isRevertToIncompleteButtonDisabled = true;
      isCompleteActivityButtonDisabled = false;
      isSkipActivityButtonDisabled = false;
    }
  } else {
    isFormDisabled = true;
    isSaveButtonDisabled = true;
    isRevertToIncompleteButtonDisabled = true;
    isCompleteActivityButtonDisabled = true;
    isSkipActivityButtonDisabled = true;
  }

  if (formDisabled) {
    isFormDisabled = true;
  }
  if (buttonDisabled) {
    isSaveButtonDisabled = true;
    isRevertToIncompleteButtonDisabled = true;
    isCompleteActivityButtonDisabled = true;
    isSkipActivityButtonDisabled = true;
  }

  const qnaRef = useRef<QnAComponentRef>(null);
  const adminContext = useContext(AdminContext);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const guid = getGuid();
  const folderStructure = `${activity?.initiativeId}/${activity?.id}/${guid}`; //files/${activity.id}/guid is folder stucture inside container
  const containerName = selectedRow.acepSiteNumber; //replace with Site Number
  function getData() {
    return qnaRef.current?.getData();
  }

  function validateSurvey() {
    return qnaRef.current?.validate();
  }

  function onQuestionValueChanged(sender: any, options: any) {
    try {
      return;
    } catch (ex) {
      console.log(ex);
    }
  }
  async function onActivityQuestionFileUpload(sender: any, options: any) {
    try {
      waitIndicatorLoaderRef.current?.show();
      await onQuestionFileUpload(
        sender,
        options,
        containerName,
        folderStructure
      );
      waitIndicatorLoaderRef.current?.hide();
    } catch (error) {
      console.error("Error:", error);
    } finally {
    }
  }
  async function onActivityQuestionClearFiles(sender: any, options: any) {
    try {
      waitIndicatorLoaderRef.current?.show();
      await onQuestionClearFiles(sender, options);
      waitIndicatorLoaderRef.current?.hide();
    } catch (ex) {
      console.log(ex);
    }
  }

  async function preInitiativeActivityAnswerSave(
    answerStatus: SiteActivityStatus
  ) {
    var surveyAnswerData = getData();

    var multiSiteActivityData: {
      siteId: any;
      enrollmentId: any;
      siteEnrollmentId: string;
      answer: string;
      status: number;
      initiativeActivityId: string;
      SiteInitiativeEnrollmentId: string;
    }[] = [];

    if (selectedRow != null) {
      multiSiteActivityData.push({
        siteId: selectedRow.siteId,
        enrollmentId: selectedRow.enrollmentId,
        siteEnrollmentId: selectedRow.siteEnrollmentId,
        answer: JSON.stringify(surveyAnswerData),
        status: answerStatus,
        initiativeActivityId: activity.id,
        SiteInitiativeEnrollmentId: selectedRow.id,
      });

      return multiSiteActivityData;
    }
  }

  async function saveButtonOnClickHandler() {
    try {
      waitIndicatorLoaderRef.current?.show();
      var dataToSave = await preInitiativeActivityAnswerSave(
        SiteActivityStatus.Saved
      );
      const response = await updateSiteActivities(dataToSave);
      waitIndicatorLoaderRef.current?.hide();
      if (response.data != null && response.status == 200) {
        onSubmit(response.data, true);
        adminContext.onRecordSave(true, "Activity saved!");
        scrollToTop();
      }
    } catch (ex) {
      console.log(ex);
      adminContext.onError(true, "Error saving data");
      waitIndicatorLoaderRef.current?.hide();
      scrollToTop();
    }
  }

  async function revertToIncompleteButtonOnClickHandler() {
    try {
      waitIndicatorLoaderRef.current?.show();
      var dataToSave = await preInitiativeActivityAnswerSave(
        SiteActivityStatus.Saved
      );
      if (dataToSave != null && dataToSave.length > 0) {
        dataToSave[0].answer = "";
      }
      const response = await updateSiteActivities(dataToSave);
      waitIndicatorLoaderRef.current?.hide();
      if (response.data != null && response.status == 200) {
        onSubmit(response.data, false);
        adminContext.onRecordSave(true, "Activity reverted to incomplete");
        scrollToTop();
      }
    } catch (ex) {
      console.log(ex);
      adminContext.onError(true, "Error saving data");
      waitIndicatorLoaderRef.current?.hide();
      scrollToTop();
    }
  }

  async function completeActivityButtonOnClickHandler() {
    try {
      if (!validateSurvey()) {
        return;
      }
      waitIndicatorLoaderRef.current?.show();
      var dataToSave = await preInitiativeActivityAnswerSave(
        SiteActivityStatus.Completed
      );
      const response = await updateSiteActivities(dataToSave);
      waitIndicatorLoaderRef.current?.hide();
      if (response.data != null && response.status == 200) {
        onSubmit(response.data, false);
        adminContext.onRecordSave(true, "Activity completed!");
        scrollToTop();
      }
    } catch (ex) {
      console.log(ex);
      adminContext.onError(true, "Error saving data");
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  async function skipActivityButtonOnClickHandler() {
    try {
      waitIndicatorLoaderRef.current?.show();
      var dataToSave = await preInitiativeActivityAnswerSave(
        SiteActivityStatus.Skipped
      );
      const response = await updateSiteActivities(dataToSave);
      waitIndicatorLoaderRef.current?.hide();
      if (response.data != null && response.status == 200) {
        onSubmit(response.data, false);
        adminContext.onRecordSave(true, "Activity skipped!");
        scrollToTop();
      }
    } catch (ex) {
      console.log(ex);
      adminContext.onError(true, "Error saving data");
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  return (
    <Grid className="Container_MUIGrid" container direction="column">
      {/* commented this to show full width <Grid item className="containerMaxWidth"> */}
      {isOptionalActivity && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          padding={"35px 35px 0px 35px"}
          style={{
            backgroundColor: "#f3f3f3",
          }}
        >
          <Grid item>
            <Grid item>
              <RoundedButton
                disabled={isSkipActivityButtonDisabled}
                name="btnSkipThisActivity"
                text="Skip This Activity"
                buttonType="secondary"
                onClick={skipActivityButtonOnClickHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item>
        <WaitIndicator ref={waitIndicatorLoaderRef} />
        <QnAComponent
          ref={qnaRef}
          activity={activity}
          answer={answerData}
          isFormDisabled={isFormDisabled}
          onQuestionValueChanged={onQuestionValueChanged}
          onQuestionFileUpload={onActivityQuestionFileUpload}
          onQuestionClearFiles={onActivityQuestionClearFiles}
        />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        padding={"0px 35px 35px 35px"}
      >
        <Grid item xs={2}>
          <RoundedButton
            disabled={isSaveButtonDisabled}
            name="btnSave"
            text="Save"
            buttonType="secondary"
            onClick={saveButtonOnClickHandler}
          />
        </Grid>

        <Grid item xs={10}>
          <Grid container direction="row" justifyContent="flex-end" spacing={2}>
            <Grid item>
              <RoundedButton
                disabled={isRevertToIncompleteButtonDisabled}
                name="btnRevertToIncomplete"
                text="Revert to Incomplete"
                buttonType="secondary"
                onClick={revertToIncompleteButtonOnClickHandler}
              />
            </Grid>

            {
              // show skip button only if activity is optional
              isOptionalActivity && (
                <Grid item>
                  <RoundedButton
                    disabled={isSkipActivityButtonDisabled}
                    name="btnSkipThisActivity"
                    text="Skip This Activity"
                    buttonType="secondary"
                    onClick={skipActivityButtonOnClickHandler}
                  />
                </Grid>
              )
            }

            <Grid item>
              <RoundedButton
                disabled={isCompleteActivityButtonDisabled}
                name="btnCompleteActivity"
                text="Complete This Activity"
                onClick={completeActivityButtonOnClickHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default ActivityQnASheet;
