import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../pages/RootLayout";
import HomePage from "../pages/Home";
import RouteChangeListener from "./RouteChangeListener";

export const unAuthenticatedRoutes = createBrowserRouter([
  {
    path: "/",
    element: (
      <RouteChangeListener>
        <RootLayout />
      </RouteChangeListener>
    ),
    children: [
      {
        index: true,
        element: <HomePage />,
      },
    ],
  },
]);
