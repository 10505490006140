import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../pages/RootLayout";
import HomePage from "../pages/Home";
import ChampionLayout from "../pages/champion/ChampionLayout";
import ChampionHomePage from "../pages/champion/ChampionHomePage";
import EnrollmentHomePage from "../pages/champion/enrollment/EnrollmentDashboard";
import InitiativesHomePage from "../pages/champion/initiatives/InitiativesDashboard";
import InitiativeQuestionnairesPage from "../pages/champion/initiatives/InitiativeQuestionnairesPage";
import ManageChampionsHomePage from "../pages/champion/manage-champions/ManageChampionsDashboard";
import PerformanceValidationPage from "../pages/champion/performance-validation/PerformanceValidationPage";
import RouteChangeListener from "./RouteChangeListener";

export const championRoutes = createBrowserRouter([
  {
    path: "/",
    element: (
      <RouteChangeListener>
        <RootLayout />
      </RouteChangeListener>
    ),
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "champion",
        element: <ChampionLayout />,
        children: [
          {
            index: true,
            element: <ChampionHomePage />,
          },
          {
            path: "enrollment",
            element: <EnrollmentHomePage />,
          },
          {
            path: "initiatives",
            element: <InitiativesHomePage />,
          },
          {
            path: "initiative-questionnaires",
            element: <InitiativeQuestionnairesPage />,
          },
          {
            path: "performance-validation",
            element: <PerformanceValidationPage />,
          },
          {
            path: "manage-champions",
            element: <ManageChampionsHomePage />,
          },
        ],
      },
    ],
  },
]);
