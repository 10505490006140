import classes from "./AnalyticsDashboard.module.css";
import StatusForAllSitesHome from "./analytics/sites-status/StatusForAllSitesHome";
import UpcomingDatesHome from "../common/analytics/upcoming-dates/UpcomingDatesHome";
import SiteInitiativesHome from "./analytics/sites-initiatives/SiteInitiativesHome";
import { useState } from "react";
import { getSiteInitiativesEnrollmentProgressForAllSites } from "../../helpers/api-util";
import useAppData from "../../hooks/useAppData";
import WaitIndicator from "../atoms/WaitIndicator";
import { mapStatusForAllSitesForPieChart } from "./analytics/sites-status/StatusForAllSites";
import NoDataComponent from "../common/NoDataComponent";
import { handleErrorReponse } from "../../helpers/ui-util";

export default function AnalyticsHome() {
  const [refreshData, setRefreshData] = useState(false);
  const { appData, loading, error } = useAppData(async () => {
    return await getSiteInitiativesEnrollmentProgressForAllSites();
  }, refreshData);
  const mappedData = mapStatusForAllSitesForPieChart(appData);
  if (loading) {
    return <WaitIndicator show={true} />;
  } else if (error) {
    const errorJSX = handleErrorReponse(error);
    return <>{errorJSX}</>;
  }
  return (
    <>
      {mappedData.length > 0 ? (
        <div className={classes.Main_container}>
          <div className={classes.UpcomingDatesHome_container}>
            <UpcomingDatesHome />
          </div>
          <div className={classes.StatusForAllSites_container}>
            <StatusForAllSitesHome mappedData={mappedData} />
          </div>
          <div className={classes.SiteInitiatives_container}>
            <SiteInitiativesHome />
          </div>
        </div>
      ) : (
        <div className={classes.noDataContainer}>
          <NoDataComponent />
        </div>
      )}
    </>
  );
}
