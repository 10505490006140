import RoundedButton from "../formElements/RoundedButton";
import classes from "./ContentContainer.module.css";

export interface ContentContainerProps {
  text: string;
}

export function ContainerHeader(props: ContentContainerProps) {
  return (
    <div className={classes.container_header}>
      <div className={classes.container_header_title}>
        <h1>{props.text}</h1>
      </div>
    </div>
  );
}

export interface ContainerButtonProps {
  name: string;
  text: string;
  onClick: any;
  buttonType: any;
}

export function ContainerButton(props: ContainerButtonProps) {
  return (
    <div className={classes.container_button}>
      <RoundedButton
        name={props.name}
        text={props.text}
        onClick={props.onClick}
        buttonType={props.buttonType}
      />
    </div>
  );
}

export interface ContainerContentProps {
  children: any;
  style?: any;
}
export function ContainerContent(props: ContainerContentProps) {
  return (
    <div style={props.style} className={classes.container_content}>
      {props.children}
    </div>
  );
}

export function MainContainer(props: any) {
  return <div className={classes.container}>{props.children}</div>;
}
