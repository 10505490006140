import AdminManageUsers from "../../../components/admin/AdminManageUsersHome";

function ManageUsersHomePage() {
    return (
        <>
            <AdminManageUsers />
        </>
    );
}

export default ManageUsersHomePage;