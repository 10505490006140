import WaitIndicator from "../WaitIndicator";
import DataTable from "./DatatableBase";
import React, { useState, useEffect } from 'react';
import { useTheme,createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ReactDataTable(props: any) {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 900,
        lg: 1200,
        xl: 1920,
      },
    },
  });
  const isMobileDevice = useMediaQuery(theme.breakpoints.between('xs', 'md'));;
  const isTabletDevice = useMediaQuery(theme.breakpoints.between('md', 'lg'));;
  const [visibleColumns, setVisibleColumns] = useState(props.columns);
  const [originalColumnWidths, setOriginalColumnWidths] = useState({});
  const [isDataSetReadyToShow, setDataSetReadyToShow] = useState(false); //This is to ensure that the data set is ready to show

  useEffect(() => {
    if (isMobileDevice) {
      // Adjust columns for smaller devices
      adjustColumnsForSmallDevice();
    } else  if (isTabletDevice) {
      // Adjust columns for smaller devices
      adjustColumnsForSmallDevice();
    } else {
      // Restore original column widths for larger devices
      restoreOriginalColumnWidths();
    }
    setDataSetReadyToShow(true); // Store original column widths when component mounts on neighter mobile nor tablet device
  }, [isMobileDevice,isTabletDevice, props.columns]);

  // Function to adjust columns for smaller devices
  const adjustColumnsForSmallDevice = () => {
    let totalAdjustedColumns = 0;let adjustedColumns;

    if(isMobileDevice)
      adjustedColumns = (props.columns.filter((column: any) => column.showOnMobileDevice !== false));
    else if(isTabletDevice)
      adjustedColumns = (props.columns.filter((column: any) => column.showOnTabletDevice !== false));
    
    adjustedColumns.forEach((column: { adjustWidthOnSmallerDevice: any; }) => {
      if ((isMobileDevice && column.adjustWidthOnSmallerDevice) || 
          (isTabletDevice && column.adjustWidthOnSmallerDevice)) {
          totalAdjustedColumns++;
      }
  });

    // Calculate column width for smaller devices
    const columnWidth = calculateColumnWidth(totalAdjustedColumns);
    adjustedColumns = adjustedColumns.map((column: any) => {
      if (isMobileDevice && column.adjustWidthOnSmallerDevice) {
        return { ...column, width: columnWidth };
      }else if (isTabletDevice && column.adjustWidthOnSmallerDevice) {
        return { ...column, width: columnWidth };
      }
      return column;
    });

  setVisibleColumns(adjustedColumns);
  };

  // Function to calculate column width for smaller devices
  const calculateColumnWidth = (totalAdjustedColumns: number) => {
    const totalWidth = isTabletDevice ?  74 : isMobileDevice ? 67:0; // 65% width in total
    const columnWidth = totalAdjustedColumns > 1 ? (totalWidth / totalAdjustedColumns) : totalWidth;
    return (columnWidth) + "%";
  };

// Function to restore original column widths for larger devices
const restoreOriginalColumnWidths = () => {
  const restoredColumns = props.columns.map((column: any) => {
    if (originalColumnWidths[column.name as keyof typeof originalColumnWidths] !== undefined) {
      return { ...column, width: originalColumnWidths[column.name as keyof typeof originalColumnWidths] };
    }
    return column;
  });
  setVisibleColumns(restoredColumns);
};

// useEffect(() => {
//   // Store original column widths when component mounts
//   const originalWidths: { [key: string]: string } = {};
//   props.columns.forEach((column: any) => {
//     if (!column.adjustWidthOnSmallerDevice && column.width) {
//       originalWidths[column.name] = column.width;
//     }
//   });
//   setOriginalColumnWidths(originalWidths);
//   setDataSetReadyToShow(true);
// }, []);

  // Rest of your component code
  let {
    columns,
    data,
    selectable,
    progress,
    onSelectedRowsChange,
    clearSelectedRows,
    conditionalRowStyles,
    dataTableStyles,
    noDataComponent, 
    expandableRows,
    expandableRowsComponent,
    fixedHeader,
    fixedHeaderScrollHeight,
  } = props;
  const customStyles = {
    headRow: {
      style: {
        fontSize: "14px",
        minHeight: "16px",
        fontWeight: 600,
        borderBottomWidth: "5px",
        borderBottomColor: "#f0f0f0",
        borderBottomStyle: "solid",
        paddingRight: "0px!important",
        ...(dataTableStyles?.headRow || {}),        
      },
    },
    rows: {
      style: {
        fontSize: "16px",
        paddingTop: "12px",
        paddingBottom: "12px",
        lineheight: "23.52px",
        height: "48px",
        fontweight: 400,
        borderBottom:"1px solid rgba(0, 0, 0, 0.12)",
        ...(dataTableStyles?.rows || {}),        
      },
    },
  };

  function handleChange(state: any) {
    onSelectedRowsChange(state.selectedRows);
  }

  let otherProps = {};
  if (conditionalRowStyles !== undefined) {
    otherProps = {
      conditionalRowStyles: [...conditionalRowStyles],
    };
  }
  const customSort = (rows: any, selector: any, direction: any) => {
    return rows.sort((rowA: any, rowB: any) => {
        // use the selector function to resolve your field names by passing the sort comparitors
        let aField = selector(rowA);
        let bField = selector(rowB);
        
        // Check if the fields are numbers
        const isNumberType = (value: any) => typeof value === 'number';

        if (!isNumberType(aField)) {
            aField = aField?.trim().toLowerCase() || "";
        }
        if (!isNumberType(bField)) {
            bField = bField?.trim().toLowerCase() || "";
        }

        let comparison = 0;

        if (aField > bField) {
            comparison = 1;
        } else if (aField < bField) {
            comparison = -1;
        }
     return direction === 'desc' ? comparison * -1 : comparison;
    });
   };
  return (
    <>
      { isDataSetReadyToShow && // Show the data table only when the data set is ready to show
       <DataTable
        columns={visibleColumns}
        className={"react-data-table"} //Custom class for the data table
        data={data}
        customStyles={customStyles}
        selectableRows={selectable}
        progressPending={progress}
        progressComponent={<div><WaitIndicator show={progress}></WaitIndicator></div>}
        onSelectedRowsChange={handleChange}
        noDataComponent={noDataComponent}
        clearSelectedRows={clearSelectedRows}
        expandableRows={expandableRows}
        expandableRowsComponent={expandableRowsComponent}
        sortFunction={customSort}
        fixedHeader={fixedHeader}
        fixedHeaderScrollHeight={fixedHeaderScrollHeight}
        {...otherProps}
      ></DataTable>}
    </>
  );
}
