import SiteChampionsHome from "../../../components/admin/SiteChampionsHome";

function SiteChampionsHomePage() {
  return (
    <>
      <SiteChampionsHome />
    </>
  );
}

export default SiteChampionsHomePage;
