export interface DynamicTagProps {
  tagName: string;
  children: React.ReactNode;
}

const DynamicTag: React.FC<DynamicTagProps> = ({ tagName, children }) => {
  const Tag = tagName as keyof JSX.IntrinsicElements;

  return <Tag>{children}</Tag>;
};

export default DynamicTag;
