import React, { useContext } from "react";
import ErrorAlert from "./ErrorAlert";
import AdminContext from "../../../store/admin-context";
import DOMPurify from "dompurify";

function HTMLErrorAlert() {
  const adminContext = useContext(AdminContext);

  const renderHTML = (htmlString: string) => {
    return { __html: htmlString };
  };

  return (
    <ErrorAlert>
      {adminContext.errorMessage ? (
        <div
          dangerouslySetInnerHTML={renderHTML(
            DOMPurify.sanitize(adminContext.errorMessage)
          )}
        />
      ) : (
        "Something went Wrong, Please retry!"
      )}
    </ErrorAlert>
  );
}

export default HTMLErrorAlert;
