import { Box, Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import TabPanel from "../../../components/atoms/TabPanel";
import { useNavigate } from "react-router-dom";
import AdminContext from "../../../store/admin-context";
import classes from "./InitiativeLayout.module.css";
import BackNavLink from "../../../components/atoms/BackNavLink";
import {
  ActiveSVG1,
  DisabledSVG1,
  ActiveSVG2,
  DisabledSVG2,
  ActiveSVG3,
  DisabledSVG3,
  ActiveSVG4,
  DisabledSVG4,
} from "../../../components/atoms/Icons/LooksRounded";

function a11yProps(index: number) {
  return {
    id: `initiative-tab-${index}`,
    "aria-controls": `initiative-tabpanel-${index}`,
  };
}

function ManageInitiativeLayout() {
  const location = useLocation();
  const path = location.pathname;
  const adminContext = useContext(AdminContext);
  const navigate = useNavigate();

  const [pageIndex, setPageIndex] = React.useState(0);

  useEffect(() => {
    let tab = 0;
    if (path === "/admin/initiative/activity/list") {
      //Activity Settings
      tab = 1;
    } else if (path === "/admin/initiative/activity/builder") {
      //Activity Builder
      tab = 2;
    } else if (path === "/admin/initiative/activity/publish") {
      //Preview & Publish
      tab = 3;
    }
    setPageIndex(tab);
  }, [path]);

  function canTabBeEnabled(tabIndex: number) {
    if (tabIndex === 0) {
      return true;
    } else if (tabIndex === 1) {
      return adminContext.initiativeId !== "";
    } else if (tabIndex === 2) {
      if (
        adminContext.initiative != null &&
        adminContext.initiative.id != null &&
        adminContext.initiative.initiativeActivities.length > 0
      ) {
        return true;
      }
      return false;
    } else if (tabIndex === 3) {
      if (
        adminContext.initiative != null &&
        adminContext.initiative.id != null &&
        adminContext.initiative.initiativeActivities.length > 0
      ) {
        return adminContext.initiative.initiativeActivities.some((activity) => {
          if (activity.hasQnDefinitions) {
            return true;
          }
          return false;
        });
      }
    }
  }

  const handleWizardPageChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    if (newValue === 0) {
      if (adminContext.initiativeId === "") {
        navigate("/admin/initiative/create");
      } else {
        navigate("/admin/initiative/update");
      }
    } else if (newValue === 1) {
      navigate("/admin/initiative/activity/list");
    } else if (newValue === 2) {
      navigate("/admin/initiative/activity/builder");
    } else if (newValue === 3) {
      navigate("/admin/initiative/activity/publish");
    }
    setPageIndex(newValue);
  };

  return (
    <div className={classes.container}>
      <div className={classes.container_back}>
        <BackNavLink
          to="/admin/manage-initiatives"
          text="Back to Manage Initiatives"
        />
      </div>
      <div className={classes.container_content}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginLeft: "24px",
              marginRight: "24px",
            }}
          >
            <Tabs value={pageIndex} onChange={handleWizardPageChange}>
              <Tab
                label={
                  <div className={classes.tabLabel}>
                    <div className={classes.iconContainer}>
                      {canTabBeEnabled(0) ? <ActiveSVG1 /> : <DisabledSVG1 />}
                    </div>
                    <span
                      className={`${classes.tabText} ${
                        canTabBeEnabled(0)
                          ? classes.activeTab
                          : classes.disabledTab
                      }`}
                    >
                      Initiative Settings
                    </span>
                  </div>
                }
                {...a11yProps(0)}
                style={{ textTransform: "none", width: "25%" }}
              />
              <Tab
                disabled={!canTabBeEnabled(1)}
                //disabled={adminContext.initiativeId === ""}
                // label="2.Activity Settings"
                label={
                  <div className={classes.tabLabel}>
                    <div className={classes.iconContainer}>
                      {canTabBeEnabled(1) ? <ActiveSVG2 /> : <DisabledSVG2 />}
                    </div>
                    <span
                      className={`${classes.tabText} ${
                        canTabBeEnabled(1)
                          ? classes.activeTab
                          : classes.disabledTab
                      }`}
                    >
                      Activity Settings
                    </span>
                  </div>
                }
                {...a11yProps(1)}
                style={{ textTransform: "none", width: "25%" }}
              />
              <Tab
                disabled={!canTabBeEnabled(2)}
                //disabled={adminContext.initiativeId === ""}
                // label="3.Activity Builder"
                label={
                  <div className={classes.tabLabel}>
                    <div className={classes.iconContainer}>
                      {canTabBeEnabled(2) ? <ActiveSVG3 /> : <DisabledSVG3 />}
                    </div>
                    <span
                      className={`${classes.tabText} ${
                        canTabBeEnabled(2)
                          ? classes.activeTab
                          : classes.disabledTab
                      }`}
                    >
                      Activity Builder
                    </span>
                  </div>
                }
                {...a11yProps(2)}
                style={{ textTransform: "none", width: "25%" }}
              />
              <Tab
                disabled={!canTabBeEnabled(3)}
                //disabled={adminContext.initiativeId === ""}
                // label="4.Preview & Publish"
                label={
                  <div className={classes.tabLabel}>
                    <div className={classes.iconContainer}>
                      {canTabBeEnabled(3) ? <ActiveSVG4 /> : <DisabledSVG4 />}
                    </div>
                    <span
                      className={`${classes.tabText} ${
                        canTabBeEnabled(3)
                          ? classes.activeTab
                          : classes.disabledTab
                      }`}
                    >
                      Preview & Publish
                    </span>
                  </div>
                }
                {...a11yProps(3)}
                style={{ textTransform: "none", width: "25%" }}
              />
            </Tabs>
          </Box>
          <TabPanel value={pageIndex} index={0}>
            <Outlet />
          </TabPanel>
          <TabPanel value={pageIndex} index={1}>
            <Outlet />
          </TabPanel>
          <TabPanel value={pageIndex} index={2}>
            <Outlet />
          </TabPanel>
          <TabPanel value={pageIndex} index={3}>
            <Outlet />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}

export default ManageInitiativeLayout;
