import React, { useState } from 'react';
import InitiativesCardView from './initiatives/InitiativesCardView';
import classes from './InitiativesHome.module.css';
import InitiativesGridView from './initiatives/InitiativesGridView';
import GenericTabs from '../atoms/GenericTabs';
import ExpiredInitiativeGridView from './initiatives/ExpiredInitiativeGridView';
import { scrollToTop } from '../../helpers/ui-util';

function InitiativesHome() {
  const [selectedInitiativeId, setSelectedInitiativeId] = useState('');
  const [activeTab, setActiveTab] = useState(0); // 0 for 'active', 1 for 'expired'

  const handleCardSelect = (id: string) => {
    setSelectedInitiativeId(id);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
    scrollToTop("initiativeGridcontainer");
  };

  const tabs = [
    {
      label: 'Active',
      content: (
        <>
          <div className={classes.initiativeGridcontainer}>
            <InitiativesGridView selectedInitiativeId={selectedInitiativeId} />
          </div>
        </>
      ),
    },
    {
      label: 'Expired',
      content: (
        <>
          <div className={classes.initiativeGridcontainer}>
            <ExpiredInitiativeGridView />
          </div>
        </>
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.container_header}>
        <div className={classes.container_header_title}>
          <h1>Initiatives</h1>
        </div>
         {activeTab === 0 && (
          <div className={classes.initiativeCard}>
            <InitiativesCardView onCardSelect={handleCardSelect} />
          </div>
        )}
        <div className={classes.tabs_container}>
        <GenericTabs tabs={tabs} activeTab={activeTab} onChange={handleTabChange} />
        </div>
      </div>
    </div>
  );
}

export default InitiativesHome;
