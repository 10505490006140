import Card from '@mui/material/Card';
import PieChartWithCustomizedLabel from '../../../atoms/charts/Pie';
import classes from './StatusForAllSites.module.css';
import { CardContent } from '@mui/material';

interface StatusForAllSitesHomeProps {
  mappedData: any;
}

function StatusForAllSitesHome(mappedData: StatusForAllSitesHomeProps) {
  
  const sizing = {
    width: 366,
    height: 266,
    legend: {
      hidden: true,
      direction: 'column',
      position: { vertical: 'bottom', horizontal: 'right' },
    },
  };
  return (
    <Card className={classes.card}>
      <CardContent className={classes.container}>
        <div className={classes.LongTitle}>Status for All Sites</div>
          <PieChartWithCustomizedLabel 
            data={mappedData.mappedData}
            sizing={sizing} 
          />
      </CardContent>
    </Card>
  );
}

export default StatusForAllSitesHome;



