import { useState, useRef } from "react";
import AddActivity from "../add-activity/AddActivity";
import NoActivity from "../no-activity/NoActivity";
import ActivityList from "./activity-list/ActivityList";
import ActivitySettingsAction from "./ActivitySettingsAction";
import ActivitySettingsHeader from "./ActivitySettingsHeader";
import { useNavigate } from "react-router-dom";
import AdminContext from "../../../../store/admin-context";
import { useContext } from "react";
import { reIndexInitiativeActivity } from "../../../../helpers/api-util";
import WizardHeader from "../../../atoms/WizardHeader";
import WaitIndicator, { WaitIndicatorRef } from "../../../atoms/WaitIndicator";
import useActivitiesData from "../../../../hooks/useActivitiesData";
import { handleErrorReponse, scrollToTop } from "../../../../helpers/ui-util";

function ActivitySettingsHome() {
  const [refreshData, setrefreshData] = useState(false);
  const [enableAddActivity, setEnableAddActivity] = useState(true);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);
  const adminContext = useContext(AdminContext);

  const activitiesData = useActivitiesData(refreshData);

  const navigate = useNavigate();

  function addActivityOnClickHandler(event: any) {
    setEnableAddActivity(false);
  }

  function canContinue() {
    if (
      adminContext.initiative != null &&
      adminContext.initiative.id != null &&
      adminContext.initiative.initiativeActivities.length > 0
    ) {
      return true;
    }
    return false;
  }

  function addActivityFormCancelOnClickHandler(event: any) {
    setEnableAddActivity(true);
    scrollToTop();
  }

  async function addActivityFormSaveOnClickHandler(data: any) {
    setEnableAddActivity(true);
    setrefreshData(!refreshData);
    scrollToTop();
  }

  async function updateActivityFormDeleteOnClickHandler(data: any) {
    setEnableAddActivity(true);
    setrefreshData(!refreshData);
    scrollToTop();
  }

  function updateActivityFormCancelOnClickHandler(event: any) {
    setEnableAddActivity(true);
    setrefreshData(!refreshData);
    scrollToTop();
  }

  async function updateActivityFormSaveOnClickHandler(data: any) {
    setEnableAddActivity(true);
    setrefreshData(!refreshData);
  }

  function continueOnClickHandler(event: any) {
    navigate("/admin/initiative/activity/builder");
  }

  function backButtonClickHandler(event: any) {
    navigate("/admin/initiative/update");
  }

  async function onActivityDragDrop(activityData: any) {
    try {
      waitIndicatorLoaderRef.current?.show();
      if (activityData === null) {
        adminContext.onError(true);
      } else {
        const response = await reIndexInitiativeActivity(activityData);
        if (response.data != null) {
          adminContext.onRecordSave(true);
          setEnableAddActivity(true);
          setrefreshData(!refreshData);
        }
      }
      waitIndicatorLoaderRef.current?.hide();
    } catch (ex) {
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  if (activitiesData.loading === true) {
    return (
      <>
        <WizardHeader title="Activity Settings" />
        <WaitIndicator show={true} />
      </>
    );
  }
  if (activitiesData.error) {
    const errorJSX = handleErrorReponse(activitiesData.error);
    return (
      <>
        <WizardHeader title="Activity Settings" />
        {errorJSX}
      </>
    );
  }

  let returnJSX = <></>;
  let addActivityJSX = (
    <>
      <AddActivity
        cancelOnClick={addActivityFormCancelOnClickHandler}
        saveOnClick={addActivityFormSaveOnClickHandler}
      />
    </>
  );

  const activitySettingsActionJSX = (
    <ActivitySettingsAction
      enableContinue={canContinue()}
      continueOnClick={continueOnClickHandler}
      backOnClick={backButtonClickHandler}
    />
  );
  const waitIndicatorJSX = (
    <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
  );
  if (activitiesData.appData != null && activitiesData.appData.length === 0) {
    returnJSX = (
      <>
        <ActivitySettingsHeader
          enableAddActivity={!enableAddActivity}
          addActivityOnClick={addActivityOnClickHandler}
          initiativeId={adminContext.initiativeId}
          refreshData={refreshData}
        />
        {enableAddActivity === false ? addActivityJSX : <NoActivity />}
        {activitySettingsActionJSX}
        {waitIndicatorJSX}
      </>
    );
  } else {
    returnJSX = (
      <>
        <ActivitySettingsHeader
          enableAddActivity={!enableAddActivity}
          addActivityOnClick={addActivityOnClickHandler}
          initiativeId={adminContext.initiativeId}
          refreshData={refreshData}
        />
        <ActivityList
          onActivityDragDrop={onActivityDragDrop}
          cancelOnClick={updateActivityFormCancelOnClickHandler}
          saveOnClick={updateActivityFormCancelOnClickHandler}
          deleteOnClick={updateActivityFormDeleteOnClickHandler}
          activities={activitiesData.appData}
        />
        {enableAddActivity === false ? addActivityJSX : <></>}
        {activitySettingsActionJSX}
        {waitIndicatorJSX}
      </>
    );
  }

  return returnJSX;
}

export default ActivitySettingsHome;
