import React from "react";
import axios from "axios";
import { LOGIN_URL, ACEPAuthorizatioinAPIURL } from "../../config/config";

import AuthContextProvider from "../../store/auth-context";
import { generateToken, getUserInfoFromStorage } from "../../helpers/api-util";
import WaitIndicator from "../atoms/WaitIndicator";

export default function SSOACEP(props: any) {
  const [loadPage, setLoadPage] = React.useState(false);

  React.useEffect(() => {
    setSSOCookies(true);

    const interval = setInterval(function () {
      const loadDataVar: string = localStorage.getItem("loadSSOData") || "0";
      if (loadDataVar === "1") setSSOCookies();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const checkSSOFromStorage = async () => {
    var date = new Date();
    var int_date_frame = Math.floor(date.getTime() / 1000);
    const user_info: IACEPTokenInfo =await getUserInfoFromStorage();
    var exp_time_session = Number(user_info.exp) || 0;
    if (int_date_frame >= exp_time_session) {
      setSSOCookies();
    } else {
      setLoadPage(true);
    }
  };

  async function setSSOCookies(isPageRefreshedFromBrowser?: boolean | false) {
    const loadDataVar: string = localStorage.getItem("loadSSOData") || "1";
    if (loadDataVar === "1" || isPageRefreshedFromBrowser) {
      localStorage.clear();
      var requestOptions: any = {
        method: "GET",
        redirect: "follow",
        withCredentials: true, //This is needed for Cookies to be sent to the Api Call : MB 8/8/2022
      };
      try {
        const response = await axios.get(
          ACEPAuthorizatioinAPIURL,
          requestOptions
        );
        if (response.status === 200) {
          localStorage.setItem("ACEPUSERINFO", String(response.data));
          const user_info: IACEPTokenInfo =await getUserInfoFromStorage();
          if (user_info.sub !== undefined) {
            try {
              // var postdata = {
              //   userName: process.env.REACT_APP_CLIENT_USERNAME,
              //   password: process.env.REACT_APP_CLIENT_PASSWORD,
              //   acepId: user_info.sub,
              //   acepJTI: user_info.jti,
              // };
              const tokenResponse = await generateToken();
            } catch (error) {
              console.log("Error in generating token", error);
            }
          } else {
            console.log("Error in getting user info from SSO");
          }

          localStorage.setItem("loadSSOData", "0");
          setLoadPage(true);
        }
      } catch (error) {
        localStorage.setItem("loadSSOData", "0");
        window.location.href = LOGIN_URL + window.location.origin;
      }
    } else {
      checkSSOFromStorage();
    }
  }
  return (
    <div>
      {loadPage ? <AuthContextProvider /> : <WaitIndicator show={true} />}
    </div>
  );
}
