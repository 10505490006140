export const mapStatusForAllSitesForPieChart = (resultData: any): StatusForAllSitesPieChart[] => {
    let statusForAllSitesPieChartData: StatusForAllSitesPieChart[] = [];
  
    try {
    
      if (Array.isArray(resultData) && resultData.length > 0) {

      if(resultData.length === 1) {
        return [];
      }       
      statusForAllSitesPieChartData = resultData.map((result: any) => {  
          return {
            label: result.status,
                value: result.percentage,
                color: result.status === "Completed" ? "#8AF28E" :( result.status === "In Progress" ? "#89B8FF" : "#E7E7E7"),
          };
        });
      }
    } catch (error) {
      console.error("Error occurred while fetching Data:", error);
    }  
    
    return statusForAllSitesPieChartData;
  };
  