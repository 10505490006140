import { useContext, useState } from "react";
import { getInitiativeActivities } from "../helpers/api-util";
import AdminContext from "../store/admin-context";
import useAppData from "./useAppData";

function useActivitiesData(refresh: boolean = false) {
  const [result, setResult] = useState<any>({
    appData: null,
    loading: true,
    error: null,
  });

  const adminContext = useContext(AdminContext);
  useAppData(async () => {
    {
      setResult({
        appData: null,
        loading: true,
        error: null,
      });
      var activities = await getInitiativeActivities(adminContext.initiativeId);
      setResult({
        appData: activities.data,
        loading: false,
        error: activities.error,
      });
      if (activities.data != null) {
        adminContext.updateInitiative({
          ...adminContext.initiative,
          initiativeActivities: activities.data,
        });
      }
    }
  }, refresh);
  return result;
}

export default useActivitiesData;
