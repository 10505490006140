import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ReactDataTable from "../../atoms/tables/ReactDataTable";
import { useState } from "react";
import useAppData from "../../../hooks/useAppData";
import { getInitiativesEnrollments } from "../../../helpers/api-util";
import {
  mapInitiativesGridData,
  getFilteredDataByInitiativeId,
  getSameSiteEnrollmentData,
} from "./InitiativesGrid";
import WaitIndicator from "../../atoms/WaitIndicator";
import { Grid, Tooltip } from "@mui/material";
import classes from "./InitiativesGridView.module.css";
import { useNavigate } from "react-router-dom";
import { getUIFormattedDate } from "../../../helpers/ui-util";
import GenericLinearProgress from "../../atoms/formElements/linearProgressbar";

interface InitiativesGridViewProps {
  selectedInitiativeId: string | "";
}
function InitiativesGridView({
  selectedInitiativeId,
}: InitiativesGridViewProps) {
  const [refreshData, setrefreshData] = useState(false);
  const navigate = useNavigate();

  const [selectedRows, setSelectedRows] = useState<InitiativesEnrollment[]>([]); // [1, 2, 3

  const { appData, loading, error } = useAppData(async () => {
    return await getInitiativesEnrollments();
  }, refreshData);

  const mappedData: InitiativesEnrollment[] = mapInitiativesGridData(appData);

  // const filteredData = selectedInitiativeId
  //   ? mappedData.filter((item) => item.initiativeId === selectedInitiativeId)
  //   : mappedData;

  const filteredData = selectedInitiativeId
    ? getFilteredDataByInitiativeId(mappedData, selectedInitiativeId)
    : mappedData;

  function sortData(field: string) {
    const sortedData = [...filteredData];

    if (field === "siteName" || field === "physicianGroupName") {
      sortedData.sort((a: InitiativesEnrollment, b: InitiativesEnrollment) => {
        const fieldValueA = (a[field] || "").toUpperCase();
        const fieldValueB = (b[field] || "").toUpperCase();

        // If both values are numbers, compare them numerically
        if (!isNaN(Number(fieldValueA)) && !isNaN(Number(fieldValueB))) {
          return Number(fieldValueA) - Number(fieldValueB);
        }

        // If one of the values is a number, place it at the end
        if (!isNaN(Number(fieldValueA))) return 1;
        if (!isNaN(Number(fieldValueB))) return -1;

        // If both values are null or empty, consider them equal
        if (!fieldValueA && !fieldValueB) return 0;

        // If one of the values is null or empty, place it at the end
        if (!fieldValueA) return 1;
        if (!fieldValueB) return -1;

        // For non-numeric and non-null values, use regular string comparison
        if (fieldValueA < fieldValueB) return -1;
        if (fieldValueA > fieldValueB) return 1;
        return 0;
      });
    }

    return sortedData;
  }

  function onArrowButtonClickHandler(row: InitiativesEnrollment) {
    if (row.openToActivity === true) {
      setSelectedRows([row]);
      // filter from filteredData where initiativeId = row.initiativeId
      // var sameSiteEnrollmentData = filteredData.filter(
      //   (item) => item.initiativeId === row.initiativeId
      // );
      var sameSiteEnrollmentData = getSameSiteEnrollmentData(
        filteredData,
        row.initiativeId
      );
      navigate("/champion/initiative-questionnaires", {
        replace: true,
        state: {
          initiativeEnrollmentInfo: row,
          sameSiteEnrollmentData: sameSiteEnrollmentData,
        },
      });
    }
  }

  const columns = [
    {
      name: "Site",
      selector: (row: InitiativesEnrollment) => row.siteName,
      cell: (row: InitiativesEnrollment) => (
        <Tooltip title={row.siteName} placement="top" arrow>
          <div className={"ellipsis"}>{row.siteName}</div>
        </Tooltip>
      ),
      sortable: true,
      adjustWidthOnSmallerDevice: true,
      width: "30%",
    },
    {
      name: "Physician Group",
      selector: (row: InitiativesEnrollment) => row.physicianGroupName,
      cell: (row: any) => (
        <Tooltip title={row.physicianGroupName} placement="top" arrow>
          <div className={"ellipsis"}>{row.physicianGroupName}</div>
        </Tooltip>
      ),
      sortable: true,
      showOnMobileDevice: false,
      showOnTabletDevice: false,
       
    },
    {
      name: "Progress",
      width: "18%",
      selector: (row: InitiativesEnrollment) => row.progressPercentage,
      cell: (row: any) => (
        <GenericLinearProgress
          value={row.progressPercentage}
          variant="determinate"
        />
      ),
       
    },
    {
      name: "Initiative",
      selector: (row: InitiativesEnrollment) => row.initiativeName,
      cell: (row: InitiativesEnrollment) => (
        <Tooltip title={row.initiativeName} placement="top" arrow>
          <div className={"ellipsis"}>{row.initiativeName}</div>
        </Tooltip>
      ),     
      adjustWidthOnSmallerDevice: true,
       
    },
    {
      name: "Last Updated",
      sortable: false,
      width: "13%",
      selector: (row: InitiativesEnrollment) => row.lastUpdated,
      cell: (row: InitiativesEnrollment) => (
        <div>
          {row.lastUpdated !== "" ? getUIFormattedDate(row.lastUpdated) : ""}
        </div>
      ),
      showOnMobileDevice: false,
      showOnTabletDevice: false,
    },
    {
      name: "",
      cell: (row: InitiativesEnrollment) => (
        <button
          style={{
            border: "none",
            background: "none",
            cursor:
              row.initiativeStatusName === "Deactivated" ||
              row.openToActivity === false
                ? "default"
                : "pointer",
          }}
          onClick={(e: React.MouseEvent) => {
            onArrowButtonClickHandler(row);
          }}
        >
          <KeyboardArrowRightIcon
            style={{
              color:
                row.initiativeStatusName === "Deactivated" ||
                row.openToActivity === false
                  ? "#cccccc"
                  : "",
            }}
          />
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "60px",
       
    },
  ];

  const reactDataTableConditionalRowStyles = [
    {
      when: (row: InitiativesEnrollment) =>
        row.initiativeStatusName === "Deactivated" ||
        row.openToActivity === false,
      style: {
        backgroundColor: "#F0F0F0",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const newStyle = {
    headRow: {
      border: "none",
    },
    rows: {
      border: "1px solid #DDDDDD",
      borderRadius: "10px",
      marginBottom: "10px",
      marginTop: "0px",
    },
  };

  if (loading) {
    return <WaitIndicator show={true} />;
  }
  return (
    <Grid>
      {filteredData.length > 0 ? (
        <ReactDataTable
          columns={columns}
          data={filteredData}
          selectable={false}
          striped
          pointerOnHover
          onSort={(field: string, direction: "asc" | "desc") => {
            const sortedData = sortData(field);
            setrefreshData(!refreshData);
          }}
          conditionalRowStyles={reactDataTableConditionalRowStyles}
          dataTableStyles={newStyle}
        ></ReactDataTable>
      ) : (
        <div className={classes.noDataMessage}>
          You do not have any sites enrolled in this initiative, please select
          another initiative.
        </div>
      )}
    </Grid>
  );
}

export default InitiativesGridView;
