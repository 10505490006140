import { useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  createInitiative,
  saveInitiativeIcon,
} from "../../../helpers/api-util";
import AdminContext from "../../../store/admin-context";
import SaveConfirmDialog from "../../atoms/dialogs/SaveConfirmDialog";
import InitiativeForm from "./InitiativeForm";
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";
import { InitiativeFormType } from "../../../models/Initiative";

function CreateInitiative() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const initiativeInitialState: InitiativeFormType = {
    id: "",
    year: today.getFullYear(),
    name: "",
    enrollmentOpenDate: today.toISOString().substring(0, 10),
    enrollmentEndDate: "",
    initiativeStartDate: tomorrow.toISOString().substring(0, 10),
    initiativeEndDate: "",
    longSummary: "",
    logo: "",
  };

  const adminContext = useContext(AdminContext);
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [initiativeData, setInitiativeData] = useState<InitiativeFormType>(
    initiativeInitialState
  );
  const [iconFile, setIconFile] = useState<File[]>([]);
  const WaitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);

  async function createInitiativeInfo(
    initiativeData: InitiativeFormType,
    iconFile: File[]
  ) {
    let response = await createInitiative(initiativeData);
    if (response.data != null) {
      initiativeData.id = response.data.id;
    }
    if (response.error && response.error.response) {
      const errorData = response.error.response.data as {
        errors: { LongSummary: string[] };
      };

      if (errorData && errorData.errors && errorData.errors.LongSummary) {
        const longSummaryError = errorData.errors.LongSummary[0];

        adminContext.onError(true, longSummaryError);
      }

      return response;
    }
    if (response.error != null) {
      adminContext.onError(true, "Unable to save Initiative Information!");
      return response;
    }

    if (response.data != null && iconFile && iconFile.length > 0) {
      response = await saveInitiativeIcon(
        initiativeData,
        response.data.id,
        iconFile[0]
      );
      if (response.error != null) {
        adminContext.onError(true, "Unable to save Initiative Icon!");
        return response;
      }
    }

    if (response.data != null && response.data.id) {
      adminContext.setInitiativeId(response.data.id);
      adminContext.updateInitiative(response.data);
      adminContext.onRecordSave(true, "Initiative saved successfully!");
      return response;
    }
    return response;
  }

  const onSaveHandler = async (initiativeData: any, iconFile: File[]) => {
    try {
      const response = await createInitiativeInfo(initiativeData, iconFile);
      if (response.data && response.data.id) {
        navigate("/admin/initiative/update");
      }
      WaitIndicatorLoaderRef.current?.hide();
    } catch (ex) {
      WaitIndicatorLoaderRef.current?.hide();
    }
  };

  const onSaveAndContinueHandler = async (
    initiativeData: any,
    iconFile: File[]
  ) => {
    try {
      WaitIndicatorLoaderRef.current?.show();
      const response = await createInitiativeInfo(initiativeData, iconFile);
      if (response.data && response.data.id) {
        navigate("/admin/initiative/activity/list");
      }
      WaitIndicatorLoaderRef.current?.hide();
    } catch (ex) {
      WaitIndicatorLoaderRef.current?.hide();
    }
  };

  function onBackClickHandler(initiativeData: any, iconFile: File[]) {
    setInitiativeData(initiativeData);
    setIconFile(iconFile);
    setConfirmOpen(true);
  }

  async function saveInitiativeAndExit() {
    const response = await createInitiativeInfo(initiativeData, iconFile);
    if (response.data != null) {
      navigate("/admin/manage-initiatives");
    }
  }

  return (
    <>
      <SaveConfirmDialog
        title="Are you sure?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={saveInitiativeAndExit}
      ></SaveConfirmDialog>
      <InitiativeForm
        initiative={initiativeData}
        onSave={onSaveHandler}
        onBackClick={onBackClickHandler}
        onSaveAndContinue={onSaveAndContinueHandler}
      />
      <WaitIndicator ref={WaitIndicatorLoaderRef} show={false} />
    </>
  );
}

export default CreateInitiative;
