import { useRef, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RoundedButton from "../../atoms/formElements/RoundedButton";
import {
  getAllInitiatives,
  downloadCompletionReport,
} from "../../../helpers/api-util";
import useAppData from "../../../hooks/useAppData";
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";
import { Initiative } from "./SiteDataReportsView";
import classes from "./ReportsHome.module.css";
import { handleErrorReponse } from "../../../helpers/ui-util";

export default function CompletionReportPage() {
  const [selectedInitiative, setSelectedInitiative] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);

  const initiativesFromAPI = useAppData(async () => {
    return await getAllInitiatives();
  }, refreshData);
  if (initiativesFromAPI?.loading) {
    return (
      <>
        <div>
          <WaitIndicator show={true}></WaitIndicator>
        </div>
      </>
    );
  }
  if (initiativesFromAPI?.error) {
    const errorJSX = handleErrorReponse(initiativesFromAPI.error);
    return <>{errorJSX}</>;
  }
  const filteredInitiatives = initiativesFromAPI?.appData
    ?.filter(
      (initiative: any) =>
        initiative.initiativeStatus?.name === "Published" ||
        initiative.initiativeStatus?.name === "Expired" ||
        initiative.initiativeStatus?.name === "Deactivated"
    )
    .sort((a: any, b: any) => {
      const dateA = new Date(a.initiativeStartDate);
      const dateB = new Date(b.initiativeStartDate);
      if (dateA.getTime() === dateB.getTime()) {
        return a?.name?.localeCompare(b?.name);
      }
      return dateA.getTime() - dateB.getTime();
    });

  const getInitiativeWithEarliestStartDate = () => {
    const filteredPublishInitiatives = filteredInitiatives;
    if (filteredPublishInitiatives.length > 0) {
      // Sort the initiatives by their status priority
      filteredPublishInitiatives.sort((a: any, b: any) => {
        //  If dates are the same, prioritize by status
        const statusPriority: { [key: string]: number } = {
          Published: 0,
          Expired: 1,
          Deactivated: 2,
        };
        const statusA = statusPriority[a?.initiativeStatus?.name || ""];
        const statusB = statusPriority[b?.initiativeStatus?.name || ""];
        return statusA - statusB;
      });

      // Retrieve the initiative with the earliest start date (first in the sorted array)
      return filteredPublishInitiatives;
    } else {
      return null;
    }
  };

  const earliestInitiative: Initiative[] | null =
    getInitiativeWithEarliestStartDate();

  const handleInitiativeChange = (event: any) => {
    setSelectedInitiative(event.target.value);
  };

  const handleExportClick = async () => {
    try {
      waitIndicatorLoaderRef.current?.show();
      let initiativeToExport = selectedInitiative;

      // If no initiative is selected, use the earliest initiative as a default
      if (!initiativeToExport && earliestInitiative) {
        initiativeToExport = earliestInitiative[0].id;
        setSelectedInitiative(initiativeToExport);
      }
      if (!initiativeToExport) {
        console.error("No initiatives available for export");
        waitIndicatorLoaderRef.current?.hide();
        return;
      }
      var done = await downloadCompletionReport(initiativeToExport);
      if (done) {
        waitIndicatorLoaderRef.current?.hide();
      }
    } catch (error) {
      console.log(error);
      waitIndicatorLoaderRef.current?.hide();
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div>
          <h2 className={classes.cardTitle}>Completion Report</h2>
          <div className={classes.cardDescription}>
            Download to see completion status for each activity by site.
          </div>
          {filteredInitiatives.length > 0 ? (
            <div style={{ marginTop: "24px" }}>
              <Select
                value={
                  selectedInitiative ||
                  (earliestInitiative && earliestInitiative[0]?.id)
                }
                MenuProps={{
                  PaperProps: {
                    elevation: 0,
                  },
                }}
                onChange={handleInitiativeChange}
                className={classes.select}
              >
                {earliestInitiative?.map((initiative: any) => (
                  <MenuItem
                    key={initiative?.id}
                    value={initiative?.id}
                    className={classes.menuItem}
                  >
                    {initiative?.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          ) : (
            <div>
              <Select value="" disabled className={classes.select}>
                <MenuItem disabled />
              </Select>
            </div>
          )}
        </div>
        <div className={classes.exportBtn}>
          <RoundedButton
            name="btnExport"
            text="Export"
            disabled={
              (selectedInitiative === null || selectedInitiative === "") &&
              earliestInitiative === null
            }
            onClick={handleExportClick}
          />
        </div>
      </CardContent>
      <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
    </Card>
  );
}
