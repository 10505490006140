import React from "react";
import PieChartWithCustomizedLabel, {
  PieChartDataItem,
} from "../../../atoms/charts/Pie";
import { Box, Card, CardContent, Typography } from "@mui/material";
import classes from "./InitiativesStatusForAllSites.module.css";
import { getSiteInitiativesEnrollmentProgressForAllAdminSites } from "../../../../helpers/api-util";
import useAppData from "../../../../hooks/useAppData";
import { mapInitiativesData, mappedInitiativeData } from "./InitiativeStatus";
import WaitIndicator from "../../../atoms/WaitIndicator";

const InitiativesStatusForAllSites: React.FC = () => {
  const [refreshData, setRefreshData] = React.useState(false);

  const StatusForAllAdminSitesAPI = useAppData(async () => {
    return await getSiteInitiativesEnrollmentProgressForAllAdminSites();
  }, refreshData);
  if (StatusForAllAdminSitesAPI.loading) {
    return <WaitIndicator show={true}></WaitIndicator>;
  } else if (StatusForAllAdminSitesAPI.error) {
    return <p>Error</p>;
  } else if (!StatusForAllAdminSitesAPI.appData) {
    return <p>No data found</p>;
  } else {
    const initiativesData: mappedInitiativeData[] = mapInitiativesData(
      StatusForAllAdminSitesAPI.appData
    );
    const sizing = {
      width: 366,
      height: 266,
      legend: {
        hidden: true,
      },
    };
    const cardMaxWidth =
      initiativesData.length === 1
        ? "100%"
        : {
            xs: "100%", // 1 card per row on xs 0-600px
            sm: "100%", // 1 card per row on sm 600-900px
            md: "calc(50% - 12px)", // 2 cards per row on md 900-1200px
            lg: "calc(50% - 12px)", // 2 cards per row on lg 1200-1536px
            xl: "calc(33.33% - 16px)", // 3 cards per row on xl screens (1536px and above)
          };
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          gap: "20px",
          maxWidth: "100%",
        }}
      >
        {initiativesData.map((initiative) => (
          <Card
            key={initiative.initiativeId}
            className={classes.initiativeChart}
            sx={{
              flexBasis: "100%", // Default flexBasis for xs and sm screens (1 card per row)
              maxWidth: cardMaxWidth,
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography className={classes.LongTitle}>
                {initiative.initiativeName}
              </Typography>
              <Typography className={classes.shortTitle}>
                Total Enrolled Sites: {initiative.totalSites}
              </Typography>
              <PieChartWithCustomizedLabel
                data={initiative.PieChartDataItem}
                sizing={sizing}
              />
            </CardContent>
          </Card>
        ))}
      </Box>
    );
  }
};

export default InitiativesStatusForAllSites;
