function EnrollmentQuestionnaireListDetail(props: any) {
  const { data } = props;
  const divStyle = {
    paddingLeft: 40,
  };

  if (data && data.qnDefinitions && data.qnDefinitions.length > 0) {
    const qnDefinitions = data.qnDefinitions;
    return (
      <div style={divStyle}>
        {qnDefinitions.map((qnDefinition: any) => {
          const definition = JSON.parse(qnDefinition.definition);
          if (definition) {
            return <p key={qnDefinition.id}>{definition.title}</p>;
          }
        })}
      </div>
    );
  }

  return (
    <div style={divStyle}>
      <p>No data available!</p>
    </div>
  );
}
export default EnrollmentQuestionnaireListDetail;
