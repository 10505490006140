import { Box, MenuItem, Select } from "@mui/material";
import { styled } from "@mui/system";
import ReactDataTable from "../../../atoms/tables/ReactDataTable";
import useAppData from "../../../../hooks/useAppData";
import { GetInitiativeEnrolledSiteLocations } from "../../../../helpers/api-util";
import { useState } from "react";
import classes from "./EnrollmentSiteLocations.module.css";
import WaitIndicator from "../../../atoms/WaitIndicator";
import { handleErrorReponse } from "../../../../helpers/ui-util";

interface EnrolledSiteLocation {
  initiativeId: string;
  initiativeName: string;
  siteCount: number;
  stateName: string;
}
interface EnrollmentSiteLocationsProps {
  display: (show: boolean) => void;
}

function EnrollmentSiteLocations({ display }: EnrollmentSiteLocationsProps) {
  const [refreshData, setRefreshData] = useState(false);
  const [selectedInitiative, setSelectedInitiative] = useState("all");
  const [filteredGrid, setFilteredGrid] = useState<any>([]);

  const initiativesSiteLocationsFromAPI = useAppData(async () => {
    return await GetInitiativeEnrolledSiteLocations();
  }, refreshData);

  //On small screnn, set the select element width upto 300px and for tablet or desktop set it upto 500px
  const StyledSelect = styled(Select)(({ theme }) => ({
    maxWidth: '100%',
    [theme.breakpoints.between("xs", "md")]: {
      maxWidth: 250,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
  }));

  if (initiativesSiteLocationsFromAPI?.loading) {
    return (
      <>
        <div>
          <WaitIndicator show={true}></WaitIndicator>
        </div>
      </>
    );
  }
  if (initiativesSiteLocationsFromAPI?.error) {
    const errorJSX = handleErrorReponse(initiativesSiteLocationsFromAPI.error);
    return (
      <>
        <div>{errorJSX}</div>
      </>
    );
  } else {
    const formattedEnrollmentSummary =
      initiativesSiteLocationsFromAPI?.appData?.map(
        (data: EnrolledSiteLocation) => {
          if (data.stateName === null) {
            data.stateName = "Other";
          }
          return {
            initiativeId: data.initiativeId,
            initiativeName: data.initiativeName,
            siteCount: data.siteCount,
            stateName: data.stateName,
          };
        }
      );
    const distinctAllInitiativeData = [
      ...Array.from(
        new Set(
          formattedEnrollmentSummary?.map(
            (initiative: any) => initiative.stateName
          )
        )
      ).map((stateName) => ({
        stateName: stateName,
        siteCount: formattedEnrollmentSummary
          ?.filter((site: any) => site.stateName === stateName)
          .reduce((acc: number, site: any) => acc + site.siteCount, 0),
      })),
    ];

    const distinctInitiativename = [
      { initiativeId: "all", initiativeName: "All Initiatives" },
      ...Array.from(
        new Set(
          formattedEnrollmentSummary?.map(
            (initiative: any) => initiative.initiativeId
          )
        )
      ).map((initiativeId) => ({
        initiativeId: initiativeId,
        initiativeName: formattedEnrollmentSummary?.find(
          (initiative: any) => initiative.initiativeId === initiativeId
        )?.initiativeName,
      })),
    ];
    if (
      formattedEnrollmentSummary === null ||
      formattedEnrollmentSummary?.length === 0
    ) {
      display(false);
    }
    const columns = [
      {
        name: "State",
        selector: (row: { stateName: string }) => row.stateName,
        sortable: true,
      },
      {
        name: "Number of Enrolled Sites",
        selector: (row: { siteCount: number }) => row.siteCount,
        sortable: true,
        center: true,
      },
    ];
    const handleInitiativeChange = (event: any) => {
      const selectedValue = event.target.value;
      setSelectedInitiative(selectedValue);
      if (selectedValue === "all") {
        setFilteredGrid(formattedEnrollmentSummary);
      } else {
        const filteredInitiativechange = formattedEnrollmentSummary?.filter(
          (data: any) => data.initiativeId === selectedValue
        );
        setFilteredGrid(filteredInitiativechange);
      }
    };

    return (
      <Box className={classes.container_head}>
        <div className={classes.container_header}>
          <div className={classes.title}>Enrollment Site Locations</div>
          <div className={classes.select_container}>
            {formattedEnrollmentSummary.length > 0 ? (
              <StyledSelect
                value={selectedInitiative}
                MenuProps={{
                  PaperProps: {
                    elevation: 0,
                  },
                }}
                onChange={handleInitiativeChange}
                className={classes.select}
              >
                {distinctInitiativename?.map((initiative: any) => (
                  <MenuItem
                    key={initiative?.initiativeId}
                    value={initiative?.initiativeId}
                    className={classes.menuItem}
                  >
                    {initiative.initiativeName}
                  </MenuItem>
                ))}
              </StyledSelect>
            ) : null}
          </div>
        </div>
        <div style={{ margin: "20px 20px 35px 20px" }}>
          <ReactDataTable
            selectable={false}
            columns={columns}
            data={
              selectedInitiative === "all"
                ? distinctAllInitiativeData
                : filteredGrid
            }
            fixedHeader={true}
            fixedHeaderScrollHeight="310px"
          />
        </div>
      </Box>
    );
  }
}

export default EnrollmentSiteLocations;
