import classes from "./AddActivity.module.css";
import ActivityForm from "../activity-form/ActivityForm";
import { useContext, useRef, useState } from "react";
import AdminContext from "../../../../store/admin-context";
import ActivityFormHeader from "../activity-form/ActivityFormHeader";
import { createInitiativeActivity } from "../../../../helpers/api-util";
import SaveConfirmDialog from "../../../atoms/dialogs/SaveConfirmDialog";
import { getFormattedDate } from "../../../../helpers/ui-util";
import WaitIndicator, { WaitIndicatorRef } from "../../../atoms/WaitIndicator";

function AddActivity(props: any) {
  const [confirmSaveOpen, setConfirmSaveOpen] = useState(false);
  const adminContext = useContext(AdminContext);
  const [activityData, setActivityData] = useState({});
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);

  const today = new Date();
  const newActivityData = {
    id: "",
    name: "",
    startDate: getFormattedDate(today.toISOString()),
    endDate: adminContext.getInitiativeEndDate(),
    longSummary: "",
    includePerformanceValidation: false,
    performanceValidationName: "",
    isOptional: false,
  };

  async function saveOnClickHandler(activityData: any) {
    try {
      if (activityData === null) {
        adminContext.onError(true);
      } else {
        waitIndicatorLoaderRef.current?.show();
        const response = await createInitiativeActivity(
          adminContext.initiativeId,
          activityData
        );
        waitIndicatorLoaderRef.current?.hide();
        if (response.data != null) {
          adminContext.onRecordSave(true);
          props.saveOnClick(true);
        } else {
          adminContext.onError(true);
        }
      }
    } catch (ex) {
      console.log(ex);
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  async function cancelOnClickHandler(activityData: any) {
    if (activityData.isDirty === true) {
      setConfirmSaveOpen(true);
      setActivityData(activityData);
    } else {
      props.cancelOnClick();
    }
  }
  async function saveActivityAndExit() {
    try {
      waitIndicatorLoaderRef.current?.show();
      const response = await createInitiativeActivity(
        adminContext.initiativeId,
        activityData
      );
      waitIndicatorLoaderRef.current?.hide();
      if (response.data != null) {
        adminContext.onRecordSave(true);
        props.saveOnClick(true);
      } else {
        adminContext.onError(true);
      }
    } catch (ex) {
      console.log(ex);
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  return (
    <>
      <SaveConfirmDialog
        title="Are you sure?"
        open={confirmSaveOpen}
        setOpen={setConfirmSaveOpen}
        onConfirm={saveActivityAndExit}
      />
      <div className={classes.container}>
        <ActivityFormHeader showDelete={false} />
        <ActivityForm
          activity={newActivityData}
          cancelOnClick={cancelOnClickHandler}
          saveOnClick={saveOnClickHandler}
        />
        <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
      </div>
    </>
  );
}

export default AddActivity;
