import cssClasses from "./Sidebar.module.css";

export interface SidebarProps {
  children: React.ReactNode;
}

export default function Sidebar(props: SidebarProps) {
  return (
    <>
      <aside className={cssClasses.sidebar}>{props.children}</aside>
    </>
  );
}
