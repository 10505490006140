import React, { ChangeEvent, forwardRef, useImperativeHandle, useState } from "react";
import LabelTextField from "./LabelTextField";
import { UserSearchSetting } from "../../../helpers/ui-util";
import { getSearchUsers } from "../../../helpers/api-util";

interface TypeAheadUserListProps {    
  label: string; 
  name: string; 
  value?: string;
  options: any[];
    handleTextFieldChange?: (e: any) => void;
    onValidate?: () => boolean;
    inputStyle?: React.CSSProperties;
    setValueHandler?: (e: any) => void;
    notFoundMessage?: string;
  }

  // Define the custom ref type
export type TypeAheadUserListRef = {
    resetSearchQuery: () => void;
  };
  
const TypeAheadUserList = forwardRef<TypeAheadUserListRef, TypeAheadUserListProps>(
(props, ref) => {
    const {    
      label,
      name,   
    //   value,
    //     options, 
        setValueHandler ,
        handleTextFieldChange,
        notFoundMessage,
         
      } = props;

      let searchTimer: any = null;
     // const [userId, setUserId] = React.useState("");
      const [dataFiltered, setDataFiltered] = useState<any[]>([]);
      const [display, setDisplay] = React.useState("none");    
      const [searchQuery, setSearchQuery] = useState<string>("");   
      const [textFieldStyle, setTextFieldStyle] = useState<React.CSSProperties>({ fontWeight: 400 });
    
    // Attach the resetSearchQuery function to the ref
    useImperativeHandle(ref, () => ({
        resetSearchQuery
    }));
    // Function to reset the search query
    const resetSearchQuery = () => {
        setSearchQuery("");
    };
    const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
             try {

            const textFieldSearchQuery = e.target?.value ? e.target?.value : "";
            setSearchQuery(textFieldSearchQuery);
            setTextFieldStyle({ fontWeight: 400 });
            if (
              textFieldSearchQuery.length >= UserSearchSetting.userSearchMinLength
            ) {
              clearTimeout(searchTimer);
              searchTimer = setTimeout(async () => {
                const appUsersSearchDataObject: any = await getSearchUsers(
                  textFieldSearchQuery
                );
                let dataFiltered =await appUsersSearchDataObject.data;
              
                const notSeeOptionExists = dataFiltered?.some((item: { userNumber: string | undefined; }) => item.userNumber === notFoundMessage);
                  if (!notSeeOptionExists) {
                    dataFiltered.push({
                          "userId": "notFound",
                          "firstName": null,
                          "lastName": null,
                          "userNumber": notFoundMessage,
                                               
                      })
                  }
                  setDataFiltered(dataFiltered);                
                if (dataFiltered) {
                  //setUserId("");
                  setDataFiltered(dataFiltered);
                  if (textFieldSearchQuery) {
                    setDisplay("block");
                    setSearchQuery(e.target.value);
                  } else {
                    setSearchQuery("");
                    setDisplay("none");
                  }
                  if (dataFiltered.length === 0) {
                    setDisplay("none");
                  // setUserId("");
                  }
                } else {
                  setDisplay("none");
                  //setUserId("");
                  setDataFiltered([]);
                }
              }, UserSearchSetting.userSearchDebounceTime);
            } else {
              clearTimeout(searchTimer);
              setDataFiltered([]);
              setDisplay("none");
             // setUserId("");
            }
            handleTextFieldChange && handleTextFieldChange(e);
          } catch (ex) {
            // console.log(ex);
          }
  };
  const SetValueHandler = (e: any) => {
      setSearchQuery(e.target.innerText);
      const selectedItem = dataFiltered.find((item) => item.userId === e.target.id);
      //setId(e.target.id);  
     // setUserId(e.target.id);    
      setDisplay("none");
      if (e.target.innerText === notFoundMessage) {                
          setTextFieldStyle({ fontWeight: 600 });      }
      else {         
          setTextFieldStyle({ fontWeight: 400 });
      }
      if(setValueHandler)
      setValueHandler(selectedItem);
      
  };

  return (
<>
 <LabelTextField
     displayName={label}
                    name={name}
                    onTextChange={onTextChange}
                    value={searchQuery}
                    TextFieldStyle={textFieldStyle}                    
                />
                <div style={{ padding: 3, display: display,borderRadius: 20,border: "1px solid #cccc",}}>
                    <ul
                        style={{
                            listStyle: "none",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            height: " 104px",
                            scrollbarWidth: "thin",
                            margin: "6px 0px 6px 0px",
                        }}
                    >
                        {dataFiltered.map((dataFiltered: any) => {
                            // Calculate the fontWeight value based on the condition for each item
                            const fontWeightValue = dataFiltered.userId === "notFound" ? 600 : 400;
                            return (
                                <li
                                    className="text"
                                    style={{
                                        padding: 5,
                                        justifyContent: "normal",
                                        fontSize: 16,
                                        color: "#000000",
                                        fontWeight: fontWeightValue,
                                        margin: 1,
                                        width: "100%",
                                        borderWidth: "10px",
                                        cursor: "pointer",
                                    }}
                                    key={dataFiltered.userId}
                                >
                                    <div                                       
                                        id={dataFiltered.userId}
                                        onClick={(e) => SetValueHandler(e)}
                                        style={{ color: "#000000" }}
                                    >   
                                       {
                                       dataFiltered.userId!=="notFound"?
                                       dataFiltered.firstName +
                              " " +
                              dataFiltered.lastName +
                              " (" +
                              dataFiltered.userNumber +
                              ")":dataFiltered.userNumber
                        }
                                    </div>
                                </li>
                            )
                        }
                        )
                        }
                    </ul>
                </div>
</>   
  );
});

export default TypeAheadUserList;