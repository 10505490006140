import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { useEffect, useRef } from "react";
import $ from "jquery";
import { localization } from "survey-creator-react";
import { Serializer, matrixDropdownColumnTypes } from "survey-core";

import { settings } from "survey-creator-core";
import "survey-creator-core/survey-creator-core.css";
import { registerHeadingQuestion } from "./types/heading/QuestionHeading";
import { registerParagraphQuestion } from "./types/QuestionParagraph";
import { scrollToTop } from "../../../helpers/ui-util";

//remove properties from the survey creator

// Serializer.removeProperty("panelbase", "visibleIf");
// Serializer.removeProperty("panelbase", "questionTitleLocation");
// Serializer.removeProperty("question", "visibleIf");
// Serializer.removeProperty("question", "popupdescription");
// Serializer.removeProperty("question", "popupTitle");
// Serializer.removeProperty("question", "popupWidth");
// Serializer.removeProperty("question", "popupHeight");
// Serializer.removeProperty("question", "popupDescription");
// Serializer.removeProperty("question", "popupdescription");
// Serializer.removeProperty("question", "popupdescription");
// Serializer.removeProperty("question", "popupdescription");

const translations = localization.getLocale("");

export interface QuestionnaireCreatorProps {
  creator: SurveyCreator;
  propertyStopList: string[];
}
const allowedTypes = [
  "text",
  "checkbox",
  "radiogroup",
  "comment",
  "dropdown",
  "tagbox",
];

const columnTypes: { [key: string]: any } = matrixDropdownColumnTypes;
// Use type assertion to tell TypeScript that matrixDropdownColumnTypes has an index signatureconst columnTypes: { [key: string]: any } = matrixDropdownColumnTypes;

Object.keys(columnTypes).forEach((key) => {
  if (!allowedTypes.includes(key)) {
    delete columnTypes[key];
  }
});

// rename various surveyjs questions to the ones we want to use
translations.ed.surveyPlaceHolder =
  "Start building activity by clicking the button below.";
//translations.qt.boolean = "Yes/No";
translations.qt.checkbox = "Multi Select";
translations.qt.radiogroup = "Single Select";
translations.qt.file = "Upload File";
translations.qt.matrix = "Single Select Horizontal View";
translations.qt.matrixdropdown = "Multi Select Horizontal View";
translations.qt.matrixdynamic = "Dynamic Table";
translations.qt.text = "Short Text";
translations.qt.comment = "Long Text";
translations.pe.tabs.logic = "Conditions";
translations.pe.rowCount = "Number of rows by default";
translations.qt.dropdown = "Dropdown Single Select"; //"Physician Group"
translations.qt.tagbox = "Dropdown Multi Select"; //"Multi-Select Drop Down"

//console.log(translations);
registerHeadingQuestion();
registerParagraphQuestion();

(settings.toolbox.defaultJSON as any).matrixdynamic = {
  cellType: "text",
};

(settings.toolbox.defaultJSON as any).matrixdropdown = {
  cellType: "checkbox",
};

function QuestionnaireCreatorComponent(props: QuestionnaireCreatorProps) {
  const surveyCreator = props.creator;

  surveyCreator.showSurveyTitle = false;

  // surveyCreator.toolbox.orderedQuestions = [
  //   "radiogroup",
  //   "checkbox",
  //   "matrix",
  //   "matrixdropdown",
  //   "matrixdynamic",
  //   "text",
  //   "comment",
  //   "file",
  //   "dropdown",
  // ];

  //surveyCreator.toolbar//['radiogroup', 'rating', 'checkbox', 'dropdown', 'tagbox', 'boolean', 'file', 'imagepicker', 'ranking', 'text', 'comment', 'multipletext', 'panel', 'paneldynamic', 'matrix', 'matrixdropdown', 'matrixdynamic', 'html', 'expression', 'image', 'signaturepad']

  // Serializer.removeProperty("panelbase", "navigateToUrl");
  // Serializer.removeProperty("panelbase", "showCompletedPage");
  // Serializer.removeProperty("panelbase", "completedHtml");
  // Serializer.removeProperty("panelbase", "navigateToUrlOnCondition");
  //  Serializer.removeProperty("panelbase", "expression");
  //  Serializer.removeProperty("panelbase", "tagbox");
  // Serializer.removeProperty("panelbase", "url");
  // Serializer.removeProperty("panelbase", "completedHtmlOnCondition");
  // Serializer.removeProperty("panelbase", "expression");
  // Serializer.removeProperty("panelbase", "html");
  // Serializer.removeProperty("panelbase", "loadingHtml");
  // Serializer.removeProperty("panelbase", "completedBeforeHtml");
  // Serializer.removeProperty("panelbase", "maxTimeToFinish");
  // Serializer.removeProperty("panelbase", "maxTimeToFinishPage");
  // Serializer.removeProperty("panelbase", "showTimerPanel");
  // Serializer.removeProperty("panelbase", "showTimerPanelMode");
  //console.log(Serializer);
  //Set Cell Type to text for all matrix questions
  //settings.matrixDefaultCellType="checkbox";

  //Add a tag property
  Serializer.addProperty("question", { name: "tag", category: "general" });
  //Make name and tag properties read-only
  Serializer.findProperty("question", "name").readOnly = true;
  Serializer.findProperty("question", "tag").readOnly = true;
  // hide the properties for the heading question
  Serializer.findProperty("Heading", "header").placeholder =
    "Enter Header Text";
  Serializer.getProperty("Heading", "title").visible = false;
  Serializer.getProperty("Heading", "description").visible = false;
  Serializer.getProperty("Heading", "isRequired").visible = false;
  Serializer.getProperty("Heading", "readOnly").visible = false;
  Serializer.getProperty("Heading", "visibleIf").visible = true;
  Serializer.getProperty("Heading", "enableIf").visible = false;
  Serializer.getProperty("Heading", "requiredIf").visible = false;

  // Serializer.findProperty("matrixdropdowncolumn", "cellType").setChoices( ["default", "checkbox"]);
  // Serializer.findProperty("matrixdynamic", "cellType").setChoices( ["default", "text"]);
  // Serializer.findProperty("matrixdropdown", "cellType").setChoices( ["default", "checkbox"]);
  const propertiesToShowInSurvey = [
    "questionTitleLocation",
    "questionTitlePattern",
  ];
  function guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  }
  var questionCounter = 1;

  surveyCreator.onQuestionAdded.add(function (sender, options) {
    var q = options.question;
    var qType = q.getType();
    //console.log("QuestionType" + qType);
    //Set the Question name to the time stamp etc...
    q.name = "Question_" + Math.floor(Date.now() / 1000);
    switch (qType) {
      case "comment": //Long Text
        q.autoGrow = true;
        break;
      case "file":
        q.allowMultiple = true;
        q.allowImagesPreview = false;
        q.showPreview = true;
        q.storeDataAsText = false;
        q.maxSize = 104857600;
        q.waitForUpload = true;
        q.needConfirmRemoveFile = true;
        q.acceptedTypes = [
          "image/gif",
          "image/jpg",
          "image/jpeg",
          "image/png",
          "image/svg",
          "image/tif",
          "image/tiff",
          "image/x-tiff",
          "image.heic", //blank file type
          "image/svg+xml",
          "text/csv",
          "application/pdf",
          "application/rtf",
          "application/doc",
          "application/docx",
          "application/xls",
          "application/xlsx",
          "application/odt",
          "application/zip",
          "application/ppt",
          "application/pptx",
          "application/vnd.oasis.opendocument.text", //odt
          "application/msword", //rtf
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel", //xls
          "application/epub+zip", //epub
          "text/plain",
          "application/epub",
          "application/mobi", //blank file type
          "application/mus",
          "application/musx",
          "application/sib",
          "application/key",
          "application/x-zip-compressed",
        ].join("|");
        break;
      case "matrixdynamic":
        q.minRowCount = "1";
        q.maxRowCount = "1000";
        q.confirmDelete = true;
        q.confirmDeleteText = "Are you sure you want to delete this?";
        q.hideColumnsIfEmpty = false;
        // q.alternateRows=true;
        break;
      case "matrix":
        // q.alternateRows=true;
        q.isAllRowRequired = true;
        break;
      // case 'dropdown':
      //     q.choicesByUrl.url=`${process.env.REACT_APP_API_URL}PhysicianGroup/Public`;
      //     q.choicesByUrl.valueName="name";
      //     //remove suggestions
      //     q.showOptionsCaption = false;
      //     //remove default choices
      //     q.choices = null;
      //     break;
      default:
      // code block
    }
    q.tag = guid();
    questionCounter++;
  });

  //Hide properties contained in the stop list, show all other properties

  surveyCreator.onShowingProperty.add(function (sender, options) {
    //console.log(options.property.name);
    // console.log("onShowingProperty", options.obj.getType());
    if (options.obj.getType() !== "survey")
      options.canShow =
        props.propertyStopList.indexOf(options.property.name) === -1;
    switch (options.obj.getType()) {
      case "comment":
        if (options.property.name === "rows") {
          options.canShow = false;
        }
        break;
      case "matrix":
        // code block
        if (options.property.name === "visibleIf") {
          options.canShow = false;
        }
        break;
      case "matrixdropdown": //Multi Select Horizontal View
        if (options.property.name === "visibleIf") {
          options.canShow = false;
        }

        break;
      case "matrixdynamic": //Dynamic Table
        // code block
        if (options.property.name === "visibleIf") {
          options.canShow = false;
        }

        break;

      case "dropdown": //Multi Select Horizontal View
        // code block
        if (options.property.name === "choices") {
          options.canShow = true;
        }
        break;
      case "tagbox": //Multi Select Horizontal View
        // code block
        if (options.property.name === "choices") {
          options.canShow = true;
        }
        break;
      default:
      // code block
    }

    // if(options.obj.getType()==='matrixdynamic')
    //   {  if(options.property.name==="cellType")
    //     {
    //       // options.property.choicesValue =options.property.choices.filter((choice: string) => ( choice !== 'rating' && choice !== 'expression' &&
    //       // choice !== 'tagbox' && choice!=='boolean'
    //       //   ));
    //        // const choices: string []=[ 'checkbox', 'radiogroup', 'text', 'comment','dropdown']

    //         // const choices: string []=[  'text','checkbox', 'radiogroup', 'comment']
    //         // options.property.choicesValue =choices
    //        // options.property.defaultCellType='text';
    //        //settings.matrixDefaultCellType="text";

    //     }
    //   }
    if (
      options.obj.getType() === "matrixdropdown" &&
      options.property.name === "cellType"
    ) {
      options.canShow = false;
    }

    if (
      options.obj.getType() === "matrixdropdowncolumn" &&
      options.property.name === "showOtherItem"
    ) {
      options.canShow = true;
    }
    if (
      options.obj.getType() === "matrixdropdowncolumn" &&
      options.property.name === "showNoneItem"
    ) {
      options.canShow = true;
    }

    if (
      options.obj.getType() === "matrixdropdowncolumn" &&
      options.property.name === "cellType"
    ) {
      options.canShow = false;
      (settings.toolbox.defaultJSON as any).matrixdropdowncolumn = {
        cellType: "checkbox",
      };
      if (
        options?.obj?.templateQuestionValue?.loadingOwner?.colOwnerValue
          ?.classMetaData?.name === "matrixdynamic"
      ) {
        options.canShow = true;

        (settings.toolbox.defaultJSON as any).matrixdropdowncolumn = {
          cellType: "text",
        };
      }
    }
  });

  const currentSurveyElementRef = useRef<any>(null);

  function saveSurvey(survey: any, options: any) {
    //send Ajax request to your web server.
    //console.log("saveMySurvey", surveyCreator.JSON);
    //props.onQuestionnaireChanged(surveyCreator.JSON);
    //console.log(surveyCreator.currentPage.questions[3].qid);
    //setSurveyText(surveyCreator.text);
    //alert("The survey is saved. " + JSON.stringify(survey));
  }

  function getCreaterElement() {
    return currentSurveyElementRef.current?.rootNode?.current;
  }

  function setSurveyCreatorHeight() {
    let svcCreator = $(getCreaterElement());
    if (svcCreator === null || svcCreator.length === 0) return;
    if (surveyCreator.JSON.elements && surveyCreator.JSON.elements.length > 0) {
      svcCreator.css("height", "100%");
    } else {
      svcCreator.css("height", "40vh");
    }
    svcCreator.css("min-height", "300px");
  }

  useEffect(() => {
    setSurveyCreatorHeight();
    return () => {
      currentSurveyElementRef.current = null;
    };
  }, []);

  surveyCreator.saveSurveyFunc = saveSurvey;
  // surveyCreator.showSurveyTitle = false;
  // surveyCreator.showEmbeddedSurveyTab = false;
  // surveyCreator.pageEditMode = "single"; //show only one page at a time
  // surveyCreator.text = JSON.stringify(defaultJson);
  //surveyCreator.JSON = props.questionnaireJSON;
  // surveyCreator.onQuestionAdded.add(function (survey: any, options: any) {
  //  // console.log("onQuestionAdded");
  //   let svcCreator = $(getCreaterElement());
  //   svcCreator.css("height", "100%");
  // });

  //remove dynamic panel question type from the toolbox of the survey creator
  surveyCreator.toolbox.removeItem("paneldynamic");
  surveyCreator.toolbox.removeItem("rating");
  surveyCreator.toolbox.removeItem("expression");
  surveyCreator.toolbox.removeItem("imagepicker");
  surveyCreator.toolbox.removeItem("image");
  surveyCreator.toolbox.removeItem("html");
  surveyCreator.toolbox.removeItem("ranking");
  //surveyCreator.toolbox.removeItem("dropdown");
  surveyCreator.toolbox.removeItem("signaturepad");
  surveyCreator.toolbox.removeItem("multipletext");
  //surveyCreator.toolbox.removeItem("tagbox"); MultiSelect DropDown
  surveyCreator.toolbox.removeItem("boolean");
  surveyCreator.toolbox.removeItem("panel");

  //surveyCreator.toolbox.getItemByName("dropdown").json.choices = [];

  surveyCreator.onQuestionAdded.add(function (survey: any, options: any) {
    //console.log("onQuestionAdded");
    // add a new index property to the question
    // console.log("called onQuestionAdded");
    options.question["index"] = options.page.questions.length;
  });

  surveyCreator.survey.onQuestionRemoved.add(function (
    survey: any,
    options: any
  ) {
    // console.log("called onQuestionRemoved");
    setSurveyCreatorHeight();
  });
  // This for Add Question Button in the Survey Creator
  surveyCreator.onElementAllowOperations.add(function (_, options) {
    options.allowEdit = true; //show settings
  });

  // surveyCreator.onDefineElementMenuItems.add((_, options) => {
  //   //console.log("called onDefineElementMenuItems");
  //   if (options.name === 'name' && options.obj.getType() === 'radiogroup') {
  //     // Update the 'value' property to match the 'name'
  //     options.obj.choices.forEach((choice: { value: any; name: any; }) => {
  //         choice.value = choice.name;
  //     });
  // }

  // });

  // surveyCreator.onSurveyPropertyValueChanged.add((_sender, options) => {
  //   console.log("onSurveyPropertyValueChanged");
  // });

  surveyCreator.survey.onAfterRenderQuestion.add(function (
    survey: any,
    options: any
  ) {
    //console.log("onAfterRenderQuestion");
    let svcCreator = $(getCreaterElement());
    if (svcCreator === null || svcCreator.length === 0) return;
    svcCreator.find(".svc-tab-designer_content").css("max-width", "100%");
    svcCreator
      .find(".sd-container-modern")
      .css("max-width", "100%")
      .css("width", "100%");
    svcCreator.css("height", "100%");
  });

  surveyCreator.onShowSidebarVisibilityChanged.add((_, options) => {
    //This is to scroll to the top of the sidebar when click on the settings button
    if (options?.show === true) {
      scrollToTop("svc-side-bar", getCreaterElement());
    }
  });

  return (
    <SurveyCreatorComponent
      ref={currentSurveyElementRef}
      creator={surveyCreator}
    />
  );
}

export default QuestionnaireCreatorComponent;
