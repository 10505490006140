export function getSelectedInitiatives(siteEnrollments: any[]) {
  let selectedInitiativeIds: any[] = [];
  //get the initiativeIds from first siteEnrollments siteInitiativeEnrollments
  var firstSiteEnrollments = siteEnrollments[0].siteEnrollments;
  selectedInitiativeIds =
    getInitiativeIdsFromSiteEnrollment(firstSiteEnrollments);
  return selectedInitiativeIds;
}

export function getInitiativeIdsFromSiteEnrollment(siteEnrollment: any) {
  let initiativeIds: any[] = [];
  if (siteEnrollment != null) {
    siteEnrollment.forEach((element: any) => {
      element.siteInitiativeEnrollments.forEach((element: any) => {
        initiativeIds.push(element.initiativeId);
      });
    });
  }
  return initiativeIds;
}

// export function isSameInitiativeEnrolled(siteEnrollments: any[]) {
//   var initiativeIdsToCheck: any[] = [];
//   //get the initiativeIds from first siteEnrollments siteInitiativeEnrollments
//   var firstSiteEnrollments = siteEnrollments[0].siteEnrollments;
//   initiativeIdsToCheck =
//     getInitiativeIdsFromSiteEnrollment(firstSiteEnrollments);

//   //convert above foreach logic to for loop
//   for (let i = 0; i < siteEnrollments.length; i++) {
//     const siteEnrollment = siteEnrollments[i];
//     for (let j = 0; j < siteEnrollment.siteEnrollments.length; j++) {
//       let matchedCount = 0;
//       const siteInitiativeEnrollment =
//         siteEnrollment.siteEnrollments[j].siteInitiativeEnrollments;
//       for (let k = 0; k < siteInitiativeEnrollment.length; k++) {
//         const initiativeId = siteInitiativeEnrollment[k].initiativeId;
//         if (!initiativeIdsToCheck.includes(initiativeId)) {
//           return false;
//         } else {
//           matchedCount++;
//         }
//       }
//       if (matchedCount != initiativeIdsToCheck.length) {
//         return false;
//       }
//     }
//   }

//   return true;
// }

export function getFilteredEnrollmentData(
  siteIdsToCheck: any[],
  sitesEnrollmentData: any
) {
  var enrollmentData = sitesEnrollmentData;
  // return all the matching site ids from the enrollment data
  var filteredEnrollmentData = enrollmentData.siteInitiativeEnrollments.filter((element: any) => {
    const siteId = element.siteId;
    return siteIdsToCheck.includes(siteId);
  }); 
  return filteredEnrollmentData;
}

export function isSameInitiativeEnrolled(firstSiteInitiaveEnrollments: any[], secondSiteInitiaveEnrollments: any[]) {
  var initiativeIdsToCheck = new Array();
  if (firstSiteInitiaveEnrollments != null && firstSiteInitiaveEnrollments.length > 0) {
    firstSiteInitiaveEnrollments.forEach((element: any) => {
      initiativeIdsToCheck.push(element.initiativeId);
    });
  }

  //convert above foreach logic to for loop
  for (let i = 0; i < secondSiteInitiaveEnrollments.length; i++) {
    const siteInitiativeEnrollment = secondSiteInitiaveEnrollments[i];
    let matchedCount = 0;
    const initiativeId = siteInitiativeEnrollment.initiativeId;
    if (!initiativeIdsToCheck.includes(initiativeId)) {
      return false;
    } else {
      matchedCount++;
    }

    if (matchedCount != initiativeIdsToCheck.length) {
      return false;
    }
  }
  return true;
}

export function getEnrolledInitiativesForSite(enrollmentData: any, siteId: any) {
  var filteredInitiativeEnrollment = enrollmentData.siteInitiativeEnrollments.filter((element: any) => {

    return element.siteId === siteId;
  });
  return filteredInitiativeEnrollment;
}

//Check if the selected sites has different initiative enrolled

export function openInitiativesEnrolledForSite(enrollmentData: any, siteId: any) {
  var filteredInitiativeEnrollment = enrollmentData.siteInitiativeEnrollments.filter((element: any) => {
    var result = false;
    if (element.siteId === siteId && element.initiativeId != null) {
      var openInitiativeEnrolled = enrollmentData.openInitiatives
        .filter((openInitiative: any) => {
          return openInitiative.id === element.initiativeId;
        });
      if (openInitiativeEnrolled.length > 0) {
        result = true;
      }
    }
    return result;
  });

  return filteredInitiativeEnrollment;
}

export function mapSiteChampionEnrollmentData(enrollmentData: any): any[] {
  //var openInitiatives = enrollmentData.openInitiatives;
  var siteInitiativeEnrollments = enrollmentData.siteInitiativeEnrollments;
  var sites = enrollmentData.sites;
  var isSiteEnrolledToOpenInitiative = false;
  var isSiteFilledQuestionnaire = false;
  let enrollData: any[] = [];

  for (let i = 0; i < sites.length; i++) {
    var currentSite = sites[i];
    isSiteEnrolledToOpenInitiative = false;
    isSiteFilledQuestionnaire = false;
    var enrolledInitiatives = siteInitiativeEnrollments.filter((element: any) => {
      return element.siteId === currentSite.id;
    });

    // var enrolledInitiatives = siteInitiativeEnrollments
    //   .find((siteInitiativeEnrollment: any) =>
    //     siteInitiativeEnrollment.siteId === currentSite.id);
    var openEnrolledInitiatives = openInitiativesEnrolledForSite(enrollmentData, currentSite.id);
    var initiativeNames = new Array();
    var isSiteEnrolledtoOpenInitiative = false;
    var isSiteFilledQuestionnaire = false;
    initiativeNames = [];
    var initiativeIds = new Array();
    initiativeIds = [];
    if (openEnrolledInitiatives.length > 0) {
      isSiteEnrolledToOpenInitiative = true;
      isSiteFilledQuestionnaire = openEnrolledInitiatives[0].siteEnrollmentQuestionaireId != null;
      openEnrolledInitiatives.forEach((element: any) => {
        //push unique openEnrolledInitiatives
        if (!initiativeIds.includes(element.initiativeId)) {
          isSiteEnrolledtoOpenInitiative = true;
          isSiteFilledQuestionnaire =
            openEnrolledInitiatives[0].siteEnrollmentQuestionaireId !=
            null;
          initiativeIds.push(element.initiativeId);
          initiativeNames.push(element.initiativeName);
        }
      });

    }
    var enrollmentStatus = "";
    if (
      isSiteEnrolledToOpenInitiative === true &&
      isSiteFilledQuestionnaire === true
    ) {
      enrollmentStatus = "Enrolled";
    } else if (isSiteEnrolledToOpenInitiative === true) {
      enrollmentStatus = "Pending";
    } else {
      enrollmentStatus = "";
    }

    var siteEnrollmentData = {
      id: currentSite.id,
      siteName: currentSite.siteName,
      acepSiteNumber: enrolledInitiatives.length > 0 ? enrolledInitiatives[0].acepSiteNumber : null,
      physicianGroupId: currentSite.physicianGroupId,
      physicianGroupName: currentSite.physicianGroupName,

      siteEnrollmentId: openEnrolledInitiatives.length > 0 ?
        openEnrolledInitiatives[0].siteEnrollmentId : enrolledInitiatives.length > 0 ?
          enrolledInitiatives[0].siteEnrollmentId : null,

      siteEnrollmentStatusId: openEnrolledInitiatives.length > 0 ?
        openEnrolledInitiatives[0].siteEnrollmentStatusId :
        enrolledInitiatives.length > 0 ?
          enrolledInitiatives[0].siteEnrollmentStatusId : null,

      siteEnrollmentStatusName: openEnrolledInitiatives.length > 0 ?
        openEnrolledInitiatives[0].siteEnrollmentStatusName : enrolledInitiatives.length > 0 ?
          enrolledInitiatives[0].siteEnrollmentStatusName : "",

      initiativeNames: initiativeNames,

      siteEnrollmentQuestionaireId: openEnrolledInitiatives.length > 0 ?
        openEnrolledInitiatives[0].siteEnrollmentQuestionaireId :
        enrolledInitiatives.length > 0 ?
          enrolledInitiatives[0].siteEnrollmentQuestionaireId : null,
      enrollmentId: openEnrolledInitiatives.length > 0 ?
        openEnrolledInitiatives[0].enrollmentId :
        enrolledInitiatives.length > 0 ?
          enrolledInitiatives[0].enrollmentId : null,
    }
    siteEnrollmentData.siteEnrollmentStatusName = enrollmentStatus;
    enrollData.push(siteEnrollmentData);
  }
  return enrollData;
}


export function checkInitiativeEnrolled(
  siteIdsToCheck: any[],
  sitesEnrollmentData: any
) {
  if (siteIdsToCheck.length === 1) {
    return true; // no need to check anything
  }
  // if more than one site selected
  var firstSiteOpenInitiatives = openInitiativesEnrolledForSite(sitesEnrollmentData, siteIdsToCheck[0]);
  for (let i = 0; i < siteIdsToCheck.length; i++) {
    var selectedSiteOpenInitiatives = openInitiativesEnrolledForSite(sitesEnrollmentData, siteIdsToCheck[i]);
    if (firstSiteOpenInitiatives == null && selectedSiteOpenInitiatives != null) {
      return false; //mismatch
    }
    if (selectedSiteOpenInitiatives == null && firstSiteOpenInitiatives != null) {
      return false;
    }
    if (firstSiteOpenInitiatives.length == 0 && selectedSiteOpenInitiatives.length > 0) {
      return false; //mismatch
    }

    //Check if the selectedSiteOpenInitiatives is same as firstSiteOpenInitiatives
    if (firstSiteOpenInitiatives != null && selectedSiteOpenInitiatives != null) {
      if (firstSiteOpenInitiatives.length != selectedSiteOpenInitiatives.length) {
        return false;
      }
      var sameEnrolled = isSameInitiativeEnrolled(firstSiteOpenInitiatives, selectedSiteOpenInitiatives);
      if (!sameEnrolled) {
        return false;
      }
    }
  }
  return true;
}


export function checkQuestionnaireToFilled(siteIdsToCheck: any[],
  sitesEnrollmentData: any) {

  if (siteIdsToCheck.length === 1) {
    return true; // no need to check anything
  }

  if (siteIdsToCheck.length > 1) {
    var firstSiteEnrollmentQuestionaireId = null;
    var firstSiteEnrollmentData = getEnrolledInitiativesForSite(sitesEnrollmentData, siteIdsToCheck[0]);

    if (firstSiteEnrollmentData != null && firstSiteEnrollmentData.length > 0) {
      firstSiteEnrollmentQuestionaireId = firstSiteEnrollmentData[0].siteEnrollmentQuestionaireId;
    }

    for (var i = 1; i < siteIdsToCheck.length; i++) {
      var siteId = siteIdsToCheck[i];
      var siteEnrollmentData = getEnrolledInitiativesForSite(sitesEnrollmentData, siteId);
      if (siteEnrollmentData != null && siteEnrollmentData.length > 0) {
        var siteEnrollmentQuestionaireId = siteEnrollmentData[0].siteEnrollmentQuestionaireId;
        if (siteEnrollmentQuestionaireId != firstSiteEnrollmentQuestionaireId) {
          return false;
        }
      }
    }
  }
  return true;

}