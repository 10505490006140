import React, { createContext, useEffect } from "react";
import jwt from "jwt-decode";
//import ContactContextProvider from "./contactContext";
import {
  AuthorizationCredentials,
  ACEPGetContactDetailsAPIURL,
} from "../config/config";
import axios from "axios";
import PromiseContextProvider from "./promise-context";
import WaitIndicator from "../components/atoms/WaitIndicator";

//'Context/UserContextProvider' is used to get the authenticated users ACEPId and segregate that application wide or across components
export const authContext = createContext({
  User: {
    name: "",
    acepid: "",
    city: "",
    state: "",
    country: "",
    isAdmin: false,
    firstName: "",
    lastName: "",
    formalName: "",
    acepExpirationDate: "",
    acepMemberStatus: 0,
    acepMemberType: "",
    acepMemberRate: "",
    sempaExpirationDate: "",
    sempaMemberStatus: 0,
    sempaMemberType: "",
    sempaMemberRate: "",
    ecmeCatalog: "",
    email2: "",
    address1: "",
    address2: "",
    phone: "",
    priceLevel: "",
    isValidUser: false,
    isOnAutoRenewal: false,
    loginUI_ExpiryDate: "",
    loginUI_Join: "",
    avatar:""
  },
});
export default function AuthContextProvider(props: any) {
  const [loadPage, setLoadPage] = React.useState(false);
  const [userObject, setUserObject] = React.useState({
    User: {
      name: "",
      acepid: "",
      city: "",
      state: "",
      country: "",
      isAdmin: false,
      firstName: "",
      lastName: "",
      formalName: "",
      acepExpirationDate: "",
      acepMemberStatus: 0,
      acepMemberType: "",
      acepMemberRate: "",
      sempaExpirationDate: "",
      sempaMemberStatus: 0,
      sempaMemberType: "",
      sempaMemberRate: "",
      ecmeCatalog: "",
      email2: "",
      address1: "",
      address2: "",
      phone: "",
      priceLevel: "",
      isValidUser: false,
      isOnAutoRenewal: false,
      loginUI_ExpiryDate: "",
      loginUI_Join: "",
      avatar:""
    },
  });

  useEffect(() => {
    const user_info: IACEPTokenInfo = localStorage.getItem("ACEPUSERINFO")
      ? jwt(String(localStorage.getItem("ACEPUSERINFO")))
      : {
          jti: "",
          sub: "",
          email: "",
          given_name: "",
          family_name: "",
          exp: 0,
          iss: "",
          aud: "",
        };

    if (user_info && user_info.sub !== "") {
      const UserD = {
        User: {
          name: user_info.given_name,
          acepid: user_info.sub,
          city: "",
          state: "",
          country: "",
          isAdmin: false,
          firstName: "",
          lastName: "",
          formalName: "",
          acepExpirationDate: "",
          acepMemberStatus: 0,
          acepMemberType: "",
          acepMemberRate: "",
          sempaExpirationDate: "",
          sempaMemberStatus: 0,
          sempaMemberType: "",
          sempaMemberRate: "",
          ecmeCatalog: "",
          email2: "",
          address1: "",
          address2: "",
          phone: "",
          priceLevel: "",
          isValidUser: false,
          isOnAutoRenewal: false,
          loginUI_ExpiryDate: "",
          loginUI_Join: "",
          avatar:""
        },
      };

      var config = {
        method: "get",
        url: ACEPGetContactDetailsAPIURL + UserD.User.acepid,
        headers: {
          Authorization: AuthorizationCredentials,
        },
      };

      axios(config).then(function (response: any) {
        const res: any = response.data;
        UserD.User.city = res.city;
        UserD.User.state = res.stateProvince;
        UserD.User.country = res.country;
        UserD.User.firstName = res.firstName;
        UserD.User.lastName = res.lastName;
        UserD.User.formalName = res.formalName;
        UserD.User.acepExpirationDate = res.acepExpirationDate;
        UserD.User.acepMemberStatus = Number(res.acepMemberStatus);
        UserD.User.acepMemberType = res.acepMemberType;
        UserD.User.acepMemberRate = res.acepMemberRate;
        UserD.User.sempaExpirationDate = res.sempaExpirationDate;
        UserD.User.sempaMemberStatus = res.sempaMemberStatus;
        UserD.User.sempaMemberType = res.sempaMemberType;
        UserD.User.sempaMemberRate = res.sempaMemberRate;
        UserD.User.ecmeCatalog = res.ecmeCatalog;
        UserD.User.email2 = res.email2;
        UserD.User.address1 = res.address1;
        UserD.User.address2 = res.address2;
        UserD.User.phone = res.phone;
        UserD.User.priceLevel = res.priceLevel;
        UserD.User.isValidUser = res.isValidUser;
        UserD.User.isOnAutoRenewal = res.isOnAutoRenewal;
        UserD.User.loginUI_ExpiryDate = res.loginUI_ExpiryDate;
        UserD.User.loginUI_Join = res.loginUI_Join;
        UserD.User.avatar = res.avatar
        
        setUserObject(UserD);
        setLoadPage(true);
      });
    }
  }, [loadPage]);

  return (
    <div>
      {loadPage ? (
        <>
          <authContext.Provider value={userObject}>
            {props.children}
            <PromiseContextProvider />
          </authContext.Provider>
        </>
      ) : (
        <WaitIndicator show={true} />
      )}
    </div>
  );
}
