import { getUIFormattedDate } from "../../../../helpers/ui-util";
import ReactDataTable from "../../../atoms/tables/ReactDataTable";
import InitiativeActivityListItemStatus from "./InitiativeActivityListItemStatus";
import classes from "./InitiativeActivityList.module.css";
import { InitiativeActivity } from "../../../../models/Initiative";

export interface InitiativeActivityListProps {
  initiativeActivities: InitiativeActivity[];
}

function InitiativeActivityList(props: InitiativeActivityListProps) {
  const { initiativeActivities } = props;

  if (!initiativeActivities || initiativeActivities.length === 0) {
    return (
      <div className={classes.noDataFound}>
        <p>No Activities Found!</p>
      </div>
    );
  }

  initiativeActivities.forEach((activity) => {
    activity.startDate = getUIFormattedDate(activity.startDate);
    activity.endDate = getUIFormattedDate(activity.endDate);
  });

  const columns = [
    {
      name: "Activity",
      selector: (row: InitiativeActivity) => row.name,
      sortable: true,
      width: "31%",
      adjustWidthOnSmallerDevice: true,
    },
    {
      name: "Start Date",
      selector: (row: InitiativeActivity) => row.startDate,
      width: "160px",
      showOnMobileDevice: false,
      showOnTabletDevice: false,
    },
    {
      name: "End Date",
      selector: (row: InitiativeActivity) => row.endDate,
      width: "160px",
      showOnMobileDevice: false,
      showOnTabletDevice: false,
    },
    {
      name: "Status",
      selector: (row: InitiativeActivity) => (
        <InitiativeActivityListItemStatus
          activityStatus={row.activityStatus.name}
        />
      ),
       
    },
  ];

  const dataTableStyles = {
    headRow: {
      border: "none",
      display: "none",
    },
    rows: {
      paddingLeft: "50px",
      borderBottom: "none!important",
    },
  };
  return (
    <ReactDataTable
      columns={columns}
      data={initiativeActivities}
      dataTableStyles={dataTableStyles}
      showHeadRow={false}
    />
  );
}

export default InitiativeActivityList;
