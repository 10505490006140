import { Card, CardContent, CardMedia, Box, Tooltip } from "@mui/material";
import { useState } from "react";
import useAppData from "../../../hooks/useAppData";
import { getOpenInitiatives } from "../../../helpers/api-util";
import { mapinitiativesCardData } from "./InitiativesCard";
import WaitIndicator from "../../atoms/WaitIndicator";
import classes from "./InitiativesCardView.module.css";
import { handleErrorReponse } from "../../../helpers/ui-util";

export interface InitiativesCardProps {
  onCardSelect: (id: string) => void;
}
const InitiativesCardView = ({ onCardSelect }: InitiativesCardProps) => {
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [refreshData, setrefreshData] = useState(false);

  const { appData, loading, error } = useAppData(async () => {
    return await getOpenInitiatives();
  }, refreshData);
  const mappedData: InitiativesCard[] = mapinitiativesCardData(appData);

  if (loading) {
    return <WaitIndicator show={true} />;
  }
  if (error) {
    return handleErrorReponse(error);
  }

  const handleCardClick = (id: string) => {
    if (selectedCard === id) {
      // If the clicked card is already selected, deselect it
      setSelectedCard(null);
      onCardSelect(""); // Clear the selectedInitiativeId in the parent component
    } else {
      // If the clicked card is not selected, select it
      setSelectedCard(id);
      onCardSelect(id); // Pass the selected initiative ID to the parent component
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        gap: "16px",
      }}
    >
      {mappedData.map((item) => (
        <Card
          key={item.name}
          onClick={() => handleCardClick(item.id)}
          sx={{
            flexBasis: "100%", // Default flexBasis for xs and sm screens (1 card per row)
            maxWidth: {
              xs: "100%", // 1 card per row on xs 0-600px
              sm: "100%", // 1 card per row on sm 600-900px
              md: "calc(50% - 16px)", // 2 cards per row on md 900-1200px
              lg: "calc(33.33% - 16px)", // 3 cards per row on lg 1200-1536px
              xl: "calc(33.33% - 16px)", // 3 cards per row on xl screens (1536px and above)
            },
            width: "100%",
            minHeight: "150px",
            borderRadius: "16px",
            cursor: "pointer",
            borderColor: selectedCard === item.id ? "#1976D2" : "#DDDDDD",
            backgroundColor:
              selectedCard === item.id ? "#005EB833!important" : "#FFFFFF",
            boxShadow:
              selectedCard === item.id
                ? "0 0 0 2px #1976D2"
                : "0 0 0 1px #DDDDDD",
            "&:hover": {
              borderColor: "#1976D2",
              boxShadow: "0 0 0 2px #1976D2",
              backgroundColor: "#FFFFFF",
            },
            position: "relative",
            overflow: "hidden",
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div className={classes.icon}>
                {item.logo && item.logo.trim() !== "" && (
                  <CardMedia
                    component="img"
                    image={item.logo}
                    sx={{
                      width: "64px",
                      height: "69px",
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                    }}
                  />
                )}
              </div>
              <div className={classes.LongTitle}>{item.name}</div>
              {item.description.length > 150 ? (
                <Tooltip title={item.description} placement="top" arrow>
                  <div className={classes.LongSummary}>
                    {`${item.description.slice(0, 150)}...`}
                  </div>
                </Tooltip>
              ) : (
                <div className={classes.LongSummary}>{item.description}</div>
              )}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default InitiativesCardView;
