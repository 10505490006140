import { Alert, Snackbar } from "@mui/material";
import { useContext } from "react";
import AdminContext from "../../../store/admin-context";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

interface ErrorAlertProps {
  children?: React.ReactNode;
}
function ErrorAlert(props: ErrorAlertProps) {
  const adminContext = useContext(AdminContext);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={adminContext.isError}
      autoHideDuration={3000}
      onClose={() => adminContext.onError(false)}
    >
      <Alert
        severity="error"
        onClose={() => adminContext.onError(false)}
        iconMapping={{ error: <CancelOutlinedIcon /> }}
        sx={{
          // Here you can apply custom styles using sx prop
          "& .MuiAlert-action ": {
            color: " #C71F1F", // Change the color to red
          },
          "& .MuiAlert-message ": {
            color: "#C71F1F", // Change the color to red
          },
        }}
      >
        {props.children
          ? props.children
          : adminContext.errorMessage
          ? adminContext.errorMessage
          : "Something went Wrong,Please retry!"}
      </Alert>
    </Snackbar>
  );
}
export default ErrorAlert;
