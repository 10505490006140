import React from 'react';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import RoundedButton from '../atoms/formElements/RoundedButton';
import { useNavigate } from "react-router-dom";
import { Typography } from '@mui/material';

const NoDataComponent: React.FC = () => {
    const navigate = useNavigate();
    const initiativeEnrollmentClickHandler = (_event: any) => {
        navigate("/champion/enrollment");
      }
    return (
        <div 
          style={{
                   display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    marginTop:"108px",
        }}>
            <HomeWorkOutlinedIcon sx={{ color: "#A3A3A3", minWidth: "104px", minHeight: "104px" }} />
            <Typography sx={{marginTop:"60px", marginBottom:"10px", textAlign:"center"}}>There are no participating sites.</Typography>
            <Typography sx={{ marginBottom:"40px"}}>Please enroll a site to an initiative to get started.</Typography>
            <RoundedButton 
              name="btnInitiative"
              text="Initiative Enrollment"
              onClick={initiativeEnrollmentClickHandler}
            />
        </div>
    );
};

export default NoDataComponent;
