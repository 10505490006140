import { useRef, useState, useContext, useEffect } from 'react';
import { authContext } from "../store/auth-context" 
import { env } from '../config/config';

function AcepMaster(props) {
  const acepMaster = useRef();
  const [ssoUser, setSSOUser] = useState(null);
  const { onAcepMasterLoad } = props; // Callback function prop

  let userInfo = useContext(authContext);

  useEffect(() => {
    const currentAcepMaster = acepMaster.current;
    var _ssoUser = null;
    var obj = {
      firstName: userInfo?.User?.firstName,
      acepid: userInfo?.User?.acepid,
      loginUI_ExpiryDate: userInfo?.User?.loginUI_ExpiryDate,
      loginUI_Join: userInfo?.User?.loginUI_Join,
      avatar: userInfo?.User?.avatar
    };

    _ssoUser = JSON.stringify(obj);
    setSSOUser(_ssoUser);

    // Call the callback function to notify that AcepMaster is loaded
    if (onAcepMasterLoad) {
      onAcepMasterLoad();
    }

    currentAcepMaster.addEventListener("logout", logout);

    return () => {
      currentAcepMaster.removeEventListener("logout", logout);
    };

  }, [onAcepMasterLoad, userInfo]);

  function logout() {
    //This is called before WC's logout method is called which takes care of SLO
    console.log("L O G O U T called");
    localStorage.clear();
    setSSOUser(null);
  }

  return (
    <acep-master
      ref={acepMaster}
      env={env===""?"prod":env}
      title="E-Qual Initiative Portal"
      ssouser={ssoUser}
    >
      <div slot="bodySlot">{props.children}</div>
    </acep-master>
  );
}

export default AcepMaster;
