import { useContext, useState, useRef } from "react";
import { getQuestionnaireData } from "../../../helpers/api-util";
import useAppData from "../../../hooks/useAppData";
import classes from "./Questionnaire.module.css";
import QuestionnaireAction from "./QuestionnaireAction";
import QuestionnaireCreatorComponent from "./QuestionnaireCreatorComponent";
import { updateQuestionnaireData } from "../../../helpers/api-util";
import { SurveyCreator } from "survey-creator-react";
import AdminContext from "../../../store/admin-context";
import questionOptions from "../../../store/questionOptions.json";
import { Serializer } from "survey-core";
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";
import { registerHeadingQuestion } from "./types/heading/QuestionHeading";
import { registerParagraphQuestion } from "./types/QuestionParagraph";
import { handleErrorReponse, scrollToTop } from "../../../helpers/ui-util";

export interface QuestionnaireProps {
  activityId: string;
  cancelOnClick: (data: any) => void;
  saveOnClick: (data: any) => void;
}

const creatorOptions = questionOptions.creatorOptions;
const surveyPropertiesRemoveList = questionOptions.surveyPropertiesRemoveList;

const propertyStopList = questionOptions.propertyStopList;

function removeSurveyProperties(propertiesToRemove: string[]) {
  propertiesToRemove.forEach((propertyName) => {
    Serializer.removeProperty("survey", propertyName);
  });
  Serializer.getProperty("survey", "progressBarType").visible = false;
  Serializer.getProperty("survey", "locale").visible = false;
  Serializer.getProperty("survey", "questionsOnPageMode").visible = false;
  Serializer.getProperty("survey", "questionTitleLocation").visible = false;
}

function Questionnaire(props: QuestionnaireProps) {
  //loop into surveyPropertiesRemoveList and remove the properties from survey
  removeSurveyProperties(surveyPropertiesRemoveList);
  const surveyCreator = new SurveyCreator(creatorOptions);
  const adminContext = useContext(AdminContext);
  const [refreshData, setRefreshData] = useState(false);

  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);

  surveyCreator.showSurveyTitle = false;
  surveyCreator.showEmbeddedSurveyTab = false;
  surveyCreator.pageEditMode = "single"; //show only one page at a time

  surveyCreator.getOptions().questionTypes = [
    "Heading",
    "Paragraph",
    "text",
    "matrix",
    "radiogroup",
    "rating",
    "checkbox",
    "tagbox",
    "boolean",
    "file",
    "imagepicker",
    "ranking",
    "comment",
    "multipletext",
    "panel",
    "paneldynamic",
    "matrixdropdown",
    "matrixdynamic",
    "html",
    "expression",
    "image",
    "signaturepad",
    "dropdown",
  ];
  surveyCreator.toolbox.orderedQuestions = [
    "Heading",
    "Paragraph",
    "text",
    "matrix",
    "radiogroup",
    "rating",
    "checkbox",
    "boolean",
    "file",
    "imagepicker",
    "ranking",
    "comment",
    "multipletext",
    "panel",
    "paneldynamic",
    "matrixdropdown",
    "matrixdynamic",
    "html",
    "expression",
    "image",
    "signaturepad",
    "dropdown",
    "tagbox",
  ];
  //surveyCreator.JSON = {};
  registerHeadingQuestion();
  registerParagraphQuestion();

  const { activityId, cancelOnClick } = props;

  const { appData, loading, error } = useAppData(async () => {
    return await getQuestionnaireData(activityId);
  }, refreshData);

  async function saveOnClickHandler() {
    // send the questionnaireJSON to the server with the activityId
    try {
      waitIndicatorLoaderRef.current?.show();
      const questionnaireJSON = surveyCreator.JSON;
      questionnaireJSON.widthMode = "responsive";
      const response = await updateQuestionnaireData(
        activityId,
        questionnaireJSON
      );

      if (response.data != null) {
        adminContext.onRecordSave(true, "Questionnaire Saved");
        props.saveOnClick(response.data);
        setRefreshData(!refreshData);
        scrollToTop();
      }
      if (response.error != null) {
        var status = response.error?.response?.status;
        if (status === 400) {
          adminContext.onError(true, "Bad Request");
          waitIndicatorLoaderRef.current?.hide();
          scrollToTop();
          return;
        }
        adminContext.onError(true, "Error Saving Questionnaire Information!");
        waitIndicatorLoaderRef.current?.hide();
        scrollToTop();
        return;
      }
      waitIndicatorLoaderRef.current?.hide();
    } catch (ex) {
      // console.log(ex);
      waitIndicatorLoaderRef.current?.hide();
      scrollToTop();
    }
  }

  if (!activityId) {
    return <p>Activity key not found! </p>;
  }

  if (loading) {
    return <WaitIndicator show={true} />;
  }
  if (error) {
    return handleErrorReponse(error);
  }

  if (appData) {
    surveyCreator.JSON = {
      ...appData,
      widthMode: "responsive",
    };
  } else {
    surveyCreator.JSON = {
      widthMode: "responsive",
    };
  }

  return (
    <div className={classes.container}>
      <QuestionnaireCreatorComponent
        creator={surveyCreator}
        propertyStopList={propertyStopList}
      />
      <QuestionnaireAction
        cancelOnClick={cancelOnClick}
        saveOnClick={saveOnClickHandler}
        saveDisabled={false}
        cancelDisabled={false}
      />
      <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
    </div>
  );
}

export default Questionnaire;
