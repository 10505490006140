import PerformanceValidationHome from '../../../components/champion/PerformanceValidationHome';

function PerformanceValidationPage() {
  return (
    <div>
      <PerformanceValidationHome/>
    </div>
  );
}

export default PerformanceValidationPage;