//require("dotenv").config();

//Here urls will be differernt for dev, test and prod
//SPA(dev) https://devsignin.acep.org/     
//SPA(test) https://testsignin.acep.org/    
//SPA(prod) https://signin.acep.org/


// const API_URL  = 'https://cmeapiapplication-dev.azurewebsites.net/v1/api'; 
// export const CMEDashboardURL = 'https://cmeuiapplication.azurewebsites.net/';
const API_URL = 'https://acep-scus-prd-equal-api.azurewebsites.net/api/v1'
export const EQUAL_UI_DashboardURL = 'https://equal.acep.org/';

//const API_URL = 'https://localhost:7226/api'
//export const EQUAL_UI_DashboardURL = 'http://localhost:3000/';

// const API_URL  = 'https://cme-api-test-t1.azurewebsites.net/v1/api'; 
// export const CMEDashboardURL = 'https://cme-test.azurewebsites.net/';

//based on this variable, dev, test or prod, we will handle the subsequence URL
export const env = ''

//here, we will also add/handle the URL based on the Organizatins like ACEP or SEMPA or EMRA
export const LOGIN_URL = 'https://' + env + 'signin.acep.org/spapi/Saml/InitiateSingleSignOn?returnurl='
export const LOGOUT_URL = 'https://' + env + 'signin.acep.org/spapi/Saml/InitiateSingleLogout?returnurl='

export const ACEPAuthorizatioinAPIURL = 'https://' + env + 'signin.acep.org/spapi/api/Authorization'
export const ACEPGetContactDetailsAPIURL = 'https://' + env + 'ws.acep.org/aceptoolkit/api/2015/contactdetailsbyid?acepid='


export const ACEPAccountURL = 'https://' + env + 'webapps.acep.org/membership/dashboard/';
export const ACEPTrackerURL = 'http://' + env + 'webapps.acep.org/cmetracker/Default.aspx';
export const ACEPLogoURL = 'https://www.acep.org/globalassets/sites/acep/media/global/acep-logo3x.png';
export const AcepHeaderLogo_RedirectURL = "https://www.acep.org/"
export const MyCME_RedirectURL = "https://cmetracker.acep.org"
export const DefaultNoImageURL = "https://s3.amazonaws.com/user-portraits/none.jpg"
export const AuthorizationCredentials = "Basic Y21lbWFuYWdlbWVudDpQRXh3R0xSdw=="

export const ACEPJoinRenewURL = "http://" + env + "webapps.acep.org/memberapplication"

export const ACEPHeaderEclipsePhotoURL = "https://s3.amazonaws.com/AcepPortfolio/"

export const ACEPOpportunitiesLink = 'https://www.acep.org/education/cme/';

//Report URL's
export const ACEPReportURL = 'https://' + env + 'ws.acep.org/AcepToolkit/api/2022/report'

export const ACEPChatBotURL = 'https://www.acep.org/Static/Common/js/external/liveperson.js'

export const Token_Header_Key = 'Authorization';
export default API_URL;

