import ManageChampionsHome from "../../../components/champion/ManageChampionsHome";

function ManageChampionsHomePage() {
  return (
    <>
      <ManageChampionsHome />
    </>
  );
}

export default ManageChampionsHomePage;