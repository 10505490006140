import {
  Home,
  HomeOutlined,
  PeopleAltOutlined,
  SimCardDownloadOutlined,
} from "@mui/icons-material";
import AddTaskIcon from "@mui/icons-material/AddTask";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import Sidebar from "../atoms/sidebar/Sidebar";
import SidebarItem from "../atoms/sidebar/SidebarItem";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { useLocation } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import classes from "./ChampionSidebar.module.css";
import { useContext, useEffect, useState } from "react";
import AdminContext from "../../store/admin-context";
import useAppData from "../../hooks/useAppData";
import {
  getInitiativesEnrollments,
  getPerformanceValidationActivities,
} from "../../helpers/api-util";
import { scrollToTop } from "../../helpers/ui-util";

export default function ChampionSidebar() {
  const location = useLocation();
  const path = location.pathname;
  const adminContext = useContext(AdminContext);
  const [refreshData, setrefreshData] = useState(false);  

  const initiativesEnrollmentData = useAppData(async () => {
    return await getInitiativesEnrollments();
  }, refreshData);


  const initiativeAndActivitiesData = useAppData(async () => {
    return await getPerformanceValidationActivities();
  }, refreshData);

  useEffect(() => {   
   
    if (initiativesEnrollmentData?.appData?.length > 0) {
      var initiativesFound = {
        initiativeFound: true,
      };
      adminContext.setRecordsFound(initiativesFound);
    }

    if (initiativeAndActivitiesData?.appData?.length > 0) {
      var recordsFound = {
        performanceValidationEnabled: true,
      };
      adminContext.setRecordsFound(recordsFound);
    }
  }, [initiativesEnrollmentData, initiativeAndActivitiesData,refreshData]);
  
  return (
    <Sidebar>
      <div className={classes.borderLine}>
        <SidebarItem
          to=""
          isActive={path === "/champion"}
          activeIcon={<Home />}
          pendingIcon={<HomeOutlined />}
          text="Home"
        />
        <SidebarItem
          to="enrollment"
          isActive={path === "/champion/enrollment"}
          activeIcon={<AddTaskIcon />}
          pendingIcon={<AddTaskIcon />}
          text="Enrollment"
        />
        <SidebarItem
          to="initiatives"
          isActive={
            path === "/champion/initiatives" ||
            path === "/champion/initiative-questionnaires"
          }
          activeIcon={<StarIcon />}
          pendingIcon={<StarOutlineIcon />}
          text="Initiatives"
          disabled={!adminContext.recordsFound.initiativeFound}
        />
        <SidebarItem
          to="performance-validation"
          isActive={path === "/champion/performance-validation"}
          activeIcon={<SimCardDownloadIcon />}
          pendingIcon={<SimCardDownloadOutlined />}
          text="Performance Validation"
          disabled={!adminContext.recordsFound.performanceValidationEnabled}
        />
        <SidebarItem
          to="manage-champions"
          isActive={path === "/champion/manage-champions"}
          activeIcon={<PeopleAltIcon />}
          pendingIcon={<PeopleAltOutlined />}
          text="Manage Champions"
        />
      </div>
    </Sidebar>
  );
}
