import { Card, CardContent } from "@mui/material";
import classes from "./SiteInitiativesHome.module.css";
import SiteInitiativesGridView from "./SiteInitiativesGridView";
import { mapSiteInitiativesGridData } from "./SiteInitiativesGrid";
import { getInitiativesEnrollments } from "../../../../helpers/api-util";
import useAppData from "../../../../hooks/useAppData";
import WaitIndicator from "../../../atoms/WaitIndicator";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleErrorReponse } from "../../../../helpers/ui-util";
import {
  mapInitiativesGridData,
  filterOpenToActivityData,
} from "../../initiatives/InitiativesGrid";

function SiteInitiativesHome() {
  const [refreshData, setrefreshData] = useState(false);
  const navigate = useNavigate();

  const { appData, loading, error } = useAppData(async () => {
    return await getInitiativesEnrollments();
  }, refreshData);

  var totalMappedData: InitiativesEnrollment[] =
    mapInitiativesGridData(appData);
  totalMappedData = filterOpenToActivityData(totalMappedData);

  var mappedNotStartedData: InitiativesEnrollment[] =
    mapSiteInitiativesGridData(appData, "Not Started");
  mappedNotStartedData = filterOpenToActivityData(mappedNotStartedData);
  var mappedInProgressData: InitiativesEnrollment[] =
    mapSiteInitiativesGridData(appData, "In Progress");
  mappedInProgressData = filterOpenToActivityData(mappedInProgressData);
  const handleViewAllClick = (activity: InitiativesEnrollment) => {
    navigate("/champion/initiatives", {
      replace: true,
    });
  };
  if (loading) {
    return <WaitIndicator show={true} />;
  } else if (error) {
    const errorJSX = handleErrorReponse(error);
    return <>{errorJSX}</>;
  }
  const hasNotStartedData = mappedNotStartedData.length > 0;
  const hasInProgressData = mappedInProgressData.length > 0;

  const cardWidth = hasNotStartedData && hasInProgressData ? "50%" : "100%";
  const marginLeftStyle =
    cardWidth === "100%" ? { marginLeft: 0 } : { marginLeft: "20px!important" };

    const getMaxWidth = (cardWidth:string) => {
      return cardWidth === "100%"
        ? {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "100%",
            xl: "100%",
          }
        : {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "calc(50% - 10px)",
            xl: "calc(50% - 10px)",
          };
    };

  return (
    <div className={classes.siteInitiativeGridcontainer}>
      {hasNotStartedData && (
        <Card 
        sx={{
          flexBasis: "100%", // Default flexBasis for xs and sm screens (1 card per row)
          maxWidth: getMaxWidth(cardWidth),
                 
       
        }}
        className={classes.card} 
        style={{ width: cardWidth, maxWidth:cardWidth === "100%" ? '100%':'' }}>
          <CardContent className={classes.container}>
            <div className={classes.LongTitle}>Not Started</div>
            <div>
              <SiteInitiativesGridView
                data={mappedNotStartedData}
                noDataMessage="You do not have any not started sites enrolled."
                onViewAllClick={handleViewAllClick}
                siteInitiativeColdWidth={
                  cardWidth === "50%" ? "46%" : "46%"
                }
                totalMappedData={totalMappedData}
              />
            </div>
          </CardContent>
        </Card>
      )}
      {hasInProgressData && (
        <Card
          className={classes.card}
          sx={{
            
            flexBasis: "100%", // Default flexBasis for xs and sm screens (1 card per row)
            maxWidth: getMaxWidth(cardWidth),                 
         
          }}
          
        >
          <CardContent className={classes.container}>
            <div className={classes.LongTitle}>In Progress</div>
            <div>
              <SiteInitiativesGridView
                data={mappedInProgressData}
                noDataMessage="You do not have any in progress sites enrolled."
                onViewAllClick={handleViewAllClick}
                siteInitiativeColdWidth={
                  cardWidth === "50%" ? "46%" : "46%"
                }
                totalMappedData={totalMappedData}
              />
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

export default SiteInitiativesHome;
