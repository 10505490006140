import React, { useState } from "react";
import { getFormattedDate } from "../helpers/ui-util";

const AdminContext = React.createContext({
  initiative: {
    id: "",
    initiativeEndDate: "",
    initiativeActivities: [
      {
        hasQnDefinitions: false,
      },
    ],
  },
  setInitiativeId: (initiativeId: string) => {},
  setEnrollmentId: (enrollmentId: string) => {},
  initiativeId: "",
  enrollmentId: "",
  updateInitiative: (initiative: any) => {},
  isSaved: false,
  successMessage: "",
  isError: false,
  errorMessage: "",
  onError: (value: boolean, message?: string) => {},
  onRecordSave: (value: boolean, message?: string) => {},
  getInitiativeEndDate: () => {},
  recordsFound: {
    initiativeFound: false,
    enrollmentFound: false,
    performanceValidationEnabled: false,
  },
  setRecordsFound: (value: any) => {},
});

export const AdminContextProvider = (props: any) => {
  const initialState = {
    id: "",
    initiativeEndDate: "",
    initiativeActivities: [],
  };
  const [initiative, setInitiative] = useState(initialState);
  const [isSaved, setIsSaved] = useState(false);
  const [savedMessage, setSavedMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [initiativeId, setInitiativeId] = useState("");
  const [enrollmentId, setEnrollmentId] = useState("");
  const [recordsFound, setRecordsFound] = useState({
    initiativeFound: false,
    enrollmentFound: false,
    performanceValidationEnabled: false,
  });

  function updateInitiativeId(initiativeId: string) {
    setInitiativeId(initiativeId);
  }

  function updateEnrollmentId(enrollmentId: string) {
    setEnrollmentId(enrollmentId);
  }

  const updateInitiative = (initiative: any) => {
    setInitiative(initiative);
  };
  const updateRecordsFound = (value: any) => {
    setRecordsFound((prevRecordsFound: any) => {
      var newRecordsFound = { ...prevRecordsFound, ...value };
      // compare the newRecordsFound with the previous value and set the state
      if (
        JSON.stringify(newRecordsFound) !== JSON.stringify(prevRecordsFound)
      ) {
        return {
          ...prevRecordsFound,
          ...value,
        };
      } else {
        return prevRecordsFound;
      }
    });
  };

  function onSaved(value: boolean, message: string = "") {
    setSavedMessage(message);
    setIsSaved(value);
  }
  function onError(value: boolean, message: string = "") {
    setError(message);
    setIsError(value);
  }

  function getInitiativeEndDate() {
    return getFormattedDate(initiative.initiativeEndDate);
  }

  return (
    <AdminContext.Provider
      value={{
        initiative: initiative,
        initiativeId: initiativeId,
        enrollmentId: enrollmentId,
        setEnrollmentId: updateEnrollmentId,
        setInitiativeId: updateInitiativeId,
        updateInitiative: updateInitiative,
        isSaved: isSaved,
        successMessage: savedMessage,
        isError: isError,
        onError: onError,
        errorMessage: error,
        onRecordSave: onSaved,
        getInitiativeEndDate: getInitiativeEndDate,
        recordsFound: recordsFound,
        setRecordsFound: updateRecordsFound,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
