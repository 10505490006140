import { useRef, useState } from "react";
import {
  getPerformanceValidationActivities,
  getPerformanceValidationSiteData,
} from "../../../helpers/api-util";
import useAppData from "../../../hooks/useAppData";
import WaitIndicator from "../../atoms/WaitIndicator";
import { MenuItem, Select } from "@mui/material";
import {
  getActivitiesWithEarliestStartDate,
  getInitiativeWithEarliestStartDate,
  mapPerformanceInitiativesData,
} from "../../common/PerformanceValidationOptions";
import classes from "./PerformanceValidationView.module.css";
import {
  MappedInitiative,
  Activity,
} from "../../common/PerformanceValidationOptions";
import PerformanceValidationUploader, {
  PerformanceValidationUploaderRef,
} from "./PerformanceValidationUploader";
import RoundedButton from "../../atoms/formElements/RoundedButton";
import PerformanceValidationTable from "./PerformanceValidationTable";

export interface Props {
  selectedActivityId: string;
  onActivityChange: (activityId: string) => void;
}
function PerformanceValidationFilterOption({
  selectedActivityId,
  onActivityChange,
}: Props) {
  const [selectedInitiative, setSelectedInitiative] = useState<string>("");
  const [selectedActivity, setSelectedActivity] = useState<string>("");
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [sitesCompleted, setSitesCompleted] = useState<boolean>(false);
  const perfValidationUplaodPopupRef =
    useRef<PerformanceValidationUploaderRef>(null);

  function disableUploadFunction(show: boolean) {
    setSitesCompleted(show);
  }

  const initiativeAndActivitiesData = useAppData(async () => {
    return await getPerformanceValidationActivities();
  }, refreshData);

  if (initiativeAndActivitiesData?.loading) {
    return (
      <div>
        <WaitIndicator show={true}></WaitIndicator>
      </div>
    );
  }

  if (initiativeAndActivitiesData?.error) {
    return <div>Some Error Occurred!</div>;
  }

  const initiatives = mapPerformanceInitiativesData(
    initiativeAndActivitiesData.appData
  );

  const earliestInitiative: MappedInitiative[] | null =
    getInitiativeWithEarliestStartDate(initiatives);

  const earliestActivity: Activity[] | null =
    getActivitiesWithEarliestStartDate(
      selectedInitiative || (earliestInitiative && earliestInitiative[0]?.id),
      initiatives
    );

  const handleInitiativeChange = (event: any) => {
    const selectedInitiativeId = event.target.value as string;
    setSelectedInitiative(selectedInitiativeId);

    const selectedInitiatives = initiatives.find(
      (initiative) => initiative.id === selectedInitiativeId
    );

    if (selectedInitiatives) {
      setSelectedActivity(selectedInitiatives.activityDetails[0].activityId);
    }
  };

  const getActivitiesForSelectedInitiative = (
    InitiativeSelected: any
  ): Activity[] | null => {
    const selectInitiative = initiatives.find(
      (initiative) => initiative.id === InitiativeSelected
    );
    return selectInitiative?.activityDetails || null;
  };

  const handleActivityChange = (event: any) => {
    const selectedActivityId = event.target.value as string;
    setSelectedActivity(selectedActivityId);
    onActivityChange(selectedActivityId);
  };

  const getSelectedInitiative = () => {
    return (
      selectedInitiative || (earliestInitiative && earliestInitiative[0].id)
    );
  };

  const activitiesForSelectedInitiative = getActivitiesForSelectedInitiative(
    selectedInitiative || (earliestInitiative && earliestInitiative[0].id)
  );

  const getSelectedActivity = () => {
    return (
      selectedActivity || (earliestActivity && earliestActivity[0]?.activityId)
    );
  };

  const openPerformanceValidationUploader = () => {
    // get selected Initiative and Activity
    var selectedInitiative: any = initiatives.find(
      (initiative) => initiative.id === getSelectedInitiative()
    );
    if (!selectedInitiative) {
      return;
    }
    var selectedActivity: Activity | undefined =
      activitiesForSelectedInitiative?.find(
        (activity) => activity.activityId === getSelectedActivity()
      );
    if (!selectedActivity) {
      return;
    }
    //var containerName = selectedInitiative.year;
    //var initiativeName = sanatizeFolderName(selectedInitiative.name);
    //var activityName = sanatizeFolderName(selectedActivity.activityName);
    //var folderStructure = `${initiativeName}/${activityName}`;

    perfValidationUplaodPopupRef.current?.init(
      selectedInitiative.id,
      selectedActivity.activityId
    );
    // perfValidationUplaodPopupRef.current?.setUploadLocation(
    //   containerName,
    //   folderStructure
    // );

    perfValidationUplaodPopupRef.current?.openDialog();
  };

  const onPerformanceValidationUploaded = () => {
    setRefreshData(!refreshData);
  };

  const generateRandomKey = () => {
    return Math.random().toString(36).substring(7);
  };

  return (
    <div>
      <div className={classes.filterContainer}>
        {initiativeAndActivitiesData.appData.length > 0 ? (
          <Select
            value={
              selectedInitiative ||
              (earliestInitiative && earliestInitiative[0].id)
            }
            onChange={handleInitiativeChange}
            MenuProps={{
              PaperProps: {
                elevation: 0,
              },
            }}
            className={classes.select}
          >
            {earliestInitiative &&
              earliestInitiative.map((initiative) => (
                <MenuItem
                  key={initiative.id}
                  value={initiative.id}
                  className={classes.menuItem}
                >
                  {initiative.name}
                </MenuItem>
              ))}
          </Select>
        ) : (
          <div>
            <Select
              value=""
              disabled
              className={classes.select}
              sx={{ minWidth: "225px" }}
            >
              <MenuItem disabled />
            </Select>
          </div>
        )}
        {initiativeAndActivitiesData.appData.length > 0 ? (
          <Select
            value={
              selectedActivity ||
              (earliestActivity && earliestActivity[0]?.activityId)
            }
            onChange={handleActivityChange}
            MenuProps={{
              PaperProps: {
                elevation: 0,
              },
            }}
            className={classes.select}
            sx={{ marginLeft: "10px", minWidth: "225px!important" }}
          >
            {activitiesForSelectedInitiative &&
              activitiesForSelectedInitiative.map((activity) => (
                <MenuItem
                  key={activity.activityId}
                  value={activity.activityId}
                  className={classes.menuItem}
                >
                  {activity.activityName}
                </MenuItem>
              ))}
          </Select>
        ) : (
          <div>
            <Select
              value=""
              disabled
              className={classes.select}
              sx={{ marginLeft: "10px", minWidth: "300px!important" }}
            >
              <MenuItem disabled />
            </Select>
          </div>
        )}
        <div style={{ marginLeft: "10px" }}>
          <RoundedButton
            name="btnUpload"
            text="Upload"
            onClick={openPerformanceValidationUploader}
            disabled={
              initiativeAndActivitiesData.appData.length === 0 || sitesCompleted
            }
          />
        </div>
      </div>
      <div className={classes.tableContainer}>
        <PerformanceValidationTable
          activityData={
            selectedActivity ||
            (earliestActivity && earliestActivity[0]?.activityId)
          }
          disableUpload={disableUploadFunction}
        />
      </div>
      <PerformanceValidationUploader
        key={generateRandomKey()}
        ref={perfValidationUplaodPopupRef}
        onUploadComplete={onPerformanceValidationUploaded}
      />
    </div>
  );
}

export default PerformanceValidationFilterOption;
