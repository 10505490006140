import { NavLink } from "react-router-dom";
import cssClasses from "./SidebarItem.module.css";

function SidebarItem(props: any) {
  const sidebarItemClasses = `${cssClasses.sidebarItem} ${
    props?.disabled
      ? cssClasses.sidebarItemDisabled
      : cssClasses.sidebarItemEnabled
  }`;
  return (
    <>
      <NavLink
        to={props.to}
        className={sidebarItemClasses}
        style={{
          backgroundColor: props.isActive ? "#FFFFFF" : "inherit",
        }}
      >
        <div
          className={cssClasses.sidebarItemIcon}
          style={{
            color: props.isActive ? "#2569af" : "inherit",
          }}
        >
          {props.isActive ? props.activeIcon : props.pendingIcon}
        </div>
        <div
          className={cssClasses.sideItemText}
          style={{
            color: props.isActive ? "#2569af" : "inherit",
          }}
        >
          {props.text}
        </div>
      </NavLink>
    </>
  );
}

export default SidebarItem;
