export const getAdminEnrollSiteColumns = (otherColumns?: any) => {
  const columns = [
    {
      name: "Site",
      selector: (row: { site: string; }) => row.site,
      sortable: true
    },
    {
      name: "Physician Group",
      selector: (row: { physician_group: string; }) => row.physician_group,
      sortable: true
    },
  ]
  if (otherColumns) {
    columns.push(...otherColumns);
  }
  return columns;
}