import InitiativesHome from "../../../components/champion/InitiativesHome";

function InitiativesHomePage(){
    return (
       <>
       <InitiativesHome/>
       </>
    );
}

export default InitiativesHomePage;