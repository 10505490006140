import { MenuItem, Select, Grid } from "@mui/material";
import classes from "./PerformanceValidationView.module.css";
import { useContext, useRef, useState } from "react";
import RoundedButton from "../../atoms/formElements/RoundedButton";
import useAppData from "../../../hooks/useAppData";
import {
  downloadPerformanceValidationReports,
  getPerformanceValidationActivities,
} from "../../../helpers/api-util";
import {
  MappedInitiative,
  getActivitiesWithEarliestStartDate,
  getInitiativeWithEarliestStartDate,
  mapPerformanceInitiativesData,
  Activity,
} from "../../common/PerformanceValidationOptions";
import PerformanceValidationTable from "./PerformanceValidationTable";
import WaitIndicator, { WaitIndicatorRef } from "../../atoms/WaitIndicator";

export interface Props {
  onActivityChange: (activityId: string) => void;
}

export default function PerformanceValidationView({ onActivityChange }: Props) {
  const [selectedInitiative, setSelectedInitiative] = useState<string>("");
  const [selectedActivity, setSelectedActivity] = useState<string>("");
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [recordsFound, setRecordsFound] = useState<boolean>(false);
  const waitIndicatorLoaderRef = useRef<WaitIndicatorRef>(null);

  // Get INITIATIVE and ACTIVITIES dropdown data
  const initiativeAndActivitiesData = useAppData(async () => {
    return await getPerformanceValidationActivities();
  }, refreshData);

  if (initiativeAndActivitiesData?.loading) {
    return (
      <div>
        <WaitIndicator show={true}></WaitIndicator>
      </div>
    );
  }

  if (initiativeAndActivitiesData?.error) {
    return <div>Some Error Occurred!</div>;
  }

  const initiatives = mapPerformanceInitiativesData(
    initiativeAndActivitiesData.appData
  );

  const earliestInitiative: MappedInitiative[] | null =
    getInitiativeWithEarliestStartDate(initiatives);

  const earliestActivity: Activity[] | null =
    getActivitiesWithEarliestStartDate(
      selectedInitiative || (earliestInitiative && earliestInitiative[0]?.id),
      initiatives
    );

  const handleInitiativeChange = (event: any) => {
    const selectedInitiativeId = event.target.value as string;
    setSelectedInitiative(selectedInitiativeId);

    const selectedInitiatives = initiatives.find(
      (initiative) => initiative.id === selectedInitiativeId
    );

    if (selectedInitiatives) {
      setSelectedActivity(selectedInitiatives.activityDetails[0].activityId);
    }
  };

  const getActivitiesForSelectedInitiative = (
    InitiativeSelected: any
  ): Activity[] | null => {
    const selectInitiative = initiatives.find(
      (initiative) => initiative.id === InitiativeSelected
    );
    var activityDetails = selectInitiative?.activityDetails;
    return activityDetails || null;
  };

  const handleActivityChange = (event: any) => {
    const selectedActivityId = event.target.value as string;
    setSelectedActivity(selectedActivityId);
    onActivityChange(selectedActivityId);
  };

  const getSelectedInitiative = () => {
    return (
      selectedInitiative || (earliestInitiative && earliestInitiative[0].id)
    );
  };

  const activitiesForSelectedInitiative = getActivitiesForSelectedInitiative(
    selectedInitiative || (earliestInitiative && earliestInitiative[0].id)
  );

  const getSelectedActivity = () => {
    return (
      selectedActivity || (earliestActivity && earliestActivity[0]?.activityId)
    );
  };

  async function downloadAllButtonClickHandler() {
    try {
      var selectedInitiativeId = getSelectedInitiative();
      var selectedActivityId = getSelectedActivity();
      if (selectedActivityId === "") {
        return;
      }
      if (selectedInitiativeId === "") {
        return;
      }

      waitIndicatorLoaderRef.current?.show();

      var done = await downloadPerformanceValidationReports(
        selectedInitiativeId ? selectedInitiativeId : "",
        selectedActivityId ? selectedActivityId : ""
      );
      if (done) {
        waitIndicatorLoaderRef.current?.hide();
      }
    } catch (ex) {
      waitIndicatorLoaderRef.current?.hide();
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.container}>
          {initiativeAndActivitiesData.appData.length > 0 ? (
            <Select
              value={
                selectedInitiative ||
                (earliestInitiative && earliestInitiative[0].id)
              }
              onChange={handleInitiativeChange}
              MenuProps={{
                PaperProps: {
                  elevation: 0,
                },
              }}
              className={classes.select}
            >
              {earliestInitiative &&
                earliestInitiative.map((initiative) => (
                  <MenuItem
                    key={initiative.id}
                    value={initiative.id}
                    className={classes.menuItem}
                  >
                    {initiative.name}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <div>
              <Select
                value=""
                disabled
                className={classes.select}
                sx={{ minWidth: "220px" }}
              >
                <MenuItem disabled />
              </Select>
            </div>
          )}
          {initiativeAndActivitiesData.appData.length > 0 ? (
            <Select
              value={
                selectedActivity ||
                (earliestActivity && earliestActivity[0]?.activityId)
              }
              onChange={handleActivityChange}
              MenuProps={{
                PaperProps: {
                  elevation: 0,
                },
              }}
              className={classes.select}
              sx={{ marginLeft: "2px", minWidth: "250px!important" }}
            >
              {activitiesForSelectedInitiative &&
                activitiesForSelectedInitiative.map((activity) => (
                  <MenuItem
                    key={activity.activityId}
                    value={activity.activityId}
                    className={classes.menuItem}
                  >
                    {activity.activityName}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <div>
              <Select
                value=""
                disabled
                className={classes.select}
                sx={{ marginLeft: "10px", minWidth: "300px!important" }}
              >
                <MenuItem disabled />
              </Select>
            </div>
          )}
          <RoundedButton
            name="btnDownload"
            text="Download All"
            onClick={downloadAllButtonClickHandler}
            disabled={recordsFound ? false : true}
            className={classes.downloadButton}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.tableContainer}>
          <PerformanceValidationTable
            activityData={
              selectedActivity ||
              (earliestActivity && earliestActivity[0]?.activityId)
            }
            recordsFound={setRecordsFound}
          />
        </div>
      </Grid>
      <WaitIndicator ref={waitIndicatorLoaderRef} show={false} />
    </Grid>
  );
}
