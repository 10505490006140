import React, { createContext, useEffect } from "react";
import {
  getEQUALUserInfoFromStorage,
  validateToken,
} from "../helpers/api-util";
import NewApp from "../NewApp";

export const promiseContext = createContext<PromiseType | null>(null);
export default function PromiseContextProvider(props: any) {
  const [promiseObject, setPromiseObject] = React.useState<string[]>([]);

  useEffect(() => {
    validateToken();
  }, []);

  const eQualUser_Info: IEQUALTokenInfo = getEQUALUserInfoFromStorage();

  return (
    <div>
      {
        <>
          <promiseContext.Provider value={{ promiseObject, setPromiseObject }}>
            {props.children}
            <NewApp userInfo={eQualUser_Info} />
          </promiseContext.Provider>
        </>
      }
    </div>
  );
}
