import React from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
  SurveyElementBase,
} from "survey-react-ui";
import DynamicTag from "../../../../atoms/DynamicTag";

const CUSTOM_HEADINGQUESTION_TYPE = "Heading";

class HeadingHtmlModel extends Question {
  public constructor(header = "") {
    super(header);
    this.titleLocation = "hidden";
    this.createLocalizableString("header", this);
    this.header = header;
  }
  public getType(): string {
    return CUSTOM_HEADINGQUESTION_TYPE;
  }
  public get headingType(): string {
    return this.getPropertyValue("headingType");
  }
  public set headingType(val: string) {
    this.setPropertyValue("headingType", val);
  }
  public get header(): string {
    return this.getLocalizableStringText("header");
  }
  public set header(val: string) {
    this.setLocalizableStringText("header", val);
  }
  public get locHeader(): any {
    return this.getLocalizableString("header");
  }
}

export function registerHeadingQuestion() {
  ElementFactory.Instance.registerElement(
    CUSTOM_HEADINGQUESTION_TYPE,
    (name) => {
      return new HeadingHtmlModel(name);
    }
  );
}

Serializer.addClass(
  CUSTOM_HEADINGQUESTION_TYPE,
  [
    {
      name: "header",
      category: "general",
      visibleIndex: 2,
      serializationProperty: "locHeader",
    },
    {
      name: "headingType",
      category: "general",
      visibleIndex: 3,
      default: "h2",
      choices: ["h2", "h3", "h4"],
    },
  ],
  function () {
    return new HeadingHtmlModel("");
  },
  "question"
);

class SurveyQuestionHeading extends SurveyQuestionElementBase {
  public constructor(props: any) {
    super(props);
    this.question.hideNumber = true;
    this.question.titleLocation = "hidden";
    this.state = { value: this.question.value };
  }

  protected get question(): HeadingHtmlModel {
    return this.questionBase as HeadingHtmlModel;
  }

  public renderElement(): JSX.Element {
    const HeaderElement = this.question.headingType;

    if (this.question.isDesignMode) {
      return SurveyElementBase.renderLocString(this.question.locHeader);
    } else {
      return (
        <DynamicTag tagName={HeaderElement}>
          {SurveyElementBase.renderLocString(this.question.locHeader, {
            maxWidth: "100%",
            display: "block",
            whiteSpace: "pre-wrap",
          })}
        </DynamicTag>
      );
    }
  }
}

ReactQuestionFactory.Instance.registerQuestion(
  CUSTOM_HEADINGQUESTION_TYPE,
  (props) => {
    return React.createElement(SurveyQuestionHeading, props);
  }
);
