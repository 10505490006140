import { Grid } from "@mui/material";
import LabelTextField from "../atoms/formElements/LabelTextField";
import React from "react";
import LabelSelect from "../atoms/formElements/LabelSelect";

interface SiteFormProps {
    style?: React.CSSProperties;
    formData?: any;
    setFormData ?: any;
}
const SiteForm: React.FC<SiteFormProps> = ({
    style,
    formData,
    setFormData
}) => {

    const [PhysicianAssigndisplay, setPhysicianAssigndisplay] =
        React.useState("none");
    // const appCountryData = useAppData(async () => {
    //     return await getCountries();
    // }, false);
    // const appStateData = useAppData(async () => {
    //     return await getStates();
    // }, false);
    const isEDPartOfLargeGroupOptions = [

        { id: "true", name: "Yes" },
        { id: "false", name: "No" },

    ];

   

    const handleChange = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData((values: any) => ({ ...values, [name]: value }));
        if (name === "isEDPartOfLargeGroup") {
            if (event.target.value === "Yes") {
                setPhysicianAssigndisplay("block");
            }
            else {
                setPhysicianAssigndisplay("none");
                setFormData((values: any) => ({ ...values, physicianGroupName: "" }));
            }
        }
        
    };

    const inputChangeHandler = (      
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData((values: any) => ({ ...values, [name]: value }));       
    };

    return (
        <Grid
            container
            style={{ marginTop: 20, ...style }}
        >
            <Grid
                item
                xs={12}
                sm={12}
                style={{ marginTop: 20 }}
            >
                <LabelTextField
                    displayName="Site Name"
                    name="siteName"
                    onTextChange={(e) => inputChangeHandler(e)}
                    value={formData.siteName}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                style={{ marginTop: 20 }}
            >
                <LabelSelect
                    label="Is your ED part of a larger group?"
                    name="isEDPartOfLargeGroup"
                    value={formData.isEDPartOfLargeGroup}
                    onChange={handleChange}
                    options={isEDPartOfLargeGroupOptions}
                    inputStyle={{borderRadius:20}}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                style={{ marginTop: 20, display: PhysicianAssigndisplay }}
            >
                <LabelTextField
                    displayName=" What is the name of the group that your ED site is associated with?"
                    name="physicianGroupName"
                    onTextChange={(e) => inputChangeHandler(e)}                  
                    value={formData.physicianGroupName} 
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                style={{ marginTop: 20 }}
            >
                <LabelTextField
                    displayName="Country"
                    name="country"
                    onTextChange={(e) => inputChangeHandler(e)}
                    value={formData.country}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                style={{ marginTop: 20 }}
            >
                <LabelTextField
                    displayName="Address Line 1:"
                    name="addressLine1"
                    onTextChange={(e) => inputChangeHandler(e)}
                    value={formData.addressLine1}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                style={{ marginTop: 20 }}
            >
                <LabelTextField
                    displayName="Address Line 2:"
                    name="addressLine2"
                    onTextChange={(e) => inputChangeHandler(e)}
                    value={formData.addressLine2}
                />
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                style={{ marginTop: 20 }}
            >
                <LabelTextField
                    displayName="City"
                    name="city"
                    onTextChange={(e) => inputChangeHandler(e)}
                    value={formData.city}
                />
            </Grid>
            <Grid
                container              
                style={{ marginTop: 20 }}
            >
                <Grid
                    item
                    xs={5.75}
                    sm={5.75}
                >
                    <LabelTextField
                        name="state"
                        displayName="State/Province"
                        onTextChange={(e) => inputChangeHandler(e)}
                        value={formData.state}
                    />
                </Grid>
                <Grid
                    item
                    xs={0.5}
                    sm={0.5}
                ></Grid>
                <Grid
                    item
                    xs={5.75}
                    sm={5.75}
                >
                    <LabelTextField
                        displayName="Zip/Postal Code"
                        name="zipCode"
                        onTextChange={(e) => inputChangeHandler(e)}
                        value={formData.zipCode}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
export default SiteForm;